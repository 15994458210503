import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilityService} from "../../utility.service";

@Component({
  selector: 'app-timeslots-input',
  templateUrl: './timeslots-input.component.html',
  styleUrls: ['./timeslots-input.component.styl']
})
export class TimeslotsInputComponent implements OnInit {

    options = [];
    timeslots = [];

    @Input('value') value: string;
    @Input('isMultiple') isMultiple: boolean = false;
    @Output('valueChange') valueChange = new EventEmitter<string>();

  constructor(public _util: UtilityService) { }

  ngOnInit(): void {
      this.fillOptions();

      if (this.timeslots.length === 0) {
          this.timeslots.push({
              start: 1000,
              end: 2000
          });
      }

      if (this.value) this.timeslots = this._util.formattedTimeToTimeslots(this.value);
  }

    fillOptions() {
        for ( let i = 4; i < 24; i++) {
            const ampm = i < 12 ? 'AM' : 'PM';

            for ( let j = 0; j < 60; j += 15) {
                this.options.push({
                    label: this._util.twoDigits( i < 13 ? i : i - 12 )
                        + ':' + this._util.twoDigits('' + j) + ' ' + ampm,
                    value: i * 100 + j
                });
            }
        }

    }

    valueChanged() {
        this.valueChange.emit(this._util.formatTimeslotsToFormattedTime(this.timeslots));
    }

    addTimeslot() {
      this.timeslots.push({
            start: 1915,
            end: 1930
      })
    }

}
