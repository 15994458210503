<div>
    
<div class="card show-on-mobile">
            <div class="card-body">
                    <div class="card-title">
                        <div class="d-flex flex-row" (click)="selectTest(selectedTest)">
                            <input type="checkbox" name="{{ selectedTest['Gcode'] + selectedTest['Scode'] + selectedTest['TestCode'] }}" [checked]="tableType === 'selected'" *ngIf="showCheckInput">
                            &nbsp;&nbsp;
                            <h4>{{selectedTest['Description'] || ''}}</h4>
                        </div>
                    </div>
                    <div class="card-text">
                        TAT - {{selectedTest['ConsolidatedTATTo'] || ''}}&nbsp;days
                    </div>
                </div>
                <div class="list-group list-group-flush">
                    <li class="list-group-item">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th class="col-4">MRP</th>
                                    <th class="col-4">Standard</th>
                                    <th class="col-4">Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        ₹&nbsp;{{selectedTest['MRP']}}   
                                    </td>
                                    <td>
                                        <span>₹&nbsp;</span>
                                        {{selectedTest['StdPrice']}}
                                        &nbsp;
                                    </td>
                                    <td [class.black-thin-border] ="editable">
                                        <span>₹&nbsp;</span>
                            <input type="text" name="{{ selectedTest['Gcode'] + selectedTest['Scode'] + selectedTest['TestCode'] }}-final-price" required [(ngModel)]="selectedTest['FinalPrice']" (click)="$event.target.select()" class="w-75" (change)="valueChanged(selectedTest)" [disabled]="!editable">
                            &nbsp;   
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>-{{discountPercentage('standard', selectedTest)}}%</td>
                                    <td>-{{discountPercentage('final', selectedTest)}}%</td>
                                </tr>
                            </tbody>    
                        </table>
                    </li>      
                </div>
                </div>

<tr scope="row" class="d-flex hide-on-mobile">
                    <td scope="col" class="col-1" (click)="selectTest(selectedTest)">
                        <div class="d-flex flex-row">
                            {{(index + 1) + "."}} &nbsp;
                            <input type="checkbox" name="{{ selectedTest['Gcode'] + selectedTest['Scode'] + selectedTest['TestCode'] }}" [checked]="tableType === 'selected'" *ngIf="showCheckInput">
                        </div>
                    </td>
                    <td scope="col" class="col-3" (click)="selectTest(selectedTest)">
                        {{selectedTest['Description'] || []}}
                    </td>
                    <td scope="col" class="col-1">
                        {{selectedTest['ConsolidatedTATTo']|| ''}}&nbsp;days
                    </td>
                    <td scope="col" class="col-1">
                        ₹&nbsp;{{selectedTest['MRP']||''}}
                    </td>
                    <td scope="col" class="col-1">
                        <div class="d-flex flex-row">
                            <span>₹&nbsp;</span>
                            {{selectedTest['StdPrice']}}
                            &nbsp;        
                        </div>
                    </td>
                    <td scope="col" class="col-1">
                        <div class="d-flex flex-row" [class.black-thin-border] ="editable">
                            <span>₹&nbsp;</span>
                            <input type="text" name="{{ selectedTest['Gcode'] + selectedTest['Scode'] + selectedTest['TestCode'] }}-final-price" required [(ngModel)]="selectedTest['FinalPrice']" (click)="$event.target.select()" class="w-75" (change)="valueChanged(selectedTest)" [disabled]="!editable" >
                            &nbsp;
                        </div>
                    </td>
                    <td scope="col" class="col-2">
                        {{discountPercentage('standard', selectedTest)}}%
                    </td>
                    <td scope="col" class="col-2">{{discountPercentage('final', selectedTest)}}%</td>
                </tr>
                
</div>
