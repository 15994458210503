import {Component, OnInit} from '@angular/core';
import {UtilityService} from "../utility.service";
import {RestService} from "../rest.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-patch-requests-management',
  templateUrl: './patch-requests-management.component.html',
  styleUrls: ['./patch-requests-management.component.styl']
})
export class PatchRequestsManagementComponent implements OnInit {

    pagination = {
        page: 1,
        lastPage: 1,
        perPage: 20,
        total: "",
    };

    filter = {
        hoName: "",
        requestType: "",
        requestedBy: "",
        customerDoctorName: "",
    }
    stats = {
        pending: {
            customers: 0,
            doctors: 0,
            unlinkDoctor: 0,
        }
    }

    uniqueUsers = [];
    uniqueHOLocations = [];

    editing = false;
    loading = false;
    currentRequest = null;
    operatorRangedSheetFrom = "";
    operatorRangedSheetTo   = "";

    FieldLabels = {};
    DoctorFieldLabels = {};
    CustomerFieldLabels = {};

    BooleanFields = [];
    DatetimeFields = [];
    DateFields = [];
    SelectionBoxes = [];
    SemicolonSeperated = [];
    NonLabmateFields = [];

    patchRequests = [];
    updatedColumns = [];
    updatedLabmateColumns = [];
    updatedNonLabmateColumns = [];

    acceptedColumns = [];
    rejectedColumns = [];
    workingObject = {};
    workingObjectBackup = {};
    SelectionBoxLabels = [];
    SelectionBoxOptions = {};
    showingDownloadOperatorReport = false;
    ShouldShowNonLabmateFieldsCheckbox = false;
    NonLabmateFieldsCheckboxChecked = false;
    isOperator = false;

    sortedColumn = "created_at";
    sortedColumnReverse = true;
    isSalesExecutive = false;


  constructor(public _util: UtilityService, public _rest: RestService) { }

  ngOnInit(): void {

      if ( ! this._util.canUser(this._util.Constants.Permissions.AllowPatchRequest))
          this._util.redirectAccordingly();

      (JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user) || '{}').permissions || [])
          .forEach(permission => {
              if ( permission === this._util.Constants.Permissions.ApproveStage2)
                  this.isOperator = true;
          });

      this.refreshListing();
      this.initialize();

      this.isSalesExecutive = this._util.canUser(this._util.Constants.Permissions.JarvisLogin) &&
          ! this._util.canUser(this._util.Constants.Permissions.ManageUsers);
  }

  async initialize() {

      const resp = await this._rest.getSalesConstants();
      if ( ! resp['success']) return;

      this.FieldLabels = resp['labels'] || {};
      this.DoctorFieldLabels = resp['doctorLabels'] || {};
      this.CustomerFieldLabels = resp['customerLabels'] || {};

      this.BooleanFields = resp['booleans'] || [];
      this.DatetimeFields = resp['dateTimes'] || [];
      this.DateFields = resp['dates'] || [];
      this.SelectionBoxes = resp['selectedBoxes'] || [];
      this.SemicolonSeperated = resp['semicolonSeperated'] || [];
      this.NonLabmateFields = (resp['nonLabmateFields'] || [])
          .filter(row => ["ReportEmailDeliveryMode", "ReportsPrintedAt"].indexOf(row) === -1);

      this.SelectionBoxLabels = this.SelectionBoxes.map(row => row.name);
      this.SelectionBoxes.forEach(row => {
          this.SelectionBoxOptions[row.name] = row.options;
      });
  }

  async loadListing(page) {
      this.pagination.page = page;
      await this.refreshListing();
  }

  changeSortedColumn(column) {
      if ( column === this.sortedColumn) {
          this.sortedColumnReverse = ! this.sortedColumnReverse;
      }

      this.sortedColumn = column;
      return this.refreshListing();
  }

    getValueForField(fieldName, isCurrent = false) {
        const fieldValue = isCurrent ? this.workingObject[fieldName] : this.workingObjectBackup[fieldName];

        if ( this.BooleanFields.indexOf(fieldName) > -1 )
            return ["0", 0, false, null, undefined].indexOf(fieldValue) > -1 ? "No" : "Yes";

        if ( this.DatetimeFields.indexOf(fieldName) > -1)
            return this._util.showDateLocally(fieldValue);

        if ( this.DateFields.indexOf(fieldName) > -1)
            return this._util.niceDateFormat(fieldValue);

        if ( this.SelectionBoxLabels.indexOf(fieldName) > -1) {
            return this.SelectionBoxOptions[fieldName][ fieldValue ] || "";
        }

        if ( this.SemicolonSeperated.indexOf(fieldName) > -1) {
            return (fieldValue || "").split(";").join("\n");
        }

        return isCurrent
            ? this._util.undefinedOrEmpty(this.workingObject[fieldName])
            : this._util.undefinedOrEmpty(this.workingObjectBackup[fieldName]);
    }

    emptyAllVars() {
        this.updatedColumns = [];
        this.updatedLabmateColumns = [];
        this.updatedNonLabmateColumns = [];
        this.acceptedColumns = [];
        this.rejectedColumns = [];
        this.NonLabmateFieldsCheckboxChecked = false;
    }

    viewFullRequest(request) {
      this.currentRequest = request;

        this.emptyAllVars();

        switch (request.type) {
            case "customer":
                this.FieldLabels = this.CustomerFieldLabels;
                break;
            case "doctor":
                this.FieldLabels = this.DoctorFieldLabels;
                break;
            default: break;
        }


        request.orig["ReportEmailDeliveryMode"] = this._util.convertValueTo10(request.orig["EmailReport"]) + "-" + this._util.convertValueTo10(request.orig["SendEmailWHeader"]);
        request.orig["ReportsPrintedAt"] = this._util.convertValueTo10(request.orig["DefaultCenterReport"]) + "-" + this._util.convertValueTo10(request.orig["RepPrinterAtCenWHeader"]);

      this.workingObjectBackup = { ... request.orig };
      this.workingObject = { ... this.workingObjectBackup };
      for (let key in request.data ) {
          this.workingObject[key] = request.data[key];

          this.updatedColumns.push(key);
          if ( this.NonLabmateFields.indexOf(key) > -1) this.updatedNonLabmateColumns.push(key);
          else this.updatedLabmateColumns.push(key);
      }

        this.editing = true;

      this.ShouldShowNonLabmateFieldsCheckbox = !! this.NonLabmateFields
          .filter(field => this.updatedColumns.indexOf(field) > -1)
          .length;
    }

    async acceptSelected() {
      const frontendValues = {};
      for ( let key in this.currentRequest.data) {
          frontendValues[key] = this.getValueForField(key, true);
      }

        await this._rest.updatePatchRequestColumns(this.currentRequest.id, this.acceptedColumns, frontendValues);

        return this.acceptRequest();
    }

    async acceptRemaining() {
        this.acceptedColumns = this.updatedColumns.filter(columnName => this.rejectedColumns.indexOf(columnName) === -1);
        this.rejectedColumns = [];

        return this.acceptSelected();
    }

    rejectField(field) {
        if ( this.acceptedColumns.indexOf(field) > -1)
            this.acceptedColumns.splice(this.acceptedColumns.indexOf(field), 1);

        if ( this.rejectedColumns.indexOf(field) === -1)
            this.rejectedColumns.push(field);
    }

    acceptField (field) {
        if ( this.rejectedColumns.indexOf(field) > -1)
            this.rejectedColumns.splice(this.rejectedColumns.indexOf(field, 1));

        if ( this.acceptedColumns.indexOf(field) === -1)
            this.acceptedColumns.push(field);
    }

  async refreshListing() {
      this.loading = true;

      const resp = await this._rest.listPatchRequest2(this.pagination.page, {
          "sort": this.sortedColumn,
          "order": this.sortedColumnReverse ? "desc" : "asc",
          ... this.filter,
      });

      // const resp = await this._rest.listPatchRequests(this.pagination.page, this.sortedColumn, this.sortedColumnReverse ? "desc" : "asc");
      if ( ! resp['success'] && resp['message'])
          return this._util.showAlert("Oops!", resp['message']);

      this.loading = false;
      this.patchRequests = resp['patchRequests']["data"];
      this.pagination.page = resp['patchRequests']["page"];
      this.pagination.lastPage = resp['patchRequests']["lastPage"];
      this.pagination.perPage = resp['patchRequests']["perPage"];
      this.pagination.total = resp['patchRequests']["total"];

      this.uniqueHOLocations = resp['uniqueHOLocations'] || [];
      this.uniqueUsers = resp['uniqueUsers'] || [];
      if ( resp['stats'] && Object.keys(resp['stats']).length) {
          this.stats = resp['stats'];
      }
  }

    async acceptRequest() {
      if ( this.currentRequest && (this.currentRequest.status === 'stage-3') && this.ShouldShowNonLabmateFieldsCheckbox && ! this.NonLabmateFieldsCheckboxChecked)
          return alert("Please copy all Non-Labmate fields for your reference, and check the checkbox for the same.");

        if ( ! confirm("Are you sure, you want to accept? changes"))
            return;

        await Swal.fire({
            title: `Accepting request ..`,
            text: 'please wait it may take a while',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();

                const resp = await this._rest.acceptPatchRequest(this.currentRequest.id);
                if ( ! resp['success'] && resp['message'])
                    return this._util.showAlert(
                        resp['description'] ? resp['message'] : 'Server resp', 
                        resp['description'] || resp['message']
                    );

                this.editing = false;

                setTimeout(() => {
                    // this.refreshListing();

                    this.patchRequests = this.patchRequests.filter(patchRequest => patchRequest.id !== this.currentRequest.id);
                }, 10);

                Swal.close();
            }
        });
    }

    async rejectAll() {
        if ( ! confirm("Are you sure, you want to delete this request?"))
            return;

        await Swal.fire({
            title: `Rejecting request ..`,
            text: 'please wait it may take a while',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();

                const resp = await this._rest.rejectPatchRequest(this.currentRequest.id);
                if ( ! resp['success'] && resp['message'])
                    return this._util.showAlert('Server resp', resp['message']);

                this.editing = false;

                setTimeout(() => {
                    this.refreshListing();
                }, 1000);

                Swal.close();
            }
        });


    }

  async goBackToListing() {
      this.editing = false;
      this.workingObject = {};
      this.workingObjectBackup = {};
  }

    toggleNonLabmateFieldCheckbox($event) {
        $event.stopPropagation();
        this.NonLabmateFieldsCheckboxChecked = ! this.NonLabmateFieldsCheckboxChecked;
    }

    async downloadOperatorRangedSheet() {
      console.log('this.downloadOperatorRangedSheet() called: ', this.operatorRangedSheetFrom, this.operatorRangedSheetTo,
          typeof this.operatorRangedSheetFrom, typeof this.operatorRangedSheetTo);

      if ( ! this.operatorRangedSheetFrom)
          return this._util.showAlert("Missing required field", "Please select 'From' date from left side input");

      const resp = await this._rest.downloadOperatorLogSheet(this.operatorRangedSheetFrom, this.operatorRangedSheetTo || "");
      if ( ! resp['success'] && resp['message']) return this._util.showAlert('Server resp', resp['message']);

        this._util.saveByteArray("Operator report.xls", this._util.base64ToArrayBuffer(resp['buffer']));

        this.showingDownloadOperatorReport = false;
    }

    async applyFilters() {
      console.log("aplying filters: ", this.filter);

      this.pagination.page = 1;
      await this.refreshListing();
    }

}
