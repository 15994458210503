import { Component, OnInit, Input, Output } from '@angular/core';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import {EventService} from '../event.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-test-detail-row',
  templateUrl: './test-detail-row.component.html',
  styleUrls: ['./test-detail-row.component.styl']
})
export class TestDetailRowComponent implements OnInit {

  @Input('selectedTest') selectedTest: object;
  @Input('isTestSelected') isTestSelected: boolean;
  @Input('editable') editable: boolean;
  @Input('tableType') tableType: string;
  @Input('index') index: number;
  @Input('showCheckInput') showCheckInput: boolean;

  constructor(private _rest: RestService, public _util: UtilityService, public _event: EventService) { }

  ngOnInit(): void {
  }

  selectTest(test) {
        let eventType = (this.tableType === 'remaining') ? 'testSelected': 'testDeselected';
        console.log('select', test, eventType);
        this._event.tableRow_selectedTestEmitter.emit({test, event:eventType});
  }

  valueChanged(test){
        this._event.tableRow_valueChangeEmitter.emit({test, event:'testValueChange'});
  }

  discountPercentage(type, test){
        switch(type){
            case 'standard':
                if(((test['MRP'] || 0) - (test['StdPrice'] || 0)) > 0)
                    return (((test.MRP - test.StdPrice)/test.MRP) * 100).toFixed(2);
                return 0.00;

            case 'final':
                if(((test['MRP']|| 0) - (test['FinalPrice']||0)) > 0)
                    return (((test.MRP - test.FinalPrice)/test.MRP) * 100).toFixed(2); 
                return 0.00;
        }
  }

}
