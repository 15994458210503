import { Component, OnInit } from '@angular/core';
import { UtilityService } from "../utility.service";
import { RestService } from "../rest.service";
import Swal from 'sweetalert2';
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-payment-receipt-dashboard',
    templateUrl: './payment-receipt-dashboard.component.html',
    styleUrls: ['./payment-receipt-dashboard.component.styl']
})
export class PaymentReceiptDashboardComponent implements OnInit {

    userName: string = "";
    form = {
        filter: "",
        filterType: "",
        selectedFilterOption:"",
        paymentMethodFilter: "",
        paymentLabmateStatusFilter: "",
        page: 1,
        search: "",
        lastPage: 1,
        reSyncing: 0,
        changingBarcode: 0,
        verifying: 0,
        markingReceipt: 0,
        filterDateStart: "",
        filterDateEnd: "",
        barcode: "",
    }

    filterData = {
        filterSearchKey: "",
        filterOptions : [],
        selectedFilterOptions: [],
        filteredFilterOptions: [],
    }

    receiptRemarks = "";

    showTotal = false;
    totalRecordsAmount = 0;
    currentRecord = {};
    records = [];

    constructor ( public _util: UtilityService, public _rest: RestService, private _ActivatedRoute: ActivatedRoute ) {
    }


    ngOnInit (): void {
        const userInfo = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'))
        this.userName = (userInfo || {}).username;

        this.form.search = this._ActivatedRoute.snapshot.paramMap.get('barcode') || "";

        this.refreshListing().then();
    }

    async refreshListingWthPage ( page ) {
        this.form.page = page;
        await this.refreshListing();
    }

    async reSyncRow ( record ) {
        this.form.reSyncing = record.id;

        const resp = await this._rest.reSyncPaymentReceipts(record.id);

        this.form.reSyncing = 0;

        if ( this._util.handleFailureResponse(resp) ) return;

        this.updateRecord(record, resp['record']);
    }

    async updateBarcode ( record ) {
        this.form.changingBarcode = record.id;

        await Swal.fire({
            title: 'Enter barcode',
            html: 'This will break the connection with Payment DB. Are you sure?',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Update & Re-sync',
            showLoaderOnConfirm: true,
            preConfirm: ( barcode ) => {

                return new Promise(( resolve, reject ) => {
                    this._rest.updatePaymentBarcode(record.id, barcode).then(resp => {
                        if ( this._util.handleFailureResponse(resp) ) return reject(resp['message']);

                        this.updateRecord(record, resp['record']);

                        resolve();
                    });
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        });

        this.form.changingBarcode = 0;


        return this.reSyncRow(record);
    }

    //to detect if any of the filters are applied
    filterApplied(){
        if(this.form.filter.length || this.form.paymentMethodFilter.length || this.form.paymentLabmateStatusFilter.length || this.form.filterDateStart.length
            || this.form.filterDateEnd.length || this.form.search.length || this.form.selectedFilterOption.length){
            return true;
        }
        return false;
    }

    async markReceiptConfirmation ( record ) {
        const swalResult = await Swal.fire({
            title: 'Mark receipt',
            html: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Mark',
            showLoaderOnConfirm: true,
        });

        if ( !swalResult.isConfirmed ) return;

        await this.markReceipt(record);
    }

    async markReceipt ( record ) {
        this.form.markingReceipt = record.id;

        const resp = await this._rest.markPaymentReceipt(record.id);

        this.form.markingReceipt = 0;

        if ( this._util.handleFailureResponse(resp) ) return;

        this.updateRecord(record, resp['record']);

        if ( resp['success'] && resp['labmateResp']['status'] ) {
            await this._util.showAlert('Success', 'Receipt marked successfully', 'success');
        }
    }

    async resetFilter(){
        this.form.paymentMethodFilter = "";
        this.form.paymentLabmateStatusFilter = "";

        this.refreshListing();
    }

    openRecieptRemarkModal(record){
        this.currentRecord = record;
        this._util.modal('receipt-remark-modal', 'show');
    }

    closeReceiptRemarkModal(){
        this._util.modal('receipt-remark-modal', 'hide');
        this.receiptRemarks = "";
    }

    async verifyPaymentDeposited ( record = {} ) {
        if(!Object.keys(record).length){
            record = this.currentRecord;
        }

        this.form.verifying = record['id'];

        if(this.receiptRemarks.length && record['id']){
            const resp = await this._rest.verifyPaymentReceipts(record['id'], this.receiptRemarks);
            this.closeReceiptRemarkModal();
            this.form.verifying = 0;

            if ( this._util.handleFailureResponse(resp) ) return;

            this.updateRecord(record, resp['record']);
        }

        else{
            Swal.fire('Please enter the Remarks for this transaction before proceedin to generate the receipt.', 'error');
        }
    }

    private updateRecord ( record, updated ) {
        this.records = this.records.map(r => {
            if ( r.id === record.id ) {
                return updated;
            }

            return r;
        });
    }

    async toggleFilter(fieldType, value){
        this.form[fieldType] = value;
        await this.refreshListing();
    }

    async refreshListing () {
        this.showTotal = false;
        this.totalRecordsAmount = 0;

        const resp = await this._rest.getPaymentReceipts(this.form);

        if ( !resp['success'] && resp['message'] ) {
            return this._util.showAlert('Server error', resp['message'], 'error');
        }

        this.records = resp['records'] || [];
        this.form.lastPage = resp['lastPage'] || 1;

        this.filterData.filterOptions = resp['filterData'];

        this.form.page = 1;

        if(this.form.filterType === 'deposite_id' && this.form.selectedFilterOption.length){
            this.records.forEach(record => {
                this.totalRecordsAmount = this.totalRecordsAmount + parseInt(record.meta.paymentDBRecord.amount);
                this.showTotal = true;
            });
        }

        this._util.initiateTooltips();
    }

    async downloadCollectionAppData(){
        const response = await this._rest.downloadCollectionAppData(this.form);
        const a = document.createElement("a");
        let bin = atob(decodeURIComponent(response['data']));
        let ab = this._util.s2ab(bin);
        let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        let object_URL = window.URL.createObjectURL(blob);
        a.href = object_URL;
        a.download = 'collection-app-data' + "_" + this._util.showDateLocally(this._util.getNow()) + ".xlsx";
        a.click();
    }

    setFilterType(){
        this.filterData.selectedFilterOptions = this.filterData.filterOptions[this.form.filterType];
    }

    resetColumnFilters(){;
        this.form.filter = '';
        this.form.filterType = '';
        this.form.selectedFilterOption = '';
        this.filterData.selectedFilterOptions = [];
        this.refreshListing();
    }

    async syncBrcodeWiseTransaction(){
        if(this.form.barcode.length){
            const resp = await this._rest.reSyncPaymentReceiptBarcodeWise(this.form.barcode);
            if(resp['success']){
                Swal.fire('Sync Successful', 'Synced all transactions related to this barcode', 'success');
                await this.refreshListing();
            }
        }
        else{
            return Swal.fire('Invalid Barcode','Please enter a valid barcdoe before proceeding', 'error');
        }
    }

}
