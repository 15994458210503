<div>
        <div class="list-group d-sm-none show-on-mobile">
              <app-test-detail-row *ngFor="let test of displayList" [selectedTest]="test" [editable]="editable" [tableType]="tableType" [index]="indexOfTest" [showCheckInput]="showCheckInput"></app-test-detail-row>  
        </div>
        <div class="table-responsive d-none d-sm-block hide-on-mobile">
            <table class="table table-sm min-width-900">
            <thead *ngIf="showHeader" class="thead-light">
                <tr scope="row" class="d-flex">
                    <th scope="col" class="col-1">S.No</th>
                    <th scope="col" class="col-3">Service/Test</th>
                    <th scope="col" class="col-1">TAT (days)</th>
                    <th scope="col" class="col-1">MRP (₹)</th>
                    <th scope="col" class="col-1">Std. Price To Hospital</th>
                    <th scope="col" class="col-1">Final Price To Hospital</th>
                    <th scope="col" class="col-2">Discount from Std. Price</th>
                    <th scope="col" class="col-2">Discount against MRP</th>
                </tr> 
            </thead>
            <tbody>
                <app-test-detail-row *ngFor="let test of displayList" [selectedTest]="test" [editable]="editable" [index]="displayList.indexOf(test)" [tableType]="tableType" 
                [showCheckInput]="showCheckInput"></app-test-detail-row>
            </tbody>
        </table>
        </div>
</div>