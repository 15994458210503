import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RestService} from '../rest.service';
import {UtilityService} from '../utility.service';
import {EventService} from '../event.service';
import {SignaturePadComponent} from '../signature-pad/signature-pad.component';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import Swal from "sweetalert2";
import html2canvas from "html2canvas";


@Component({
  selector: 'app-preview-mou',
  templateUrl: './preview-mou.component.html',
  styleUrls: ['./preview-mou.component.styl']
})
export class PreviewMouComponent implements OnInit {

  previewParams = {};
  pdfPreviewTitle = '';
  showWebViewer = false;
  showPdfViewer = false;
  signatureDrawn = '';
  signatureFontPreview = '';
  showSignatureOptions = false;
  customerContactPersonName = '';
  customerInstituteName = '';
  showSignaturePreview = false;
  selectedFontClass = "";
  currentCustomerId = 0;
  signatureFontPreviewSelected = false;

  previewFontList = [
    {
      'name':'Maissiel',
      'class': 'maissiel-preview',
    },
    {
      'name':'Midnight',
      'class': 'midnight-preview',
    },
    {
      'name':'Routerand',
      'class': 'routerand-preview',
    }
    ];

  @ViewChild('iFrame', {static: true}) iFrame: ElementRef;
  @ViewChild('pdfViewer', {static: true}) pdfViewer: ElementRef;
  @ViewChild('webViewer', {static: true}) webViewer: ElementRef;
  
  constructor(public _util: UtilityService, public _rest: RestService, public _event: EventService) { }

  ngOnInit(): void {
    this.previewParams = this._util.getQueryParameters();
    if ( Object.keys(this.previewParams).length) this.initializePreview();

    console.log("this.previewParams: ", this.previewParams);

    const user = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));
    this._event.signaturePad_signEmitter.subscribe((event) => {
      this.getSignatures(event)
    })
  }

  initializePreview() {
      let query = [];

      for ( let key in this.previewParams)
          query.push(`${key}=${this.previewParams[key]}`);

      this.currentCustomerId = this.previewParams['customerId'];

    this.iFrame.nativeElement.src = window.location.origin + "/api/customer-creations/view-mou?"
        + `token=${this._util.getToken()}&`
        + query.join('&');

    //this.refreshAuthenticatedStatus();
  }

  getSignatures(event){
    this.signatureDrawn = event;
  }

  proceedToSignature(){
    if(!this.customerContactPersonName.length || !this.customerInstituteName.length){
      Swal.fire('Name not filled', 'Please fill your name and institute name before proceeding', 'error')
    }
    else{
      this.showSignatureOptions = true;
    }
  }

  async uploadSignatures(){
    let uploadFile = {};

    let dataURI = (this.signatureDrawn.length)? this.signatureDrawn: this.signatureFontPreview;

    let blob = this.dataURItoBlob(dataURI);

    const formData = new FormData();

    formData.append('signatureImage', blob);
    formData.append('customerId', this.currentCustomerId.toString());
    formData.append('signatureName', this.customerContactPersonName);
    formData.append('purpose', 'signature-image');

    await Swal.fire({
            title: `Uploading Signatures ..`,
            text: 'should not take much time',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();
                
                await this._rest.uploadCustomerSignatures(formData).then(function(response){
                  Swal.close();
                  window.close();
                })
                
            }
        });
  }

  showPreview(font){
    this.selectedFontClass = font.class;
    console.log('selected font:', this.selectedFontClass, font);
    this.showSignaturePreview = true;
  }

  saveSignatureFontPreview(){
    var targetEl = document.getElementById("signature-font-preview")
    html2canvas(targetEl, {allowTaint: true}).then((canvas) => {
      this.signatureFontPreview = canvas.toDataURL();
    });
    this.signatureFontPreviewSelected = true;
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }

}
