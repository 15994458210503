import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilityService} from "../../utility.service";

@Component({
  selector: 'app-time-hours-input',
  templateUrl: './time-hours-input.component.html',
  styleUrls: ['./time-hours-input.component.styl']
})
export class TimeHoursInputComponent implements OnInit {

  options = [];
    timehours = 0;

    @Input('value') value: string;
    @Input('isTimeHours') isTimeHours: boolean = false;
    @Output('valueChange') valueChange = new EventEmitter<string>();

  constructor(public _util: UtilityService) { }

  ngOnInit(): void {
      this.fillOptions();

      this.timehours = 2000;
      //if (this.value) timehours = this._util.formattedTimeToTimeslots(this.value, this.isTimeHours);
  }

    fillOptions() {
        for ( let i = 4; i < 24; i++) {
            const ampm = i < 12 ? 'AM' : 'PM';

            for ( let j = 0; j < 60; j += 15) {
                this.options.push({
                    label: this._util.twoDigits( i < 13 ? i : i - 12 )
                        + ':' + this._util.twoDigits('' + j) + ' ' + ampm,
                    value: i * 100 + j
                });
            }
        }

    }

    valueChanged() {
        this.valueChange.emit(this._util.formatTimeslotsToFormattedTime(this.timehours, this.isTimeHours));
    }

}
