import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { UtilityService } from '../utility.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-report-dropbox',
  templateUrl: './report-dropbox.component.html',
  styleUrls: ['./report-dropbox.component.styl']
})

export class ReportDropboxComponent implements OnInit {

  private readonly notifier: NotifierService;

  reportFilesWithHeader: File[] = [];
  reportFilesWithoutHeader: File[] = [];
  shouldSendEmail = true;
  shouldSendSms = true;

  mailingQueue = [];
  processingInterval = null;
  processing = false;

  constructor(public _util: UtilityService, private _rest: RestService, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {

    this._util.redirectAccordingly();
    this.setupProcessingInterval();
  }

  findFromQueue(barcode) {
    return this.mailingQueue.map(row => row.barcode).indexOf(barcode);
  }

  setupProcessingInterval() {
    this.processingInterval = setInterval(async () => {
      const date = (new Date);
      console.log("Starting to process: ", date, this.processing)

      if ( this.processing) return;

      this.processing = true;

      for ( let i = 0; i < this.mailingQueue.length; i ++) {
        const record = this.mailingQueue[i];

        if (record.deleted || ! record.known) continue;
        if (record.dueTime.getTime() > date.getTime()) continue;

        this.mailingQueue[i].status = "Processing ...";

        const resp = await this._rest.mailAttachmentsTestcode(
          record.barcode, record.testcode || '',
          record.attachments.map(row => row.id),
          this.shouldSendEmail, this.shouldSendSms
        );

        this.mailingQueue[i].status = resp['success'] ? "Successful" : (resp['paymentOnHold'] ? "Submitted" : "Failed");
        this.mailingQueue[i].reason = resp["message"];
        this.mailingQueue[i].deleted = true;
      }

      this.processing = false;

    }, 5000);
  }

  async fetchNotKnownRecords() {
    for ( let i = 0; i < this.mailingQueue.length; i ++) {
      const record = this.mailingQueue[i];
      if ( record.deleted || record.known) continue;

      const resp = await this._rest.getTestsListForPatient(record.patient, record.barcode);
      if ( ! resp["success"]) {
        this.notifier.notify("error", resp["message"] || "Server side error, removing entry");
        this.mailingQueue[i].deleted = true;
        this.mailingQueue[i].status  = "Invalid";
        continue;
      }

      const tests = resp["tests"].filter(row => row.code === record.testcode);
      const test  = tests.length ? tests[0] : null;

      if ( ! test) {

        this.notifier.notify("error", "Testcode not found in DB");
        this.mailingQueue[i].deleted = true;
        this.mailingQueue[i].status  = "Invalid";
        continue;
      }

      console.log("resp: ", resp);
      const info = {
        patientName: resp["patientData"].patientRow.name,
        testName: test.name,
        data: resp,
      }

      const dueTime = (new Date);
      dueTime.setMinutes(dueTime.getMinutes() + 2);;

      this.mailingQueue[i].dueTime = dueTime;
      this.mailingQueue[i].known   = true;
      this.mailingQueue[i].info    = info;
      this.mailingQueue[i].status  = "Waiting ...";
    }
  }

  parseFilename(filename) {
    const origName  = filename;
    const extension = this._util.extensionFromUrl(filename);

    filename = filename.split("_");
    if ( filename.length < 3) {
      this.notifier.notify('error', "Invalid filename detected: " + origName);
      return null;
    };

    return {
      patient: filename[0],
      barcode: filename[1],
      testcode: filename[2],
      extension,
    }
  }

  async selectedAttachmentForMail(event, header = true) {

    const addedFiles = event.addedFiles;

    for ( let i = 0; i < addedFiles.length; i ++) {
      const file = addedFiles[i];
      file['header'] = header;

      const parsedFilename = this.parseFilename(file.name);
      if ( ! parsedFilename) return;

      file['overrideFilename'] = 'report-' + parsedFilename.barcode + '-' + parsedFilename.testcode + "." + parsedFilename.extension;
      parsedFilename["header"] = header;

      if ( header) this.reportFilesWithHeader.push(file);
      else this.reportFilesWithoutHeader.push(file);

      console.log("file: ", file);

      const formData = new FormData();
      formData.append('attachment', file);
        formData.append('barcode', parsedFilename.barcode);
        formData.append('testcode', parsedFilename.testcode);
      formData.append('header', header ? "1" : "0");

      const resp = await this._rest.updateMailAttachments(formData);
      if ( ! resp["success"] && resp["message"]) {
        this._util.showAlert("Upload error", resp["message"]);
        continue;
      }

      const queueIndex = this.findFromQueue(parsedFilename.barcode)
      if ( queueIndex > -1) {
        this.mailingQueue[queueIndex].attachments.push(resp['attachment']);
      } else {
        this.mailingQueue.push({
          barcode: parsedFilename.barcode,
          patient: parsedFilename.patient,
          testcode: parsedFilename.testcode,
          attachments: [ resp['attachment'] ],
          status: "fetching ..."
        });
      }

      if ( header) this.reportFilesWithHeader.splice(this.reportFilesWithHeader.indexOf(file));
      else this.reportFilesWithoutHeader.splice(this.reportFilesWithoutHeader.indexOf(file));

    }

    this.fetchNotKnownRecords();
  }

  openAttachmentInNewTab($event, attachment) {
    $event.stopPropagation();

    window.open(attachment.last_url, "_blank");
  }

  removeMailingInfoAttachment($event, attachment) {
    $event.stopPropagation();

    this.mailingQueue = this.mailingQueue.map( row => {
      row.attachments = row.attachments.filter(col => col.id !== attachment.id);
      return row;
    }).filter(row => row.attachments.length);
  }

  // async sendAttachmentMail(email, sms, confirmed = null) {
  //   if ( ! this.mailingInfo.attachmentWithHeader.length && ! this.mailingInfo.attachmentWithHeader.length) {
  //     if ( confirmed === null ) {

  //       Swal.fire({
  //         title: 'No attachments?',
  //         text: "You want to send mail, without any reports!",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, I am sure!'
  //       }).then((result) => {
  //         this.sendAttachmentMail(email, sms, !! result.isConfirmed);
  //       });

  //       return;
  //     }

  //     if ( confirmed === false) return;
  //     if ( confirmed === true) {
  //       console.log("mark confirmed by user");
  //     }
  //   }

  //   this.mailingSample.customerAddress.loadingAttachedReport = "Sending ...";

  //   setTimeout(() => {
  //     eval('$("#mail-preview-modal").modal("hide")');
  //   }, 1);

  //   const resp = await this._rest.mailAttachments(
  //     this.mailingSample.barcode,
  //     this.mailingInfo.attachmentWithHeader.concat(this.mailingInfo.attachmentWithoutHeader).map(row => row.id),
  //     email, sms
  //   );

  //   this.mailingSample.customerAddress.loadingAttachedReport = "Mailed Successfully";

  //   if ( ! resp["success"] && resp["message"]) {
  //     return this._util.showAlert("Server error", resp["message"]);
  //   }

  //   this._util.showAlert("Success", "Sent successfully !!", "success");
  // }

}
