<div class="vertical-flex start-stretch fill-width">
    <div class="horizontal-flex start-center mt-2" >
        <select [name]="'timehours'" id="timehours" class="form-control mr-2"
                [(ngModel)]="timehours"
                (change)="valueChanged()">
        >
            <option *ngFor="let option of options" [value]="option.value"
                    [selected]="option.value === timehours"
                    (change)="timehours = $event.target.value"
            >{{ option.label }}</option>
        </select>
    </div>
</div>