<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <img src="/assets/images/lilac-logo.png" class="big-logo">
    </h5>
    <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
    <button type="button" class="btn btn-outline-danger"
            (click)="_util.logout()"
    >{{ _util.logoutButtonText || "Logout" }}</button>
</div>

<div class="container pb-3">
    <div class="row">

        <div class="col-md-6">
            <form action="" class="mt-2">
                <div class="form-group mb-0 horizontal-flex space-between-center">
                    <h5 style="flex: 1 auto;">Search by patient id or barcode? </h5>
                    <label class="radio-inline mb-0">
                        <input type="radio" name="search-by" value="patient"
                               [checked]="searchBy === 'patient'" (change)="changeSearchBy('patient')"
                        >&nbsp;Patient id
                    </label>
                    &nbsp;&nbsp;
                    <label class="radio-inline mb-0">
                        <input type="radio" name="search-by" value="barcode"
                               [checked]="searchBy === 'barcode'" (change)="changeSearchBy('barcode')"
                        >&nbsp;Barcode
                    </label>
                </div>

                <div class="form-group" *ngIf="(searchBy === 'patient') || patientid.length">
                    <label for="patientid" class="horizontal-flex space-between-center">
                        <span>Patient ID</span>
                        <div>
                            <button type="button" *ngIf="patientInfo" (click)="clearSearchForm()"
                                    class="btn btn-secondary btn-xs">clear
                            </button>
                            <button type="button"
                                    *ngIf="patientInfo && _util.canUser(_util.Constants.Permissions.SyncSamplesManually)"
                                    (click)="syncToCommandCenter()" class="btn btn-info btn-xs ml-2">Sync
                            </button>
                        </div>
                    </label>
                    <input type="text" class="form-control" id="patientid"
                           name="patientid"
                           [(ngModel)]="patientid"
                           (change)="tryToFillPatientBarcodeId()"
                           placeholder="">
                </div>

                <div class="form-group" [hidden]="sampleIdsOptions.length"
                     *ngIf="(searchBy === 'barcode') || barcodeid.length">
                    <label for="barcodeid" class="horizontal-flex space-between-center">
                        <span>Sample barcode ID</span>
                    </label>
                    <input type="text" class="form-control" id="barcodeid"
                           name="barcodeid"
                           [(ngModel)]="barcodeid"
                           (change)="tryToFillPatientBarcodeId()"
                           placeholder="">
                </div>

                <div class="form-group" *ngIf="sampleIdsOptions.length">
                    <label for="select-barcode">Select barcode ID</label>
                    <select class="form-control" id="select-barcode" name="barcodeselection"
                            [(ngModel)]="barcodeid"
                            (change)="tryToFillPatientBarcodeId()">
                        <option *ngFor="let barcode of sampleIdsOptions" [value]="barcode">{{ barcode }}</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="testCodes.length">
                    <label for="select-test">Select test</label>
                    <select class="form-control" id="select-test" name="testselection"
                            required="required"
                            [(ngModel)]="selectedTestCode">
                        <option value="">Select test</option>
                        <option *ngFor="let testCode of testCodes; let i = index"
                                [value]="testCode.code">{{ testCode.description }}</option>
                    </select>
                </div>

                <div class="form-group horizontal-flex space-between-center" *ngIf="patientInfo">
                    <h5 style="flex: 1 auto;">Headers? </h5>
                    <label class="radio-inline mb-0">
                        <input type="radio" name="headers" value="with"
                               [checked]="headers === 'with'" (change)="headers = 'with'"
                        >&nbsp;with
                    </label>
                    &nbsp;&nbsp;
                    <label class="radio-inline mb-0">
                        <input type="radio" name="headers" value="without"
                               [checked]="headers === 'without'" (change)="headers = 'without'"
                        >&nbsp;without
                    </label>
                </div>

                <button type="button" class="btn btn-secondary btn-lg btn-block" (click)="previewReport()"
                        *ngIf="testCodes.length">
                    Preview report
                </button>

                <button type="button" class="btn btn-secondary btn-lg btn-block" (click)="tryToFillPatientBarcodeId()"
                        *ngIf="!testCodes.length">
                    Search
                </button>

                <button type="button" class="btn btn-light btn-lg btn-block" (click)="mailReport()"
                        *ngIf="shouldBeAbleToPrint && _util.canUser(_util.Constants.Permissions.MailReport)"
                        [hidden]="true"
                >
                    Mail report
                </button>

                <div class="alert alert-danger mt-4" role="alert" *ngIf="errorMessageForPrintingReport">
                    {{ errorMessageForPrintingReport }}
                </div>

            </form>
        </div>

        <div class="col-md-6 vertical-flex start-stretch">

            <div *ngIf="patientInfo && patientInfo.name">
                <h3 class="horizontal-flex space-between-center">
                    <span>Patient details</span>

                    <button type="button" (click)="clearSearchForm()" class="btn btn-secondary">&times;</button>
                </h3>
                <h5>Name: {{ patientInfo.name }}</h5>
                <h5>DOB: {{ patientInfo.dob }}</h5>
                <h5>City: {{ patientInfo.city }}</h5>
                <h5>Gender: {{ patientInfo.gender }}</h5>
                <h5>Referred by: {{ patientInfo.referredBy }}</h5>
                <h5>Hospital: {{ patientInfo.customer }}</h5>
                <h5>Booking date: {{ _util.showDateLocally(patientInfo.bookingDate, true) }}</h5>
            </div>

            <div *ngIf="linkedPatientRow && linkedPatientRow.name" class="mt-4">
                <h3 class="horizontal-flex space-between-center">
                    <span>Linked Patient details</span>

                    <button type="button" (click)="unlinkPatient()" class="btn btn-sm btn-danger">
                        Un-link
                    </button>
                </h3>
                <h5>Name: {{ linkedPatientRow.name }}</h5>
                <h5>DOB: {{ linkedPatientRow.dob }}</h5>
                <h5>City: {{ linkedPatientRow.city }}</h5>
                <h5>Gender: {{ linkedPatientRow.gender }}</h5>
                <h5>Referred by: {{ linkedPatientRow.referredBy }}</h5>
                <h5>Hospital: {{ linkedPatientRow.customer }}</h5>
                <h5>Booking date: {{ _util.showDateLocally(linkedPatientRow.bookingDate, true) }}</h5>
            </div>

            <div
                *ngIf="patientInfo && patientInfo.name && _util.canUser(_util.Constants.Permissions.UnAuthenticateReport)"
                [hidden]="linkedPatientRow && linkedPatientRow.name" class="mt-4">
                <h4 class="horizontal-flex space-between-center">
                    <span>Enter Patient ID, that you want to link to above patient.</span>
                </h4>

                <div class="input-group mb-3 mt-2">
                    <input type="text" class="form-control"
                           [(ngModel)]="linkingPatientId"
                           (change)="tryToFindPatientHandler()"
                           (keyup)="tryToFindPatientHandler()"
                           placeholder="Patient ID" aria-label="Patient ID"
                           aria-describedby="link-patient-button">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="tryToFindPatientHandler()"
                                [hidden]="searchedPatientDetails && searchedPatientDetails['name']"
                                type="button" id="link-patient-search-button">Search patient
                        </button>
                        <button class="btn btn-primary" (click)="linkPatientId()"
                                *ngIf="searchedPatientDetails && searchedPatientDetails['name']"
                                type="button" id="link-patient-button">Link patient
                        </button>
                    </div>
                </div>

                <div *ngIf="searchedPatientDetails && searchedPatientDetails['name']" class="mt-4">
                    <h4 class="horizontal-flex space-between-center">
                        <span>Searched Patient details</span>
                        <br>
                    </h4>
                    <h5>Name: {{ searchedPatientDetails['name'] }}</h5>
                    <h5>DOB: {{ searchedPatientDetails['dob'] }}</h5>
                    <h5>City: {{ searchedPatientDetails['city'] }}</h5>
                    <h5>Gender: {{ searchedPatientDetails['gender'] }}</h5>
                    <h5>Referred by: {{ searchedPatientDetails['referredBy'] }}</h5>
                    <h5>Hospital: {{ searchedPatientDetails['customer'] }}</h5>
                    <h5>Booking date: {{ _util.showDateLocally(searchedPatientDetails['bookingDate'], true) }}</h5>
                </div>
            </div>
        </div>
    </div>

</div>
