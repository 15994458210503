import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxDropzoneModule } from 'ngx-dropzone'
import { NotifierModule } from 'angular-notifier'
import { SortPipe } from './shared/pipes/sort.pipe'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccessionFormComponent } from './accession-form/accession-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReportPrintComponent } from './report-print/report-print.component';
import { LoginComponent } from './login/login.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageTatComponent } from './manage-tat/manage-tat.component';
import { TatInputComponent } from './components/tat-input/tat-input.component';
import { PreviewReportComponent } from './preview-report/preview-report.component';
import { ReportDropboxComponent } from './report-dropbox/report-dropbox.component';
import { DownloadBiochemComponent } from "./download-biochem/download-biochem.component";
import { JarvisHomeComponent } from './jarvis-home/jarvis-home.component';
import { SalesCustomerListingComponent } from './sales-customer-listing/sales-customer-listing.component';
import { PatchRequestsManagementComponent } from './patch-requests-management/patch-requests-management.component';
import { PrintDashboardComponent } from './print-dashboard/print-dashboard.component';
import { AdministrationControllerComponent } from './administration-controller/administration-controller.component';
import { EditReportComponent } from './edit-report/edit-report.component';
import { CustomerCreationComponent } from './customer-creation/customer-creation.component';
import { TimeslotsInputComponent } from './components/timeslots-input/timeslots-input.component';
import { TimeHoursInputComponent } from './components/time-hours-input/time-hours-input.component';
import {
    CustomerApprovalDashboardComponent
} from './customer-approval-dashboard/customer-approval-dashboard.component';
import { TestDetailRowComponent } from './test-detail-row/test-detail-row.component';
import { TestDetailTableComponent } from './test-detail-table/test-detail-table.component';
import { TestSearchFilterComponent } from './test-search-filter/test-search-filter.component';
import { PreviewMouComponent } from './preview-mou/preview-mou.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { JamsifyDashboardComponent } from './jamsify-dashboard/jamsify-dashboard.component';
import { JamsifyStatisticsChartsComponent } from './jamsify-statistics-charts/jamsify-statistics-charts.component';
import { JamsifyRecordsStatisticsComponent } from './jamsify-records-statistics/jamsify-records-statistics.component';
import {
    OutstandingBalanceDashboardComponent
} from './outstanding-balance-dashboard/outstanding-balance-dashboard.component';
import { PaymentReceiptDashboardComponent } from './payment-receipt-dashboard/payment-receipt-dashboard.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { ReportsSessionManagerComponent } from './components/reports-session-manager/reports-session-manager.component';


@NgModule({
    declarations: [
        AppComponent,
        AccessionFormComponent,
        DashboardComponent,
        ReportPrintComponent,
        LoginComponent,
        ManageUsersComponent,
        ManageTatComponent,
        TatInputComponent,
        PreviewReportComponent,
        ReportDropboxComponent,
        DownloadBiochemComponent,
        JarvisHomeComponent,
        SalesCustomerListingComponent,
        PatchRequestsManagementComponent,
        PrintDashboardComponent,
        AdministrationControllerComponent,
        EditReportComponent,
        CustomerCreationComponent,
        TimeslotsInputComponent,
        TimeHoursInputComponent,
        CustomerApprovalDashboardComponent,
        TestDetailRowComponent,
        TestDetailTableComponent,
        TestSearchFilterComponent,
        SortPipe,
        PreviewMouComponent,
        SignaturePadComponent,
        JamsifyDashboardComponent,
        JamsifyStatisticsChartsComponent,
        JamsifyRecordsStatisticsComponent,
        OutstandingBalanceDashboardComponent,
        PaymentReceiptDashboardComponent,
        StatusIconComponent,
        ReportsSessionManagerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgxDropzoneModule,
        NotifierModule,
        ReactiveFormsModule,
    ],
    providers: [
        SortPipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
