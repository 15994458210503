<div class="vertical-flex start-stretch fill-width">
    <div class="horizontal-flex start-center mt-2" *ngFor="let timeslot of timeslots; index as i">
        <select [name]="'timeslot-input--start--' + i" id="timeslot-input--start--1" class="form-control mr-2"
                [(ngModel)]="timeslot.start"
                (change)="valueChanged()">
        >
            <option *ngFor="let option of options" [value]="option.value"
                    [selected]="option.value === timeslot.start"
                    (change)="timeslot.start = $event.target.value"
            >{{ option.label }}</option>
        </select>

        <select [name]="'timeslot-input--end--' + i" id="timeslot-input--end--1" class="form-control mr-2"
                [(ngModel)]="timeslot.end"
                (change)="valueChanged()"
        >
            <option *ngFor="let option of options" [value]="option.value"
                    [hidden]="option.value <= timeslot.start"
                    [selected]="option.value === timeslot.end"
                    (change)="timeslot.end = $event.target.value"
            >{{ option.label }}</option>
        </select>

        <button class="btn btn-primary" (click)="addTimeslot()" type="button"
                *ngIf="isMultiple"
                [ngClass]="{'visible': i === timeslots.length - 1, 'invisible': i !== timeslots.length - 1}"
        >Add</button>
    </div>
</div>
