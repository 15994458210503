
<div class="d-flex flex-row">
	<div class="input-group mr-2 mb-4">
                        <input type="search" class="form-control" (input)="searchStringChange()" name="testsSearchString"
                            id="search-input"
                            [(ngModel)]="testsSearchString" placeholder="Search ..."
                            aria-label="by Test Name or test code">

                        <div class="input-group-append" [hidden]="! testsSearchString.length">
                            <button type="button" class="btn btn-danger" (click)="resetSearchString()">
                                <i class="zmdi zmdi-close red"></i>
                            </button>
                        </div>    
                        <div class="input-group-append">
						    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{(selectedGcode.length)?testDropdownLabels[selectedGcode]['name']:'Select Department'}}</button>
						    <div class="dropdown-menu">
						    	<div class="dropdown-item" *ngFor="let testLabel of testDropdownLabels | keyvalue" (click)="gcodeSelection(testLabel.key)">{{testLabel.value['name']}}</div>
						    </div>
						  </div>
						  <div class="input-group-append">
						    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="!selectedGcode.length">{{(selectedScode.length)?currentDepartmentTestLabels[selectedScode]:'Select Test Group'}}</button>
						    <div class="dropdown-menu">
						      <div class="dropdown-item" *ngFor="let testLabel of currentDepartmentTestLabels | keyvalue" (click)="scodeSelection(testLabel.key)">{{testLabel.value}}</div>
						    </div>
						  </div>
						  <div class="input-group-append" [hidden]="! selectedGcode.length && ! selectedScode.length">
                            <button type="button" class="btn btn-danger" (click)="resetFilterString()">
                                <i class="zmdi zmdi-close red"></i>
                            </button>
                        </div>
                    </div>
</div>                        