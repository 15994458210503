import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RestService } from '../rest.service';
import { UtilityService } from '../utility.service';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import Swal from "sweetalert2";
import { interval, Subscription } from 'rxjs';


@Component({
    selector: 'app-preview-report',
    templateUrl: './preview-report.component.html',
    styleUrls: ['./preview-report.component.styl']
})
export class PreviewReportComponent implements OnInit {

    fullWidthError = "";
    haveAdminRights = false;
    canAuthenticateReports = false;

    haveSignature = false;
    previewParams = {};
    customerAddress = {};
    sample = {};

    showSendToGCExpert = false;

    altumParams = {
        "caseType": "",
        "lschType": "",
        "lohType": "",
        "altumCategory": "",
        "altumSubCategory": "",
        "gender": "",
    }

    gcExpertState = {
        allUserEmails: [],
        state: "initial",
        loading: false,
        mailing: false,
        mailVars: {},
        activeAutocomplete: "",
        overrides: [],
    };
    gcExpertForm = {
        "ccEmail": '',
        "bccEmail": '',
        "remarks": '',
        "baseParams": {},
        "override": {},
    }

    headerReportAttachmentId = 0;
    withoutHeaderReportAttachmentId = 0;

    infoMessage = "Loading authentication status ...";
    thalTestcodes = [];
    cytoTestcodes = [];
    flowCytometryTestCodes = [];
    flowCytometryTestType = "";
    cytoProcessStatus = "";
    astriaTestcodes = [];
    isConvertedToAstria = false;
    thalInterpretations = [];
    thalReportValue = {
        A0: "",
        A2: "",
        A1c: "",
        P2: "",
        P3: "",
        F: "",
        D: "",
        S: "",
        FractionPresent: false,
        FractionLabel: "Hb Fraction",
        FractionObserved: "",
        FractionExpected: "",
        FractionAbnormal: "0",
        InterpretationName: "",
        InterpretationText: "",
        SuggestionText: "",
        BloodTransfusion: "",
    }

    sfltPlgfTestValues = {
        updatedGestationalAgeDays: 0,
        updatedGestationalAgeWeeks: 0,
        showConfirmationMessage: false,
    }

    reticulocyteCountTestReportValues = {
        InterpretationText: "",
    }

    cbcTestReportValues = {
        rbcText: '',
        wbcText: '',
        plateletsText: '',
        parasitesText: '',
        impressionText: '',
        advisedText: '',
    }

    patCytoAuthenticated = null;
    patResultVerified = null;
    patCytoAdditionalInfoRow = {};
    patCytoNotesChanged = false;
    patCytoStatus = '';
    patCytoStatusDateTime = null;
    patCytoStatusBy = '';
    patCytoStatusReason = '';
    patCytoStatusLocal = false;
    patCytoNotes = '';
    patCytoSuggestion = '';
    trfDocument = '';

    sfltTestcodes = [];

    mailing = false;
    printing = false;
    markedReported = false;
    reasonType = '';
    uploadingFilePurpose = '';
    uploadButtonText = '';
    uploadedFileUrl = '';
    uploadedThalReportButtonText = '';
    uploadedThalReportFileUrl = '';
    createdReportsObject = [];
    matchedReportTypesRow = {};
    matchedReportTemplate = null;
    filePickerEl = null;

    reportFilesWithHeader: File[] = [];
    reportFilesWithoutHeader: File[] = [];
    supportFiles: File[] = [];
    missingIdInFiles: boolean = false;
    mailingInfo = {
        supportAttachments: [],
        attachmentWithHeader: [],
        attachmentWithoutHeader: [],
    };

    pdfPreviewTitle = '';
    showWebViewer = false;
    showPdfViewer = false;
    showDownloadingStatus: boolean = false;

    user = {};


    @ViewChild('iFrame', { static: true }) iFrame: ElementRef;
    @ViewChild('pdfViewer', { static: true }) pdfViewer: ElementRef;
    @ViewChild('webViewer', { static: true }) webViewer: ElementRef;

    constructor ( public _util: UtilityService, public _rest: RestService ) {
    }

    ngOnInit (): void {

        this.previewParams = {
            ...this._util.getQueryParameters(),
            loadReportCache: 1,
        };
        this.gcExpertForm.baseParams = this.previewParams;

        this.altumParams = { ...this.altumParams, ...this._util.parseAltumParams() };

        this.showSendToGCExpert = true;

        if ( Object.keys(this.previewParams).length ) this.initializePreview();

        this.haveAdminRights = this._util.canUser(this._util.Constants.Permissions.ManageUsers);
        this.canAuthenticateReports = this._util.canUser(this._util.Constants.Permissions.AuthenticateReports);

        this.user = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));
        this.haveSignature = !!this.user['signature'];

        this.filePickerEl = document.getElementById('biochemistry-email-attachment');
    }

    async putAmmendmentStamp () {
        console.log("putAmmendmentStamp: ");

        const resp = await this._rest.putAmendedStamp(this.sample["barcode"], this.previewParams['testcode'] || null);
        if ( !resp["success"] && resp["message"] ) return this._util.showAlert("Oops !", resp['message']);

        this.sample["amended_at"] = new Date(resp['amendedAt']);
        this.refreshPage();
    }

    initializePreview () {
        let query = [];

        for ( let key in this.previewParams )
            query.push(`${key}=${this.previewParams[key]}`);

        this.iFrame.nativeElement.src = window.location.origin + "/api/reports/view?"
            + `token=${this._util.getToken()}&`
            + query.join('&');

        this.refreshAuthenticatedStatus();
    }

    async refreshPage () {
        this._util.refreshPage();
    }

    async saveNotesSuggestions () {
        this.infoMessage = "Saving, Please wait ...";

        const resp = await this._rest.saveNotesAndSuggestions({
            ...this.previewParams,
            notes: this.patCytoNotes, suggestions: this.patCytoSuggestion,
        });

        if ( resp["success"] ) this.patCytoNotesChanged = false;

        this.infoMessage = resp["message"] || "";
        setTimeout(() => {
            this.infoMessage = "";
        }, 3000);

        this.refreshPage();
    }

    async askForResultAndSubmit () {
        let returnedValue = this.previewParams['redraw'] || "";

        try {
            if ( this.iFrame.nativeElement.contentWindow.getOverallRiskValue )
                returnedValue = this.iFrame.nativeElement.contentWindow.getOverallRiskValue();

            // if ( returnedValue.trim().length)
            //     returnedValue = returnedValue.toUpperCase() + " RISK";

            if ( returnedValue === "LOW RISK" ) returnedValue = "SCREEN NEGATIVE";
            if ( returnedValue === "HIGH RISK" ) returnedValue = "SCREEN POSITIVE";
        } catch (e) {
            return alert("report not loaded yet");
        }

        if ( this.previewParams["redraw"] && this.previewParams['redraw'] )
            returnedValue = this.previewParams['redraw'];

        let matchedReportTypesRow = this.getMatchedReportTypesRow();
        if ( !matchedReportTypesRow ) return alert("These reports are not yet integrated");

        const resultOptions = {};
        (matchedReportTypesRow['resultOptions'] || [])
            .filter(row => row.toLowerCase().indexOf(returnedValue.toLowerCase()) === 0)
            .forEach(row => resultOptions[row] = row);

        // @ts-ignore
        if ( matchedReportTypesRow.forceShowResultOptions ) {

            // @ts-ignore
            matchedReportTypesRow.resultOptions.forEach(row => resultOptions[row] = row);
            console.log("resultOptions: ", resultOptions);


            return Swal.fire({
                title: `Result ?`,
                input: 'select',
                inputOptions: resultOptions,
                inputValidator: ( value ) => {
                    if ( !value ) {
                        return 'Can\'t authenticate without result Value.!'
                    }
                }

            }).then(( result ) => {
                if ( !result.isConfirmed ) return;

                Swal.close();

                if ( this.isConvertedToAstria && this.isAstriaTestcode() )
                    return this.confirmResultBeforeAuthentication('astria', result.value);

                if ( this.isThalTestcode() )
                    return this.confirmResultBeforeAuthentication('thal', result.value);


                setTimeout(() => {
                    this.authenticateWithResult(result.value);
                }, 100);

                return;
            });

        }

        Swal.fire({
            title: `Result ?`,
            text: '"' + returnedValue + '"',
            showCancelButton: true,
            confirmButtonText: (this.isSfltPlgfTestcode()) ? 'Confirm' : 'Mark authenticated',
            showLoaderOnConfirm: true,

        }).then(( result ) => {
            if ( !result.isConfirmed ) return;

            Swal.close();

            if ( this.isSfltPlgfTestcode() ) {
                return this.confirmResultBeforeAuthentication('sflt', returnedValue);
            }

            this._rest.markLabmateHoldReject({
                ...this.previewParams,
                type: 'Authenticate',
                result: returnedValue,
            }).then(resp => {

                this.infoMessage = resp["message"] || "";

                if ( resp['success'] ) {
                    this.mailReport();
                }
            })
        });
    }

    confirmResultBeforeAuthentication ( type, resultValue ) {
        switch ( type ) {
            case 'thal':
                Swal.fire({
                    title: `Are you sure ?`,
                    html: `THAL Result value - ${resultValue},  <br>  THAL interpretation - ${this.thalReportValue.InterpretationName}`,
                    showCancelButton: true,
                    confirmButtonText: 'Mark authenticated',
                }).then(( result ) => {
                    if ( !result.isConfirmed )
                        return;

                    Swal.close();
                    setTimeout(() => {
                        this.authenticateWithResult(resultValue);
                    }, 100);
                })

                break;

            case 'astria':
                Swal.fire({
                    title: `Are you sure ?`,
                    text: `Selected result value - ${resultValue}`,
                    showCancelButton: true,
                    confirmButtonText: 'Mark authenticated',
                }).then(( result ) => {
                    if ( !result.isConfirmed )
                        return;

                    Swal.close();
                    setTimeout(() => {
                        this.authenticateWithResult(resultValue);
                    }, 100);
                })

                break;

            case 'sflt':
                Swal.fire({
                    title: `Are you sure ?`,
                    text: `Selected gestational age value - ${this.sfltPlgfTestValues.updatedGestationalAgeWeeks} weeks ${this.sfltPlgfTestValues.updatedGestationalAgeDays} days`,
                    showCancelButton: true,
                    confirmButtonText: 'Mark authenticated',
                }).then(( result ) => {
                    if ( !result.isConfirmed )
                        return;

                    Swal.close();
                    setTimeout(() => {
                        this.authenticateWithResult(resultValue);
                    }, 100);
                })

                break;

            default:
                break;
        }
    }


    private getMatchedReportTypesRow () {
        this.matchedReportTypesRow = null;

        this.createdReportsObject.forEach(row => {
            if ( this.matchedReportTypesRow )
                return;

            if ( this.isConvertedToAstria ) {
                if ( row.isAstriaFlow )
                    this.matchedReportTypesRow = row;

                return;
            }

            if ( row.isAstriaFlow ) return;

            if ( row.testcodes.filter(testcode => this.previewParams["testcode"].indexOf(testcode) === 0).length )
                this.matchedReportTypesRow = row;
        });

        return this.matchedReportTypesRow;
    }

    async authenticateWithResult ( resultValue ) {
        await Swal.fire({
            title: `Authenticating report ..`,
            text: 'should not take much time',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();

                // This parameter is now provided while building the 'previewParams' variable
                // this.previewParams['loadReportCache'] = true; //this parameter will determine wether the report data will be piacked from cache or from master.

                await this._rest.markLabmateHoldReject({
                    ...this.previewParams,
                    type: 'Authenticate',
                    result: resultValue,
                }).then(async resp => {
                    Swal.close();

                    this.infoMessage = resp["message"] || "";

                    if ( resp['success'] ) {
                        setTimeout(async () => {
                            await this.mailReport();

                        }, 100);
                    } else {
                        await this._util.showAlert("Something went wrong while authenticating: ", resp['message'], 'error');
                    }
                })
            }
        });
    }

    async askForReasonAndSubmit ( reasonType ) {
        this.reasonType = reasonType;

        const result = Swal.fire({
            title: `Reason to mark ${this._util.ucFirst(reasonType)}`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Mark ' + reasonType,
            showLoaderOnConfirm: true,
        }).then(( result ) => {
            if ( !result.isConfirmed ) return;

            this._rest.markLabmateHoldReject({
                ...this.previewParams,
                type: reasonType,
                reason: result.value,
            }).then(resp => {

                this.infoMessage = resp["message"] || "";
                if ( resp['success'] ) setTimeout(this.refreshPage, 3000);
            })
        });
    }

    async markPaymentDone () {
        const barcode = this.sample["barcode"];

        Swal.fire({
            title: `Releasing report ..`,
            text: 'please wait it may take a while',
            didOpen: async () => {
                Swal.showLoading();

                const resp = await this._rest.markPaymentSuccess(barcode);

                Swal.close();

                if ( resp["success"] ) await this.mailReport();
            }
        });
    }

    async refreshAuthenticatedStatus () {
        this.patCytoAuthenticated = null;
        this.patResultVerified = null;

        // @ts-ignore
        const resp = await this._rest.getSampleInfo(this.previewParams.barcode, this.previewParams.testcode);

        this.infoMessage = "";
        this.patCytoAuthenticated = resp["patCytoAuthenticated"] || false;
        this.patResultVerified = resp["patResultsVerified"] || false;
        this.customerAddress = resp["customerAddress"] || {};
        this.markedReported = resp["markedReported"] || false;
        this.createdReportsObject = resp["createdReportsObject"] || [];
        this.sfltPlgfTestValues.updatedGestationalAgeWeeks = (resp["sample"]["meta"] || {})["updatedGestationalAgeWeeks"] || 0;
        this.sfltPlgfTestValues.updatedGestationalAgeDays = (resp["sample"]["meta"] || {})["updatedGestationalAgeDays"] || 0;
        this.thalTestcodes = resp['thalTestcodes'] || [];
        this.sfltTestcodes = resp['sfltTestcodes'] || [];
        this.flowCytometryTestCodes = resp['flowCytometryTestCodes'] || [];
        this.astriaTestcodes = resp['astriaTestcodes'] || [];
        this.cytoTestcodes = resp['cytoTestcodes'] || [];
        this.isConvertedToAstria = resp['isConvertedToAstria'] || false;
        this.thalInterpretations = resp['thalInterpretations'] || [];
        this.matchedReportTemplate = resp['matchedReportTemplate'] || null;
        this.cytoProcessStatus = ((resp['cytoProcessStatus'] || 0) + "").trim();


        for ( let key in this.mailingInfo ) {
            this.mailingInfo[key] = [];
        }

        (resp['reportAttachments'] || [])
            .forEach(attachment => {
                // load first attachment to astria attachment report.

                const meta = JSON.stringify(attachment.meta);

                // if testcode not present in meta, ignore
                if ( meta.indexOf(this.previewParams['testcode']) === -1 )
                    return;

                if ( attachment.purpose === "support-file" ) this.mailingInfo.supportAttachments.push(attachment);
                if ( (attachment.purpose === "report") && attachment.header ) this.mailingInfo.attachmentWithHeader.push(attachment);
                if ( (attachment.purpose === "report") && !attachment.header ) this.mailingInfo.attachmentWithoutHeader.push(attachment);

                if ( attachment.purpose !== "report" ) return;

                if ( !this.headerReportAttachmentId && attachment.header )
                    this.headerReportAttachmentId = attachment.id;

                if ( !this.withoutHeaderReportAttachmentId && !attachment.header )
                    this.withoutHeaderReportAttachmentId = attachment.id;
            })

        this.getMatchedReportTypesRow();

        //console.log('matched: ', this.matchedReportTypesRow);

        if ( this.matchedReportTypesRow && this.matchedReportTypesRow["ignoreResultNotVerified"] )
            this.patResultVerified = true;

        this.sample = resp['sample'] || {};
        this.trfDocument = resp['trfDocument'] || '';

        if ( this.matchedReportTypesRow['cbcTestCode'] && this.matchedReportTypesRow['reticulocyteCountTestCode'] ) {
            if ( this.previewParams['testcode'].indexOf(this.matchedReportTypesRow['cbcTestCode']) > -1 ) {
                this.flowCytometryTestType = 'CBC';
            } else if ( this.previewParams['testcode'].indexOf(this.matchedReportTypesRow['reticulocyteCountTestCode']) > -1 ) {
                this.flowCytometryTestType = 'Reticulocyte Count';
            }
        }

        // allowing to print, on THAL testcodes
        const sampleTestcodes = (this.sample["tests"] || [])
            .map(row => row.testcode);
        const isInsideThalTestcodes = !!this.thalTestcodes.filter(row => {
            return !!sampleTestcodes.filter(sampleTestcode => sampleTestcode === row).length
        }).length;


        if ( resp['attachment'] && resp['attachment']['last_url'] )
            this.uploadedFileUrl = resp['attachment']['last_url'];

        if ( resp['thalAttachment'] && resp['thalAttachment']['last_url'] )
            this.uploadedThalReportFileUrl = resp['thalAttachment']['last_url'];

        const patCytoAdditionalDetails = resp['patCytoAdditionalDetails'] || {};
        this.patCytoStatusLocal = patCytoAdditionalDetails.isLocal || false;

        if ( patCytoAdditionalDetails.Authenticate ) {
            this.patCytoStatus = 'authenticate';
            this.patCytoStatusBy = patCytoAdditionalDetails.AuthenticateBy;
            this.patCytoStatusDateTime = new Date(patCytoAdditionalDetails.AuthenticateDatetime);
            this.patCytoStatusReason = patCytoAdditionalDetails.Result;
        }

        if ( patCytoAdditionalDetails.Hold ) {
            this.patCytoStatus = 'hold';
            this.patCytoStatusBy = patCytoAdditionalDetails.HoldBy;
            this.patCytoStatusDateTime = new Date(patCytoAdditionalDetails.HoldDatetime);
            this.patCytoStatusReason = patCytoAdditionalDetails.HoldReason || "";
        }

        if ( patCytoAdditionalDetails.Reject ) {
            this.patCytoStatus = 'reject';
            this.patCytoStatusBy = patCytoAdditionalDetails.RejectBy;
            this.patCytoStatusDateTime = new Date(patCytoAdditionalDetails.RejectDatetime);
            this.patCytoStatusReason = patCytoAdditionalDetails.RejectReason || "";
        }

        if ( patCytoAdditionalDetails.Notes ) this.patCytoNotes = patCytoAdditionalDetails.Notes;
        if ( patCytoAdditionalDetails.Suggestion ) this.patCytoSuggestion = patCytoAdditionalDetails.Suggestion;
        this.patCytoAdditionalInfoRow = patCytoAdditionalDetails;

        if ( this.isThalTestcode() ) {
            this.thalReportValue = {
                ...this.thalReportValue,
                ...this.sample['meta'] || {},
            }
        }

        if ( this.isFlowCytometryTestcode() ) {
            this.reticulocyteCountTestReportValues = {
                ...this.reticulocyteCountTestReportValues,
                ...this.sample['meta'] || {},
            }
            this.cbcTestReportValues = {
                ...this.cbcTestReportValues,
                ...this.sample['meta'] || {},
            }
        }

        console.log('patCytoStatusReason : ', this.patCytoStatusReason);

    }

    async printReport () {
        this.iFrame.nativeElement.contentWindow.print();
        this.printing = true;
    }

    async printAndEmailReport () {
        await this.printReport()
        await this.mailReport()
    }

    checkIfNotesOrSuggestionsChanged () {
        if ( (this.patCytoStatus === 'authenticate') && !this.canAuthenticateReports ) return;

        this.patCytoNotesChanged = (this.patCytoNotes !== this.patCytoAdditionalInfoRow["Notes"])
            || (this.patCytoSuggestion !== this.patCytoAdditionalInfoRow["Suggestion"]);

    }

    getAttachedReportsArray () {
        return this.mailingInfo.attachmentWithHeader.concat(this.mailingInfo.attachmentWithoutHeader)
            .map(row => row.id);
    }

    async mailReport () {
        if ( this.markedReported ) {
            const confirmed = confirm("Already marked 'reported' Are you sure? you want to e-mail again.");
            if ( !confirmed ) return;
        }

        if ( this.isConvertedToAstria && !this.getAttachedReportsArray().length ) {
            return this._util.showAlert(
                "Please uploads reports",
                "For astria Use-case cannot authenticate without adding attachments",
                "error");
        }

        await Swal.fire({
            title: `Mailing report ..`,
            text: 'please wait it may take a while',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();

                let resp = null;

                if ( this.isConvertedToAstria ) {
                    resp = await this._rest.mailAttachments(this.sample['barcode'],
                        this.getAttachedReportsArray()
                        , true, true)
                        .catch(err => resp = {
                            success: 0,
                            message: err.error.message
                        });
                } else {
                    resp = await this._rest.mailReportAllParams(
                        this.previewParams
                    ).catch(err => resp = {
                        success: 0,
                        message: err.error.message
                    });
                }

                Swal.close();

                setTimeout(() => {
                    this._util.showAlert(
                        resp["success"] ? "Server resp" : "Something went wrong",
                        this.cleanHTMLResponseMessage(resp['message']),
                        resp["success"] ? "success" : "error");
                }, 100);

                if ( resp["success"] ) this.markedReported = true;
                this.mailing = false;
            }
        });

        this.mailing = true;
    }

    cleanHTMLResponseMessage ( message ) {
        return (message || "")
            .replace("<", "&lt;")
            .replace(">", "&gt;");
    }

    beginUploadFileWithPurpose ( purpose ) {
        this.uploadingFilePurpose = purpose;
        this.filePickerEl.click()
    }

    async fileSelectedToUpload ( $event: Event ) {

        this.uploadButtonText = "Uploading ...";

        const file = $event.target["files"][0];

        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('barcode', this.previewParams['barcode']);
        formData.append('header', "1");
        formData.append('purpose', this.uploadingFilePurpose || 'biochem-attachment');
        formData.append('ignoreSamplePatientValidation', this.isConvertedToAstria ? "1" : "0");
        formData.append('testcode', this.previewParams['testcode']);

        await Swal.fire({
            title: `Uploading ...`,
            text: 'please wait it may take a while',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();

                const resp = await this._rest.updateMailAttachments(formData);

                if ( !resp['success'] ) this.uploadButtonText = '';

                if ( !resp["success"] && resp["message"] ) {
                    if ( !resp["success"] && resp["missingId"] ) {
                        await this._util.showAlert("The sample ID / patient ID does not match");
                        return;
                    }

                    await this._util.showAlert("Upload error", resp["message"]);
                    return;
                }

                this.uploadButtonText = "File uploaded";

                Swal.close();

                switch ( this.uploadingFilePurpose ) {
                    case "biochem-attachment":
                        this.uploadedFileUrl = resp['attachment']['last_url'];
                        break;
                    case "thal-report-embed":
                        this.uploadedThalReportFileUrl = resp['attachment']['last_url'];
                        await this.refreshPage();
                        break;
                    case 'report':
                        this.mailingInfo.attachmentWithHeader.push(resp['attachment']);
                        break;
                    default:
                        break;
                }

                setTimeout(() => {
                    this.uploadButtonText = "";
                    this.uploadedThalReportButtonText = "";
                }, 3000);
            }
        });
    }

    async unAuthenticateReport () {
        const confirmed = confirm("Are you sure? you want to un-authenticate report");
        if ( !confirmed ) return;

        const resp = await this._rest.markLabmateUnAuthenticated(this.previewParams['barcode'], this.previewParams['testcode']);
        if ( !resp['success'] ) return this._util.showAlert("Server resp", resp['message']);

        await this.refreshPage();
    }

    async fillThalReportValues () {
        const resp = await this._rest.updateTHALReportValues(this.previewParams['barcode'], this.thalReportValue);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp', resp['message']);

        await this.refreshPage();
    }

    async fillReticulocyteCountReportValues () {
        const resp = await this._rest.updateReticulocyteCountReportValues(this.previewParams['barcode'], this.reticulocyteCountTestReportValues);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp', resp['message']);

        await this.refreshPage();
    }

    async fillCBCReportValues () {
        const resp = await this._rest.updateCBCReportValues(this.previewParams['barcode'], this.cbcTestReportValues);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp', resp['message']);

        await this.refreshPage();
    }

    thalInterpretationChanged () {
        setTimeout(() => {
            if ( !this.thalReportValue.InterpretationName ) return;

            const selectedInterpretation = this.thalInterpretations.filter(row => row.name === this.thalReportValue.InterpretationName);
            if ( !selectedInterpretation.length ) return;

            this.thalReportValue.InterpretationText = selectedInterpretation[0]['interpretation'];
            this.thalReportValue.SuggestionText = selectedInterpretation[0]['suggestion'];

            const thalValues = (this.sample || {})['meta'] || {};

            for ( let key in thalValues ) {
                this.thalReportValue.SuggestionText = this.thalReportValue.SuggestionText
                    .replace('{' + key + '}', thalValues[key]);
                this.thalReportValue.InterpretationText = this.thalReportValue.InterpretationText
                    .replace('{' + key + '}', thalValues[key]);
            }


        }, 1)

    }

    confirmationPrompt () {
        if ( this.sfltPlgfTestValues.updatedGestationalAgeWeeks > 19 && this.sfltPlgfTestValues.updatedGestationalAgeWeeks <= 42 && this.sfltPlgfTestValues.updatedGestationalAgeDays >= 0 && this.sfltPlgfTestValues.updatedGestationalAgeDays < 7 ) {
            this.sfltPlgfTestValues.showConfirmationMessage = true;
        }
    }


    async updateGestationalAge () {
        const updatedGestationAge = {
            "updatedGestationalAgeWeeks": this.sfltPlgfTestValues.updatedGestationalAgeWeeks,
            "updatedGestationalAgeDays": this.sfltPlgfTestValues.updatedGestationalAgeDays,
        }

        console.log(updatedGestationAge);

        const resp = await this._rest.updateSfltPlgfReportValues(this.previewParams['barcode'], updatedGestationAge);

        console.log(resp);

        await this.refreshPage();
    }


    openModal ( modalName ) {
        setTimeout(() => {
            eval('$("#' + modalName + '").modal("show")');
        }, 1);
    }

    closeModal ( modalName ) {
        setTimeout(() => {
            eval('$("#' + modalName + '").modal("hide")');
        }, 1);
    }

    isAstriaTestcode () {
        return this.astriaTestcodes.indexOf(this.previewParams['testcode']) > -1;
    }

    isThalTestcode () {
        return this.thalTestcodes.indexOf(this.previewParams['testcode']) > -1;
    }

    isFlowCytometryTestcode () {
        return this.flowCytometryTestCodes.indexOf(this.previewParams['testcode']) > -1;
    }

    isSfltPlgfTestcode () {
        return this.sfltTestcodes.indexOf(this.previewParams['testcode']) > -1;
    }

    async sendPreviouslySharedReports () {

        if ( !confirm('You are about to re-send a previously sent mail. Are you sure?') )
            return;

        const resp = await this._rest.mailAttachments(this.sample['barcode'], [this.headerReportAttachmentId, this.withoutHeaderReportAttachmentId], true, true);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp', resp['message']);

        await this._util.showAlert('Mail sent successfully', '', 'success');
    }

    async markReportReleased () {

        if ( !confirm("This will remove this report, from appearing in 'TAT error Sheet'. Are you sure?") )
            return;

        const resp = await this._rest.markReportReleased(this.sample['barcode'], this.previewParams['testcode']);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        await this._util.showAlert('Marked reported successfully', '', 'success');
        await this.refreshPage();
    }

    async convertBackToNonAstria () {

        if ( !confirm('Converting this report back to NON-Astraia Use case. Are you sure?') )
            return;

        this.isConvertedToAstria = false;
        this.getMatchedReportTypesRow();

        const resp = await this._rest.convertToNonAstria(this.previewParams['barcode'], this.previewParams['testcode']);
        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);
    }

    async convertToAstria () {

        if ( !confirm('Converting this report to Astria Use case. Are you sure?') )
            return;

        this.isConvertedToAstria = true;
        this.getMatchedReportTypesRow();

        await this._util.showAlert('Please attach Astria report', 'Using attach-reports section in top-right or portal', 'info');

        const resp = await this._rest.convertToAstria(this.previewParams['barcode'], this.previewParams['testcode']);
        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);
    }

    isCytoTestcode () {
        return !!this.cytoTestcodes
            .filter(cytoTestcode => this.previewParams['testcode']
                .indexOf(cytoTestcode) > -1)
            .length;
    }

    isCytoDisabledReport () {
        const matchedReportFormat = this.getMatchedReportTypesRow();
        if ( !matchedReportFormat ) return false;

        switch ( matchedReportFormat['processFlowType'] + "" ) {
            case "1": return parseInt(this.cytoProcessStatus) < 10;
            case "5": return parseInt(this.cytoProcessStatus) < 11;
            case "4": return parseInt(this.cytoProcessStatus) < 11;

            default: return false;
        }
    }

    async resyncCytoImages () {
        await this._rest.invalidateCytoImages(this.previewParams['barcode']);
        await this.refreshPage();
    }


    async selectedAttachmentForMail ( event, header = 1 ) {
        if ( !this.sample ) return;

        const addedFiles = event.addedFiles;

        for ( let i = 0; i < addedFiles.length; i++ ) {
            const file = addedFiles[i];
            file['header'] = !!header;

            if ( header === 2 ) this.supportFiles.push(file);
            else if ( header ) this.reportFilesWithHeader.push(file);
            else this.reportFilesWithoutHeader.push(file);

            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('barcode', this.sample['barcode']);
            formData.append('testcode', this.previewParams['testcode']);
            formData.append('header', header ? "1" : "0");
            formData.append('ignoreSamplePatientValidation', this.isConvertedToAstria ? "1" : "0");

            if ( header === 2 ) formData.append('purpose', 'support-file')
            else formData.append('purpose', 'report');

            const resp = await this._rest.updateMailAttachments(formData);
            if ( !resp["success"] && resp["message"] ) {
                if ( !resp["success"] && resp["missingId"] ) {
                    if ( header === 2 ) this.supportFiles.pop();
                    else if ( header ) this.reportFilesWithHeader.pop();
                    else this.reportFilesWithoutHeader.pop();

                    this.missingIdInFiles = true;

                    setTimeout(() => {
                        this.missingIdInFiles = false;
                    }, 5000);
                }

                this._util.showAlert("Upload error", resp["message"]);
                continue;
            }

            if ( header === 2 ) this.supportFiles.splice(this.supportFiles.indexOf(file));
            else if ( header ) this.reportFilesWithHeader.splice(this.reportFilesWithHeader.indexOf(file));
            else this.reportFilesWithoutHeader.splice(this.reportFilesWithoutHeader.indexOf(file));

            if ( header === 2 ) this.mailingInfo.supportAttachments.push(resp['attachment']);
            else if ( header ) this.mailingInfo.attachmentWithHeader.push(resp["attachment"]);
            else this.mailingInfo.attachmentWithoutHeader.push(resp['attachment']);
        }
    }

    removeMailingInfoAttachment ( $event, attachment ) {
        $event.stopPropagation();

        this.mailingInfo.attachmentWithHeader = this.mailingInfo.attachmentWithHeader.filter(row => row.id !== attachment.id);
        this.mailingInfo.attachmentWithoutHeader = this.mailingInfo.attachmentWithoutHeader.filter(row => row.id !== attachment.id);
        this.mailingInfo.supportAttachments = this.mailingInfo.supportAttachments.filter(row => row.id !== attachment.id);

        this._rest.unlinkAttachment(this.previewParams['barcode'], attachment.id)
            .then(() => {
            });
    }

    backToEditing () {
        if ( !this._util.canUser(this._util.Constants.Permissions.AuthenticateReports) ) return;

        this._util.redirectTo('edit-report', {
            barcode: this.previewParams['barcode'],
            testcode: this.previewParams['testcode']
        });
    }

    async showPCPNDTForm () {
        console.log("show pcpndt form: ", this.previewParams);
        const url = `${window.location.origin}/api/sample/show-pc-pndt-form?barcode=${this.previewParams['barcode']}&testcode=${this.previewParams['testcode']}&token=${this._util.getToken()}`;

        this._util.modal('pdf-preview-modal');
        this.webViewer.nativeElement.src = url;
        this.showWebViewer = true;
        this.pdfPreviewTitle = "PCPNDT Form";
    }

    printWebViewer () {
        this.webViewer.nativeElement.contentWindow.print();
        this._util.modal('pdf-preview-modal', 'hide');
    }

    async downloadPCPNDTForm () {
        console.log("download pcpndt form: ", this.previewParams);
        this.showDownloadingStatus = true;

        const resp = await this._rest.showPCPNDTForm(this.previewParams['barcode'], this.previewParams['testcode'], true);
        if ( !resp["success"] && resp['message'] ) {
            await this._util.showAlert("Download error", resp["message"]);
            return;
        }

        const linkSource = `data:application/pdf;base64,${resp['buffer']}`;
        const downloadLink = document.createElement("a");
        const fileName = `pcpndt-form-${this.previewParams['barcode']}-${this.previewParams['testcode']}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.showDownloadingStatus = false;
    }

    getAutoCompleteEmails ( emailIds ): string[] {
        const lastEmailId = emailIds.split(",").pop().trim();
        if ( lastEmailId.indexOf('@') > -1 ) return [];
        if ( lastEmailId.length < 3 ) return [];

        return this.gcExpertState.allUserEmails
            .filter(row => row.includes(lastEmailId))
            .slice(0, 5);
    }

    addThisEmail ( key, emailId ) {
        const value = this.gcExpertForm[key];
        const lastEmailId = value.split(",").pop().trim();

        this.gcExpertForm[key] = value.replace(lastEmailId, emailId);

    }

    addToOverrides ( key ) {
        if ( this.gcExpertState.overrides.indexOf(key) === -1 )
            this.gcExpertState.overrides.push(key);
    }

    async beginSendingToGCExpert () {
        this.gcExpertState.loading = true;
        this._util.modal('gc-expert-modal', 'show');
        const resp = await this._rest.sendMailToGCExpert(this.gcExpertForm, true);
        if ( !resp['success'] && resp['message'] ) {
            return await this._util.showAlert('Server resp', resp['message']);
        }

        this.gcExpertState.loading = false;
        this.gcExpertState.allUserEmails = resp['userEmails'];
        this.gcExpertState.mailVars = resp['mailVars'];
        this.gcExpertState.state = 'modal-open';
        this.gcExpertForm.ccEmail = this.gcExpertForm.ccEmail + "," + this.gcExpertState.mailVars['cc'];
        this.gcExpertForm.bccEmail = this.gcExpertForm.bccEmail + "," + this.gcExpertState.mailVars['bcc'];
    }

    async sendGCEmail () {
        this.gcExpertState.mailing = true;
        const resp = await this._rest.sendMailToGCExpert(this.gcExpertForm);

        this.gcExpertState.mailing = false;

        if ( !resp['success'] && resp['message'] ) {
            return await this._util.showAlert('Server resp', resp['message']);
        }

        await this._util.showAlert('Success', 'Mail sent to GC Expert', 'success');

        this._util.modal('gc-expert-modal', 'hide');
    }
}
