import { Component, OnInit } from '@angular/core';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import Swal from 'sweetalert2';


@Component({
  selector: 'app-outstanding-balance-dashboard',
  templateUrl: './outstanding-balance-dashboard.component.html',
  styleUrls: ['./outstanding-balance-dashboard.component.styl']
})
export class OutstandingBalanceDashboardComponent implements OnInit {

  isRPRole = false;
  selectedCustomerId = "";
  selectedBDMId = "";
  userName = "";
  outstandingBalanceRecords = [];
  filteredOutstandingBalanceRecords = [];
  recordsPageNo = 1;
  recordsLastPage = 1;
  bdmId = ""; 
  filterString = ""; 
  searchString = "";
  customerList = [];
  bdmList = [];


  constructor(
    private _rest: RestService,
    public _util : UtilityService,
    ) { }

  ngOnInit(): void {

    const userInfo = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));

    if(userInfo['permissions'].indexOf(this._util.Constants.Permissions.ApproveNewCustomer) > -1) this.isRPRole = true;
    this.userName  = (userInfo || {}).username;

    this.getOutstandingBalanceData();

  }


  async customerFilterChange(){
    if(!this.selectedCustomerId.length) return
    const selectedCustomer = this.customerList.find(element => {return element.pname === this.selectedCustomerId});
    const resp = await this._rest.filterByCustomer(selectedCustomer);
    this.filteredOutstandingBalanceRecords = this.outstandingBalanceRecords.filter(function(element){
      return resp['matchingPatientIDList'].indexOf(element.limp_patient_id) > -1; 
    });
  }

  async bdmFilterChange(){
    this.customerList = [];
    this.selectedCustomerId = "";
    if(!this.selectedBDMId.length) return;
    const selectedBDM = this.bdmList.find(element => {return element.ABARID === this.selectedBDMId});
    const resp = await this._rest.filterByBDM(selectedBDM);
    this.filteredOutstandingBalanceRecords = resp['bdmWiseData'];
    this.customerList = resp['customerList'];
  }

  resetAllFilters(){
    this.customerList = [];
    this.selectedCustomerId = "";
    this.selectedBDMId = "";
    this.filteredOutstandingBalanceRecords = this.outstandingBalanceRecords;
  }

  loadPageWithPrevQuery(newpageno){
    this.recordsPageNo = newpageno;
    this.getOutstandingBalanceData();
  }

  returnToDashboard(){
    this._util.redirectTo('jarvis-home');
  };

  async getOutstandingBalanceData(){
    const resp = await this._rest.getOutstandingBalanceData(this.recordsPageNo);
    this.outstandingBalanceRecords = resp['outstandingBalanceRecords'];
    if(this.isRPRole){
      this.bdmList = resp['bdmList'];  
    }
    this.customerList = resp['customerList'] || [];
    
    this.filteredOutstandingBalanceRecords = this.outstandingBalanceRecords;
  }

}
