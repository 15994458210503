<div class="container pb-3">
	<div class="row vertical-flex-on-mobile">
		
 		<div class="col-md-9 flex-filler">
 			<iframe title="MOU preview" class="fill-width min-height-100 " #iFrame ></iframe>
 		</div>

 		<div class="col-md-3">
 			<div class="d-flex flex-column justify-content-center mt-5">
 				<h3 class="bold mb-3">Make your Signatures</h3>
 				<div class="d-flex flex-column justify-content-center align-content-center" *ngIf="!showSignatureOptions">
 					<input type="text" name="contactPersonName" class="mt-3 mb-3" placeholder="Your name" [(ngModel)]='customerContactPersonName' required>
 					<input type="text" name="customerInstituteName" class="mt-3 mb-5" placeholder="Hospital / Clinic name" [(ngModel)]="customerInstituteName" required>
 					<button class="btn btn-primary" (click)="proceedToSignature()">
 						Proceed to Signature
 					</button>
 				</div>
 				<div class="d-flex flex-column justify-content-center" *ngIf="showSignatureOptions">
 					<div class="dropdown" *ngIf="!signatureDrawn.length">
  						<button class="btn btn-outline dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						    Choose Signature Font
						 </button>
						 <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						   <option *ngFor="let font of previewFontList" (click)="showPreview(font)">{{font.name}}</option>
						 </div>
					</div>
					<div *ngIf="showSignaturePreview">
						<div class="preview-box" [ngClass]="{'routerand-preview': selectedFontClass==='routerand-preview', 'maissiel-preview': selectedFontClass==='maissiel-preview', 'midnight-preview':selectedFontClass==='midnight-preview'}" id="signature-font-preview">{{customerContactPersonName}}</div> 	
						<div class="d-flex flex-row mb-2">
							<button class="btn btn-primary mr-1" (click)="saveSignatureFontPreview()" *ngIf="!signatureFontPreviewSelected">Save</button>
							<button class="btn btn-secondary ml-1" (click)="showSignaturePreview = false" *ngIf="!signatureFontPreviewSelected">Clear</button>
							<h4 *ngIf="signatureFontPreviewSelected">Selected</h4>
						</div>						
					</div>
					<div *ngIf="!showSignaturePreview">
						<h3 class="bold mt-2" >Or Draw your signatures</h3>
 						<signature-pad></signature-pad>
					</div>
 					<div class="d-flex flex-row">
						<button class="btn btn-primary mr-1" (click)='uploadSignatures()'>
							Save Signatures
						</button>
					</div>
 				</div>
 			</div>
 		</div>
	</div>
</div>