import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

	public uniqueEventEmitter = new EventEmitter();
	public tableRow_selectedTestEmitter = new EventEmitter();
	public tableRow_valueChangeEmitter = new EventEmitter();

	public table_selectedTestEmitter = new EventEmitter();
	public table_valueChangeEmitter = new EventEmitter();

	public tableSearch_filterStringEmitter = new EventEmitter();
	public tableSearch_searchStringEmitter = new EventEmitter();

	public signaturePad_signEmitter = new EventEmitter();
}

