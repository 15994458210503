<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <img src="/assets/images/lilac-logo.png" class="big-logo">
    </h5>
    <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
    <button type="button" class="btn btn-outline-danger"
            (click)="_util.logout()">{{ _util.logoutButtonText ||  "Logout" }}</button>
</div>

<div class="vertical-flex p-4">
    <div class="vertical-flex start-start">
        <h2 class="mb-0">Mark an address identifier Un-verified</h2>
        <h4 class="mt-0 mb-0">Its the opposite of 'mark verified' in jarvis platform. It will open verified entity to editing for Jarvis Flow.</h4>

        <div class="input-group mb-3">
            <input type="text" class="form-control" name="customer-identifier"
                   [(ngModel)]="markingUnverifiedIdentifier"
                   placeholder="Customer / Doctor identifier"
                   aria-label="Customer / Doctor identifier" aria-describedby="button-addon2">
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" id="button-addon2"
                        (click)="markUnVerify()"
                >Mark Un-Verify</button>
            </div>
        </div>

        <hr class="divider">

        <h2 class="mb-0">Reset OTP counter</h2>
        <div class="input-group mb-3">
            <input type="text" class="form-control" name="otp-reset-identifier"
                   [(ngModel)]="otpResetIdentifier"
                   placeholder="Executive E-mail id"
                   aria-label="Executive e-mail id" aria-describedby="button-addon3">
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" id="button-addon3"
                        (click)="resetOTPTimeout()"
                >Reset OTP</button>
            </div>
        </div>


    </div>


</div>
