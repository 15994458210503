import { Component, OnInit } from '@angular/core';
import {UtilityService} from "../utility.service";
import {RestService} from "../rest.service";


@Component({
  selector: 'app-download-biochem',
  templateUrl: './download-biochem.component.html',
  styleUrls: ['./download-biochem.component.styl']
})
export class DownloadBiochemComponent implements OnInit {

    selectionType = "";
    upcomingMonthsArray = [];
    selectionCenterId = null;
    selectionCode = null;
    selectionStartDate = null;
    selectionEndDate = null;
    selectionStartDateObject = null;
    verifiedDetails = false;
    pendingJobs = 0;
    runningJobs = 0;

    testSpecificForm = {
      'selectionStartDate': null,
      'selectionStartDateObject': null,
      'selectionEndDate': null,
      'testType': "",
      'verifiedDetails': false,
    }


    dateLimit = 35;
    monthLimit = 1;

    upcomingEddMonthsValue = "";
    upcomingEddResultType = "";
    upcomingEddMonthsEMailIds = "";

    userEmail = "";

  constructor(public _util: UtilityService, public _rest: RestService) { }

  ngOnInit(): void {
      this._util.redirectAccordingly();

      const user = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));
      this.userEmail = user.email;
      this.upcomingEddMonthsEMailIds = user.email;

      this.refreshQueuedJobsCount();
      this.fillUpcomingMonthsArray();
  }



    updateDateLimit() {
      switch (this.selectionType) {
          case 'D': this.dateLimit = 370; break;
          case 'C': this.dateLimit = 370; break;
          case 'E': this.dateLimit = 185; break;

          default:
              this.dateLimit = 95;
              break;
      }

      this.monthLimit = Math.floor(this.dateLimit / 30);
    }

    async refreshQueuedJobsCount() {
      const resp = await this._rest.getQueuedJobsCount();

      this.pendingJobs = resp["pending"] || 0;
      this.runningJobs = resp["running"] || 0;
    }

    updateSelectionEndDate() {
      console.log("selection start date: ", this.selectionStartDate);

      // this date cannot be older than 10 years
        const now = new Date();
        const start = new Date(this.selectionStartDate);

        this.selectionEndDate = null;
        if ( (now.getFullYear() - start.getFullYear()) > 10 ) return;

        this.selectionEndDate = new Date(start.getTime());
        this.selectionEndDate.setDate(this.selectionEndDate.getDate() + this.dateLimit);
        this.selectionStartDateObject = start;
    }

    async createBioChemJob() {
      console.log("createBioChemJob: ");

      const typeString = this.selectionType ? ( this.selectionType + '-' + this.selectionCenterId + '-' + this.selectionCode ) : '';

      const resp = await this._rest.createBioChemReportJob(typeString, this.selectionStartDateObject, this.selectionEndDate);
      if ( ! resp['success'] && resp['message']) return this._util.showAlert('Server error', resp['message'], 'error');

      await this._util.showAlert('Job created successfully', '', 'success');
      this.selectionEndDate = null;
      this.selectionStartDate = "";
      this.selectionStartDateObject = null;
      this.verifiedDetails = false;
      this.selectionType = "";
      this.selectionCenterId = null;
      this.selectionCode = null;

      await this.refreshQueuedJobsCount();
    }

    async createTestSpecificJob() {
      console.log("createTestSpecificJob : ");

      const typeString = this.testSpecificForm['testType'];
      const resp = await this._rest.createTestSpecificJob(typeString, new Date(this.testSpecificForm['selectionStartDate']), new Date(this.testSpecificForm['selectionEndDate']));
      
      this.testSpecificForm = {
        'selectionEndDate': null,
        'testType': "",
        'selectionStartDate': null,
        'selectionStartDateObject': null,
        'verifiedDetails': false,
      };

      await this._util.showAlert('Job created successfully', '', 'success');
      
      await this.refreshQueuedJobsCount();
    }

    async submitPNSUpcomingEDDJob() {
      console.log("submitPNSUpcomingJob: ");

      if ( ! this.upcomingEddMonthsValue || ! this.upcomingEddMonthsEMailIds) return;

      const scheduleDate = new Date();
      scheduleDate.setHours(23, 0, 0, 0);

      const resp = await this._rest.createQueuedDataJob("pns-upcoming-edd-list", {
          month: this.upcomingEddMonthsValue,
          emails: this.upcomingEddMonthsEMailIds,
          resultType: this.upcomingEddResultType,
          scheduleDate,
      });

      if ( ! resp['success'] && resp['message']) return this._util.showAlert("Server resp", resp['message']);

      await this._util.showAlert("Job queued successfully, you wll receive the e-mail soon", "", "success");
      this.upcomingEddMonthsValue = "";
      this.upcomingEddResultType = "";

      await this.refreshQueuedJobsCount();
    }

    fillUpcomingMonthsArray() {
        const today = new Date();

        today.setMonth(today.getMonth() - 12);

        for ( let i = 0; i < 24; i ++ ) {
            const date = new Date(today.getTime());
            const label = this._util.getMonthFromDate(date) + " " + date.getFullYear();

            this.upcomingMonthsArray.push({ date: date.getTime(), label });
            today.setMonth(today.getMonth() + 1);
        }
    }
}
