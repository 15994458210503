<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="overview-wrap horizontal-flex space-between-center mb-4">
        <div class="vertical-flex">
          <h2 class="title-1">Accessions</h2>
          <h6>Initial entry, so this system can be aware of incomming customers</h6>
        </div>

        <button type="button" class="btn btn-light" (click)="redirectToHome()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>

    <div class="fill-width card">
      <div class="card-body card-block">
        <form class="ui form" (ngSubmit)="submitForm()">
          <div class="row form-group">
            <div class="col col-md-3">
              <label for="form-location" class=" form-control-label">Lab location</label>
            </div>
            <div class="col-12 col-md-9">
              <select name="form-location" id="form-location" class="form-control" title="Select lab location"
                      required [(ngModel)]="form.location"
              >
                <option value="">Select Lab</option>
                <option *ngFor="let location of labLocations"
                        value="{{ location['id'] }}">{{ location['name'] }}</option>
              </select>
            </div>
          </div>

          <div class="row form-group">
            <div class="col col-md-3">
              <label for="barcode" class=" form-control-label">Barcode</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" id="barcode" name="text-input" placeholder="Scan or enter manually"
                     required title="Scan or enter barcode manually" [(ngModel)]="form.barcode"
                     class="form-control">
            </div>
          </div>

          <button class="btn btn-primary"
                  [ngClass]="{disabled: form.loading}"
                  type="submit">Submit</button>
        </form>
      </div>
    </div>

    <div *ngIf="formMessage.text.length"  class="col-md-12 alert " [ngClass]="formMessage.type" role="alert">
      {{formMessage.text}}
    </div>
  </div>
</div>
