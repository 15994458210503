import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {EventService} from '../event.service';


@Component({
  selector: 'app-test-search-filter',
  templateUrl: './test-search-filter.component.html',
  styleUrls: ['./test-search-filter.component.styl']
})
export class TestSearchFilterComponent implements OnInit {

  gcodes = [];
  scodes = [];

  selectedGcode = '';
  selectedScode = '';
  filterString = '';

  currentDepartmentTestLabels = {};

  @Input('testsSearchString') testsSearchString: string;
  @Input('testList')testList: any;
  @Input('testDropdownLabels') testDropdownLabels: any;
  @Output('testsSearchStringChanged') testsSearchStringChanged = new EventEmitter<string>();
  @Output('testsSearchStringReset') testsSearchStringReset = new EventEmitter<string>();
  @Output('filterStringChanged') filterStringChanged = new EventEmitter<string>();
  @Output('filterStringReset') filterStringReset = new EventEmitter<string>();

  constructor(public _event:EventService) { }

  ngOnInit(): void {
  }

  searchStringChange(){
    this._event.tableSearch_searchStringEmitter.emit(this.testsSearchString);
  }

  testFilterGenerator(){
    if(!this.gcodes.length && !this.scodes.length){
      this.testList = this.testList.map(function(element){
      return (element['Gcode'] || '') + (element['Scode'] || '') + (element['TestCode']|| '');
    });
    }
    let gcodelist = [];
    let scodelist = [];
    this.testList.forEach(function(element){
      gcodelist.push(element.substring(0,3));
      scodelist.push(element.substring(3,6));
    })
    this.gcodes= gcodelist.filter((item, index) => gcodelist.indexOf(item) === index);
    this.scodes= scodelist.filter((item, index) => scodelist.indexOf(item) === index);
  }

  resetSearchString(){
    this.testsSearchString = '';
    this._event.tableSearch_searchStringEmitter.emit(this.testsSearchString);
  }

  gcodeSelection(gcode){
    this.selectedGcode = gcode;
    this.filterString = this.selectedGcode;
    this.currentDepartmentTestLabels = this.testDropdownLabels[gcode]['testScodes'];
    this._event.tableSearch_filterStringEmitter.emit(this.filterString);
  }
  scodeSelection(scode){
    this.selectedScode = scode;
    this.filterString = this.filterString.substring(0,3) + this.selectedScode;
    this._event.tableSearch_filterStringEmitter.emit(this.filterString);
  }

  resetFilterString(){
    this.selectedGcode = '';
    this.selectedScode = '';
    this.filterString = '';
    this._event.tableSearch_filterStringEmitter.emit(this.filterString);
  }
}
