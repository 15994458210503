import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { UtilityService } from '../utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.styl']
})
export class ManageUsersComponent implements OnInit {

  users = [];
  roles = [];
  tokens = [];
  currentUser = null;
  managingUser = null;

  creatingUser = false;
  managingTokens = false;
  filterByRole = "";
  createButtonText = "";
  message = {
    text: "",
    type: "",
  }
  form = {
    name: "",
    username: "",
    email: "",
    password: "",
    role: "0",
  };
  rolesIdVsName = {};

  constructor(public _util: UtilityService, public _rest: RestService) { }

  ngOnInit(): void {

    this.refreshUsers();
    this.refreshTokens();

    if ( ! this.currentUser)
      this.currentUser = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user));

    if ( ! this._util.canUser(this._util.Constants.Permissions.ManageUsers))
      this._util.redirectTo();
  }

  async refreshUsers() {
    console.log("Refreshing users: ");

    const resp = await this._rest.getAllUsers();
    if ( ! resp["success"] && resp["message"]) return this._util.showAlert("Server error: ", resp["message"]);

    if ( resp["roles"]) this.roles = resp["roles"];
    this.roles.forEach(role => {
        this.rolesIdVsName[role.id] = role.name;
    });

    if ( resp["users"]) this.users = resp["users"]
        .filter(this.filterOutCurrentUser)
        .map(this.mapUserForFrontend);
  }

  async refreshTokens() {
    console.log("refreshing tokens: ");

    const resp = await this._rest.refreshAppKeys();
    if ( ! resp["success"] && resp["message"])
      this._util.showAlert("Server error", resp["message"]);

    this.tokens = resp["tokens"] || [];
  }

  filteredUsers = () => {
    if ( ! this.filterByRole) return this.users;

    return this.users.filter(user => {
      return user.roles
        .map(role => role.id)
        .indexOf(parseInt(this.filterByRole)) > -1;
    });
  }

  isSuperAdmin = (user) => {
    return user.roles.map(row => row.name).indexOf('superadmin') > -1;
  }

  isOperationsHOD = (user) => {
    return user.roles.map(row => row.name).indexOf('operations-hod') > -1;
  }

  isLabHOD = (user) => {
    return user.roles.map(row => row.name).indexOf('admin') > -1;
  }

  uploadSignature = (user) => {
    console.log("preparing to upload signature: ", user);

    this.managingUser = user;
    document.getElementById('signature-upload-container').click();
  }

  onSignatureUploaded = async (event) => {
    if ( ! event.target.files.length) return;

    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('signature', file);
    formData.append('user', this.managingUser.id);

    const resp = (await this._rest.uploadSignature(formData))["body"];

    console.log("resp: ", resp);
    if ( ! resp['success'] && resp['message'])
      return this._util.showAlert('Server error', resp['message']);

    if ( resp['user'] && resp['user']['signature'])
      this.managingUser.signature = resp['user']['signature'];

    return this._util.showAlert('Uploaded successfully', '', 'success');
  }

  createToken = () => {
    Swal.fire({
      title: 'Enter label',
      text: 'To identify the tokens',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Generate token',
      showLoaderOnConfirm: true,
      preConfirm: (label) => {
        return new Promise((resolve, reject) => {

          const promise = this._rest.createToken(label)
            .then(response => {
              if (! response["success"]) {
                throw new Error(response["message"])
              }

              return response
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            });

          return resolve(promise);
        });
      },

      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Token created`,
          icon: `success`
        });
      }

      this.refreshTokens();
    })
  }

  enableToken = async (i, active) => {
    const token = this.tokens[i];
    token.active = active;

    this.tokens[i] = token;
    const resp = await this._rest.updateToken(token.id, active);

    if ( ! resp["success"] && resp["message"]) {
      this._util.showAlert("Server error", resp["message"]);

      token.active = ! active;
      this.tokens[i] = token;
    }
  }

  deleteToken = async (i, active) => {
    const token = this.tokens[i];

    this.tokens.splice(i, 1);

    const resp = await this._rest.deleteToken(token.id);

    if ( ! resp["success"] && resp["message"]) {
      this._util.showAlert("Server error", resp["message"]);
    }

    await this.refreshTokens();
  }



  filterOutCurrentUser = (row ) => {
    return row.id !== this.currentUser.id;
  }

  cancelCreatingUser = () => {
    this.creatingUser = false;
    this.managingTokens = false;

    this.form = {
      name: "",
      username: "",
      email: "",
      password: "",
      role: "0",
    };
  }

  roleCompareFn = (row1, row2) => {
    return true;
  }

  createUser = async () => {
    if ( ! this.form.name || ! this.form.username || ! this.form.email || ! this.form.password || (this.form.role === ''))
      return this._util.showAlert("Form validation", "Missing required fields");

    if ( ! this._util.isPasswordValid(this.form.password))
      return this._util.showAlert("Form validation", "Password not matching creteria");

    this.createButtonText = "Creating ...";

    const resp = await this._rest.createUser(this.form.name, this.form.username,
      this.form.email, this.form.password, this.form.role
    );

    // @ts-ignore
    if ( ! resp.success && resp.message) {
      this._util.showAlert("Server resp", resp["message"]);
      this.createButtonText = "";
      return;
    }

    // @ts-ignore
    this.message.text = "Created successfully";
    this.message.type = "success";
    this.createButtonText = "";
    this.creatingUser = false;

    setTimeout(() => {
      this.message.text = "";
    }, 3000);

    console.log(" creatingUser: ", this.form, resp);

    this.refreshUsers();
  }

  mapUserForFrontend = (row) => {
    row.name = row.firstname + " " + row.lastname;
    row.haveChanges = false;
    row.saveButtonText = "save";
    row.password = "";
    row.role_id = (row.roles[0] || {}).id || 0;
    row.isSalesExecutive = this.rolesIdVsName[row.role_id] === this._util.Constants.Roles.SalesExecutive;

    return row;
  }

  enableLogin(user) {
      const index = this.findIndexByUser(user);

    this.users[index].disabled = false;
    this.users[index].haveChanges = true;

    this.saveUser(index);
  }

  async disableLogin(passedUser) {
      const index = this.findIndexByUser(passedUser);

    const user = this.users[index];

    user.disabled = true;
    user.saveButtonText = "Disabling ...";
    this.users[index] = user;

    const resp = await this._rest.disableUser(user.id);
    if (! resp['success'] && resp['message']) {
      this._util.showAlert("Server resp", resp['message']);
      this.users[index].saveButtonText = "save";
      return;
    }

    this.users[index].saveButtonText = "Disabled successfully";

    setTimeout(() => {
      this.users[index].saveButtonText = "save";
    }, 3000);
  }

  findIndexByUser(user) {
      return this.users.map(row => row.email).indexOf(user.email);
  }

  async saveUser(passedUser) {
      const index = this.findIndexByUser(passedUser);
    this.users[index].saveButtonText = "saving ...";
    const user = this.users[index];

    const resp = await this._rest.updateUser(user.id, user.username, user.name, user.email, user.disabled, user.role_id);
    if (! resp['success'] && resp['message']) {
      this._util.showAlert("Server resp", resp['message']);
      this.users[index].saveButtonText = "save";
      return;
    }

    this.users[index].saveButtonText = "Saved successfully";
    this.users[index].haveChanges = false;

    setTimeout(() => {
      this.users[index].saveButtonText = "save";
    }, 3000);
  }

  async updatePassword(user) {
      const index = this.findIndexByUser(user);

    Swal.fire({
      title: 'New password for ' + this.users[index].name,
      text: 'More than 8 characters alpha numeric with Uppercase, lowercase and Special characters',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Update',
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        return new Promise((resolve, reject) => {
          if (! this._util.isPasswordValid(password)) return resolve(Swal.showValidationMessage(
            `Invalid password`
          ));

          const promise = this._rest.updateUserPassword(this.users[index].id, password)
            .then(response => {
              if (! response["success"]) {
                throw new Error(response["message"])
              }

              return response
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            });

          return resolve(promise);
        });
      },

      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Password updated`,
          text: `for ${this.users[index].name}`,
          icon: `info`
        });
      }
    })
  }

  async askForceReSync(user) {
      console.log("asking for force-resync")
      if ( ! confirm("Are you sure? This action is not reversible")) return;

      const resp = await this._rest.updateUserForceResyncStatus(user.id);

      if ( ! resp['success'] && resp['message']) return this._util.showAlert("Server resp", resp['message']);

      return this._util.showAlert(
          "Marked successfully",
          "Next time this user will asked to refill his centerID and code in Jarvis Portal",
          "success");
  }

}
