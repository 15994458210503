<div class="container-fluid pt-3 fill-height">

    <div class="modal" tabindex="-1" id="blacklist-customer-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-dialog-centered blacklist-modal-container">
            <div class="modal-content">
                <div class="modal-body blacklist-modal-body">
                    <div class="vertical-flex">
                        <h3 class="mb-3">Enter Customer Ids of Customer to be blacklisted (1 customer Id per line):</h3>
                        <textarea class="form-control mt-2 blacklist-modal-textArea" rows="3"
                                  [(ngModel)]="customersToBeBlacklisted" placeholder="Enter here..."></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            [disabled]="!customersToBeBlacklisted.length"
                            (click)="blacklistCustomer()">
                        Submit
                    </button>
                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close"
                            (click)="closeBlacklistModal()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <h2 class="title-2 mb-1">{{ "Hi, " + userName }}</h2>

            <div class="overview-item mb-2 overview-item--c1 mt-4">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="text">
                            <h2 class="mb-2">Time Duration</h2>
                            <span class="fill-width">
                                <select name="statistics-range" id="statistics-range" [(ngModel)]="statisticsRange"
                                        title="Time range for statistics" (change)="fetchJamsfyStatistics()">

                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="currentMonth">Month Start - Present</option>
                                    <option value="currentQuarter">Quarter Start - Present</option>
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <app-jamsify-records-statistics [whatsappAttemptCount]="whatsappAttemptCount"
                                            [whatsappSentCount]="whatsappSentCount"
                                            [whatsappOptInCount]="whatsappOptInCount"></app-jamsify-records-statistics>

            <app-jamsify-statistics-charts [xAxisLabels]="chartXAxisLabels"
                                           [yAxisData]="chartYAxisData"></app-jamsify-statistics-charts>

            <button type="button" class="btn btn-outline-danger btn-lg btn-block mb-4" (click)="_util.logout()">{{
                "Logout" }}
            </button>
        </div>

        <div class="col-md-9">
            <div class="row">

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12 horizontal-flex space-between-center">
                            <div class="flex-fill"></div>
                            <button type="button" class="btn btn-outline-info ml-2" (click)="openBlacklistModal()">
                                Blacklist Customers
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 horizontal-flex space-between-center mt-2 mb-2 pl-0">
                    <div class="input-group col-md-8 mr-2 pl-0">
                        <input type="search" class="form-control" (input)="searchStringChange()"
                               name="recordsSearchString"
                               id="search-input"
                               [(ngModel)]="recordsSearchString" placeholder="Search ..."
                               aria-label="by Patient ID, Barcode or Patient name">

                        <div class="input-group-append" *ngIf="recordsSearchString.length">
                            <button type="button" class="btn btn-danger" (click)="resetSearchString()">
                                <i class="zmdi zmdi-close red"></i>
                            </button>
                        </div>
                    </div>

                    <nav aria-label="Page navigation example">
                        <ul class="pagination col-md-4">
                            <li class="page-item " [ngClass]="{'disabled': recordsPageNo === 1}">
                                <a class="page-link" aria-label="Previous"
                                   (click)="loadPageWithPrevQuery(recordsPageNo - 1)">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>

                            <li class="page-item" [ngClass]="{'active': recordsPageNo === i}"
                                *ngFor="let i of _util.getPagesRange(recordsPageNo, recordsLastPage)">
                                <a class="page-link" (click)="loadPageWithPrevQuery(i)">{{ i }}</a>
                            </li>

                            <li class="page-item" [ngClass]="{'disabled': recordsPageNo === recordsLastPage}">
                                <a class="page-link" aria-label="Next"
                                   (click)="loadPageWithPrevQuery(recordsPageNo + 1)">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div class="alert alert-info mt-4 mr-2 fill-width" role="alert" *ngIf="status.fetchingLogs || status.fetchingCounts">
                    <span *ngIf="status.fetchingLogs">Fetching logs ...</span>
                    <span *ngIf="status.fetchingCounts">Fetching counts ...</span>
                </div>


                <div class="alert alert-warning fade show mt-4 mr-2 fill-width" role="alert"
                     [hidden]="whatsappRecords.length || status.fetchingLogs">
                    No records found !!
                </div>

                <div class="col-md-12 table-responsive full-screen-table pl-0" id="reocrds-grid"
                     [hidden]="!whatsappRecords.length"
                     #recordsGrid>

                    <p class="small red">This listing only shows record of last 20 days.</p>

                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <th scope="col" style="min-width: 102px;"># Barcode</th>
                            <th scope="col" style="min-width: 102px;"># Patient ID</th>
                            <th scope="col" style="min-width: 102px;">Customer Code</th>
                            <th scope="col" style="min-width: 102px;">Customer Mobile</th>
                            <th scope="col" style="min-width: 102px;">Log type</th>
                            <th scope="col" style="min-width: 102px;">Status</th>
                            <th scope="col" style="min-width: 200px;">Whatsapp Response</th>
                            <th scope="col" style="min-width: 200px;">Actions</th>
                            <th scope="col" style="min-width: 100px;">Created At</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let whatsappRecord of filteredWhatsappRecords">
                            <td>{{whatsappRecord['barcode']}}</td>
                            <td>{{whatsappRecord['data']['patientId']}}</td>
                            <td>{{whatsappRecord['data']['customerId']}}</td>
                            <td>{{whatsappRecord['data']['patientMobile']}}</td>
                            <td>{{whatsappRecord['description']}}</td>
                            <td>{{ statusFromResp(whatsappRecord['data']) }}</td>
                            <td>{{whatsappRecord['data']['resp'] || whatsappRecord['data']['optInResp'] || ''}}</td>
                            <td>
                                <button class="btn btn-primary"
                                        (click)="convertJamsifyCustomer(whatsappRecord['data']['customerId'], 'whitelist')">
                                    Whitelist
                                </button>&nbsp;<button class="btn btn-secondary"
                                                       (click)="convertJamsifyCustomer(whatsappRecord['data']['customerId'], 'blacklist')">
                                Blacklist
                            </button>
                            </td>
                            <td>{{whatsappRecord['created_at']}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


</div>
