import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from "../../utility.service";
import Swal from "sweetalert2";
import { RestService } from "../../rest.service";
import { interval, Subscription } from "rxjs";

@Component({
    selector: 'app-reports-session-manager',
    templateUrl: './reports-session-manager.component.html',
    styleUrls: ['./reports-session-manager.component.styl']
})
export class ReportsSessionManagerComponent implements OnInit, OnDestroy {

    @Input("previewParams") previewParams: any;

    currentSessionDetails = {};
    timerSubscription: Subscription;
    user: any = {};
    sessionExpiryShown = false;
    haveAdminRights = false;

    constructor ( public _util: UtilityService, private _rest: RestService ) {
    }

    ngOnInit (): void {
        this.user = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));
        this.haveAdminRights = this._util.canUser(this._util.Constants.Permissions.TakeOverSession);

        if ( Object.keys(this.previewParams).length ) {
            this.checkForExistingActiveSession();
        }

        //checks in every 10 seconds
        this.checkForSessionEnd();
        this.timerSubscription = interval(10000).subscribe(( val ) => {
            this.checkForSessionEnd();
        });
    }


    async ngOnDestroy () {
        if ( this.currentSessionDetails['session_owner_id'] !== this.user['id'] ) {
            await this.terminateSession();
        }
        this.timerSubscription.unsubscribe();
    }

    async checkForExistingActiveSession () {
        const currentDate = new Date();
        const resp = await this._rest.checkForExistingActiveSession(this.previewParams);
        if ( !resp['success'] && resp['message'] ) {
            await Swal.fire('Error in checking session', resp['message'], 'error');
        } else {
            if ( resp['activeSession'] ) {
                this.currentSessionDetails = {
                    'session_owner': resp['sessionOwner'],
                    'session_owner_id': resp['sessionOwnerId'],
                    'id': resp['sessionId'],
                    'session_valid_till': resp['sessionValidTill'],
                    'sealedTimeDiffernece': this._util.calcSealedDateDiff(currentDate, resp['sessionValidTill']),
                }
                if ( this.currentSessionDetails['session_owner_id'] !== this.user['id'] ) {
                    this._util.modal('session-occupancy-warning', 'show');
                }
            } else {
                const creationResp = await this._rest.createSession(this.previewParams);
                if ( creationResp['success'] ) {
                    this.currentSessionDetails = creationResp['reportSession'];
                }
            }
        }
    }

    checkForSessionEnd () {
        if ( (this.currentSessionDetails || {})['session_valid_till'] ) {
            const currentDate = new Date();
            let timeDifference = (new Date(this.currentSessionDetails['session_valid_till']).getTime() - currentDate.getTime()) / 1000; //in seconds
            if ( timeDifference <= 10 && timeDifference > 0 ) {
                this._util.modal('session-termination-warning', 'show');
                setTimeout(() => {
                    this._util.modal('session-termination-warning', 'hide');
                }, 10000); // auto close in 10 seconds in case of no response
            }

            if ( timeDifference <= 0 && !this.sessionExpiryShown ) {
                this.sessionExpiryShown = true;

                this._util.showSessionExpiredPopup(this._util.refreshPage.bind(this))
            }
        }
    }

    async extendSession () {
        const resp = await this._rest.extendReportSession(this.previewParams, this.currentSessionDetails);
        if ( resp['success'] ) {
            this.currentSessionDetails = resp['success'];
        }
    }


    async terminateSession () {
        const resp = await this._rest.terminateSession(this.previewParams, this.currentSessionDetails);
    }


    async takeOverSession () {
        const resp = await this._rest.takeOverSession(this.previewParams, this.currentSessionDetails);
        if ( resp['success'] ) {
            await Swal.fire("Take over successful", "Session Taken over successfully", "success");
            this.currentSessionDetails = resp['reportSession'];
        } else {
            await Swal.fire("Error in Take Over", "Error in taking over session", "error")
        }
        this._util.modal('session-occupancy-warning', 'hide');
    }

    async redirectBack () {
        this._util.modal('session-occupancy-warning', 'hide');
        window.top.close();
    }

}
