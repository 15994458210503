<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <img src="/assets/images/lilac-logo.png" class="big-logo">
    </h5>
    <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
    <button type="button" class="btn btn-outline-danger"
            (click)="_util.logout()">{{ _util.logoutButtonText ||  "Logout" }}</button>
</div>



<div class="container pb-3">
    <div class="row">
        <div class="col-md-12">
            <h2 class="horizontal-flex start-center">
                <span class="flex-filler">Download bio-chem data</span>

                <span *ngIf="pendingJobs" class="red">
                    <strong>{{ pendingJobs }}</strong> job(s) pending
                </span>
                <span *ngIf="pendingJobs && runningJobs">, </span>
                <span *ngIf="runningJobs" class="green">
                    <strong>{{ runningJobs }}</strong> job(s) running
                </span>

            </h2>

            <form (ngSubmit)="createBioChemJob()">
                <div class="form-group">
                    <label for="fill-type">Criteria for picking patients?</label>

                    <div class="horizontal-flex start-center">
                        <select name="fill-type" class="form-select mr-4"
                                aria-label="Select type of user" id="fill-type"
                                (change)="updateDateLimit()"
                                [(ngModel)]="selectionType">
                            <option selected value="">All</option>
                            <option value="D">Doctor</option>
                            <option value="C">Customer</option>
                            <option value="E">Executive</option>
                        </select>

                        <input title="Select center id" type="number"
                               name="fill-center-id"
                               placeholder="Center ID"
                               class="form-control mr-4" id="fill-center-id"
                               [(ngModel)]="selectionCenterId"
                               *ngIf="selectionType && selectionType.length">
                        <input title="select code" type="number"
                               class="form-control mr-4" id="fill-code"
                               placeholder="Code"
                               name="fill-code"
                               [(ngModel)]="selectionCode"
                               *ngIf="selectionType && selectionType.length">
                    </div>
                </div>

                <div class="alert alert-primary" role="alert" *ngIf="selectionType">
                    Data date limit is set for {{ this.dateLimit }} days ( or ~{{ monthLimit }} months ). <br>Please select start date from below.
                </div>

                <div class="form-group">
                    <label for="start-date">Start date</label>
                    <input type="date" name="start-date" class="form-control" id="start-date" aria-describedby="start date for selecting patients" [(ngModel)]="selectionStartDate" (change)="updateSelectionEndDate()">
                </div>

                <div class="alert alert-primary" role="alert" *ngIf="selectionEndDate">
                    Will sending details over mail for <strong>{{ selectionType ? ( selectionType + '-' + selectionCenterId + '-' + selectionCode ) : 'all' }}</strong> records between <strong>{{ selectionStartDateObject.toDateString() }}</strong> and <strong>{{ selectionEndDate.toDateString() }}</strong> to <strong>{{ userEmail }}</strong>
                </div>

                <div class="form-group form-check" *ngIf="selectionEndDate">
                    <input type="checkbox" name="verified-details" [(ngModel)]="verifiedDetails" class="form-check-input" id="verified-details" required="required">
                    <label class="form-check-label" for="verified-details">I verified all above mentioned details.</label>
                </div>

                <button type="submit" class="btn btn-primary" [disabled]="! verifiedDetails">Create job</button>
            </form>

        </div>

        <hr class="divider mb-2 mt-2">

        <div class="col-md-12">
            <h2 class="horizontal-flex start-center">
                <span class="flex-filler">Download Test Specific Report Data</span>
            </h2>

            <form (ngSubmit)="createTestSpecificJob()">
                <div class="form-group">
                    <label for="fill-type">Select Test?</label>

                    <div class="horizontal-flex start-center">
                        <select name="fill-type" class="form-select mr-4"
                                aria-label="Select test-type" id="fill-type"
                                [(ngModel)]="testSpecificForm['testType']">
                            <option value="thal">THAL</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label for="start-date">Start date</label>
                    <input type="date" name="start-date" class="form-control" id="start-date" aria-describedby="start date for selecting patients" [(ngModel)]="testSpecificForm['selectionStartDate']" >
                </div>

                <div class="form-group">
                    <label for="end-date">End date</label>
                    <input type="date" name="start-date" class="form-control" id="end-date" aria-describedby="end date for selecting patients" [(ngModel)]="testSpecificForm['selectionEndDate']" >
                </div>

                <div class="form-group form-check" *ngIf="testSpecificForm['selectionEndDate']">
                    <input type="checkbox" name="verified-details" [(ngModel)]="testSpecificForm['verifiedDetails']" class="form-check-input" id="verified-details" required="required">
                    <label class="form-check-label" for="verified-details">I verified all above mentioned details.</label>
                </div>

                <button type="submit" class="btn btn-primary" [disabled]="! testSpecificForm['verifiedDetails']">Create job</button>
            </form>

        </div>

        <hr class="divider mb-2 mt-2">

        <div class="col-md-12 mt-3">
            <h2>Download PNS Upcoming EDD data</h2>

            <form (ngSubmit)="submitPNSUpcomingEDDJob()">

                <div class="form-group">
                    <label for="fill-type" class="mb-0">Criteria for picking patients?</label>

                    <div class="vertical-flex start-start">
                        <p class="mb-0"></p>
                        <select name="upcoming-edd-patients-month" class="form-select mr-4"
                                aria-label="Select type of user" id="upcoming-edd-patients-month"
                                [(ngModel)]="upcomingEddMonthsValue">
                            <option selected value="">Choose EDD month</option>
                            <option [value]="option.date"
                                    *ngFor="let option of upcomingMonthsArray" >
                                {{ option.label }}</option>
                        </select>

                        <p class="mb-0 mt-2">ctrl + click, to choose multiple</p>
                        <select name="upcoming-edd-patients-month" class="form-select mr-4"
                                multiple aria-label="Select type of user" id="upcoming-edd-patients-risk-type"
                                [(ngModel)]="upcomingEddResultType">
                            <option selected value="">Choose Result type</option>
                            <option >SCREEN NEGATIVE</option>
                            <option >SCREEN INTERMEDIATE</option>
                            <option >SCREEN POSITIVE</option>
                            <option >LOW RISK</option>
                            <option >INTERMEDIATE RISK</option>
                            <option >HIGH RISK</option>
                            <option >REFUND</option>
                            <option >RESAMPLE</option>
                        </select>
                    </div>
                </div>

                <div class="form-group" [hidden]="true">
                    <label for="upcoming-edd-email-ids">Email addresses</label>
                    <input type="email" class="form-control" id="upcoming-edd-email-ids"
                           [(ngModel)]="upcomingEddMonthsEMailIds"
                           name="upcoming-edd-email-ids"
                           placeholder=", seperated"
                           aria-describedby="Where you want to receive e-mail">
                </div>

                <button type="submit" class="btn btn-primary">Create Job</button>
            </form>
        </div>
    </div>
</div>
