import { Component, OnChanges, Input, Output, SimpleChanges  } from '@angular/core';

@Component({
  selector: 'app-jamsify-records-statistics',
  templateUrl: './jamsify-records-statistics.component.html',
  styleUrls: ['./jamsify-records-statistics.component.styl']
})
export class JamsifyRecordsStatisticsComponent implements OnChanges{

  @Input('whatsappSentCount') whatsappSentCount: any;
  @Input('whatsappAttemptCount') whatsappAttemptCount: any;
  @Input('whatsappOptInCount') whatsappOptInCount: any;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.whatsappSentCount = changes['whatsappSentCount'].currentValue;
    this.whatsappOptInCount = changes['whatsappOptInCount'].currentValue;
    this.whatsappAttemptCount = changes['whatsappAttemptCount'].currentValue;
  }

}
