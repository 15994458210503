import {Component, OnInit} from '@angular/core';
import {UtilityService} from "../utility.service";
import {RestService} from "../rest.service";

@Component({
  selector: 'app-sales-customer-listing',
  templateUrl: './sales-customer-listing.component.html',
  styleUrls: ['./sales-customer-listing.component.styl']
})
export class SalesCustomerListingComponent implements OnInit {

    EditableFields = [];
    DoctorEditableFields = [];
    CustomerEditableFields = [];
    FieldLabels = {};
    DoctorFieldLabels = {};
    CustomerFieldLabels = {};
    BooleanFields = [];
    MobileNumberFields = [];
    DateFields = [];
    DatetimeFields = [];
    SelectionBoxes = [];
    NonLabmateFields = [];
    OptionalFields = [];
    SelectionBoxLabels = [];
    SelectionBoxOptions = {};
    EnabledOnlyIf = {};
    SemicolonSeperated = [];
    SemicolonSeperatedValues = {};
    DisabledFields = [];
    UnlinkingIdentifiers = [];
    HiddenFields = [];

    updatedFields = [];
    acceptedFields = [];
    rejectedFields = [];

    customers = [];
    doctors = [];
    doctorCustomer = {};
    customerPagination = {
        current: 1,
        max: 1,
    }
    stats = {
        customer: {
            verified: 0,
            underReview: 0,
            touched: 0,
            all: 0,
        },
        doctor: {
            verified: 0,
            underReview: 0,
            touched: 0,
            all: 0,
        },
    };
    underReview = [];
    unlinkedDoctors = [];
    uniqueHOLocations = [];
    disabledIdentifiers = [];
    chosenHOLocation = "";
    verificationFilterValue = "";

    loading = false;
    editing = false;
    reviewing = false;
    showingDoctor = false;
    editingType = '';
    editingLabel = '';
    searchQuery = "";

    workingObject = {};
    workingObjectBackup = {};

  constructor(public _util: UtilityService, public _rest: RestService) { }

  ngOnInit(): void {
      if ( ! this._util.canUser( this._util.Constants.Permissions.SalesDataView))
          return this._util.redirectTo('login');

      this.fillBaseConstants().then(this._util.doNothing);
  }

  getValueForField(fieldName, isCurrent = false) {
      const fieldValue = isCurrent ? this.workingObject[fieldName] : this.workingObjectBackup[fieldName];

      if ( this.BooleanFields.indexOf(fieldName) > -1 )
          return ["0", 0, false, null, undefined, "false"].indexOf(fieldValue) > -1 ? "No" : "Yes";

      if ( this.DatetimeFields.indexOf(fieldName) > -1)
          return this._util.showDateLocally(fieldValue);

      if ( this.DateFields.indexOf(fieldName) > -1)
          return this._util.niceDateFormat(fieldValue);

      if ( this.SelectionBoxLabels.indexOf(fieldName) > -1) {
          return this.SelectionBoxOptions[fieldName][ fieldValue ] || "";
      }

      if ( this.SemicolonSeperated.indexOf(fieldName) > -1) {
          return (fieldValue || "").split(";").join(" ");
      }

      return isCurrent
          ? this.undefinedOrEmpty(this.workingObject[fieldName])
          : this.undefinedOrEmpty(this.workingObjectBackup[fieldName]);
  }

  isFieldDisabled(fieldName) {
      if ( Object.keys(this.EnabledOnlyIf).indexOf(fieldName) === -1) return false;

      const conditions = this.EnabledOnlyIf[fieldName];
      if ( ! conditions || ! Object.keys(conditions).length) return false;

      let valid = true;
      Object.keys(conditions).forEach(keyName => {
          if (! valid) return;

          if ( conditions[keyName].indexOf(this.workingObject[keyName]) === -1)
              valid = false;
      });

      return ! valid;
  }

  undefinedOrEmpty(value) {
      if ( typeof value === "undefined") return "";
      else return value;
  }

  updateDisabledFields() {
      this.DisabledFields = [];

      this.EditableFields.forEach(fieldName => {
          if ( this.isFieldDisabled(fieldName))
              this.DisabledFields.push(fieldName);
      });
  }

  getOptionsForSelectBox(fieldName) {
      const selectionBox = this.SelectionBoxes.filter(row => row.name === fieldName);
      if ( ! selectionBox) return {};

      const options = selectionBox[0]["options"];
      if ( ! options || ! options.length) return {};

      return options;
  }

  async fillBaseConstants() {
        const resp = await this._rest.getSalesConstants();
        if ( ! resp['success']) return;

        this.FieldLabels = resp['labels'] || {};
        this.DoctorFieldLabels = resp["doctorLabels"] || {};
        this.CustomerFieldLabels = resp["customerLabels"] || {};

        this.EditableFields = resp['editableFields'] || [];
        this.DoctorEditableFields = Object.keys(this.DoctorFieldLabels);
        this.CustomerEditableFields = Object.keys(this.CustomerFieldLabels);

        this.BooleanFields = resp['booleans'] || [];
        this.MobileNumberFields = resp['mobileNumbers'] || [];
        this.DatetimeFields = resp['dateTimes'] || [];
        this.DateFields = resp["dates"] || [];

        this.SelectionBoxes = resp['selectedBoxes'] || [];
        this.SemicolonSeperated = resp['semicolonSeperated'] || [];
        this.EnabledOnlyIf = resp["enabledOnlyIf"] || {};
        this.HiddenFields = resp['hiddenToBDM'] || [];
        this.OptionalFields = resp['optionalFields'] || [];

        this.SelectionBoxLabels = this.SelectionBoxes.map(row => row.name);
        this.SelectionBoxes.forEach(row => { this.SelectionBoxOptions[row.name] = row.options; });

        await this.refreshCustomerListing();
  }

  async loadCustomerListingWithPage(page: number) {
        this.customerPagination.current = page;
        this.refreshCustomerListing();
  }

  async beginEditing(obj, type: string) {

      obj["ReportEmailDeliveryMode"] = this._util.convertValueTo10(obj["EmailReport"]) + "-" + this._util.convertValueTo10(obj["SendEmailWHeader"]);
      obj["ReportsPrintedAt"] = this._util.convertValueTo10(obj["DefaultCenterReport"]) + "-" + this._util.convertValueTo10(obj["RepPrinterAtCenWHeader"]);

      for ( let key in obj) {
          if ((typeof obj[key] === "string") && obj[key].length)
              obj[key] = obj[key].trim();
      }

    this.workingObjectBackup = { ...obj };
    this.workingObject = { ... obj };
    this.editing = true;
    this.editingType = type;
    this.updatedFields = [];

    switch (type) {
        case 'customer':
            this.editingLabel = `Editing customer ${obj.name} (${obj.identifier})`;
            this.EditableFields = this.CustomerEditableFields;
            this.FieldLabels = this.CustomerFieldLabels;
            break;
        case 'doctor':
            this.editingLabel = `Editing doctor ${obj.pname} for customer ${this.doctorCustomer['identifier']}`;
            this.EditableFields = this.DoctorEditableFields;
            this.FieldLabels = this.DoctorFieldLabels;
            break;
        default:
            this.editingLabel = '';
    }

    this.updateDisabledFields();
    this.SemicolonSeperated.forEach(fieldName => {
        this.SemicolonSeperatedValues[fieldName] = (this.workingObject[fieldName] || "")
            .split(';') || [ "" ];
    });

  }

  updateSemicolonSeperatedValue(field, event, index) {
      event.stopPropagation();

      this.SemicolonSeperatedValues[field][index] = event.target.value;

      this.workingObject[field] = this.SemicolonSeperatedValues[field]
          .filter(row => row && row.length).join(';');

      if ( this.workingObject[field] === this.workingObjectBackup[field])
          return;

      if ( this.updatedFields.indexOf(field) === -1)
          this.updatedFields.push(field);

      this.updateDisabledFields();

      return true;
  }

    onlyAllowNumericValues(event, field) {
      if ([
          "Backspace", "Delete", "ArrowLeft", "ArrowRight",
          "ArrowUp", "ArrowDown", "Control", "Alt",
          "Shift", "OS"
      ].indexOf(event.key) > -1)
          return;

      console.log("key: ", event.key);

      if ( isNaN(parseInt(event.key)))
          return event.preventDefault();

      if ( (this.workingObject[field] + "").length === 10)
          return event.preventDefault();
    }

  async addUpdatedField(field, event) {
      event.stopPropagation();
      event.preventDefault();

      this.workingObject[field] = event.target.value;

      if ( (this.workingObject[field] + "") === (this.workingObjectBackup[field] + "")) {
          if ( this.updatedFields.indexOf(field) > -1)
              this.updatedFields.splice(this.updatedFields.indexOf(field), 1);

          return;
      }

      if ( this.getValueForField(field, true) === this.getValueForField(field, false)) {
          if ( this.updatedFields.indexOf(field) > -1)
              this.updatedFields.splice(this.updatedFields.indexOf(field), 1);

          return;
      }

      if ( this.updatedFields.indexOf(field) === -1)
          this.updatedFields.push(field);

      this.updateDisabledFields();
  }

    async resetSearch() {
        this.searchQuery = "";
        await this.refreshCustomerListing();
    }

  async refreshCustomerListing() {
        this.loading = true;
        const resp = await this._rest.getSalesCustomersListing(this.customerPagination.current, this.searchQuery, this.chosenHOLocation, this.verificationFilterValue);

        this.loading = false;

        if ( ! resp['success'] && resp['message'])
            return await this._util.showAlert('Server says: ', resp['message']);

        if ( resp["customers"] && resp["customers"].length) {
            this.customers = resp["customers"];

            /* this.customers.forEach(customer => {
                const executive = customer.executive || {};
                const locationName = executive.locationName || "";

                if ( locationName && locationName.length && (this.uniqueHOLocations.indexOf(locationName) === -1))
                    this.uniqueHOLocations.push(locationName);
            }) */

            console.log("customers: ", this.customers.map(row => {
                return { identifier: row.identifier, verified: row.verified }
            }))
        } else {
            this.customers = [];
            this.customerPagination ={
                current: 1,
                max: 1,
            };
            return this._util.showAlert("No customers", "Customers for this particular filter not found. You have successfully cleared all.", "info");

        }

        if ( resp['underReview'] && resp['underReview'].length)
            this.underReview = resp['underReview'];

        if ( resp['stats'] && resp['stats'].customer && resp['stats'].customer.all)
            this.stats = resp['stats'];

        if ( resp["locationNames"] && resp["locationNames"].length)
            resp["locationNames"].filter(row => row && row.length)
                .forEach(row => {
                    if ( this.uniqueHOLocations.indexOf(row) === -1)
                        this.uniqueHOLocations.push(row);
                });

        if ( resp['disabledIdentifiers'] && resp['disabledIdentifiers'].length)
            this.disabledIdentifiers = resp['disabledIdentifiers'];

        if ( resp['disabled'] && resp['disabled'].length) {
            this.disabledIdentifiers = resp['disabled'].map(row => {
                if ( row.type === "customer" && row.data && row.data["UnlinkDoctor"]) {
                    const unlinkingIdentifier = row.data["UnlinkDoctor"].match(/\((.*?)\)/)[1];
                    this.UnlinkingIdentifiers.push(unlinkingIdentifier);
                    return unlinkingIdentifier;
                }

                return row.identifier;
            });
        }

        this.customerPagination.current = parseInt(resp["pagination"].page);
        this.customerPagination.max = parseInt(resp["pagination"].max);
  }

  rejectField(field) {
      if ( this.acceptedFields.indexOf(field) > -1)
          this.acceptedFields.splice(this.acceptedFields.indexOf(field), 1);

      if ( this.rejectedFields.indexOf(field) === -1)
          this.rejectedFields.push(field);
  }

  acceptField (field) {
      if ( this.rejectedFields.indexOf(field) > -1)
          this.rejectedFields.splice(this.rejectedFields.indexOf(field, 1));

      if ( this.acceptedFields.indexOf(field) === -1)
          this.acceptedFields.push(field);
  }

  async discardAllAndGoBack() {
      this.editingType = '';
      this.editing = false;
      this.reviewing = false;

      // revert from backup
      for ( let key in this.workingObjectBackup)
          this.workingObject[key] = this.workingObjectBackup[key];
  }

    async acceptRemainingFields() {
        this.acceptedFields = this.updatedFields.filter(row => this.rejectedFields.indexOf(row) === -1);
        await this.raisePatchRequest();
    }

  async acceptAll() {
      this.rejectedFields = [];
      this.acceptedFields = this.updatedFields;
  }

  async discardAll() {
      this.acceptedFields = [];
      this.rejectedFields = this.updatedFields;
  }

  async beginReviewing() {
      if ( false && this.editingType === 'customer' && ! this.workingObject["PANCardNumber"] && ! this.workingObject["TaxAssessmentNumber"] && ! this.workingObject["GSTNo"])
          return alert("Any one from PAN, TAN, GST is mandatory. Cannot raise request with these empty.");

      this.reviewing = true;
      this.editing = false;
      this.rejectedFields = [];
      this.acceptedFields = [];
  }

  async raisePatchRequest() {
      const body = {};
      this.acceptedFields.forEach(fieldName => {
          body[fieldName] = this.workingObject[fieldName];
      });

      const resp = await this._rest.raisePatchRequest(this.workingObject['identifier'], this.editingType, body);
      if ( ! resp['success'] && resp['message'])
          return this._util.showAlert("Server resp: ", resp['message']);

      this.editing = false;
      this.reviewing = false;
      await this._util.showAlert('Raised successfully',
          'Patch request is raised to backend. Changes will be reflected as soon as admin team, accepts.',
          'success');
      await this.refreshCustomerListing();
  }

  async toggleViewingDoctor(customer) {
      if (this.showingDoctor) {
          this.showingDoctor = false;
          this.doctors = [];
          return;
      }

      this.doctorCustomer = {...customer};
      this.showingDoctor = true;
      this.loading = true;

      const resp = await this._rest.getSalesCustomerDoctorsListing(customer.identifier, 1);
      this.loading = false;

      if (!resp['success'] && resp['message']) return this._util.showAlert("Server resp", resp['message']);

      if (resp["doctors"] && resp["doctors"].length)
          this.doctors = resp["doctors"];
  }

  isDoctorUnlinked(doctor) {
      return this.unlinkedDoctors.indexOf(doctor.identifier) > -1;
  }

    async unlinkThisDoctor(doctor) {
      if ( ! confirm(`Are you sure? you want to raise a request to unlink 'Dr. ${doctor.pname}' from '${this.doctorCustomer["pname"]}'`))
          return;

        const resp = await this._rest.raisePatchRequest(this.doctorCustomer["identifier"], 'customer', {
            "UnlinkDoctor": `${doctor.pname} (${doctor.identifier})`
        });

        if ( ! resp['success'] && resp['message'])
            return this._util.showAlert("Server resp: ", resp['message']);

        this.unlinkedDoctors.push(doctor.identifier);
        this.editing = false;
        this.reviewing = false;
        return this._util.showAlert('Raised successfully',
            'Patch request is raised to backend. Changes will be reflected as soon as admin team, accepts.',
            'success');
    }

    isEntityUnderReview(entity) {
      return this.underReview.indexOf(entity.identifier) > -1;
    }

    isUnlinkingIdentifier(entity) {
      return this.UnlinkingIdentifiers.indexOf(entity.identifier) > -1;
    }

    async markVerified() {
      const confirmationQuestion = `Mark ${this.editingType} ${this.workingObjectBackup['name'] || this.workingObject['pname']} (${this.workingObject['identifier']})`;
      if ( ! confirm(confirmationQuestion)) return null;

        const resp = await this._rest.markPatchRequestVerified(this.workingObject['identifier']);
        if ( ! resp['success'] && resp['message'])
            return this._util.showAlert("Server resp", resp['message']);

        this._util.showAlert("Marked successfully", "", "success");
        await this.refreshCustomerListing();
    }

    getTitleForVerificationEntity(row) {
      if ( ! row.verifiedObject || ! row.verifiedObject.user) return "";

      return "Marked verified by " + row.verifiedObject.user.username
          + " at " + this._util.showDateLocally(row.verifiedObject.created_at);
    }
}
