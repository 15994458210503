import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from "../utility.service";
import { RestService } from "../rest.service";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-edit-report',
    templateUrl: './edit-report.component.html',
    styleUrls: ['./edit-report.component.styl']
})
export class EditReportComponent implements OnInit {

    baseParams = {
        "barcode": "",
        "testcode": "",
        "patient": "",
    };

    unipathParam = {
        'attachment': "",
    }

    altumParams = {
        "caseType": "",
        "lschType": "",
        "lohType": "",
        "altumCategory": "",
        "altumSubCategory": "",
        "gender": "",
    }
    haveAdminRights = false;


    sample = {};

    allCreatedReportsMaster = [];
    allEditableReportsMaster = [];
    matchedEditableReportsMaster = "";
    haveReviewableFields = false;

    cnvMarkerPosition = 0;
    cnvMarkerTubeName = "";

    sureTSamples = {
        newSureTSampleTSVValues: [],
        existingSureTSampleTSVValues: [],
        unmatchedSureTSampleTSVValues: [],
        sureTSampleTSVToBeUpadated: [],
        localMatchedSamples: [],
        currentSureTSample: [],
        currentSureTSampleToBeUpdated: [],
        currentSampleCheckedAll: false,
    }

    //this is to regulate the option to edit result table to soecific abnormal altum cases only
    altumAbnormalCasesWithoutResultTable = ['haploidy', 'tetraploidy', 'triploidyxxx', 'triploidyxxy'];
    altumModalType = '';
    uploadButtonText = "";
    saveButtonText = "";
    updatedFields = {};
    arrows = [];
    arrowsUpdated = false;
    arrowsPresent = false;

    attachments = [];
    sureTEmbroyos = [];

    altumResultRows = [];
    altumAnnexureRows = [];

    remarkButtonText = "Show Remarks Section";
    remarkVisibility = false;

    hasHiddenFields = false;

    sessionExpiryShown = false;
    currentSessionDetails = {};

    timerSubscription: Subscription;
    user = {};

    @ViewChild('iFrame', { static: true }) iFrame: ElementRef;
    private uploadingFilePurpose: string = "";
    private filePickerEl = null;

    constructor ( public _util: UtilityService, public _rest: RestService, private route: ActivatedRoute, private _ngZone: NgZone ) {
    }

    ngOnInit (): void {

        this.baseParams.barcode = this.route.snapshot.paramMap.get("barcode");
        this.baseParams.testcode = this.route.snapshot.paramMap.get("testcode");

        if ( window.location.href.indexOf('?') > -1 ) {

            let queries = this._util.convertQueryToObject(window.location.href.split('?')[1].split('&'));

            if ( Object.keys(queries).length && queries['attachement'] ) {
                this.unipathParam.attachment = queries['attachement'];
            }

            console.log('unipathParam : ', this.unipathParam);

            if ( Object.keys(queries).length && queries['caseType'] ) {
                this.altumParams.caseType = queries["caseType"] || '';
                this.altumParams.lschType = queries["lschType"] || '';
                this.altumParams.lohType = queries["lohType"] || '';
                this.altumParams.altumCategory = queries["altumCategory"];
                this.altumParams.altumSubCategory = queries["altumSubCategory"] || '';
                this.altumParams.gender = queries["gender"] || '';
            }
        }
        this.user = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));
        this.filePickerEl = document.querySelector('#edit-report-attachment');
        this.haveAdminRights = this._util.canUser(this._util.Constants.Permissions.ManageUsers);

        this.refreshBaseInfo();
        this.setupEventListener();
    }


    async ngOnDestroy () {
        window.removeEventListener("message", this.receivedEventFromIframe);
    }

    async redirectBack () {
        this._util.modal('session-occupancy-warning', 'hide');
        window.top.close();
    }

    async refreshBaseInfo () {
        // @ts-ignore
        const resp = await this._rest.getSampleInfo(this.baseParams.barcode, this.baseParams.testcode);

        if ( !resp['success'] && resp['message'] ) return this._util.showAlert("Server resp", resp['message']);

        this.allCreatedReportsMaster = resp['createdReportsObject'];
        this.allEditableReportsMaster = resp['editableReportsObjects'];
        this.sample = resp['sample'] || {};

        if ( (((((this.sample['meta'] || {})['SureTContent'] || {})[this.baseParams.testcode] || [])['out'] || {})['arrows'] || []).length ) {
            this.arrowsPresent = true;
        }

        this.matchedEditableReportsMaster = this._util.getMatchedScenarioForEditableReports(this.allEditableReportsMaster, this.baseParams.testcode);


        if ( this.matchedEditableReportsMaster === 'Altum' ) {

            //try to load up altum result rows from meta if possible
            this.altumResultRows = [];
            this.altumAnnexureRows = [];

            (((((this.sample['meta'] || {}).AltumContent || {})[this.baseParams.testcode] || {})['out'] || {})['resultRows'] || [])
                .forEach(resultRow => {
                    this.altumResultRows.push(resultRow);
                });

            (((((this.sample['meta'] || {}).AltumContent || {})[this.baseParams.testcode] || {})['out'] || {})['annexureRows'] || [])
                .forEach(annexureRow => {
                    this.altumAnnexureRows.push(annexureRow);
                });

        }


        if ( this.matchedEditableReportsMaster === "SureT" ) {
            // load embroyos, if possible.
            this.sureTEmbroyos = [];

            // @ts-ignore
            ((((this.sample['meta'] || {}).SureTContent || {})[this.baseParams.testcode] || {})['in'] || [])
                .forEach(embroyo => {
                    this.sureTEmbroyos.push(embroyo);
                });

            //for CNV show/hide flag status
            this.sureTEmbroyos.forEach(( embryo ) => {
                embryo['HideCNV'] = ((((this.sample['meta'] || {}).SureTContent || {})[this.baseParams.testcode] || {})['out'] || {})[`EmbryoResults-${embryo.TubeName}-HideCNV`] || false;
            })
        }

        if ( this.matchedEditableReportsMaster === 'pCREditable' ) {
            if ( ((((this.sample['meta'] || {})['OncoPCRContent'] || {})[this.baseParams.testcode] || {})['out'] || {})['showRemarks'] ) {
                this.remarkButtonText = 'Hide Remarks Section';
                this.remarkVisibility = true;
            }
            if ( Object.values((((this.sample['meta'] || {})['OncoPCRContent'] || {})[this.baseParams.testcode] || {})['out'] || {}).indexOf('Blank') > -1 ) {
                this.hasHiddenFields = true;
            }
        }

        if ( !this.matchedEditableReportsMaster ) {
            return this._util.showAlert("Not prepared for this Testcode", "Please contact IT, if you believe otherwise.", "error");
        }

        this.initializePreview();
        if ( !this.altumParams.caseType )
            await this.refreshAllAttachments();
    }

    changeCNVVisibilityStatus ( item, $event ) {
        console.log('item :', item);
        console.log('sureTEmbroyos :', this.sureTEmbroyos);
    }

    async showHideRemarks () {
        let remarkVisibilityStatus = !this.remarkVisibility;
        let existingMeta = (((this.sample['meta'] || {})['OncoPCRContent'] || {})[this.baseParams.testcode] || {})['out'] || {};
        let toUpdate = {
            ...existingMeta,
            'showRemarks': remarkVisibilityStatus,
        };
        const resp = this._rest.saveEditedFields(this.matchedEditableReportsMaster, this.baseParams.barcode, this.baseParams.testcode, toUpdate);
        console.log(resp);
        this.refreshPage();
    }

    async showHiddenFields () {
        //will only run for TORCH Panel reports
        let toUpdateContent = {
            'showHiddenFields': true,
        }
        const resp = this._rest.saveEditedFields(this.matchedEditableReportsMaster, this.baseParams.barcode, this.baseParams.testcode, toUpdateContent);
        console.log(resp);
        this.refreshPage();
    }

    async updateSureTCNVStatus () {
        let toUpdate = {};
        this.sureTEmbroyos.forEach(function ( embryo ) {
            toUpdate[`EmbryoResults-${embryo.TubeName}-HideCNV`] = embryo.HideCNV;
        })
        if ( Object.keys(this.updatedFields).length > 0 ) {
            await this.saveUpdatedValues('update-cnv-status');
        }

        const response = await this._rest.updateSureTCNVStatus(this.baseParams.barcode, this.baseParams.testcode, toUpdate)
        console.log('response', response);
        this._util.modal('suret-cnv-modal', 'hide');
        this.refreshPage();
    }

    async saveArrowPositions () {
        const resp = await this._rest.saveEditedFields(this.matchedEditableReportsMaster, this.baseParams.barcode, this.baseParams.testcode, { arrows: this.arrows });
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp: ', resp['message']);
        this.refreshPage();
    }

    async deleteArrow () {
        if ( !this.cnvMarkerTubeName.length && this.cnvMarkerPosition === 0 )
            return Swal.fire('Incomplete Data', 'Kindly fill in the required data before proceeding', 'error');
        const resp = await this._rest.deleteCnvMarker(this.baseParams.barcode, this.baseParams.testcode, this.cnvMarkerPosition, this.cnvMarkerTubeName);
        if ( !resp['success'] ) return Swal.fire('Error', resp['message'], 'error');
        this.closeModal('suret-cnv-marker-position-modal');
        this.refreshPage();
    }

    async deleteArrows () {
        const resp = await this._rest.deleteCnvMarker(this.baseParams.barcode, this.baseParams.testcode, 'all');
        this.refreshPage();
    }

    setupEventListener () {

        setTimeout(() => {
            window.addEventListener("message", ( e ) => {
                this.receivedEventFromIframe(e);
            });
        }, 100);
    }

    filteredAttachmentsForTube ( tubeName = null ) {
        return this.attachments.filter(attachment => {

            if ( attachment.purpose !== this._sureTEmbroyoImage ) return false;
            return (attachment.meta || {}).TubeName === tubeName;
        });
    }

    async refreshAllAttachments () {
        this.attachments = [];

        const resp = await this._rest.fetchAttachments(this.baseParams.barcode, this.baseParams.testcode);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert("Server resp", resp['message']);

        this.attachments = resp['attachments'];
    }

    receivedEventFromIframe ( event ) {
        if ( ['editedField', 'haveReviewableFields', 'arrowPosition'].indexOf(event.data.type) === -1 ) return;

        if ( !this._ngZone ) return;

        this._ngZone.run(() => {
            if ( event.data.type === 'haveReviewableFields' ) {
                this.haveReviewableFields = true;
                return;
            }

            if ( event.data.type === 'arrowPosition' ) {
                this.arrows = event.data.data;
                this.arrowsUpdated = true;
            } else {
                this.updatedFields = event.data.data;
            }
        });
    }

    initializePreview () {
        let query = [];

        for ( let key in this.baseParams )
            query.push(`${key}=${this.baseParams[key]}`);

        if ( this.altumParams.caseType.length ) {
            for ( let key in this.altumParams )
                query.push(`${key}=${this.altumParams[key]}`);
        }

        if ( this.unipathParam.attachment.length ) {
            for ( let key in this.unipathParam )
                query.push(`${key}=${this.unipathParam[key]}`);
        }

        this.iFrame.nativeElement.src = window.location.origin + "/api/reports/edit?"
            + `token=${this._util.getToken()}&`
            + query.join('&');

    }

    refreshPage () {
        // @ts-ignore
        window.location = window.location.href;
    }

    async saveUpdatedValues ( nextTask = "refresh" ) {

        const resp = await this._rest.saveEditedFields(this.matchedEditableReportsMaster, this.baseParams.barcode, this.baseParams.testcode, this.updatedFields);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp: ', resp['message']);
        if ( nextTask === 'refresh' ) this.refreshPage();
    }

    haveUpdatedFields () {
        return !!Object.keys(this.updatedFields).length;
    }

    beginUploadFileWithPurpose ( purpose ) {
        this.uploadingFilePurpose = purpose;
        this.filePickerEl.click()
    }

    isSureTExcelResultLoaded () {
        return this.sample && this.sample['meta'] && this.sample['meta'].SureTContent
            && this.sample['meta'].SureTContent[this.baseParams.testcode];
    }

    openModal ( modalName ) {
        setTimeout(() => {
            eval('$("#' + modalName + '").modal("show")');
        }, 1);
    }

    closeModal ( modalName ) {
        setTimeout(() => {
            eval('$("#' + modalName + '").modal("hide")');
        }, 1);
    }

    openAltumModal ( type ) {
        this.altumModalType = type;
        setTimeout(() => {
            eval('$("#altum-result-modal").modal("show")');
        }, 1);
    }

    async removeSureTEmbroyoAttachment ( event, attachment, tubename ) {
        // TODO: Implement
        event.stopPropagation();
        await this.unlinkSureTEmbryoImage(attachment);
        await this.refreshAllAttachments();
    }

    async unlinkSureTEmbryoImage ( attachment ) {
        const resp = await this._rest.unlinkSureTEmbryoImage(this.baseParams.barcode, attachment.id);
        if ( !resp['success'] && resp['message'] ) return this._util.showAlert('Server resp: ', resp['message']);
    }

    private readonly _sureTEmbroyoImage = "sure-t-embroyo-image";

    async fileSelectedToUpload ( $event, tubeName = null ) {
        this.uploadButtonText = "Uploading ...";

        const file = ($event.target && $event.target.files && $event.target.files.length)
            ? $event.target["files"][0]
            : $event.addedFiles[0];

        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('barcode', this.baseParams['barcode']);
        formData.append('testcode', this.baseParams['testcode']);
        formData.append('header', "1");
        formData.append('ignoreSamplePatientValidation', "0");

        if ( tubeName !== null ) {
            this.uploadingFilePurpose = this._sureTEmbroyoImage;
            formData.append('purpose', this._sureTEmbroyoImage);
            formData.append('TubeName', tubeName);
        } else {
            formData.append('purpose', this.uploadingFilePurpose);
        }

        await Swal.fire({
            title: `Uploading ...`,
            text: 'please wait it may take a while',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();

                const resp = await this._rest.uploadSpecial(formData);

                if ( !resp['success'] ) this.uploadButtonText = '';

                if ( !resp["success"] && resp["message"] ) {
                    if ( !resp["success"] && resp["missingId"] ) {
                        await this._util.showAlert("The sample ID / patient ID does not match");
                        return;
                    }

                    await this._util.showAlert("Upload error", resp["message"]);
                    return;
                }

                this.uploadButtonText = "File uploaded";

                Swal.close();

                switch ( this.uploadingFilePurpose ) {
                    case "sure-t-excel-result":
                        //if a row doesnt contains the current sample id then only it is a new row;
                        this.sureTSamples.newSureTSampleTSVValues = resp['new']
                            .filter(row => {
                                    if ( row
                                        .map(function ( element ) {
                                            return element.SampleID.toString();
                                        }).indexOf(this.baseParams.barcode) <= -1 ) {

                                        return row;
                                    }
                                }
                            ) || [];

                        this.sureTSamples.existingSureTSampleTSVValues = resp['existing']
                            .filter(row => {
                                    if ( row
                                        .map(function ( element ) {
                                            return element.SampleID.toString();
                                        }).indexOf(this.baseParams.barcode) <= -1 ) {

                                        return row;
                                    }
                                }
                            ) || [];

                        //if any row contains the base barcode the wholle row will be the current sample
                        this.sureTSamples.currentSureTSample = resp['new']
                            .concat(resp['existing'])
                            .filter(row => {
                                    if ( row
                                        .map(function ( element ) {
                                            return element.SampleID.toString();
                                        }).indexOf(this.baseParams.barcode) > -1 ) {

                                        return row;
                                    }
                                }
                            )[0] || [];

                        this.sureTSamples.unmatchedSureTSampleTSVValues = resp['unmatched'];
                        this.sureTSamples.localMatchedSamples = resp['localMatchedSamples'];
                        this._util.modal('suret-TSV-save-modal', 'show')
                        break;
                    case this._sureTEmbroyoImage:
                        await this.refreshAllAttachments();
                        break;
                    default:
                        break;
                }


                setTimeout(() => {
                    this.uploadButtonText = "";
                }, 3000);
            }
        });
    }

    async overrideExistingSureTSampleTSV ( item, event ) {
        this.sureTSamples.sureTSampleTSVToBeUpadated = this.sureTSamples.sureTSampleTSVToBeUpadated.filter(element => {
            return element != item;
        });
        if ( event.target.checked ) {
            this.sureTSamples.sureTSampleTSVToBeUpadated.push(item);
        }
    }

    async currentSureTSampleTSV ( event, item = null ) {
        if ( item !== null ) {
            this.sureTSamples.currentSureTSampleToBeUpdated = this.sureTSamples.currentSureTSampleToBeUpdated.filter(element => {
                return element != item;
            });
            if ( event.target.checked ) {
                this.sureTSamples.currentSureTSampleToBeUpdated.push(item);
            }
        } else {
            this.sureTSamples.currentSureTSampleToBeUpdated = [];
            if ( event.target.checked ) {
                this.sureTSamples.currentSureTSampleToBeUpdated = this.sureTSamples.currentSureTSample;
            }
        }
    }

    deleteTableRow ( row ) {
        if ( this.altumModalType === 'result' ) {
            this.altumResultRows = this.altumResultRows.filter(element =>
                element['id'] !== row['id']
            );
        } else {
            this.altumAnnexureRows = this.altumAnnexureRows.filter(element =>
                element['id'] !== row['id']
            );
        }
    }

    addTableRow () {
        if ( this.altumModalType === 'result' ) {
            //adding empty row to altum result
            this.altumResultRows.push({
                'id': `custom-result-${this.altumResultRows.length + 1}`,
                'type': '',
                'chrNo': '',
                'cytoBand': '',
                'size': '',
                'cnState': '',
                'geneCount': '',
                'genomicCoordinates': '',
                'interpretation': '',
            })
        } else {
            //push annexure row
            this.altumAnnexureRows.push({
                'id': `customer-annexure-${this.altumAnnexureRows.length + 1}`,
                'range': '',
                'contigiousStretch': '',
                'description': '',
            })
        }
    }

    async saveAltumResult () {
        let resp = {};
        if ( this.altumModalType === 'result' ) {
            resp = await this._rest.updateAltumReportValues(this.altumResultRows, this.baseParams['barcode'], this.baseParams['testcode'], this.altumModalType);
        } else {
            resp = await this._rest.updateAltumReportValues(this.altumAnnexureRows, this.baseParams['barcode'], this.baseParams['testcode'], this.altumModalType);
        }
        if ( !resp['success'] && resp['message'] ) {
            return this._util.showAlert('Server resp', resp['message']);
        }

        console.log('response of api : ', resp);
        this.refreshPage();
    }

    hideSureTTSVSaveModal () {
        this._util.modal('suret-TSV-save-modal', 'hide');
        //reseting sureT lists
        this.sureTSamples = {
            newSureTSampleTSVValues: [],
            existingSureTSampleTSVValues: [],
            unmatchedSureTSampleTSVValues: [],
            sureTSampleTSVToBeUpadated: [],
            localMatchedSamples: [],
            currentSureTSample: [],
            currentSureTSampleToBeUpdated: [],
            currentSampleCheckedAll: false
        }
    }

    async updateSureTTSVValues () {
        this.sureTSamples.sureTSampleTSVToBeUpadated.push(this.sureTSamples.currentSureTSampleToBeUpdated);
        this.sureTSamples.sureTSampleTSVToBeUpadated.push(...this.sureTSamples.newSureTSampleTSVValues);
        const values = {
            'parsedRows': this.sureTSamples.sureTSampleTSVToBeUpadated,
            'localMatchedSamples': this.sureTSamples.localMatchedSamples
        };
        const resp = await this._rest.updateSureTTSVValues(values);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        this.hideSureTTSVSaveModal();
        await this.refreshBaseInfo();
        await this._util.showAlert('Uploaded successfully', '', 'success');
    }

    goToPreview () {
        const patientId = (this.sample['patient'] || {}).limp_patient_id || "";

        let query = [];

        if ( this.altumParams['caseType'].length ) {
            for ( let key in this.altumParams )
                query.push(`${key}=${this.altumParams[key]}`);
        }

        const url = window.location.origin + "/preview-report?"
            + `testcode=${this.baseParams.testcode}&barcode=${this.baseParams.barcode}&patient=${patientId}`
            + `&headers=with&` + query.join('&');

        window.open(url);
    }

    async saveReviewedUpdates () {

        const resp = await this._rest.reviewReportUpdates(this.baseParams.barcode, this.baseParams.testcode);
        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        this.refreshPage();
    }
}
