import { Injectable } from '@angular/core';
import { RestService } from "./rest.service";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    now = null;
    public logoutButtonText = "";

    public Constants = {
        Weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        Months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        Keys: {
            "lastUsedLabLocation": "last_used_lab_location",
            "user": "user_object",
            "token": "access_token",
            "permissions": "user_permissions",
        },
        Messages: {
            RecordNotFound: "Record not found",
        },
        Permissions: {
            OpenDashboard: "open-dashboard",
            OpenPrintReport: "open-print-report",
            AddAccession: "add-accession",
            PreviewReport: "preview-report",
            MailReport: "mail-report",
            ManageUsers: "manage-users",
            ManageRoles: "manage-roles",
            CreateUsers: "create-users",
            JarvisLogin: "jarvis-login",
            SalesDataView: "sales-data-view",
            RaisePatchRequest: "raise-patch-request",
            AllowPatchRequest: "allow-patch-request",
            ApproveStage2: "stage-2-approve",
            ReleasePendingPayments: "release-pending-payments",
            AuthenticateReports: "authenticate-reports",
            SeePrintDueReports: "see-print-due-reports",
            DownloadPrintedReportsLog: "download-printed-reports-log",
            UnAuthenticateReport: "un-authenticate-report",
            ReviewReportUpdates: "review-report-updates",
            CreateNewCustomers: "create-new-customer",
            ApproveNewCustomer: "approve-new-customer",
            VerifyNewCustomer: "verify-new-customer",
            OpenJamsifyDashboard: "open-jamsify-dashboard",
            BlacklistCustomer: "blacklist-customer",
            TakeOverSession:"take-over-session",
            SyncSamplesManually: "sync-samples-manually",
        },
        Roles: {
            SalesExecutive: "sales-executive",
        }
    };

    public userPermissions = null;

    constructor (
        private _rest: RestService,
        private router: Router
    ) {
    }

    doNothing () {
        return console.log("Doing nothing");
    }

    convertQueryToObject ( queries = [] ) {
        let queryObject = {};
        for ( let query of queries ) {
            queryObject[query.split('=')[0]] = query.split('=')[1];
        }
        return queryObject;
    }

    toFixed ( num = 1, length = 2 ) {
        if ( (num + "").length > length ) return num;

        return ("0".repeat(length - (num + "").length)) + num;
    }

    dateObjIndianToHumanReadable ( date, type = "datetime" ) {
        if ( typeof date === "string" )
            date = new Date(date.replace("Z", ""));

        if ( typeof date === "object" ) {
            date = new Date(date.getTime());
            date.setTime(date.getTime() - (5.5 * 60 * 60 * 1000));
        }

        return this.toFixed(date.getDate(), 2) + "/" + this.toFixed(date.getMonth() + 1) + "/" + date.getFullYear()
            + ((type === "datetime") ? (" " + this.toFixed(date.getHours()) + ":" + this.toFixed(date.getMinutes())) : "");
    }

    async redirectAccordingly () {
        try {
            // if this responds with 401, meaning user is not logged in.
            const resp = await this._rest.getAllLabs();

            // only redirect to dashboard if, on login screen, everywhere else, don't do anything
            if ( window.location.href.indexOf("login") > -1 ) {

                let finalRoute = "";
                if ( !finalRoute && this.canUser(this.Constants.Permissions.CreateUsers) ) finalRoute = "/dashboard";
                if ( !finalRoute && this.canUser(this.Constants.Permissions.VerifyNewCustomer) ) finalRoute = "/jarvis-home";
                if ( !finalRoute && this.canUser(this.Constants.Permissions.ApproveNewCustomer) ) finalRoute = "/jarvis-home";
                if ( !finalRoute && this.canUser(this.Constants.Permissions.JarvisLogin) ) finalRoute = "/jarvis-home";
                if ( !finalRoute && this.canUser(this.Constants.Permissions.AllowPatchRequest) ) finalRoute = '/patch-requests';
                if ( !finalRoute && this.canUser(this.Constants.Permissions.OpenJamsifyDashboard) && this.canUser(this.Constants.Permissions.BlacklistCustomer) ) finalRoute = "/jamsify-dashboard";
                if ( !finalRoute && this.canUser(this.Constants.Permissions.OpenDashboard) ) finalRoute = "/dashboard";

                console.log("redirecting to : ", finalRoute);
                this.router.navigate([finalRoute]);

                this.logoutButtonText = "";
            }

            if ( !this.canUser(this.Constants.Permissions.OpenDashboard) && this.canUser(this.Constants.Permissions.JarvisLogin)
                && window.location.href.indexOf("dashboard") > -1 ) {
                this.redirectTo('/jarvis-home');
            }

        } catch (e) {
            if ( window.location.href.indexOf("login") === -1 ) {
                this.router.navigate(['/login']);

                this.logoutButtonText = "";
            }
        }


    }

    convertValueTo10 ( value ) {
        return ["0", 0, false, null, undefined, "false"].indexOf(value) > -1 ? "0" : "1";
    }

    redirectTo ( path = "", data = {} ) {
        this.router.navigate(["/" + path].concat(Object.values(data)));
    }

    redirectToWithMultipleQueries ( path = "", pathValues = {}, query = {} ) {
        let queries = "?";

        for ( let key in query ) {
            queries = (queries.length === 1) ? queries + key + '=' + query[key] : queries + '&' + key + '=' + query[key];
        }

        for ( let key in pathValues ) {
            path = path + "/" + pathValues[key];
        }

        return "/" + path + queries;
    }

    s2ab ( s ) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for ( var i = 0; i != s.length; ++i ) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    redirectToWithQuery ( path = "", query = "", queryValue = "" ) {
        return "/" + path + "?" + query + "=" + queryValue
    }

    canUser ( permissionName ) {
        if ( this.userPermissions === null )
            this.userPermissions = this.getLocalData(this.Constants.Keys.permissions, []);

        return this.userPermissions.indexOf(permissionName) > -1;
    }

    public loggedInUserName () {
        const user = this.getLocalData(this.Constants.Keys.user, null);
        if ( !user ) return "";

        const userJson = JSON.parse(user);
        return userJson.firstname + " " + userJson.lastname;
    }

    uniqueColumnFromArrayOfObjects ( array, column ) {
        return array.map(row => row[column]).filter(( value, index, arr ) => arr.indexOf(value) === index);
    }

    public openAttachmentInNewTab ( $event, attachment ) {
        $event.stopPropagation();
        window.open(attachment.last_url, "_blank");
    }

    public logout = async () => {
        this.logoutButtonText = "  ...  "

        await this._rest.invalidateLogin();
        this.removeLocalData(this.Constants.Keys.user);
        this.removeLocalData(this.Constants.Keys.token);
        this.removeLocalData(this.Constants.Keys.permissions);

        await this.redirectAccordingly();
        this.logoutButtonText = "";
    }

    getToken = () => {
        return this.getLocalData(this.Constants.Keys.token, "foobar");
    }

    removeLocalData = ( key ) => {
        window.localStorage.removeItem(key);
    }

    isPasswordValid ( password ) {
        let anyValidationFailed = false;

        if ( !password.match(/[a-z]/) ) {
            anyValidationFailed = true;
        }

        if ( !password.match(/[A-Z]/) ) {
            anyValidationFailed = true;
        }

        if ( !password.match(/[0-9]/) ) {
            anyValidationFailed = true;
        }

        if ( !password.match(/[^0-9A-Za-z]/) ) {
            anyValidationFailed = true;
        }

        if ( password.length < 9 ) {
            anyValidationFailed = true;
        }

        return !anyValidationFailed;
    }

    setLocalData = ( key, value ) => {
        if ( typeof value === "object" )
            value = JSON.stringify(value);

        window.localStorage.setItem(key, value);
    }

    showAlert ( title, description = "", type = "error" ) {
        //@ts-ignore
        return Swal.fire(title, description, type);
    }

    showSessionExpiredPopup ( onClose ) {
        Swal.fire({
            title: 'Session Expired',
            text: 'Your current Session has expired . To view the current status of the case session kindly refresh the page.',
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Refresh',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then(( result ) => {
            if ( result.isConfirmed ) {
                onClose();
            }

            Swal.close();
        })
    }

    refreshPage () {
        // @ts-ignore
        window.location = window.location.href;
    }

    getLocalData = ( key, defaultValue = null ) => {
        return window.localStorage.getItem(key) || defaultValue;
    }

    toKebabCase = ( word ) => {
        return word.replace(/[^a-zA-Z ]*/, "")
            .toLowerCase()
            .split(" ")
            .join("-");
    }

    getNow = () => {
        if ( this.now ) return this.now;

        this.now = new Date();
        return this.getNow();
    }

    openFullscreen ( elem ) {
        if ( elem.requestFullscreen ) {
            elem.requestFullscreen();
        } else if ( elem.webkitRequestFullscreen ) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if ( elem.msRequestFullscreen ) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    closeFullscreen () {
        if ( document.exitFullscreen ) {
            document.exitFullscreen();
            // @ts-ignore
        } else if ( document.webkitExitFullscreen ) { /* Safari */
            // @ts-ignore
            document.webkitExitFullscreen();
            // @ts-ignore
        } else if ( document.msExitFullscreen ) { /* IE11 */
            // @ts-ignore
            document.msExitFullscreen();
        }
    }

    dateOfBirthToAgeY = ( date, now = null ) => {
        if ( date === null ) return "";

        if ( typeof date === "string" ) date = new Date(date);

        if ( now === null ) now = this.getNow();

        return Math.ceil((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 365));
    }

    seperateEmails = ( emailids ) => {
        return (emailids || "").split(",").filter(row => row && row.length)
    }

    textToHtml = ( text ) => {
        return text.split('\r\n').join("<br />");
    }

    imagePathForIcon = ( icon ) => {
        return "/assets/icons/files/" + icon + ".svg";
    }

    matchIcon = ( url ) => {
        if ( !url || !url.length ) return "file";

        if ( url.length > 4 ) url = this.extensionFromUrl(url);

        const knownExtensions = [
            "file", "avi", "dbf", "doc", "docx", "eml", "eps", "fla", "gif",
            "htm", "ico", "ini", "jpg", "mkv", "pdf", "otf", "png", "ppt", "psd",
            "rtf", "svg", "text", "txt", "xls",
        ];

        for ( let i = 0; i < knownExtensions.length; i++ )
            if ( url.indexOf(knownExtensions[i]) > -1 )
                return knownExtensions[i];
    }

    extensionFromUrl = ( url ) => {
        url = url.split("?")[0];
        url = url.split(".");

        return url[url.length - 1];
    }

    preparePatientForView = ( patient ) => {
        if ( !patient ) return {};

        patient.ageY = this.dateOfBirthToAgeY(patient.date_of_birth || null);
        patient.ageY = patient.ageY ? (patient.ageY + "yrs") : "";
        patient.genderChar = (patient.gender || "M").substr(0, 1).toUpperCase();

        return patient;
    }

    public showDateLocally ( date, ignoreGSTTimezone = false ) {
        if ( typeof date === null ) return "";

        if ( typeof date === "string" )
            date = new Date(date);

        if ( ignoreGSTTimezone && (date.getTimezoneOffset() !== 0) ) {
            const dateClone = new Date(date.getTime());
            dateClone.setMinutes(dateClone.getMinutes() + dateClone.getTimezoneOffset());

            return this.showDateLocally(dateClone);
        }

        if ( typeof date === null ) return "";

        return this.niceTimeFormat(date) + " on " + this.niceDateFormat(date);
    }

    getPagesRange ( current, max ) {
        let pages = [];

        for ( let i = current - 5; i <= current + 5; i++ ) {
            if ( i < 1 ) continue;
            if ( i > max ) continue;

            pages.push(i);
        }

        return pages;
    }

    calculateTATData ( maximumTat, created_at, recieved_at ) {
        if ( !maximumTat ) return {};

        let currentDate = new Date();
        let tatOffset = parseInt(maximumTat);
        let creationDate = new Date(created_at);
        let recievedDate = new Date(recieved_at);
        let tatDate = new Date(creationDate.setMinutes(creationDate.getMinutes() + tatOffset));
        let tatDifference = tatDate.getTime() - currentDate.getTime(); // in millis
        return {
            'tat': this.convertMinutesIntoHoursAndDays(tatOffset),
            'tatDifference': ((tatDifference < 0) ? '+ ' : '- ') + this.convertMinutesIntoHoursAndDays(tatDifference / (1000 * 60)),
            'tatInEpoch': tatDifference, //used for sorting the list
        };
    }

    convertMinutesIntoHoursAndDays ( value ) {
        value = Math.abs(value);
        let equiValentDays = Math.floor(value / (60 * 24));
        let extraHours = ((value % (60 * 24)) / 60).toFixed(1);
        return ((equiValentDays > 0) ? equiValentDays + ((equiValentDays > 1) ? ' days' : ' day') : '') + ((parseFloat(extraHours) > 0) ? ' ' + extraHours + ((parseFloat(extraHours) > 1) ? ' hours' : ' hour') : '')
    }

    public niceDateFormat ( date ) {
        if ( typeof date === "string" && !date.length )
            return "";

        if ( typeof date === "string" )
            date = new Date(date);

        if ( typeof date === "number" )
            date = new Date(date);

        if ( date === null ) return "null";
        if ( !date ) return "";

        const weekdays = this.Constants.Weekdays;
        const months = this.Constants.Months;
        return weekdays[date.getDay()] + " " + date.getDate() + " " + months[date.getMonth()] + ", " + date.getFullYear();
    }

    public getMonthFromDate ( date ) {
        return this.Constants.Months[date.getMonth()];
    }

    public getUserFullname ( user ) {
        if ( !user ) return "";

        return user.firstname + " " + user.lastname;
    }

    public debugFieldType ( field ) {
        console.log("debugFieldType: ", field, typeof field, JSON.stringify(field));

        return field;
    }

    undefinedOrEmpty ( value ) {
        if ( typeof value === "undefined" ) return "";
        else return value;
    }

    public niceTimeFormat ( date ) {

        if ( typeof date === "string" )
            date = new Date(date);

        if ( !date ) return "";

        return (date.getHours() > 12 ? date.getHours() - 12 : date.getHours())
            + ':' + ((date.getMinutes() < 10) ? ("0" + date.getMinutes()) : date.getMinutes())
            + ' ' + (date.getHours() >= 12 ? "PM" : "AM");
    }

    public ceil ( number ) {
        return Math.ceil(number);
    }

    public createRange ( number ) {
        var items = [];

        for ( let i = 0; i < number; i++ )
            items.push(i);

        return items;
    }

    public getMatchedScenarioForEditableReports ( masterArray, testcode ) {

        return (masterArray.find(row => !!row.testcodes.filter(query => testcode.indexOf(query) > -1).length) || { "scenario": "" }).scenario;
    }

    public getTestcodeFromFullSample ( sample ) {
        if ( !sample ) return '';

        if ( sample.testcode && sample.testcode.length ) return sample.testcode;

        if ( sample.test && sample.test.testcode && sample.test.testcode.length )
            return sample.test.testcode;

        if ( sample.tests && sample.tests.length && sample.tests[0].testcode && sample.tests[0].testcode.length )
            return sample.tests[0].testcode;

        return '';
    }

    public queryToKeyValue ( query ) {
        const json = {};

        query = query.split("&");
        query.forEach(row => {
            row = row.split("=");

            json[row[0]] = row[1] || "";
        })

        return json;
    }

    public keyValueToQuery ( json ) {
        const query = [];

        for ( let key in json ) {
            const value = json[key];
            query.push(`${key}=${value}`);
        }

        return query.join('&');
    }

    public getQueryParameters ( strr = null ) {
        if ( strr === null )
            strr = window.location.search.replace("?", "");

        const json = {};
        strr.split("&").forEach(row => {
            const parts = row.split("=");

            json[parts[0]] = parts[1];
        });

        return json;
    }

    public parseAltumParams () {
        const altumParams = {};
        const urlQueryParams = this.getQueryParameters();

        [
            "caseType",
            "lschType",
            "lohType",
            "altumCategory",
            "altumSubCategory",
            "gender",
        ].forEach(param => {
            if ( urlQueryParams[param] )
                altumParams[param] = urlQueryParams[param];
        });

        return altumParams;
    }

    public ucFirst ( word ) {
        word = "" + word;
        return word[0].toUpperCase() + word.substring(1)
    }

    public makeSlug ( text ) {
        return text.toLowerCase()
            .replace(/[^0-9a-zA-Z]/, '');
    }

    base64ToArrayBuffer ( base64 ) {
        console.log("base64: ", base64);

        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for ( let i = 0; i < binaryLen; i++ ) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        return bytes;
    }

    saveByteArray ( reportName, byte ) {
        const blob = new Blob([byte], { type: "application/pdf" });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
    };

    modal ( id, action = 'show' ) {
        setTimeout(() => {
            eval('$("#' + id + '").modal("' + action + '")');
        }, 1);
    }

    collapse ( id, action = 'show' ) {
        setTimeout(() => {
            eval('$("#' + id + '").collapse("' + action + '")');
        }, 1);
    }

    isCollapsed ( id ) {
        setTimeout(() => {
            return eval('$("#' + id + '").is(.collapse:not(.show))');
        }, 1)
    }

    base64ToBlobPdf ( base64 ) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for ( let i = 0; i < len; ++i ) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], { type: 'application/pdf' });
    };

    twoDigits ( number ) {
        return (number < 10 ? '0' : '') + number;
    }

    formatTimeslotsToFormattedTime ( timeslots, isTimeHours = false ) {
        const rows = [];

        let timeStamp = "";


        if ( isTimeHours && timeslots.length == 1 ) {
            let timeMinute = timeslots % 100;
            let timeHour = (timeslots - timeMinute) / 100;
            let timeAMPM = timeHour >= 12 ? "PM" : "AM";
            if ( timeAMPM === "PM" ) timeHour -= 12;

            timeStamp = `${this.twoDigits(timeHour)}:${this.twoDigits(timeMinute)} ${timeAMPM}`;

            return timeStamp;
        }

        timeslots.forEach(timeslot => {
            let startMinute = timeslot.start % 100;
            let startHour = (timeslot.start - startMinute) / 100;
            let startAmPM = startHour >= 12 ? "PM" : "AM";
            if ( startAmPM === "PM" ) startHour -= 12;

            let endMinute = timeslot.end % 100;
            let endHour = (timeslot.end - endMinute) / 100;
            let endAmPM = endHour >= 12 ? "PM" : "AM";
            if ( endAmPM === "PM" ) endHour -= 12;

            rows.push(`${this.twoDigits(startHour)}:${this.twoDigits(startMinute)} ${startAmPM} - ${this.twoDigits(endHour)}:${this.twoDigits(endMinute)} ${endAmPM}`);
        });


        return rows.join(", ");
    }

    formattedTimeToTimeslots ( formattedTime, isTimeHours = false ) {
        const timeslots = [];

        let timeStamp = "";

        if ( !formattedTime ) return timeslots;

        try {

            if ( !isTimeHours ) {
                const rows = formattedTime.split(", ");

                rows.forEach(row => {
                    const times = row.split(" - ");
                    const start = times[0].split(":");
                    const end = times[1].split(":");

                    const startHour = parseInt(start[0]);
                    const startMinute = parseInt(start[1]);
                    const endHour = parseInt(end[0]);
                    const endMinute = parseInt(end[1]);

                    timeslots.push({
                        start: ((startHour * 100) + (start[1].toUpperCase().indexOf("PM") > -1 ? 1200 : 0)) + startMinute,
                        end: ((endHour * 100) + (end[1].toUpperCase().indexOf("PM") > -1 ? 1200 : 0)) + endMinute,
                    });
                });
            } else {
                const timeStampRawValue = formattedTime;

                const timeHour = parseInt(timeStampRawValue.split(":")[0]);
                const timeMinute = parseInt(timeStampRawValue.split(":")[1]);

                timeStamp = `${(timeHour * 100) + ((timeStampRawValue.split(":")[1]).toUpperCase().indexOf("PM") > -1 ? 1200 : 0) + timeMinute}`;
            }
        } catch (e) {

            return timeslots;
        }
        return timeslots;
    }

    weekdays () {
        return [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ];
    }

    customTrackBy ( index: number, obj: any ): any {
        return index;
    }

    consoleLogMiddleware ( data, identifier ) {
        console.log("data: ", data, identifier || '');

        return data;
    }


    calcSealedDateDiff( date1, date2, returnType = 'minute' ) {
        const dateDifference = new Date(date2).getTime() - new Date(date1).getTime();
        if(dateDifference < 0){
            return null;
        }

        let dateDifferenceInSeconds = dateDifference/1000;
        if(dateDifferenceInSeconds%60 > 0){
            return `${(Math.floor(dateDifferenceInSeconds/60) + 1)} minutes`;
        }
        return `${dateDifferenceInSeconds/60} minutes`;
    }

    calcDateDiff ( date1, date2 ) {
        /*
        * calcDate() : Calculates the difference between two dates
        * @date1 : "First Date in the format MM-DD-YYYY"
        * @date2 : "Second Date in the format MM-DD-YYYY"
        * return : Array
        */

        //new date instance
        const dt_date1 = new Date(date1);
        const dt_date2 = new Date(date2);

        //Get the Timestamp
        const date1_time_stamp = dt_date1.getTime();
        const date2_time_stamp = dt_date2.getTime();

        let calc;

        //Check which timestamp is greater
        if ( date1_time_stamp > date2_time_stamp ) {
            calc = new Date(date1_time_stamp - date2_time_stamp);
        } else {
            calc = new Date(date2_time_stamp - date1_time_stamp);
        }

        //Retrieve the date, month and year
        const calcTime = calc.getTime();
        const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
        //Convert to an array and store
        const calcFormat = calcFormatTmp.split("-").map(row => parseInt(row));
        //Subtract each member of our array from the default date
        const days_passed = Math.abs(calcFormat[0]) - 1;
        const months_passed = Math.abs(calcFormat[1]) - 1;
        const years_passed = Math.abs(calcFormat[2]) - 1970;

        const hours_passed = Math.abs((calcTime) / (60 * 60 * 1000)) - (years_passed * 365 * 24) - (months_passed * 30.417 * 24) - (days_passed * 24);

        //Set up custom text
        const yrsTxt = ["year", "years"];
        const mnthsTxt = ["month", "months"];
        const daysTxt = ["day", "days"];

        //Convert to days and sum together
        const total_days = (years_passed * 365) + (months_passed * 30.417) + days_passed;

        const finalDiff = ((days_passed > 0) ? days_passed + ' days ' : '') + ((hours_passed >= 1) ? hours_passed.toFixed(0) + ' hours ' : (hours_passed < 1) ? ' < 1 hours ' : '');

        return finalDiff;
    }

    getDateOffset ( identifier ) {
        if ( !identifier ) return null;

        const now = new Date();

        switch ( identifier ) {
            case 'today':
                now.setHours(0, 0, 0, 0);
                break;
            case 'yesterday':
                now.setDate(now.getDate() - 1);
                break;
            case 'last_7_days':
                now.setDate(now.getDate() - 7);
                break;
            case 'last_30_days':
                now.setDate(now.getDate() - 30);
                break;
            case 'month_to_date':
                now.setDate(1);
                break;
            case 'year_to_date':
                now.setMonth(0);
                now.setDate(1);
                break;
        }

        return now.toISOString();
    }

    handleFailureResponse ( resp ) {
        if ( !resp['success'] && resp['message'] ) {
            this.showAlert('Server error', resp['message'], 'error').then();
            return true;
        }

        return false;
    }

    initiateTooltips () {
        setTimeout(() => {
            eval(`$('[data-toggle="tooltip"]').tooltip()`);
        }, 1);
    }

}
