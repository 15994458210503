import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {RestService} from '../rest.service';
import {UtilityService} from '../utility.service';

@Component({
  selector: 'app-report-print',
  templateUrl: './report-print.component.html',
  styleUrls: ['./report-print.component.styl']
})
export class ReportPrintComponent implements OnInit {

  sampleIdsOptions = [];
  testCodes = [];
  searchBy = "barcode";
  headers = "without";
  selectedTestCode = "";
  patientid = "";
  barcodeid = "";
  labLocationId = "";
  patientInfo = null;
  linkedPatientRow = null;
  linkingPatientId = "";
  errorMessageForPrintingReport = "";
  shouldBeAbleToPrint = false;
  mailingInfo = null;

  searchedPatientDetails = {};
  searchPatientTimer = null;

  constructor(private _rest: RestService, public _util: UtilityService) { }

  ngOnInit(): void {

    if ( ! this._util.canUser(this._util.Constants.Permissions.PreviewReport))
      this._util.redirectTo();
  }

  changeSearchBy(value) {
    this.searchBy = value;
      this.barcodeid = "";
      this.patientid = "";
  }

  async fetchTestCodes() {
    if ( ! this.patientid) return;

    this.testCodes = [];
    const resp = await this._rest.getTestsListForPatient(this.patientid, this.barcodeid);
    if ( ! resp['success'] && resp['message']) return Swal.fire("Server resp", resp["message"], "error");

    this.testCodes = resp['tests'];
    this.selectedTestCode = (this.testCodes.length === 1) ? this.testCodes[0].code : "";
  }

  async tryToFillPatientBarcodeId() {
    if ( ! this.barcodeid && ! this.patientid) return;

    this.errorMessageForPrintingReport = "";
    this.shouldBeAbleToPrint = false;
    this.mailingInfo = null;

    const resp = await this._rest.getMatchingPatientSampleId(this.patientid, this.barcodeid);
    if ( ! resp['success'] && resp['message']) return Swal.fire("Server resp", resp["message"], "error");

    this.sampleIdsOptions = [];
    this.patientid = resp['matchedPatientId'];
    this.patientInfo = resp['matchingPatient']['patientRow'];
    this.linkedPatientRow = resp['matchingPatient']['linkedPatientRow']
    if ( resp['matchedBarcodes'].length > 1) this.sampleIdsOptions = resp['matchedBarcodes'];

    if ( resp["matchingPatient"] && resp["matchingPatient"]["reportPrintStatus"] && resp["matchingPatient"]["reportPrintStatus"]["Hold"])
      this.errorMessageForPrintingReport = "Marked on hold by Admin.";
    if ( resp["matchingPatient"] && resp["matchingPatient"]["reportPrintStatus"] && resp["matchingPatient"]["reportPrintStatus"]["Reject"])
      this.errorMessageForPrintingReport = "Marked Rejected by Admin.";

    if ( ! this.errorMessageForPrintingReport && resp["matchingPatient"] && resp["matchingPatient"]["reportPrintStatus"] && resp["matchingPatient"]["reportPrintStatus"]["Authenticate"])
      this.shouldBeAbleToPrint = true;

    if ( resp["matchingPatient"] && resp["matchingPatient"]["address"])
      this.mailingInfo = {
        to: resp["matchingPatient"]["address"].ReportDeliveryEmailID,
        cc: resp["matchingPatient"]["address"].ReportDeliveryCCEmailID,
        bcc: resp["matchingPatient"]["address"].ReportDeliveryBCCEmailID,
      }

    this.barcodeid = this.barcodeid || resp['matchedBarcodes'][0];

    await this.fetchTestCodes();
  }

  async syncToCommandCenter() {
      const resp = await this._rest.syncSample(this.barcodeid, this.labLocationId);
      this.labLocationId = "";

      if ( ! resp['success'] && resp['message'] === this._util.Constants.Messages.RecordNotFound) {

          // ask for lab Id, and try again.
          const labLocation = await Swal.fire({
              title: 'Where is this sample registered',
              input: 'select',
              inputOptions: resp['labs'],
              inputPlaceholder: 'Select lab location',
              showCancelButton: true
          });

          console.log('labLocation: ', labLocation);
          if ( labLocation && labLocation.isConfirmed) {
              this.labLocationId = labLocation.value;
              return await this.syncToCommandCenter();
          }
      }

        if ( ! resp['success'] && resp['message'])
            return await this._util.showAlert("Server resp", resp["message"], "error");

        await this._util.showAlert("Synced", "", "success");
  }

    async previewReport() {
      if ( this.barcodeid && this.patientid && this.testCodes.length && ! this.selectedTestCode) {
          return Swal.fire("Select a test before", "", "warning");
      }

        if (!this.barcodeid || !this.patientid || !this.selectedTestCode) {
            await this.tryToFillPatientBarcodeId();
        }

        if (!this.barcodeid) return Swal.fire("Missing required field", "Barcode cannot be empty", "error");

        const url = window.location.origin + "/preview-report?"
            + `testcode=${this.selectedTestCode}&barcode=${this.barcodeid}&patient=${this.patientid}`
            + `&headers=${this.headers}`;

        window.open(url);
    }

  async mailReport() {
    const url = window.location.origin + "/api/reports/mail?"
      + `testcode=${this.selectedTestCode}&barcode=${this.barcodeid}&patient=${this.patientid}`
      + `&headers=${this.headers}`
      + `&token=${this._util.getToken()}`;

    window.open(url);

    /* Swal.fire({
      title: 'Do you really want to e-mail to following address?',
      html: `${this.mailingInfo.to}`,
      showDenyButton: true,
      confirmButtonText: `Yes, send`,
      denyButtonText: `Don't send`,
    }); */
  }

  async clearSearchForm() {
    this.testCodes = [];
    this.selectedTestCode = "";
    this.patientid = "";
    this.barcodeid = "";
    this.patientInfo = null;
    this.linkedPatientRow = null;
    this.linkingPatientId = "";
    this.searchPatientTimer = null;
    this.searchedPatientDetails = {};
    this.errorMessageForPrintingReport = "";
    this.shouldBeAbleToPrint = false;
    this.mailingInfo = null;
  }

  async unlinkPatient() {
      if ( ! this.barcodeid || ! this.linkedPatientRow.id) return;

      if ( ! confirm("Are you sure, you want to unlink this patient?")) return;

      console.log("unlink patient called: ", this.patientInfo.id, this.linkedPatientRow.id);

      const resp = await this._rest.updateLabmateLinkedPatient(this.barcodeid, this.patientInfo.id, null);
      if ( ! resp['success'] ) return this._util.showAlert("Server resp", resp['message']);

      this.linkingPatientId = "";
      await this._util.showAlert("Un-linked successfully", "", "success");

      return await this.tryToFillPatientBarcodeId();
  }

    async linkPatientId() {
      if ( ! this.linkingPatientId || ! this.linkingPatientId.length) return;

      if ( ! this.searchedPatientDetails || ! this.searchedPatientDetails['name']) return;

      if ( (new Date(this.searchedPatientDetails['bookingDate'])).getTime() >= (new Date(this.patientInfo.bookingDate)).getTime() )
          return this._util.showAlert("Booking time mismatch", "Patient linking should be booked earlier than parent.");

      const resp = await this._rest.updateLabmateLinkedPatient(this.barcodeid, this.patientInfo.id, this.linkingPatientId.trim());
      if ( ! resp['success'] ) return this._util.showAlert("Server resp", resp['message']);

        await this._util.showAlert("Updated successfully", "", "success");

        return await this.tryToFillPatientBarcodeId();
    }

    async tryToFindPatient() {

      if ( this.searchPatientTimer !== null ) {
          clearTimeout(this.searchPatientTimer);
          this.searchPatientTimer = null;
      }

      this.searchPatientTimer = setTimeout(this.tryToFindPatientHandler, 300);

      this.searchedPatientDetails = {};
    }

    async tryToFindPatientHandler() {
      if ( ! this.linkingPatientId.length) return;
        console.log("executing search: ", this.linkingPatientId);

      this.searchedPatientDetails = {};

        const resp = await this._rest.getLabmatePatientInfo(this.linkingPatientId);
        if ( ! resp['success']) return this._util.showAlert("Server resp", resp['message']);

        if ( resp['patient'].id !== this.linkingPatientId) return;

        this.searchedPatientDetails = resp['patient'] || {};
    }

}
