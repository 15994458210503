import { Component, OnInit } from '@angular/core';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";

@Component({
  selector: 'app-jarvis-home',
  templateUrl: './jarvis-home.component.html',
  styleUrls: ['./jarvis-home.component.styl']
})
export class JarvisHomeComponent implements OnInit {

    userName: string = "";

    hideCustomerDetails = true;

  constructor(public _util: UtilityService, public _rest: RestService) { }
    childrenCount: number = 0;

  ngOnInit(): void {
      this._util.redirectAccordingly();

      this.hideCustomerDetails = this.checkUrlQuery();

      console.log(this.hideCustomerDetails);

      const user = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user));
      this.childrenCount = ((user.info || {}).children || []).length;
      this.userName = user.firstname + " " + user.lastname;
      console.log("childrenCount: ", this.childrenCount, user);
  }

    viewCustomerDetails() {
        this._util.redirectTo('customer-details');
    }

    viewBDMCustomerDetails(){
      this._util.redirectTo('customer-approval-dashboard');
    }

    redirectToApprovingChildrenAddressManagerRequests() {
      this._util.redirectTo('patch-requests');
    }

    checkUrlQuery(){

    let url = window.location.href;

    let query = url.split('?')[1];

    let queryValue = (query || '').split('=')[1];

    console.log(query, queryValue);

    if(queryValue === 'true' || queryValue === '1') return true;

    return false;
  }
}
