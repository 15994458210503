import {Component, OnInit, NgZone, ElementRef} from '@angular/core';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import {TimeslotsInputComponent} from "../components/timeslots-input/timeslots-input.component";
import {TimeHoursInputComponent} from "../components/time-hours-input/time-hours-input.component";
import {TestDetailRowComponent} from "../test-detail-row/test-detail-row.component";
import {TestDetailTableComponent} from "../test-detail-table/test-detail-table.component";
import {TestSearchFilterComponent} from "../test-search-filter/test-search-filter.component";
import Swal from 'sweetalert2';
import {EventService} from '../event.service';


@Component({
  selector: 'app-customer-creation',
  templateUrl: './customer-creation.component.html',
  styleUrls: ['./customer-creation.component.styl']
})
export class CustomerCreationComponent implements OnInit {

    showCreateForm = false;
    submittedOnce = false;
    showTestSelectionForm = false;
    showAddNewCustomerOption = false;
    centerIsPCPNDT = false;
    isPartyTypeCredit = false;
    createFormDefaultData = {};
    creationFormFields = [];
    testList = [];
    filteredTestList = [];
    selectedTests = [];
    testSelectionSubmitted = false;
    changedValues = {};
    testsSearchString = "";
    testsFilterString = "";
    searchDelayTimeout = 0;
    formValidationErrors = {};
    remainingTestList = [];
    activeTable = 'remainingTable';
    testDropdownLabels = {};
    currentCustomer = {};
    licenseFile = {};
    licensePresent = false;
    userInfo = {};

  constructor(private _rest: RestService, public _util: UtilityService, public _event:EventService, public _zone: NgZone, private el: ElementRef) { }

  ngOnInit(): void {

        this._event.table_selectedTestEmitter.subscribe((event) => {
            this.selectTest(event);
            this._zone.run(() => {});
        });
        this._event.table_valueChangeEmitter.subscribe((event) => {
            this.testPriceChange(event);
            this._zone.run(() => {});
        });

        this._event.tableSearch_searchStringEmitter.subscribe((event) => {
            this.searchStringChange(event);
            this._zone.run(() => {});
        });
        this._event.tableSearch_filterStringEmitter.subscribe((event) => {
            this.filterStringChange(event);
            this._zone.run(() => {});
        });


      this.showCreateBlock().then(async() => {
          await this.afterInitiated();
      });

      this.userInfo = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));

  }

    async showCreateBlock() {
        this.showAddNewCustomerOption = false;
        this.showCreateForm = true;
        this.changedValues = {};

        this.collapseAccordingly();

        await this.checkForUrlParams().then(async () => {
            const formFieldsResp = await this._rest.getBDMMOUAppFormFields();

            if ( formFieldsResp['success'] ) this.creationFormFields = formFieldsResp['fields'];

            const defaultValuesResp  = await this._rest.getBDMMOUAppDefaultValues();
            if ( defaultValuesResp['success']) this.createFormDefaultData = defaultValuesResp['values'];
        });
    }

    isTestSelected(test) {
        if(this.selectedTests.indexOf(test) > -1) return true;
        return false;
    }

    searchStringChange(event) {
        this.testsSearchString = event;
        console.log("search string changed: ", this.testsSearchString);

        if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

        this.searchDelayTimeout = setTimeout(() => {

            if(this.testsSearchString.charAt(0) === 'G'){
                this.filterByTestCode('search');
            }
            else{
                this.filterByTestName();
            }

        }, 250);
    }

    filterStringChange(event){
        this.testsFilterString = event;
        console.log("filter string changed: ", this.testsFilterString);
        if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

        this.searchDelayTimeout = setTimeout(() => {

            this.filterByTestCode('filter');

        }, 250);
    }

    filterByTestCode(action = 'search'){
        let filter = (action === 'search') ? this.testsSearchString : this.testsFilterString;
        let currentSelectedTests = this.selectedTests;
        let currentTestList = this.testList.filter(function(element){
            return currentSelectedTests.indexOf(element) === -1;
        });
        this.remainingTestList = currentTestList.filter(function(element){
            return (element.Gcode + element.Scode + element.TestCode).indexOf(filter) > -1;
        });
    }

    filterByTestName(){
        let filter = this.testsSearchString;
        let currentSelectedTests = this.selectedTests;
        let currentTestList = this.testList.filter(function(element){
            return currentSelectedTests.indexOf(element) === -1;
        });
        this.remainingTestList = currentTestList.filter(function(element){
            return element.Description.toUpperCase().indexOf(filter.toUpperCase()) > -1;
        });
    }


    resetSearchAndFilterString() {
        this.testsSearchString = '';
        this.testsFilterString = '';
        let currentSelectedTests = this.selectedTests;
        this.remainingTestList = this.testList.filter(function(element){
            return currentSelectedTests.indexOf(element) === -1;
        });
    }

    discountPercentage(type, test){
        switch(type){
            case 'standard':
                if((test.MRP - test.StdPrice) > 0)
                    return (((test.MRP - test.StdPrice)/test.MRP) * 100).toFixed(2);
                return 0.00;

            case 'final':
                if((test.MRP - test.FinalPrice) > 0)
                    return (((test.MRP - test.FinalPrice)/test.MRP) * 100).toFixed(2); 
                return 0.00;
        }
    }

    selectTest(test) {
        if(this.selectedTests.indexOf(test) > -1){
            this.selectedTests = this.selectedTests.filter(function(element){
                return element !== test;
            });

            this.remainingTestList.push(test);
        }
        else{
            this.remainingTestList = this.remainingTestList.filter(function(element){
                return element !== test;
            });

            if(this.remainingTestList.length == 0){
                this.resetSearchAndFilterString();
            }
            this.selectedTests.push(test);
        }

    }

    async afterInitiated() {
      this.filteredCreationFormFields().forEach((field) => {
          if (this.createFormDefaultData[field.default] && !this.changedValues[field.identifier]) {
            this.changedValues[field.identifier] = this.createFormDefaultData[field.default];
          }
          else if(field.default && !this.changedValues[field.identifier]){
            this.changedValues[field.identifier] = field.default;
          }
      });

      
      if(this.changedValues['billing-account-type'] === 'Credit'){
        this.isPartyTypeCredit = true;
      }

      const allDefaultKeys = this.creationFormFields
          .map(row => row.allowedValues || null)
          .filter(row => !! row)
          .filter((row, index, arr) => arr.indexOf(row) === index);
      
    }

    getValueFor(identifier: string) {
      if ( identifier === 'clinic-timings') return '02:00 PM - 05:00 PM'; 

        let formField = this.creationFormFields.find(row => row.identifier === identifier) || null;
       
        if ( this.changedValues[identifier]) return this.changedValues[identifier];

        if ( ! formField) return null;

        if ( this.createFormDefaultData[formField.default]) return this.createFormDefaultData[formField.default];

        return '';
    }

    optionIsSelected(option, field) {
        let value = this.changedValues[field.identifier] || null;

        let formField = this.creationFormFields.find(row => row.identifier === field.identifier) || null;
        
        
        if(value !== null){
            switch( typeof value ){
                case 'object':
                    if(value.indexOf(option) > -1) return true;
                    return false;

                case 'string':
                    if ( option === value) return true;
                    return false;

                default:
                    if(option.toString() === value.toString()) return true;
                    return false;
            }
        }
        return false;
    }

    getSimilarToField(fieldProperty , similarField) {
        let similarToField = {};
        similarToField = (similarField.allowSameAs.indexOf('.') > -1) 
            ? this.filteredCreationFormFields().find(element => element.identifier === similarField.allowSameAs.split('.')[0])
            : this.filteredCreationFormFields().find(element => element.identifier === similarField.allowSameAs);

        switch(fieldProperty){
            case 'title':
                return  similarToField['title'];

            case 'value':
                if(similarField.allowSameAs.indexOf('.') > -1){
                    let requiredValueArray = this.changedValues[similarToField['identifier'] || ''].split(' ');
                    let valueIndex = similarField.allowSameAs.split('.')[1];
                    this.changedValues[similarField['identifier'] || ''] = requiredValueArray[parseInt(valueIndex)];  
                }   
                else{ 
                    this.changedValues[similarField['identifier'] || ''] = this.changedValues[similarToField['identifier'] || ''];
                }

            default:
                break;
        };
    }

    filteredCreationFormFields() {
      let excludedFields = []; 

      if(!this.centerIsPCPNDT){
        excludedFields.push('pcpndt-registration', 'pcpndt-expiration');                  
      }
      if(!this.isPartyTypeCredit){
        excludedFields.push('tan-number', 'gst-number', 'pancard-number');
      }

      //console.log('excluded fields:', excludedFields);

      return this.creationFormFields.filter(row => (row.presentIn.indexOf('BDMApp') !== -1 ) && (excludedFields.indexOf(row.identifier) === -1));      
      
    }

    /**
     * 'alphanumeric+symbols',
     * 'alphabets+symbols',
     * 'alphanumeric',
     * 'alphabets',
     *
     * 'mobile',
     * 'email',
     * 'numeric',
     *
     * 'timeslots',
     * 'timeslot',
     *
     * 'weekdays',
     *
     * 'instituteCategories',
     * 'New,Current,Lost,Lead',
     * 'Corporate-Multispeciality Hospital,Nursing/Materni…edicine Centre,Scan Centre,Poly Clinic,Own Clinic',
     * 'Report Printed at Centre with Header,Report Printed at Centre without Header,Both',
     * 'emails',
     * 'Bluedart,WOW,Maruti,Others',
     * 'Yes',
     * 'Yes,No',
     * 'Debit,Credit',
     * 'Daily,Monthly,Weekly,Quarterly,45 Days,60 Days
     *
     *
     * 'timeHours',
     * 'date',
     */
    getPatternForField(field) {
        switch (field.allowedValues) {
            case 'alphanumeric+symbols':
                return '^[a-zA-Z0-9! @#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?]*$';
            case 'alphabets+symbols':
                return '^[a-zA-Z!@ #$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?]*$';
            case 'alphanumeric':
                return '^[a-zA-Z0-9 ]*$';
            case 'alphabets':
                return '^[a-zA-Z ]*$';
            case 'mobile':
                return '^[0-9]{10}$';
            case 'email':
                return '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$';
            case 'emails':
                return '^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4};*)+$';
            case 'numeric':
                return '^[0-9]*$';
            default:
                return false;
        }
    }

    isDropdown(field) {
        if ( field.allowedValues === "weekdays" ) return true;

        return field.allowedValues && field.allowedValues.indexOf(',') !== -1;
    }

    async showTestDetailForm(){

        const testResponse = await this._rest.getProfileTestCodes({'query':'select * from test where Profile = 1'});

        this.testList = testResponse['tests'];

        this.testDropdownLabels = testResponse['departmentTestLabels'];

        this.filteredTestList = this.testList;

        this.showCreateForm = false;

        this.showTestSelectionForm = false;
        this.testSelectionSubmitted = true; 
        let chosenTests = this.selectedTests;
        this.remainingTestList = this.testList.filter(function(element){
            return chosenTests.indexOf(element) == -1;
        });
    }

    async validateInput(field, value) {
        let issues = [];

        let fieldValue = this.changedValues[field.identifier] || "";

        if(!fieldValue.length && !this.createFormDefaultData[field.default] && !this.isDropdown(field) && !field.readonly){
            if(field.identifier === 'gst-number' || field.identifier === 'tan-number' || field.identifier === 'pancard-number'){
                if(!this.changedValues['gst-number'] && !this.changedValues['tan-number'] && !this.changedValues['pancard-number']){
                    issues.push('Please enter GST, PAN or TAN number before proceeding');
                }
            }
            else if(field.identifier === 'total-patient-footfall' || field.identifier === 'no-of-pregnancies-per-month'){
                //ignore these two fields are these are not required
            }
            else{ 
                issues.push(`Please fill in ${field.title} before proceeding`);
            }
        }

        switch(field.allowedValues){
            case 'alphabets+symbols':
                if(!fieldValue.match(this.getPatternForField(field)))
                    issues.push('Numbers not allowed');
                break;

            case 'alphanumeric':
                if(!fieldValue.match(this.getPatternForField(field)))
                    issues.push('Special characters not allowed');
                break;

            case 'alphabets':
                if(!fieldValue.match(this.getPatternForField(field)))
                    issues.push('Only alphabets are allowed');
                break;

            case 'mobile':
                if(!fieldValue.match(this.getPatternForField(field)))
                    issues.push(' Please enter a valid mobile number');
                break;

            case 'email':
                if(!fieldValue.match(this.getPatternForField(field)))
                    issues.push(' Please enter a valid email');
                break;

            case 'numeric':
                if(!fieldValue.match(this.getPatternForField(field)))
                    issues.push('Only numbers are allowed');
                break;

            case 'emails':
                let fieldValueArray = fieldValue.split(';');
                let patternForField = this.getPatternForField(field);
                let issueDetected = false;
                fieldValueArray.forEach(function(element){
                    if(!element.match(patternForField))
                        issueDetected = true;       
                });
                if(issueDetected) issues.push('Please enter valid email id and seperate with ";"');
                break;

        }

        console.log('issues: ', issues);

        return issues;
    }

    async submitNewCustomer() {
        this.submittedOnce = true;
        setTimeout(() => {
            this.submittedOnce = false;
        }, 3000000);

        const fields = this.filteredCreationFormFields();

        for ( let i = 0; i < fields.length; i ++ ) {
            const field = fields[i];
            const value = this.getValueFor(field.identifier);

            this.formValidationErrors[field.identifier] = await this.validateInput(field, value) || "";
        }

        for (const key of Object.keys(this.formValidationErrors)) {
              if (this.formValidationErrors[key].length) {
                const invalidControl = this.el.nativeElement.querySelector('#' + key);
                invalidControl.focus();
                break;
             }
        }

        // @ts-ignore
        const allIssuesCount = Object.values(this.formValidationErrors).reduce((acc, row) => acc + row.length, 0);
        if ( allIssuesCount ) return;

        
        if(this.ifEditMode()){
            console.log('updateCustomerData');
            this.updateCustomerData();
        }
        else{
            
            let customerObject = {
                "customer_data": this.changedValues,
            };

            this._rest.createBDMCustomer(customerObject).then(async(resp) => {
                    if(resp['success']){
                        this.currentCustomer = resp['customer'];
                        await this.uploadPCPNDTLicense();
                    }
                    else{
                        Swal.fire('Error in creating the customer', resp['message'], 'error');
                    }
            });
        }
    }

    updateCustomerData(){
        let customerStage = '';
        if(this.userInfo['permissions'].indexOf(this._util.Constants.Permissions.VerifyNewCustomer) > -1){
            customerStage = 'Approved By RP';
        }
        else{
            customerStage = 'Pending Approval';
        }
        let updateCustomerData = {
            customer_name : this.changedValues['institute-name'] || "",
            contact_name : this.changedValues['key-decision-maker-name'] || "",
            contact_email : this.changedValues['official-email'] || "",
            contact_mobile : this.changedValues['official-mobile'] || "",
            stage : customerStage,
            bdm_user_id : this.currentCustomer['bdm_user_id'] || "",
            customer_data : this.changedValues,
        }

        this._rest.updateBDMCustomer(this.currentCustomer['id'], updateCustomerData).then((response) => {
            if(response['success']){
                if(! this.licensePresent){
                    this.uploadPCPNDTLicense();
                }
                if(this.userInfo['permissions'].indexOf(this._util.Constants.Permissions.VerifyNewCustomer) === -1)
                    this.showTestDetailForm(); //dont show test window for masters during edit mode

                else this._util.redirectTo('/jarvis-home');
            }
            else{
                Swal.fire('Error in updating the customer', response['message'], 'error');
            }
        })
    }

    getOptions(field) {
        if ( field.allowedValues === 'weekdays') return this._util.weekdays();

        return field.allowedValues.split(',');
    }

    isNativeTextInput(field) {
        if ( this.isDropdown(field)) return false;

        if ( ['timeslot', 'timeslots', 'timeHours', 'date', 'emails'].indexOf(field.allowedValues) !== -1) return false;

        return true;
    }

    updateCustomerTestData() {
        let updateCustomerData = {
            "tests_data": { 'tests':this.selectedTests },
        };

        this._rest.updateBDMCustomer(this.currentCustomer['id'], updateCustomerData).then((resp) => {
                if(resp['success']){
                    Swal.fire('Saved Successfully', 'New Customer Test Data Saved Successfully', 'success');
                    this.showAddNewCustomerOption = true;
                    this._util.redirectTo('jarvis-home');
                }
            
        });
    }

    selectOption(field, $event) {

        if(field.multiple){
            let currentValue = [];
            currentValue = this.changedValues[field.identifier];
            
            if(currentValue.length == 6){
                currentValue = [];
            }

            if(currentValue.indexOf($event.target.value) > -1){
                currentValue = currentValue.filter(function(element) { return element !== $event.target.value });
            }
            else{
                currentValue.push($event.target.value);
            }
            this.changedValues[field.identifier] = currentValue;
        }
        else{
            this.changedValues[field.identifier] = $event.target.value;
            if(this.changedValues['billing-account-type'] === 'Credit'){
                this.isPartyTypeCredit = true;
            }
            else{
                this.isPartyTypeCredit = false;
            }
        }
    }

    emptyField(field){
        switch(typeof this.changedValues[field.identifier]){
            case 'string':
                this.changedValues[field.identifier] = ""; 
                break;

            case 'number':
                this.changedValues[field.identifier].length = 0;
                break;
        }
        
    }

    testPriceChange(event){
        this.selectedTests.forEach(function(element){
            if((element.Gcode + element.Scode + element.TestCode) === (event.Gcode + event.Scode + event.TestCode)) element = event;
        })

    }

    toggleTestTables(){
        
        if(this.activeTable === 'remainingTable'){
            this.activeTable = 'selectedTable';
        }
        else{
            this.activeTable = 'remainingTable'
        }

        
        this.collapseAccordingly();
    }

    collapseAccordingly(){
        this._util.collapse("collapseRemaining", (this.activeTable === 'remainingTable')?"show":"hide");
        this._util.collapse("collapseSelected", (this.activeTable === 'selectedTable')?"show":"hide");
        
    }

    fieldIsEmpty(field){
        let value = this.changedValues[field.identifier] || '';
        if(value.length) return false;
        return true;
    }

    ifEditMode(){
        //if currentCustomer exist before test details screen then that means that it is edit mode 
        console.log('edit mode check:', this.currentCustomer);
        if(Object.keys(this.currentCustomer).length) return true;
        return false;
    }

    returnToDashboard(){
        this._util.redirectTo('jarvis-home');
    }

    showLicenseUploadModal(){
        this._util.modal('upload-pcpndt-license', 'show');
    }

    hideLicenseUploadModal(){
        this._util.modal('upload-pcpndt-license', 'hide');
    }

    getPCPNDTLicense($event){
        this.licenseFile = $event.target.files[0];
    }

    async uploadPCPNDTLicense(){
        if(!this.centerIsPCPNDT){ 
            this.showTestDetailForm();
            return;
        }
        if(this.licenseFile instanceof File){
            const formData = new FormData();
            formData.append('attachment', this.licenseFile);
    
            formData.append('customerId', this.currentCustomer['id'].toString());
            formData.append('purpose', 'pcpndt-license');

            await this._rest.uploadPCPNDTLicense(formData).then(function(response){
                    console.log('response: ', response);
                    if(!response['success']){
                        Swal.fire('Error in uploading PCPNDT License', response['message'], 'error')
                    }
                    else{
                        this.showTestDetailForm();
                    }
            });
        }else{
            Swal.fire('No file selected','Please select a file to upload before proceeding','error');
        }
    }

    async checkForUrlParams(){
      let urlParam = '';
      let urlArray = window.location.href.split('?'); 
      if(urlArray.length > 1) urlParam = urlArray[1];
      let customerId = urlParam.split("=")[1];
      if(urlParam.indexOf('customerId') > -1) await this.initiateFormWithExisitingCustomer(customerId);
    }

    async initiateFormWithExisitingCustomer(id){
      let customerId = parseInt(id);
      const resp = await this._rest.fetchBDMCustomerById(id);
      let existingCustomer = resp['customer'];
      this.currentCustomer = existingCustomer;
      this.changedValues = existingCustomer['customer_data'] || {};
      this.selectedTests = (existingCustomer['tests_data']) ? existingCustomer['tests_data']['tests'] : [];
      if(existingCustomer['customer_data']['pcpndt-registration'] && existingCustomer['customer_data']['pcpndt-expiration']){
        this.centerIsPCPNDT = true;
        this.licenseFile = resp['pcpndtLicense'] || null;
        if(this.licenseFile) this.licensePresent = true;
      }
      this.remainingTestList = this.testList.filter(function(element){
        return this.selectedTests.indexOf(element) === -1;
      })
    }
}
