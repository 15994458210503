import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Chart} from 'chart.js';

@Component({
  selector: 'app-jamsify-statistics-charts',
  templateUrl: './jamsify-statistics-charts.component.html',
  styleUrls: ['./jamsify-statistics-charts.component.styl']
})
export class JamsifyStatisticsChartsComponent implements OnInit {

  public chart: any;

  @Input('xAxisLabels') xAxisLabels: any[];
  @Input('yAxisData') yAxisData: any[];
  
  constructor() { }

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges(): void {
    this.createChart();
  }

  createChart(){
  
    this.chart = new Chart("MyChart", {
      type: 'line', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: this.xAxisLabels, 
         datasets: [
          {
            label: "No. of sents",
            data: this.yAxisData,
            backgroundColor: 'green'
          },
        ]
      },
      options: {
        aspectRatio:1
      }
      
    });
  }

}
