import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { RestService } from '../rest.service';
import { UtilityService } from '../utility.service';
import { TatInputComponent } from '../components/tat-input/tat-input.component';

// @ts-ignore
@Component({
  selector: 'app-manage-tat',
  templateUrl: './manage-tat.component.html',
  styleUrls: ['./manage-tat.component.styl']
})
export class ManageTatComponent implements OnInit {

  tests = [];
  openTest = {};

  message = {
    text: "",
    type: "",
  }
  form = {
    labType: null,
    query: "",
    orderBy: "testcode",
    orderByDesc: false,
    page: 1,
    perPage: 30,
    lastPage: 1,
    total: 0,
  }
  filter = {
      query: "",
      labType: "",
      comboOnly: false,
  }
  pagination = {
      max: 1,
      current: 1,
      total: 1,
  }

  testNameFilterQuery = "";
  filteredTests = [];
  showingChildrenLayout = false;
  searchedTests = [];
  showingCreateTestLayout = false;
  labTypes = [];

  createTestForm = {
    name: "",
    testcode: "",
    minimum_tat: 0,
    maximum_tat: 0,
    lab_type_id: "",
    parent_id: 0,
      haveChanges: false,
      saveButtonText: "",
  }

  constructor(public _util: UtilityService, public _rest: RestService) { }

  ngOnInit(): void {
    this.refreshTests();
    this.refreshLabTypes();
    this.initiateTooltips();

    if ( ! this._util.canUser(this._util.Constants.Permissions.ManageUsers))
      this._util.redirectTo();
  }

  initiateTooltips() {
      setTimeout(() => {
          eval(`$('[data-toggle="tooltip"]').tooltip()`);
      }, 1);
  }

  refreshLabTypes = async () => {
    const resp = await this._rest.getAllLabs();

    if ( ! resp['success'] && resp['message'])
      return this._util.showAlert("Server resp: ", resp['message']);

    this.labTypes = resp['types'] || [];
  }

  refreshTests = async () => {
    const resp = await this._rest.getTests({ ...this.form, ...this.filter, ...this.pagination });

    // @ts-ignore
    if ( ! resp.success && resp.message) return this._util.showAlert("Server resp: ", resp.message);

    console.log("resp: ", resp);

    this.form.lastPage = resp["tests"]["lastPage"] || 1;
    this.pagination.max = this.form.lastPage;
    this.form.total    = parseInt(resp["tests"]["total"] || "0") || 0;
    this.tests         = resp["tests"]["data"].map( test => {
      test.code = test.testcode;
      test.type = test.labType.name;

      return test;
    });
  }

  tatChanged = (value, index, type) => {

    if ( ["create-1",].indexOf(index + "") > -1 ) {
      if ( type === "min") this.createTestForm.minimum_tat = value;
      if ( type === "max") this.createTestForm.maximum_tat = value;

      this.createTestForm.haveChanges = true;

      return;
    }

    if ( (index + "").indexOf('-child-') > -1) {
        const indexParts = index.split('-');
        const child = this.openTest['children'][indexParts[2]];

        if ( type === 'min') child.minimum_tat = value;
        if ( type === 'max') child.maximum_tat = value;
        child.haveChanges = true;

        return;
    }

    const test = this.tests[index];

    // should return in invalid index comes
    if ( ! test) return;

    type === "min" ? test.minimum_tat = value : test.maximum_tat = value;
    test.haveChanges = true;

    this.tests[index] = test;
  }

    saveChildChanges = async (child) => {
      child['saveButtonText'] = "saving";

      const resp = await this._rest.updateTest(child.id, child);

      if ( ! resp['success'] && resp['message'])
          return this._util.showAlert('Server resp', resp['message']);

      child['saveButtonText'] = "saved";

      setTimeout(() => {
          child['saveButtonText'] = "";
          child.haveChanges = false;
      }, 2000);
    }

  saveChanges = async (index) => {
    const test = this.tests[index];

    test.saveButtonText = "saving";
    this.tests[index] = test;

    const resp = await this._rest.updateTest(test.id, test);

    // @ts-ignore
    if (! resp.success && resp.message) return this._util.showAlert("Server error", resp.message);

    test.saveButtonText = "saved";
    this.tests[index] = test;

    setTimeout(() => {
      test.saveButtonText = ""
      test.haveChanges = false;
      this.tests[index] = test;

    }, 2000);
  }

  async showChildren(test) {
    console.log("show childrens: ", test)

    this.openTest = test;
    this.showingChildrenLayout = true;

    await this.refreshChildren(test);
  }

  async refreshChildren(test) {

    const resp = await this._rest.getTestChildren(test.id);
    if ( ! resp['success'] && resp['message'])
      return this._util.showAlert('Server resp', resp['message']);

    this.openTest['children'] = resp['children'] || [];
    this.testNameFilterQuery = "";
    this.searchedTests = [];
  }

  async searchChildren() {
    if ( ! this.testNameFilterQuery.length)
      return;

    this.searchedTests = [];

    const resp = await this._rest.searchTestChildren(this.testNameFilterQuery.trim());
    if ( ! resp['success'] && resp['message'])
      return this._util.showAlert('Server resp', resp['message']);

    this.searchedTests = resp['tests'];
  }

  filterByTestList(newValue = null) {
    console.log("filterByTestList: ", newValue, this.testNameFilterQuery);

    if ( newValue !== null ) this.testNameFilterQuery = newValue;
    this.filteredTests = [];

    const matched = this.tests.filter(row => {
      return row.testcode.toLowerCase().indexOf(this.testNameFilterQuery) > -1
        || row.name.toLowerCase().indexOf(this.testNameFilterQuery) > -1;
    });

    this.filteredTests = matched;
  }

  async addChildren(test) {
    console.log("adding children: ", test, " to ", this.openTest);

    const resp = await this._rest.attachTestChild(this.openTest['id'], test.id);
    if ( ! resp['success'] && resp['message'])
        return this._util.showAlert("Server resp", resp['message'], 'error');

    await this.refreshChildren(this.openTest);
  }

  async detachChild(childId) {
      console.log("detaching child: ", childId, this.openTest['id']);

      const resp = await this._rest.detachTestChild(this.openTest['id'], childId);
      if ( ! resp['success'] && resp['message'])
          return this._util.showAlert("Server resp", resp['message'], 'error');

      return this.refreshChildren(this.openTest);
  }

  async showCreateTestLayout() {

    this.createTestForm.parent_id = this.openTest['id'];
    this.createTestForm.name = this.testNameFilterQuery;
    this.createTestForm.minimum_tat = this.openTest['minimum_tat'];
    this.createTestForm.maximum_tat = this.openTest['maximum_tat'];
    this.createTestForm.lab_type_id = this.openTest['lab_type_id'] + "";

    console.log("this.createTestForm: ", this.createTestForm);

    this.showingCreateTestLayout = true;

    const resp = await this._rest.getTestcodeCount(this.openTest['testcode']);
    const count = resp['count'] || 0;

    this.createTestForm.testcode = this.openTest['testcode'] + "-" + count;
  }

  async submitCreateTestForm() {
    console.log("submit create test form", this.createTestForm);

    const resp = await this._rest.createTestChildren(this.createTestForm);

    if ( ! resp['success'] && resp['message'])
      return this._util.showAlert('Server resp', resp['message']);

    await this.refreshChildren(this.openTest);
    this.showingCreateTestLayout = false;
  }

  async loadTestPage(pageNo) {
      this.pagination.current = pageNo;
      return this.refreshTests();
  }

  async resetPaginationAndSearch() {
      this.pagination.current = 1;
      this.filter.comboOnly = false;

      return this.refreshTests();
  }

  getLabTypeLabel(id) {
      return (this.labTypes
          .find(labType => (labType.id + "") === (id + "")) || {})
          .name || "";
  }

  isChildType(testcode) {
      return testcode.indexOf("-") > -1;
  }

    backToListing() {
      this.showingCreateTestLayout = false;
      this.showingChildrenLayout = false;
      this.openTest = {};
    }

    clearSearch() {
        this.testNameFilterQuery = "";
        this.filter.query = "";
        this.backToListing();

        return this.refreshTests();
    }

    async showComboOnly() {
      this.filter.comboOnly = ! this.filter.comboOnly;
      await this.refreshTests();
    }
}
