<div class="page-wrapper">
  <!-- HEADER DESKTOP-->
  <header class="header-desktop4 hidden">
    <div class="container">
      <div class="header4-wrap">
        <div class="header__logo">
          <a href="#">
            <img src="/assets/images/icon/logo-blue.png" alt="CoolAdmin" />
          </a>
        </div>
        <div class="header__tool">
          <div class="header-button-item has-noti js-item-menu">
            <i class="zmdi zmdi-notifications"></i>
            <div class="notifi-dropdown js-dropdown">
              <div class="notifi__title">
                <p>You have 3 Notifications</p>
              </div>
              <div class="notifi__item">
                <div class="bg-c1 img-cir img-40">
                  <i class="zmdi zmdi-email-open"></i>
                </div>
                <div class="content">
                  <p>You got a email notification</p>
                  <span class="date">April 12, 2018 06:50</span>
                </div>
              </div>
              <div class="notifi__item">
                <div class="bg-c2 img-cir img-40">
                  <i class="zmdi zmdi-account-box"></i>
                </div>
                <div class="content">
                  <p>Your account has been blocked</p>
                  <span class="date">April 12, 2018 06:50</span>
                </div>
              </div>
              <div class="notifi__item">
                <div class="bg-c3 img-cir img-40">
                  <i class="zmdi zmdi-file-text"></i>
                </div>
                <div class="content">
                  <p>You got a new file</p>
                  <span class="date">April 12, 2018 06:50</span>
                </div>
              </div>
              <div class="notifi__footer">
                <a href="#">All notifications</a>
              </div>
            </div>
          </div>
          <div class="header-button-item js-item-menu">
            <i class="zmdi zmdi-settings"></i>
            <div class="setting-dropdown js-dropdown">
              <div class="account-dropdown__body">
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-account"></i>Account</a>
                </div>
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-settings"></i>Setting</a>
                </div>
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-money-box"></i>Billing</a>
                </div>
              </div>
              <div class="account-dropdown__body">
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-globe"></i>Language</a>
                </div>
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-pin"></i>Location</a>
                </div>
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-email"></i>Email</a>
                </div>
                <div class="account-dropdown__item">
                  <a href="#">
                    <i class="zmdi zmdi-notifications"></i>Notifications</a>
                </div>
              </div>
            </div>
          </div>
          <div class="account-wrap">
            <div class="account-item account-item--style2 clearfix js-item-menu">
              <div class="image">
                <img src="/assets/images/icon/avatar-01.jpg" alt="John Doe" />
              </div>
              <div class="content">
                <a class="js-acc-btn" href="#">john doe</a>
              </div>
              <div class="account-dropdown js-dropdown">
                <div class="info clearfix">
                  <div class="image">
                    <a href="#">
                      <img src="/assets/images/icon/avatar-01.jpg" alt="John Doe" />
                    </a>
                  </div>
                  <div class="content">
                    <h5 class="name">
                      <a href="#">john doe</a>
                    </h5>
                    <span class="email">johndoe@example.com</span>
                  </div>
                </div>
                <div class="account-dropdown__body">
                  <div class="account-dropdown__item">
                    <a href="#">
                      <i class="zmdi zmdi-account"></i>Account</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a href="#">
                      <i class="zmdi zmdi-settings"></i>Setting</a>
                  </div>
                  <div class="account-dropdown__item">
                    <a href="#">
                      <i class="zmdi zmdi-money-box"></i>Billing</a>
                  </div>
                </div>
                <div class="account-dropdown__footer">
                  <a href="#">
                    <i class="zmdi zmdi-power"></i>Logout</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- END HEADER DESKTOP -->

  <div class="page-container3">
    <router-outlet></router-outlet>
  </div>

</div>
