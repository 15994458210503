<div class="container line-height-medium">

    <div class="modal" tabindex="-1" id="upload-pcpndt-license" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                   <div class="vertical-flex">
                        <h3 clas="mb-3">Please upload a soft copy of the PCPNDT license before proceeding</h3>
                        <input class="file-upload" type="file" name="pcpndt-license" (change)="getPCPNDTLicense($event)">
                   </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="uploadPCPNDTLicense()">
                        Submit
                    </button>
                    <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close" (click)="hideLicenseUploadModal()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row horizontal-flex space-between-center mb-5">
        <div class="horizontal-flex start-center mt-4 ml-2">
            <h2 class="min-width-300 font-size-20-px-on-mobile">Customers Creation Form</h2>
        </div>

        <button type="button" class="btn btn-outline-primary ml-2" (click)="returnToDashboard()">
                Back to Dashboard
            </button>
    </div>

    <div class="row">

    </div>

    <div class="row vertical-flex start-center" [hidden]="! showCreateForm">

        <form (submit)="submitNewCustomer()" class="width-80 fill-width-on-mobile" [ngClass]="{'was-validated': submittedOnce}">

            <div class="horizontal-flex center-center mb-3" (click)="centerIsPCPNDT = !centerIsPCPNDT">
                <input class="ml-1" type="checkbox" name="pcpndtCertified" [(ngModel)]="centerIsPCPNDT">
                <label class="form-label width-10 text-right bold pr-2 line-height-1 margin-t-12px ml-2" for="pcpndtCertified">Institute is a certified PCPNDT institute</label>
            </div>
            
            <div class="vertical-flex flex-fill margin-left-on-mobile">
                <div class="horizontal-flex start-center fill-width mb-3" *ngIf="centerIsPCPNDT">
                    <label class="form-label width-10 text-right bold pr-2 line-height-1 margin-t-12px ml-2 width-40 p-0-on-mobile width-60-on-mobile" for="pcpndtCertified">PCPNDT License :&emsp;</label>
                    <div *ngIf="!licensePresent">
                        <input class="file-upload width-60" type="file" name="pcpndt-license" (change)="getPCPNDTLicense($event)">
                    </div>
                    <div class="row mt-1" *ngIf="licensePresent">
                        <h4 class="hide-on-mobile">The license file is already recieved, click here to see</h4>&nbsp;
                        <a href="{{licenseFile['last_url']}}"><button class="btn btn-primary pcpndt-button" type="button">Open File</button></a>
                    </div>
                </div>
                <div class="vertical-flex start-center fill-width mb-3" *ngFor="let field of filteredCreationFormFields()">
                    <div class="horizontal-flex vertical-flex-on-mobile center-stretch fill-width" >
                        <label for="{{ field.identifier }}" class="form-label width-40 fill-width-on-mobile text-right text-left-on-mobile bold pr-2 line-height-1 margin-t-12px">{{ field.title }} :&nbsp; </label>
                        <div class="input-container width-60 fill-width-on-mobile flex-fill vertical-flex start-stretch">
                            <div class="d-flex horizontal-flex vertical-flex-on-mobile start-stretch" *ngIf="isNativeTextInput(field)">
                                <div class="horizontal-flex">
                                    <label class="form-label width-10 text-right bold pr-2 line-height-1 margin-t-12px" *ngIf="field.prefix">{{field.prefix}}</label>
                                    <input type="text" [readOnly]="field.readonly"
                                       [pattern]="'' + getPatternForField(field)"
                                       [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                       [value]="getValueFor(field.identifier)" [(ngModel)]="changedValues[field.identifier]"
                                       class="form-control" [ngClass]="{'w-75': field.allowSameAs && field.allowSameAs.length}" id="{{ field.identifier }}" placeholder="{{ field.hint || 'Enter here ...' }}"
                                       *ngIf="isNativeTextInput(field)" [required]="['tan-number', 'gst-number', 'pancard-number', 'total-patient-footfall', 'no-of-pregnancies-per-month'].indexOf(field.identifier) == -1"
                                    >
                                    &nbsp;
                                    <button class="btn btn-outline" type="button" name="{{ field.identifier }}-same-as" id="{{ field.identifier }}-clear-button" *ngIf="changedValues[field.identifier] && changedValues[field.identifier].length && !field.readonly" (click)="emptyField(field)">
                                        <i class="fas fa-eraser eraser-icon"></i>
                                    </button>                         
                                    <label class="form-label width-10 text-right bold pr-2 line-height-1 margin-t-12px" *ngIf="field.suffix">{{field.suffix}}</label>
                                    &nbsp;&nbsp;
                                
                                </div>
                                <button class="btn btn-outline-primary w-30 margin-t-12px-on-mobile" type="button" name="{{ field.identifier }}-same-as" id="{{ field.identifier }}-same-as" *ngIf="field.allowSameAs && field.allowSameAs.length && fieldIsEmpty(field)" (click)="getSimilarToField('value', field)">
                                    Copy {{getSimilarToField('title', field)}}
                                </button>                         
                            </div>

                            <div class="d-flex horizontal-flex vertical-flex-on-mobile start-stretch" *ngIf="field.allowedValues === 'emails'">
                            <div class="horizontal-flex">
                                <input type="text" [readOnly]="field.readonly"
                                   [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                   [value]="getValueFor(field.identifier)" [(ngModel)]="changedValues[field.identifier]"
                                   [pattern]="'' + getPatternForField(field)"
                                   class="form-control" [ngClass]="{'w-75': field.allowSameAs && field.allowSameAs.length}" id="{{ field.identifier }}" placeholder="{{ field.hint || 'Enter here ...' }}"
                                   *ngIf="field.allowedValues === 'emails'" required
                                >
                                 &nbsp;
                                <button class="btn btn-outline" type="button" name="{{ field.identifier }}-same-as" id="{{ field.identifier }}-clear-button" *ngIf="changedValues[field.identifier] && changedValues[field.identifier].length && !field.readonly" (click)="emptyField(field)">
                                    <i class="fas fa-eraser eraser-icon"></i>
                                </button>                         
                                <label class="form-label width-10 text-right bold pr-2 line-height-1 margin-t-12px" *ngIf="field.suffix">{{field.suffix}}</label>
                                &nbsp;&nbsp;
                                <button class="btn btn-outline-primary w-30 margin-t-12px-on-mobile" type="button" name="{{ field.identifier }}-same-as" id="{{ field.identifier }}-same-as" *ngIf="field.allowSameAs && field.allowSameAs.length && fieldIsEmpty(field)" (click)="getSimilarToField('value', field)">
                                    Copy {{getSimilarToField('title', field)}}
                                </button>        
                            </div>
                        
                            </div>

                            <input type="date" [readOnly]="field.readonly"
                                   [pattern]="'' + getPatternForField(field)"
                                   [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                   [value]="getValueFor(field.identifier)" [(ngModel)]="changedValues[field.identifier]"
                                   class="form-control" id="{{ field.identifier }}" placeholder="{{ field.hint || 'Choose date ...' }}"
                                   *ngIf="field.allowedValues === 'date'" required
                            >

                    
                            <select class="form-control"
                                    [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                    *ngIf="isDropdown(field) && !field.multiple"
                                    [(ngModel)] = "changedValues[field.identifier]"
                                    [value]="getValueFor(field.identifier)" 
                                    [attr.value]="getValueFor(field.identifier)"
                                    [disabled]="field.readonly" [ngClass]="{'disabled': field.readonly}"
                                    (change) = "selectOption(field, $event)"
                            >
                                <option [attr.value]="''">Choose one</option>
                                <option *ngFor="let option of getOptions(field); trackBy:_util.customTrackBy"
                                        class="detect-click"
                                        [attr.value]="option"
                                        (selected)="optionIsSelected(option, field) ? 'selected' : ''"
                                >{{ option }}</option>
                            </select>

                            <select class="form-control"
                                    [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                    *ngIf="isDropdown(field) && field.multiple" [multiple]="field.multiple"
                                    [value]="getValueFor(field.identifier)" 
                                    [attr.value]="getValueFor(field.identifier)"
                                    [disabled]="field.readonly" [ngClass]="{'disabled': field.readonly}"
                            >
                                <option [attr.value]="''">Choose multiple by holding ctrl</option>
                                <option *ngFor="let option of getOptions(field); trackBy:_util.customTrackBy"
                                        class="detect-click"
                                        [attr.value]="option"
                                        (selected)="optionIsSelected(option, field) ? 'selected' : ''"
                                        (click)="selectOption(field, $event)"
                                >{{ option }}</option>
                            </select>

                            <app-timeslots-input
                                *ngIf="['timeslot', 'timeslots'].indexOf(field.allowedValues) > -1"
                                [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                [disabled]="field.readonly" [ngClass]="{'disabled': field.readonly}"
                                [isMultiple]="field.allowedValues === 'timeslots'"
                                [value]="getValueFor(field.identifier)"
                                (valueChange)="changedValues[field.identifier] = $event"
                            ></app-timeslots-input>

                            <app-time-hours-input
                                *ngIf="['timeHours'].indexOf(field.allowedValues) > -1"
                                [attr.id]="field.identifier" [attr.name]="field.identifier" [name]="field.identifier"
                                [disabled]="field.readonly" [ngClass]="{'disabled': field.readonly}"
                                [value]="getValueFor(field.identifier)"
                                (valueChange)="changedValues[field.identifier] = $event"
                            ></app-time-hours-input>
                    </div>    
                </div>
                <div *ngIf="(formValidationErrors[field.identifier] || '').length">
                        <label for="{{field.identifier}}">
                            <span class="text-danger overflow-wrap small-label-text">{{formValidationErrors[field.identifier]}}</span>
                        </label>
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-primary mb-5 ml-2">Proceed</button>

        </form>
    </div>

    <div class="row vertical-flex start-stretch" [hidden]="! showTestSelectionForm">
        <h3 class="text-center mb-4">Test Selection Form</h3>

            

            <ol class="flex-fill vertical-flex start-center">
                <li class="horizontal-flex flex-fill align-self-stretch" *ngFor="let test of filteredTestList">
                    <label class="form-label bold pr-2 line-height-1 margin-t-12px">{{(filteredTestList.indexOf(test) + 1) + "."}}</label>&nbsp;
                    <label for="{{ test.Gcode + test.Scode + test.TestCode }}" class="col-sm-4 form-label bold pr-2 line-height-1 margin-t-12px">{{ test.Gcode + test.Scode + test.TestCode }} &nbsp; </label>
                    <label for="{{ test.Description }}" class="col-sm-4 form-label bold pr-2 line-height-1 margin-t-12px">{{ test.Description }} &nbsp; </label>
                    <div class="col-sm-4">
                        <input type="checkbox" name="{{ test.Gcode + test.Scode + test.TestCode }}"  class="margin-t-12px" [checked]="isTestSelected(test)" (click)="selectTest(test)">
                    </div>
                </li>
            </ol>
            <div class="width-30 mb-5">
                <button type="button" class="btn btn-primary" (click)="showTestDetailForm()">Proceed</button>
            </div>
    </div>

    <style type="text/css">
            .small-scrollable-container{
                overflow-y: scroll;
                max-height: 450px;
            }
        </style>


    <div class="row vertical-flex start-stretch" [hidden]="! testSelectionSubmitted">
        <h3 class="text-center mb-4">Test Details Form</h3>
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <div class="d-flex flex-row space-between-center" (click)="toggleTestTables()">
                    <h5 class="mb-0">
                        <button class="btn btn-link">
                          View Selected Tests
                        </button>
                    </h5>
                    <div class='d-flex flex-row'>
                       <i class="fas fa-plus-circle" *ngIf="activeTable !== 'selectedTable'"></i>
                       <i class="fas fa-minus-circle" *ngIf="activeTable === 'selectedTable'"></i>
                    </div>
              </div>  
            </div>

            <div id="collapseSelected" class="collapse collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body small-scrollable-container" style="overflow-y: scroll; max-height: 500px;">
                <app-test-detail-table [displayList]="selectedTests" [editable]="true" [tableType]="'selected'" [showHeader]="true" [showCheckInput]="true"></app-test-detail-table>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
                <div class="d-flex flex-row space-between-center" (click)="toggleTestTables()">
                    <h5 class="mb-0">
                        <button class="btn btn-link">
                          Select More Tests
                        </button>
                      </h5>
                    <div>
                       <i class="fas fa-plus-circle" *ngIf="activeTable !== 'remainingTable'"></i>
                       <i class="fas fa-minus-circle" *ngIf="activeTable === 'remainingTable'"></i>
                    </div>      
                </div>
            </div>

            <div id="collapseRemaining" class="collapse collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body small-scrollable-container" style="overflow-y: scroll; max-height: 500px;">
                <ol class="flex-fill vertical-flex start-center">
                    <app-test-search-filter [testsSearchString]='testsSearchString' [testList]="testList" [testDropdownLabels]="testDropdownLabels" (testsSearchStringChanged)='searchStringChange($event)' (filterStringChanged)='filterStringChange($event)' (testsSearchStringReset)='resetSearchAndFilterString()' (filterStringReset)='resetSearchAndFilterString()'></app-test-search-filter>
                    <app-test-detail-table *ngIf="remainingTestList.length" [displayList]="remainingTestList" [editable]="false" [tableType]="'remaining'" [showHeader]="true" [showCheckInput]="true"></app-test-detail-table>
                    
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="width-30 mb-5">
            <button type="button" class="btn btn-primary" (click)="updateCustomerTestData()">Save Customer Test Details</button>
        </div>
    </div>
</div>
