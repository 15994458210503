<div class="container-fluid pt-3 fill-height">

    <div class="row">
        <div id="full-screen-carousel" class="carousel slide hidden" data-ride="carousel" #carouselMain>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row p-4">
                        <div class="col-md-6">
                            <div class="overview-item mb-2 overview-item--c1"
                                 *ngIf="data['counts'] && data['counts']['accession']">
                                <div class="overview__inner pb-4">
                                    <div class="overview-box clearfix horizontal-flex">
                                        <div class="icon">
                                            <i class="zmdi zmdi-account-o"></i>
                                        </div>
                                        <div class="text flex-fill">
                                            <h2>{{ data['counts']['accession']['all'] }}</h2>
                                            <span>In accession</span>
                                        </div>
                                    </div>

                                    <div class="progress mt-2 mb-1">
                                        <div class="progress-bar bg-success" role="progressbar"
                                             [ngStyle]="{width: getAccessionGreenWidth(), position: 'relative'  }"></div>
                                        <div class="progress-bar bg-danger" role="progressbar"
                                             [ngStyle]="{width: getAccessionDangerWidth(), position: 'relative'  }">
                                        </div>
                                    </div>

                                    <p class="text-white">{{ data['counts']['accession']['red'] }} waiting for 2+ hours
                                    </p>
                                </div>
                            </div>
                            <div class="overview-item mb-2 overview-item--c2"
                                 *ngIf="data['counts'] && data['counts']['repeat'].length">
                                <div class="overview__inner pb-4">
                                    <div class="overview-box clearfix">
                                        <div class="icon">
                                            <i class="zmdi zmdi-repeat"></i>
                                        </div>
                                        <div class="text">
                                            <h2>{{ data['counts']['repeat'] }}</h2>
                                            <span>Repeat</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="overview-item mb-2 overview-item--c3" *ngIf="data['counts']">
                                <div class="overview__inner pb-4">
                                    <div class="overview-box clearfix">
                                        <div class="icon">
                                            <i class="zmdi zmdi-gps-off"></i>
                                        </div>
                                        <div class="text">
                                            <h2>{{ data['counts']['rejected'] }}</h2>
                                            <span>Rejected</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="overview-item mb-2 overview-item--c4" *ngIf="data['counts']">
                                <div class="overview__inner pb-4">
                                    <div class="overview-box clearfix horizontal-flex">
                                        <div class="icon">
                                            <i class="zmdi zmdi-money-off"></i>
                                        </div>
                                        <div class="text flex-fill">
                                            <h2>{{ data['counts']['payment_hold'] }}</h2>
                                            <span>Payments Due</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="overview-item mb-2 overview-item--c4" *ngIf="data['counts']">
                                <div class="overview__inner pb-4">
                                    <div class="overview-box clearfix horizontal-flex">
                                        <div class="icon">
                                            <i class="zmdi zmdi-check"></i>
                                        </div>
                                        <div class="text flex-fill">
                                            <h2>{{ data['counts']['reported'] }}</h2>
                                            <span>Reported</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item"
                     *ngFor="let parentIndex of _util.createRange(_util.ceil((data['labs'] || []).length / 4))">
                    <div class="row">
                        <div class="col-md-6"
                             *ngFor="let lab of (carouselLabs || []).slice( parentIndex * 4, (parentIndex * 4) + 4)">
                            <div class="chart-percent-card mb-2 p-0">
                                <div
                                    class="percent-chart floating-center-container pt-0 pb-0 horizontal-flex flex-centered pr-0 fill-width">
                                    <canvas [id]="lab.htmlId"
                                            (click)="handleCircularGraphClickEvent($event, lab.htmlId)"></canvas>
                                    <div class="floating-center vertical-flex flex-centered width-70 height-20">
                                        <h1 class="thin-text mt-5">{{ lab.all }}</h1>

                                        <h4 class="thin-text">
                                            <span>{{ lab.name }}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="carousel-item">
                    <div class="row p-4">
                        <div class="col-md-6">
                            <div class="au-card recent-report pb-2">
                                <div class="au-card-inner">
                                    <h3 class="title-2 mb-4">Rejected / Refused </h3>
                                    <div class="recent-report__chart">
                                        <canvas id="recent-reject-chart-carousel"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="au-card recent-report pb-2">
                                <div class="au-card-inner">
                                    <h3 class="title-2 mb-4">Reported </h3>
                                    <div class="recent-report__chart">
                                        <canvas id="recent-reported-chart-carousel"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="floating-close-button">
                <button type="button" class="btn btn-outline-danger" (click)="endShowingCarousel()">
                    <i class="zmdi zmdi-close"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <h2 class="title-2 mb-1">{{ headerLineOverride || "Hi, " + userName }}</h2>

        </div>

        <div class="col-md-9">
            <div class="row">
                <div class="col-md-8 horizontal-flex space-between-center">
                    <div class="flex-fill"></div>

                    <div class="alert alert-info mb-0 p-1 flex-fill" role="alert"
                         *ngIf="lightAlertString && lightAlertString.length">
                        {{ lightAlertString }}
                    </div>

                    <button type="button" [hidden]="showingSamplesGrid" class="btn btn-outline-secondary ml-2"
                            (click)="showGridData('', $event, 'All samples')">
                        <i class="zmdi zmdi-grid"></i>
                    </button>

                    <button type="button" class="btn btn-outline-warning ml-2" *ngIf="! showingSamplesGrid"
                            (click)="beginGlobalSearch()">
                        <i class="zmdi zmdi-search"></i>
                    </button>

                    <button type="button" class="btn btn-outline-primary ml-2" *ngIf="! showingSamplesGrid"
                            (click)="startShowingCarousel()">
                        <i class="zmdi zmdi-view-carousel"></i>
                    </button>

                    <button type="button" class="btn btn-outline-primary ml-2" *ngIf="! showingSamplesGrid"
                            (click)="redirectToDropbox()">
                        <i class="zmdi zmdi-dropbox"></i>
                    </button>

                    <button type="button" class="btn btn-outline-primary ml-2" *ngIf="! showingSamplesGrid"
                            (click)="redirectToDownloadSystemData()">
                        <i class="zmdi zmdi-grid"></i>
                    </button>

                    <button class="btn btn-outline-primary ml-2"
                            *ngIf="allowedGeneratingTsv && tsvSelectedRecords.length"
                            type="button" (click)="generateBulkTsv()"
                    >
                        <i class="zmdi zmdi-long-arrow-down"></i>
                        {{ generatingTsvStatus || "Bulk TSV" }}
                    </button>

                    <button class="btn btn-outline-primary ml-2" [hidden]="!showingSamplesGrid" type="button"
                            (click)="requestToDownloadCsv(this.samplesQuery)">
                        <i class="zmdi zmdi-long-arrow-down"></i>
                        CSV
                    </button>

                    <button type="button" class="btn btn-outline-danger ml-2" [hidden]="!showingSamplesGrid"
                            (click)="showingSamplesGrid = false; headerLineOverride = ''">
                        <i class="zmdi zmdi-close"></i>
                    </button>

                </div>

                <div class="col-md-4 horizontal-flex start-center gap-10">
                    <select name="select-lab-location" id="select-lab-location" class="form-control "
                            *ngIf="this.data['labLocations']" [(ngModel)]="labLocation" (change)="applyFilterToChartAndListing()"
                            title="filter following based on lab locations">
                        <option value="">Lab</option>
                        <option *ngFor="let labLocation of this.data['labLocations']" [value]="labLocation['id']">
                            {{ labLocation["name"]}}</option>
                    </select>

                    <select name="select-data-range" id="select-data-range" class="form-control "
                            [(ngModel)]="dateRange" (change)="applyFilterToChartAndListing()"
                            title="filter dashboard counts for Selected date range">
                        <option value="">Select date range</option>
                        <option *ngFor="let row of this.dateRanges" [value]="row.key">
                            {{ row.value }}</option>
                    </select>

                    <button type="btn" class="btn btn-outline-secondary" *ngIf="labLocation.length || dateRange.length" (click)="resetChartAndListingFilters()">Reset</button>
                </div>

            </div>
        </div>

        <div class="col-md-12 horizontal-flex space-between-center mt-2 mb-2">
            <div class="input-group mr-2" *ngIf="showingSamplesGrid || showingSearchInput">
                <input type="search" class="form-control" (input)="searchStringChange()" name="samplesSearchString"
                       id="search-input"
                       [(ngModel)]="samplesSearchString" placeholder="Search ..."
                       aria-label="by Patient ID, Barcode or Patient name">
                <div class="input-group-append" [hidden]="! samplesSearchString.length">
                    <button type="button" class="btn btn-danger" (click)="resetSearchString()">
                        <i class="zmdi zmdi-close red"></i>
                    </button>
                </div>
            </div>

            <select name="select" id="select-test-groups" class="form-control mr-2"
                    *ngIf="showingSamplesGrid && testGroups.length" [(ngModel)]="selectedTestGroup"
                    (change)="testGroupChanged()" title="filter following based on Test Grouping">
                <option value="">Select Test Grouping</option>
                <option *ngFor="let testGroup of testGroups" [value]="testGroup.Gcode + testGroup.Scode">
                    {{ testGroup.Gcode + testGroup.Scode }}: {{ testGroup["Description"]}}</option>
            </select>

            <nav aria-label="Page navigation example" *ngIf="showingSamplesGrid">
                <ul class="pagination">
                    <li class="page-item " [ngClass]="{'disabled': samplesPageNo === 1}">
                        <a class="page-link" aria-label="Previous" (click)="loadPageWithPrevQuery(samplesPageNo - 1)">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>

                    <li class="page-item" [ngClass]="{'active': samplesPageNo === i}"
                        *ngFor="let i of _util.getPagesRange(samplesPageNo, samplesLastPage)">
                        <a class="page-link" (click)="loadPageWithPrevQuery(i)">{{ i }}</a>
                    </li>

                    <li class="page-item" [ngClass]="{'disabled': samplesPageNo === samplesLastPage}">
                        <a class="page-link" aria-label="Next" (click)="loadPageWithPrevQuery(samplesPageNo + 1)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <div class="row" [hidden]="! showingSamplesGrid">
        <div class="sufee-alert alert with-close alert-warning alert-dismissible fade show" role="alert"
             [hidden]="samples.length" style="width: 100%;">
            No records found !!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                    (click)="showingSamplesGrid = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="col-md-12 table-responsive full-screen-table" id="samples-grid"
             #samplesGrid>
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" style="min-width: 102px;"># Barcode</th>
                    <th scope="col" style="min-width: 102px;" *ngIf="! isAccessionRecord"># Patient ID</th>
                    <th scope="col" style="min-width: 270px;">Patient</th>
                    <th scope="col" *ngIf="samplesQuery.indexOf('invalid=1') > -1">Remarks</th>
                    <th scope="col"
                        style="min-width: 148px;">{{ isAccessionRecord ? "Sample type" : "State / Status" }}</th>
                    <th scope="col" *ngIf="isAccessionRecord">Courier Partner</th>
                    <th scope="col" *ngIf="! isAccessionRecord" style="min-width: 200px;">Tests</th>
                    <th scope="col" style="min-width: 324px;" *ngIf="! isAccessionRecord">
                        Actions

                        <select name="select-sorting" *ngIf="shouldActionFilterBeVisible()" id="sort-action-select"
                                class="form-control ml-2 mr-2" [(ngModel)]="sortingAction"
                                (change)="sortingActionChanged()"
                                title="Change sorting of list based on the actions you can perform">
                            <option value="">All</option>
                            <option value="authenticated">Authenticated</option>
                            <option value="non-authenticated">Verified</option>
                        </select>
                    </th>
                    <th scope="col" style="min-width: 178px;">
                        Trf
                    </th>
                    <th scope="col" *ngIf="! isAccessionRecord" style="min-width: 200px;">General TAT</th>
                    <th scope="col" *ngIf="! isAccessionRecord" style="min-width: 200px;">TAT Status</th>
                    <th scope="col" style="min-width: 320px;">
                        Customer
                        <select name="customer-party-type" id="customer-party-type"
                                class="form-control ml-2 mr-2"
                                title="Select if you want to see only credit customers / cash customers"
                                *ngIf="headerLineOverride === 'Payment holded'"
                                (change)="updatedPartyType()" [(ngModel)]="customerPartyTypeFilter">
                            <option value="">Credit / Cash</option>
                            <option value="credit">credit</option>
                            <option value="cash">cash</option>
                        </select>
                    </th>
                    <th scope="col" style="min-width: 200px;">{{ isAccessionRecord ? "Received by" : "Executive" }}</th>
                    <th scope="col" style="min-width: 148px;">Lab location</th>
                    <th scope="col" style="min-width: 180px;" (click)="changeOrderAndRefresh('samples.created_at')">
                        Created at / collected at

                        <i *ngIf="listSortOrderColumns.indexOf('samples.created_at') > -1 && listSortOrderReverse"
                           class="ml-2 zmdi zmdi-chevron-down"></i>
                        <i *ngIf="listSortOrderColumns.indexOf('samples.created_at') > -1 && !listSortOrderReverse"
                           class="ml-2 zmdi zmdi-chevron-up"></i>
                    </th>
                    <th scope="col" style="min-width: 180px;" (click)="changeOrderAndRefresh('samples.recieved_at')">
                        Received at

                        <i *ngIf="listSortOrderColumns.indexOf('samples.s_recieved_at') > -1 && listSortOrderReverse"
                           class="ml-2 zmdi zmdi-chevron-down"></i>
                        <i *ngIf="listSortOrderColumns.indexOf('samples.s_recieved_at') > -1 && !listSortOrderReverse"
                           class="ml-2 zmdi zmdi-chevron-up"></i>
                    </th>
                    <th scope="col" style="min-width: 112px;" (click)="changeOrderAndRefresh('samples.updated_at')">
                        Updated at

                        <i *ngIf="listSortOrderColumns.indexOf('samples.updated_at') > -1 && listSortOrderReverse"
                           class="ml-2 zmdi zmdi-chevron-down"></i>
                        <i *ngIf="listSortOrderColumns.indexOf('samples.updated_at') > -1 && !listSortOrderReverse"
                           class="ml-2 zmdi zmdi-chevron-up"></i>
                    </th>
                    <th th scope="col" style="min-width: 280px;">Printed By</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let sample of samples">
                    <th scope="row">{{ sample.barcode }}</th>
                    <th *ngIf="! isAccessionRecord">{{ sample.patient.limp_patient_id }}</th>
                    <td *ngIf="! isAccessionRecord">
                        <div class="horizontal-flex" style="">
                            <button type="button" class="btn btn-link btn-xs mr-0 ml-0 pl-0 pr-0"
                                    title="Resync patient details from Labmate"
                                    (click)="syncLabMatePatientInfo(sample)"
                                    style="max-height: 26px; line-height: 12px">
                                <i class="zmdi zmdi-refresh-alt" [hidden]="resyncingPatientInfo === sample.id"></i>
                                <i class="zmdi zmdi-refresh-sync" *ngIf="resyncingPatientInfo === sample.id"></i>
                            </button>
                            {{ sample.patient.name }}
                            ( {{ sample.patient.ageY }} {{ (sample.patient.ageY && sample.patient.genderChar) ? "/" : ""
                            }} {{ sample.patient.genderChar}} )
                        </div>
                    </td>
                    <td *ngIf="isAccessionRecord">{{ sample.accession.patient_name }}</td>
                    <td><span *ngIf="sample.invalid" class="bold">{{ sample.remarks }} <br></span>
                        {{ isAccessionRecord ? sample.accession.sample_type : (sample.status || sample.state) }}
                        <span *ngIf="sample.authenticatedBy && sample.authenticatedBy.id">
                                <br>
                            {{ "By " + _util.getUserFullname(sample.authenticatedBy) }}</span>
                    </td>
                    <td *ngIf="isAccessionRecord">{{ ((sample.accession || {}).meta || {}).Preq_Allot_to }}</td>
                    <td *ngIf="! isAccessionRecord">
                        {{ this.testDescription(sample.tests, sample.test) }}
                    </td>
                    <td *ngIf="! isAccessionRecord">

                        <label class="btn btn btn-secondary btn-xs mr-1"
                               *ngIf="sample.allowGeneratingTsv && sample.patient && sample.patient.limp_patient_id"
                        >
                            <input class="form-check-input" type="checkbox"
                                   [checked]="isTsvChecked(sample['patient']['limp_patient_id'])"
                                   (change)="toggleTSVChecked($event, sample['patient']['limp_patient_id'])"
                                   [value]="sample['patient']['limp_patient_id']"
                                   [id]="sample.barcode + '-tsv-checkbox'" [name]="sample.barcode + '-tsv-checkbox'">
                            TSV
                        </label>

                        <button type="button" *ngIf="sample.allowGeneratingTsv"
                                class="btn btn-xs mr-1"
                                [class.btn-primary]="sample && sample.meta && sample.meta['Barcode']"
                                [class.btn-outline-primary]="sample && sample.meta && !sample.meta['Barcode']"
                                (click)="previewNIPTReport(sample)"
                        >
                            {{ "Preview NIPT" }}
                        </button>

                        <button type="button" [disabled]="sample.customerAddress.attachedReport"
                                *ngIf="! sample.allowPrinting"
                                class="btn btn-primary btn-xs mr-1" (click)="addReportWizard(sample)">
                            {{ sample.customerAddress.loadingAttachedReport || "Add report" }}
                        </button>

                        <button type="button" [disabled]="sample.customerAddress.attachedReport"
                                *ngIf="! sample.allowPrinting && sample.payment_hold && _util.canUser(_util.Constants.Permissions.ReleasePendingPayments)"
                                class="btn btn-primary btn-xs mr-1" (click)="markPaymentDone(sample)">
                            {{ sample.customerAddress.loadingReleaseReport || "Release report" }}
                        </button>

                        <button type="button" [disabled]="sample.customerAddress.attachedReport"
                                *ngIf="! sample.allowPrinting && !sample.customerAddress.loadingAttachedReport && sample.previousAttachedReport && ! sample.allowGeneratingTsv"
                                class="btn btn-secondary btn-xs mr-1" (click)="showReportWizard(sample)">
                            {{ "Resend" }}
                        </button>

                        <button type="button" [disabled]="sample.customerAddress.printed"
                                *ngIf="sample.authenticated && sample.patCytoAuthenticated && sample.allowPrinting"
                                class="btn btn-primary btn-xs mr-1" (click)="printReport(sample)">
                            {{ sample.customerAddress.printed ? "Printed" : "Print" }}
                        </button>
                        <button type="button"
                                *ngIf="sample.authenticated && sample.customerAddress.email && sample.allowPrinting && sample.patCytoAuthenticated"
                                class="btn btn-secondary btn-xs mr-1" (click)="previewReportWithHeader(sample)">
                            {{ "Preview" }}
                        </button>

                        <button type="button"
                                *ngIf="!sample.patCytoAuthenticated && sample.allowPrinting"
                                [hidden]="(isNIPDTestcode(sample) && !sample.allowGeneratingTsv)"
                                class="btn btn-outline-primary btn-xs mr-1" (click)="previewReport(sample)">
                            {{ "Preview" }}
                            <span class="badge badge-success badge-pill small-badge" *ngIf="sample.testcode.indexOf('G03S07') > -1 && sample.authenticated">✔</span>
                        </button>

                        <button type="button"
                                *ngIf="_util.canUser(_util.Constants.Permissions.ReleasePendingPayments) && sample.payment_hold && sample.haveReceipt"
                                class="btn btn-secondary btn-xs mr-1"
                                (click)="redirectToPaymentReceiptDashboard(sample)">
                            {{ "Receipts" }}
                        </button>

                        <button type="button"
                                *ngIf="sample.customerAddress.email && sample.allowPrinting && !sample.patCytoAuthenticated"
                                class="btn btn-outline-secondary btn-xs mr-1"
                                (click)="refreshThisReport(sample, $parentIndex)">
                            {{ sample.refreshButtonText || "Refresh" }}
                        </button>

                        <button type="button"
                                *ngIf="sample.editableReportScenario && sample.editableReportScenario === 'Unipath-Onco'"
                                class="btn btn-outline-secondary btn-xs mr-1"
                                (click)="showUnipathReportModal(sample)">
                            {{"Upload Report"}}
                        </button>

                        <button type="button"
                                *ngIf="sample.editableReportScenario && sample.editableReportScenario === 'Altum'"
                                class="btn btn-info btn-xs mr-1" (click)="showAltumModal(sample)">
                            {{ "Prepare" }}
                        </button>

                        <button type="button"
                                *ngIf="sample.editableReportScenario && (sample.editableReportScenario === 'SureT' || sample.editableReportScenario === 'FlowCytometryEditable'|| sample.editableReportScenario === 'pCREditable')"
                                class="btn btn-info btn-xs mr-1"
                                (click)="redirectToEditReport(sample.barcode, sample.testcode)"
                        >
                            {{ "Prepare" }}
                        </button>
                    </td>
                    <td>
                        <a type="button"
                           target="_blank"
                           *ngIf="sample.trfDocument"
                           class="mr-1"
                           href={{sample.trfDocument}}>
                            {{ "View" }}
                        </a>
                    </td>
                    <td *ngIf="! isAccessionRecord">
                        {{sample.tatInformation['tat']}}
                    </td>
                    <td *ngIf="!isAccessionRecord">
                        {{sample.tatInformation['tatDifference']}}
                    </td>
                    <td *ngIf="! isAccessionRecord">{{ (sample.customer || {}).name }} <br>
                        <small>({{(sample.customer || {}).referred_by}})</small> &nbsp;&nbsp;
                        <strong>({{ (sample.creditCustomer ? 'Credit' : 'Cash') }})</strong>
                    </td>
                    <td *ngIf="isAccessionRecord">{{ sample.accession.customer_name }}
                        <small>{{ sample.accession.customer_reg_no ? '( ' + sample.accession.customer_reg_no + ' )'
                            : '' }}</small>
                    </td>
                    <td>{{ isAccessionRecord ? sample.accession.received_by : (sample.executive || {}).name }}</td>
                    <td>{{ (sample.labLocation || {}).name }}</td>
                    <td>{{ sample.created }}</td>
                    <td>{{ sample.recieved }}</td>
                    <td>{{ sample.updated }}</td>
                    <td style="white-space: pre-line;">
                        <a title="{{(printStatus(sample).fullTime.length) ? printStatus(sample).fullTime : 'not printed yet'}}">
                            {{ printStatus(sample).statement }}
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">

            <div class="overview-item mb-2 overview-item--c1" *ngIf="data['counts'] && data['counts']['accession']"
                 (click)="showGridData('state=accession', $event, 'Accession samples')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="icon">
                            <i class="zmdi zmdi-account-o"></i>
                        </div>
                        <div class="text flex-fill">
                            <h2>{{ data['counts']['accession']['all'] }}</h2>
                            <span>In accession</span>
                        </div>

                        <a href="/accession-form" class="clickable"
                           *ngIf="_util.canUser(_util.Constants.Permissions.AddAccession)"><i
                            class="zmdi zmdi-plus small-white-icon"></i></a>
                    </div>

                    <div class="progress mt-2 mb-1">
                        <div class="progress-bar bg-success"
                             (click)="showGridData('state=accession&label=green', $event, 'Accession (green)')"
                             role="progressbar" [ngStyle]="{width: getAccessionGreenWidth(), position: 'relative' }">
                        </div>
                        <div class="progress-bar bg-danger"
                             (click)="showGridData('state=accession&label=red', $event, 'Accession (red)')"
                             role="progressbar" [ngStyle]="{width: getAccessionDangerWidth(), position: 'relative' }">
                        </div>
                    </div>

                    <p class="text-white">{{ data['counts']['accession']['red'] }} waiting for 2+ hours</p>
                </div>
            </div>
            <div class="overview-item mb-2 overview-item--c4" *ngIf="data['counts'] && data['counts']['invalid'].length"
                 (click)="showGridData('invalid=1', $event, 'Not to be processed')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="icon">
                            <i class="zmdi zmdi-close"></i>
                        </div>
                        <div class="text">
                            <h2>{{ data['counts']['invalid'] }}</h2>
                            <span>Not to be processed</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c2" *ngIf="data['counts'] && data['counts']['repeat'].length"
                 (click)="showGridData('state=repeat', $event, 'Repeat samples')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="icon">
                            <i class="zmdi zmdi-repeat"></i>
                        </div>
                        <div class="text">
                            <h2>{{ data['counts']['repeat'] }}</h2>
                            <span>Repeat</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c3" *ngIf="data['counts']"
                 (click)="showGridData('status=rejected&invalid=0', $event, 'Rejected samples')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="icon">
                            <i class="zmdi zmdi-gps-off"></i>
                        </div>
                        <div class="text">
                            <h2>{{ data['counts']['rejected'] }}</h2>
                            <span>Rejected</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c3" *ngIf="data['counts']"
                 (click)="showGridData('state=hold', $event, 'Hold/GC samples')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="icon">
                            <i class="zmdi zmdi-refresh-sync-alert"></i>
                        </div>
                        <div class="text">
                            <h2>{{ data['counts']['hold'] }}</h2>
                            <span>Hold</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c2"
                 *ngIf="data['counts'] && data['counts'] && data['counts']['receipts'] && _util.canUser(_util.Constants.Permissions.ReleasePendingPayments)"
                 (click)="_util.redirectTo('payment-receipts')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="icon">
                            <i class="zmdi zmdi-receipt"></i>
                        </div>
                        <div class="text">
                            <h2>{{ data['counts']['receipts'] }}</h2>
                            <span>Payment receipts</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="overview-item mb-2 overview-item--c4" *ngIf="data['counts']"
                 (click)="showGridData('payment_hold=1&partyType=credit', $event, 'Credit holded')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="icon">
                            <i class="zmdi zmdi-money-off"></i>
                        </div>
                        <div class="text flex-fill">
                            <h2>{{data['counts']['payment_hold_credit']}}</h2>
                            <span>Payments Due (Credit)</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="overview-item mb-2 overview-item--c4" *ngIf="data['counts']"
                 (click)="showGridData('payment_hold=1&partyType=cash', $event, 'Cash holded')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="icon">
                            <i class="zmdi zmdi-money-off"></i>
                        </div>
                        <div class="text flex-fill">
                            <h2>{{data['counts']['payment_hold_cash']}}</h2>
                            <span>Payments Due (Cash)</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c4"
                 *ngIf="data['counts'] && _util.canUser(_util.Constants.Permissions.SeePrintDueReports)"
                 (click)="redirectToPrintReports()">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="icon">
                            <i class="zmdi zmdi-print"></i>
                        </div>
                        <div class="text flex-fill">
                            <h2>{{ data['counts']['print_due'] }}</h2>
                            <span>Pending prints</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c4"
                 *ngIf="data['counts'] && _util.canUser(_util.Constants.Permissions.SeePrintDueReports)"
                 (click)="redirectToPrintConfirmingReportDashboard()">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="icon">
                            <i class="zmdi zmdi-confirmation-number"></i>
                        </div>
                        <div class="text flex-fill">
                            <h2>{{ data['counts']['print_confirm_due'] }}</h2>
                            <span>Pending print confirm</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c4" *ngIf="data['counts']"
                 (click)="showGridData('status=reported', $event, 'Reported samples')">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="icon">
                            <i class="zmdi zmdi-check"></i>
                        </div>
                        <div class="text flex-fill">
                            <h2>{{ data['counts']['reported'] }}</h2>
                            <span>Reported</span>
                        </div>

                        <a href="/print-report" class="clickable"
                           *ngIf="_util.canUser(_util.Constants.Permissions.PreviewReport)"><i
                            class="zmdi zmdi-print small-white-icon"></i></a>
                    </div>
                </div>
            </div>

            <button type="button" *ngIf="_util.canUser(_util.Constants.Permissions.ManageUsers)"
                    class="btn btn-secondary btn-lg btn-block mb-1" (click)="redirectToAdmin()">Manage users
            </button>
            <button type="button"
                    *ngIf="_util.canUser(_util.Constants.Permissions.AllowPatchRequest) || _util.canUser(_util.Constants.Permissions.ApproveStage2)"
                    class="btn btn-secondary btn-lg btn-block mb-1" (click)="redirectToPatchRequestsManager()">
                BDM Address Requests
            </button>
            <button type="button" *ngIf="_util.canUser(_util.Constants.Permissions.ApproveNewCustomer)"
                    class="btn btn-secondary btn-lg btn-block mb-1" (click)="redirectToCustomerApprovalDashboard()">
                New BDM Customers
            </button>
            <button type="button" *ngIf="_util.canUser(_util.Constants.Permissions.ManageUsers)"
                    class="btn btn-secondary btn-lg btn-block mb-1" (click)="redirectToTatManagement()">Tests & TAT
            </button>
            <button type="button" class="btn btn-outline-danger btn-lg btn-block mb-4" (click)="_util.logout()">{{
                _util.logoutButtonText || "Logout" }}
            </button>
        </div>

        <div class="col-md-9">


            <div class="row">
                <div class="col-md-4" *ngFor="let lab of data['labs']">
                    <div class="chart-percent-card mb-2 p-0">
                        <div
                            class="percent-chart floating-center-container horizontal-flex flex-centered pt-0 pb-0 pr-0 fill-width ">
                            <canvas [id]="lab.htmlId"
                                    (click)="handleCircularGraphClickEvent($event, lab.htmlId)"></canvas>
                            <div class="floating-center vertical-flex flex-centered width-70 height-20">
                                <h1 class="thin-text mt-5">{{ lab.all }}</h1>

                                <h4 class="thin-text">
                                    <span
                                        (click)="showGridData('state=processing&labType=' + lab.labTypeId, $event, lab.name + ' samples')">
                                        {{ lab.name }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="au-card recent-report pb-2">
                        <div class="au-card-inner">
                            <h3 class="title-2 mb-4">Rejected / Refused </h3>
                            <div class="recent-report__chart">
                                <canvas id="recent-reject-chart"></canvas>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="au-card recent-report pb-2">
                        <div class="au-card-inner">
                            <h3 class="title-2 mb-4">Reported </h3>
                            <div class="recent-report__chart">
                                <canvas id="recent-reported-chart"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row hidden">
        <input type="file" id="hidden-attachment-input" (change)="hiddenAttachmentInputChanged($event)">
        <input type="file" multiple="yes" id="hidden-xml-attachments-input"
               (change)="hiddenAttachmentInputChanged($event)">
    </div>
</div>

<div class="modal" tabindex="-1" id="nipt-reports-modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="alert alert-info" role="alert" [hidden]="sample && sample.meta && sample.meta['Barcode']">
                    <h4 class="alert-heading">TSV / XML Required!</h4>
                    <p>Result TSV / XML not found for this sample, upload from below</p>
                    <hr>

                    <button type="button" class="btn btn-primary mr-2" (click)="beginUploadNIPTResultTSV()">TSV</button>
                    <button type="button" class="btn btn-info" (click)="beginUploadNIPTResultXML()">XML</button>
                </div>

                <div class="alert alert-warning" role="alert" *ngIf="sample && sample.meta && sample.meta['isRedraw']">
                    <h4 class="alert-heading">Fetal fraction below {{ niptRedrawPercentageLimit || "3.0" }}%</h4>
                    <p>Please choose from below options to view correct report format</p>
                    <hr>

                    <select class="form-control mb-1" name="niptRedrawReportType" [(ngModel)]="niptRedrawReportType">
                        <option selected value="">Redraw / Test Failure ?</option>
                        <option value="redraw">Redraw</option>
                        <option value="test-failure">Test Failure</option>
                    </select>

                    <select class="form-control mb-1" name="niptRedrawReportResampleReason"
                            *ngIf="niptRedrawReportType === 'redraw'"
                            [(ngModel)]="niptRedrawReportResampleReason">
                        <option selected value="">Reason to re-sample ?</option>
                        <option value="low-fetal-fraction">Low Fetal Fraction</option>
                        <option value="post-sequencing-qc-failure">Post Sequencing QC Failure</option>
                        <option value="pre-analytical-qc-failure">Pre Analytical QC Failure</option>
                    </select>

                    <select class="form-control mb-1" name="niptRedrawReportResampleWeeksTime"
                            *ngIf="niptRedrawReportType === 'redraw'"
                            [(ngModel)]="niptRedrawReportResampleWeeksTime">
                        <option selected="true" disabled="disabled" value="">Weeks?</option>
                        <option value="immediately"
                                *ngIf="niptRedrawReportResampleReason === 'post-sequencing-qc-failure' || niptRedrawReportResampleReason === 'pre-analytical-qc-failure'">
                            0 Week/ Immediate
                        </option>
                        <option
                            *ngFor="let key of (niptRedrawReportResampleReason === 'low-fetal-fraction')?[1, 2, 3, 4, 5]:[]"
                            [value]="key">{{ key }} {{(key > 1) ? 'Weeks' : 'Week'}}</option>
                    </select>

                    <select class="form-control mb-1" name="niptRedrawReportFailureTestCount"
                            id="nipt-redraw-report-failure-test-count"
                            *ngIf="niptRedrawReportType === 'test-failure'"
                            [(ngModel)]="niptRedrawReportFailureTestCount">
                        <option selected="true" value="">Failure After 2 Tests</option>
                        <option value="1">Failure After 1 Test</option>
                    </select>

                    <button type="button" class="btn btn-primary" (click)="previewNIPTRedrawReport()"
                            [disabled]="!niptRedrawReportType || ((niptRedrawReportType === 'redraw') && ! niptRedrawReportResampleReason)"
                    >
                        Preview report
                    </button>
                </div>

                <div class="alert alert-warning" role="alert" *ngIf="niptInconclusiveValues.length">
                    <h4 class="alert-heading">Inconclusive values found</h4>
                    <p><strong>{{ niptInconclusiveValues.join(', ')}} </strong> are set to
                        <strong>*Inconclusive</strong> value, you can select from dropdown and click "Change to 'Low
                        Risk'" to move them to 'Low Risk'.</p>
                    <p>"Ctrl + Select" to select multiple values</p>
                    <hr>

                    <select class="form-control" multiple name="niptInconclusiveValues"
                            [(ngModel)]="niptInconclusiveValuesInput">
                        <option *ngFor="let option of niptInconclusiveValues" [value]="option">{{ option }}</option>
                    </select>

                    <button type="button" class="btn btn-primary mt-1" (click)="applyNIPTInconclusiveToLowRiskValues()"
                            *ngIf="niptInconclusiveValuesInput"
                    >
                        Change to 'Low Risk'
                    </button>
                </div>

                <div class="alert alert-light" role="alert" *ngIf="sample && sample.meta && sample.meta['Barcode']">
                    <h4 class="alert-heading">TSV Found</h4>
                    <p>Override Uploaded TSV with new one?</p>
                    <hr>

                    <button type="button" class="btn btn-secondary mr-2" (click)="beginUploadNIPTResultTSV()">TSV
                    </button>
                    <button type="button" class="btn btn-info" (click)="beginUploadNIPTResultXML()">XML</button>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary"
                        *ngIf="! (sample && sample.meta && sample.meta['isRedraw'])"
                        [disabled]="! (sample && sample.meta && sample.meta['Barcode'])"
                        (click)="previewReport(sample)"
                >
                    Proceed to preview
                </button>
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="hideNIPTReportModal()">
                    Close
                </button>
            </div>
        </div>


    </div>
</div>

<div class="modal" tabindex="-1" id="notification-modal" data-keyboard="false" data-backdrop="static"
     *ngIf="_util.canUser(_util.Constants.Permissions.ReleasePendingPayments) && !_util.canUser(_util.Constants.Permissions.ManageUsers) && financePaymentHoldNotification.pending">
    <div class="modal-dialog modal modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="alert alert-info" role="alert" [hidden]="sample && sample.meta && sample.meta['Barcode']">
                    <h4 class="alert-heading"></h4>
                    <p>{{financePaymentHoldNotification.notification.title || ''}}</p>
                    <hr>
                    <p>{{financePaymentHoldNotification.notification.content || ''}}</p>
                    <br><br>
                    <p *ngIf='financePaymentHoldNotification.pendingCount'>{{financePaymentHoldNotification.pendingStatement}}</p>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="goToPreview(financePaymentHoldNotification)">
                    Preview
                </button>
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="dismissFinancePaymentHoldNotification()">
                    Dismiss
                </button>
            </div>
        </div>


    </div>
</div>

<div class="modal" tabindex="-1" id="nipt-insighT-TSV-save-modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-dialog-centered" style="max-width: 600px;">
        <div class="modal-content">
            <div class="modal-body" style="height: 50vh; overflow-y: auto; max-width: 600px;">
                <div class="alert alert-light" role="alert"
                     *ngIf="insighTSamples.existingInsighTSamples.length !== 0 || insighTSamples.newInsighTSamples.length !== 0 || insighTSamples.unmatchedInsighTSamples.length !== 0">
                    <h4 class="alert-heading">Following InsighT Records will be updated</h4>
                    <hr>
                    <div *ngIf="insighTSamples.newInsighTSamples.length !== 0"
                         style="max-width: 500px; margin-bottom: 30px;"><span style="font-weight: bold;">New InsighT Samples (will be added):</span>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col" style="min-width: 50px;">S.No.</th>
                                <th scope="col" style="min-width: 150px;">Barcode</th>
                                <th scope="col" style="min-width: 150px;">Fetal Fraction</th>
                                <th scope="col" style="min-width: 150px;">Checked</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of insighTSamples.newInsighTSamples">
                                <td>{{insighTSamples.newInsighTSamples.indexOf(item) + 1}}</td>
                                <td>{{item['Barcode']}}</td>
                                <td>{{item['Fetal Fraction']}}</td>
                                <td>
                                    <div class="ui checkbox"><input type="checkbox" disabled="disabled"
                                                                    checked="checked"/></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="insighTSamples.existingInsighTSamples.length !== 0"
                         style="max-width: 500px; margin-bottom: 30px;"><span style="font-weight: bold;">Existing InsighT Samples (will be overriden if selected):</span>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col" style="min-width: 50px;">S.No.</th>
                                <th scope="col" style="min-width: 150px;">Barcode</th>
                                <th scope="col" style="min-width: 150px;">Fetal Fraction</th>
                                <th scope="col" style="min-width: 150px;">Checked</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of insighTSamples.existingInsighTSamples">
                                <td>{{insighTSamples.existingInsighTSamples.indexOf(item) + 1}}</td>
                                <td>{{item['Barcode']}}</td>
                                <td>{{item['Fetal Fraction']}}</td>
                                <td>
                                    <div class="ui checkbox"><input type="checkbox"
                                                                    (change)="overrideExistingInsighTSample(item, $event)"/>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="insighTSamples.unmatchedInsighTSamples.length !== 0"
                         style="max-width: 500px; margin-bottom: 30px;"><span style="font-weight: bold;">Unmatched InsighT Samples (not found):</span>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col" style="min-width: 50px;">S.No.</th>
                                <th scope="col" style="min-width: 150px;">Barcode</th>
                                <th scope="col" style="min-width: 150px;">Fetal Fraction</th>
                                <th scope="col" style="min-width: 150px;">Checked</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of insighTSamples.unmatchedInsighTSamples">
                                <td>{{insighTSamples.unmatchedInsighTSamples.indexOf(item) + 1}}</td>
                                <td>{{item['Barcode']}}</td>
                                <td>{{item['Fetal Fraction']}}</td>
                                <td>
                                    <div class="ui checkbox"><input type="checkbox" disabled="disabled"/></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary"
                        (click)="beginUpdateNIPTResultTSV()"
                >
                    Proceed to update
                </button>
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="hideInsighTSampleOverrideModal()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="case-type-modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">

                <div class="mt-3 mb-3" *ngIf="matchedReportControl && matchedReportControl.patientCanBeFetus">
                    <div class="ui checkbox">
                        <label style="font-weight: bold;">
                            <input type="checkbox" id="patient-is-fetus"
                                   (change)="mailAttachmentFormData.patientIsFetus = ! mailAttachmentFormData.patientIsFetus"
                                   [checked]="mailAttachmentFormData.patientIsFetus"
                            />
                            &emsp;Patient is Registered as Fetus</label>
                    </div>
                </div>

                <div class="mt-3 mb-3" *ngIf="matchedReportControl && matchedReportControl.wizardOptions"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="caseType">
                        <option selected="true" disabled="disabled" value="">Case type?</option>
                        <option *ngFor="let option of matchedReportControl.wizardOptions"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.haveRejectionReasons && caseType === 'rejection'">
                    <select name="select" class="form-control" [(ngModel)]="rejectionReason">
                        <option selected="true" disabled="disabled" value="">Reason?</option>
                        <option *ngFor="let item of matchedReportControl.rejectionReasons | keyvalue"
                                [value]="item.value"
                        >{{ item.key }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.haveFailureReasons &&  caseType === 'failure'">
                    <select name="select" class="form-control" [(ngModel)]="rejectionReason">
                        <option selected="true" disabled="disabled" value="">Reason?</option>
                        <option *ngFor="let item of matchedReportControl.failureReasons | keyvalue"
                                [value]="item.value"
                        >{{ item.key }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3" *ngIf="matchedReportControl && matchedReportControl.haveRelatedReport">
                    <div class="ui checkbox" style="margin-top: 50px;">
                        <label style="font-weight: bold;"><input type="checkbox" id="have-more-related-report"
                                                                 [(ngModel)]="haveRelatedReport"/>&emsp;Related
                            Sample</label>
                    </div>
                </div>

                <div class="mt-3 mb-3" *ngIf="haveRelatedReport">
                    <div class="horizontal-flex" style="justify-content: space-between;">
                        <div class="form-group-mr-2" style="margin-bottom: 0">
                            <input type="text" class="form-control" id="related-patient-id"
                                   placeholder="Related patient id"
                                   (blur)="relatedPatientDataAltum(relatedPatientFormData.relatedPatientId)"
                                   [(ngModel)]="relatedPatientFormData.relatedPatientId">
                        </div>
                        <button type="button" class="btn btn-primary" name="fetch-related-patient-data"
                                (click)="relatedPatientDataAltum(relatedPatientFormData.relatedPatientId)">
                            Fetch
                        </button>
                    </div>
                </div>

                <div class="mt-3 mb-3" *ngIf="haveRelatedReport">
                    <div class="form-grooup-mr-2">
                        <input type="text" class="form-control" id="related-patient-name"
                               placeholder="Related patient name"
                               [(ngModel)]="relatedPatientFormData.relatedPatientName" readonly>
                    </div>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.wizardOptions && haveRelatedReport">
                    <select name="select" class="form-control"
                            [(ngModel)]="relatedPatientFormData.relatedPatientCaseType">
                        <option selected="true" disabled="disabled" value="">Case type (related sample)?</option>
                        <option *ngFor="let option of matchedReportControl.wizardOptions"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.haveRejectionReasons && relatedPatientFormData.relatedPatientCaseType === 'rejection' && haveRelatedReport">
                    <select name="select" class="form-control"
                            [(ngModel)]="relatedPatientFormData.relatedPatientRejectionReason">
                        <option selected="true" disabled="disabled" value="">Reason (related sample)?</option>
                        <option *ngFor="let item of matchedReportControl.rejectionReasons | keyvalue"
                                [value]="item.value"
                        >{{ item.key }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.haveFailureReasons && relatedPatientFormData.relatedPatientCaseType === 'failure' && haveRelatedReport">
                    <select name="select" class="form-control"
                            [(ngModel)]="relatedPatientFormData.relatedPatientRejectionReason">
                        <option selected="true" disabled="disabled" value="">Reason (related sample)?</option>
                        <option *ngFor="let item of matchedReportControl.failureReasons | keyvalue"
                                [value]="item.value"
                        >{{ item.key }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3" *ngIf="caseType === 'resample'">
                    <select name="select" class="form-control" [(ngModel)]="resampleTime">
                        <option selected="true" disabled="disabled" value="">Weeks?</option>
                        <option *ngFor="let key of [1, 2, 3, 4, 5]"
                                [value]="key"
                        >{{ key }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3" *ngIf="caseType === 'duo' || caseType === 'trio'">
                    <div class="horizontal-flex">
                        <div class="grouped feilds">
                            <div class="form-group mr-2" style="margin-bottom: 0">
                                <input type="email" class="form-control" id="patient-2-name"
                                       placeholder="Patient 2 name" [(ngModel)]="mailAttachmentFormData.patientName2">
                            </div>
                            <div *ngIf="!patientDetailsSubmitted && (mailAttachmentFormData.patientName2 =='')"
                                 style="color: red; font-size: 12px">
                                Patient 2 Name is Required
                            </div>
                        </div>
                        <div class="grouped feilds">
                            <div class="form-group" style="margin-bottom: 0">
                                <input type="email" class="form-control" id="patient-2-id" placeholder="Patient 2 id"
                                       [(ngModel)]="mailAttachmentFormData.patientId2">
                            </div>
                            <div *ngIf="!patientDetailsSubmitted  && (mailAttachmentFormData.patientId2 =='')"
                                 style="color: red; font-size: 12px">
                                Patient 2 Id is Required
                            </div>
                        </div>
                    </div>
                </div>


                <div class="mt-3 mb-3" *ngIf="caseType === 'trio'">
                    <div class="horizontal-flex">
                        <div class="grouped feilds">
                            <div class="form-group mr-2" style="margin-bottom: 0">
                                <input type="email" class="form-control" id="patient-3-name"
                                       placeholder="Patient 3 name" [(ngModel)]="mailAttachmentFormData.patientName3">
                            </div>
                            <div *ngIf="!patientDetailsSubmitted && (mailAttachmentFormData.patientName3 =='')"
                                 style="color: red; font-size: 12px">
                                Patient 3 Name is Required
                            </div>
                        </div>
                        <div class="grouped feilds">
                            <div class="form-group" style="margin-bottom: 0">
                                <input type="email" class="form-control" id="patient-3-id" placeholder="Patient 3 id"
                                       [(ngModel)]="mailAttachmentFormData.patientId3">
                            </div>
                            <div *ngIf="!patientDetailsSubmitted  && (mailAttachmentFormData.patientId3 =='')"
                                 style="color: red; font-size: 12px">
                                Patient 3 Id is Required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary"
                        [disabled]="!checkNextButtonDisability()"
                        (click)="checkSVAPatientDetailStatus(sample, caseType)">
                    Next
                </button>
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="hideCaseTypeModal()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="altum-modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">

                <div class="mt-3 mb-3" *ngIf="matchedReportControl && matchedReportControl.wizardOptions"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="altumReportDetails.caseType">
                        <option selected="true" disabled="disabled" value="">Case type?</option>
                        <option *ngFor="let option of matchedReportControl.wizardOptions"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.wizardLSCHOptions && altumReportDetails.caseType === 'lsch'"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="altumReportDetails.lschType">
                        <option selected="true" disabled="disabled" value="">LSCH type?</option>
                        <option *ngFor="let option of matchedReportControl.wizardLSCHOptions"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.wizardLOHOptions && altumReportDetails.caseType === 'loh'"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="altumReportDetails.lohType">
                        <option selected="true" disabled="disabled" value="">LOH type?</option>
                        <option *ngFor="let option of matchedReportControl.wizardLOHOptions"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.wizardAltumCategoryOptions && altumReportDetails.caseType.length"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="altumReportDetails.altumCategory">
                        <option selected="true" disabled="disabled" value="">Altum Category?</option>
                        <option *ngFor="let option of matchedReportControl.wizardAltumCategoryOptions"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.wizardAltumSubCategories && altumReportDetails.caseType.length && altumReportDetails.altumCategory.length && altumReportDetails.altumCategory !== 'loh'"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="altumReportDetails.altumSubCategory">
                        <option selected="true" disabled="disabled" value="">Altum Subcategory?</option>
                        <option
                            *ngFor="let option of matchedReportControl.wizardAltumSubCategories[altumReportDetails.caseType]"
                            [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3"
                     *ngIf="matchedReportControl && matchedReportControl.wizardAltumGenderOption && altumReportDetails.caseType.length && altumReportDetails.altumCategory.length && altumReportDetails.altumSubCategory === 'postnatal'"
                     style="margin-top: 10px;">
                    <select name="select" class="form-control" [(ngModel)]="altumReportDetails.gender">
                        <option selected="true" disabled="disabled" value="">Gender?</option>
                        <option *ngFor="let option of matchedReportControl.wizardAltumGenderOption"
                                [value]="_util.makeSlug(option)"
                        >{{ option }}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary"
                        [disabled]="!checkAltumNextButtonDisability()"
                        (click)="redirectToAltumEditReport(sample)">
                    Next
                </button>
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="hideAltumModal()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="unipath-report-modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h2>Outsourced Report Here</h2>
                <ngx-dropzone (change)="selectUnipathReportAttachement($event)" class="attachments flex-fill ml-2">
                    <ngx-dropzone-preview *ngFor="let f of supportFiles" [removable]="false">
                        <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                    </ngx-dropzone-preview>

                    <div class="attachment" *ngFor="let attachment of unipathReportData['report-attachement']"
                         (click)="_util.openAttachmentInNewTab($event, attachment)"
                    >
                        <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                        <div class="remove"
                             (click)="removeUnipathReportAttachement($event, attachment)"
                        >
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </div>
                    </div>
                </ngx-dropzone>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="previewUnipathReport()">
                    Preview
                </button>
                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close">
                    Dismiss
                </button>
            </div>
        </div>


    </div>
</div>


<div class="modal" tabindex="-1" id="mail-preview-modal" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body mail" *ngIf="mailingInfo && mailingInfo.from">
                <div class="mail-ids" *ngIf="_util.seperateEmails(mailingInfo.to).length"><strong>To: </strong>
                    <div class="mail-ids-container">
                        <span class="email-id" *ngFor="let email of _util.seperateEmails(mailingInfo.to)">
                            {{ email }}
                        </span>
                    </div>
                </div>
                <div class="mail-ids" *ngIf="_util.seperateEmails(mailingInfo.cc).length"><strong>CC: </strong>
                    <div class="mail-ids-container">
                        <span class="email-id" *ngFor="let email of _util.seperateEmails(mailingInfo.cc)">
                            {{ email }}
                        </span>
                    </div>
                </div>
                <div class="mail-ids" *ngIf="_util.seperateEmails(mailingInfo.bcc).length"><strong>BCC: </strong>
                    <div class="mail-ids-container">
                        <span class="email-id" *ngFor="let email of _util.seperateEmails(mailingInfo.bcc)">
                            {{ email }}
                        </span>
                    </div>
                </div>
                <div class="mail-ids" *ngIf="_util.seperateEmails(mailingInfo.mobileNumbers).length"><strong>SMS
                    to: </strong>
                    <div class="mail-ids-container">
                        <span class="email-id" *ngFor="let email of _util.seperateEmails(mailingInfo.mobileNumbers)">
                            {{ email }}
                        </span>
                    </div>
                </div>

                <h5 class="mt-3 mb-3 subject">
                    <strong>Subject: </strong> {{ mailingInfo.subject }}</h5>

                <p class="body">{{ mailingInfo.text }}</p>


                <!-- <p class="body" [innerHTML]="_util.textToHtml(mailingInfo.text)"></p> -->

                <div class="alert alert-danger mt-2" role="alert" *ngIf="missingIdInFiles === true">
                    The sample ID / patient ID does not match
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            (click)="missingIdInFiles = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="horizontal-flex mt-2">
                    <p class="flex-fill center-aligned">Header reports here ..</p>
                    <p class="flex-fill center-aligned">Non Header reports here ..</p>
                    <p class="flex-fill center-aligned"
                       *ngIf="matchedReportControl && matchedReportControl['haveSupportAttachments']">Support
                        attachments here ..</p>
                </div>
                <div class="horizontal-flex">
                    <ngx-dropzone (change)="selectedAttachmentForMail($event, 1)" class="attachments flex-fill mr-2 ">
                        <ngx-dropzone-preview *ngFor="let f of reportFilesWithHeader" [removable]="false">
                            <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                        <div class="attachment" *ngFor="let attachment of mailingInfo.attachmentWithHeader"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                            <div class="remove"
                                 (click)="removeMailingInfoAttachment($event, attachment)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>

                    <ngx-dropzone (change)="selectedAttachmentForMail($event, 0)" class="attachments flex-fill ml-2">
                        <ngx-dropzone-preview *ngFor="let f of reportFilesWithoutHeader" [removable]="false">
                            <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                        <div class="attachment" *ngFor="let attachment of mailingInfo.attachmentWithoutHeader"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                            <div class="remove"
                                 (click)="removeMailingInfoAttachment($event, attachment)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>

                    <ngx-dropzone *ngIf="matchedReportControl && matchedReportControl['haveSupportAttachments']"
                                  (change)="selectedAttachmentForMail($event, 2)" class="attachments flex-fill ml-2">
                        <ngx-dropzone-preview *ngFor="let f of supportFiles" [removable]="false">
                            <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                        <div class="attachment" *ngFor="let attachment of mailingInfo.supportAttachments"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                            <div class="remove"
                                 (click)="removeMailingInfoAttachment($event, attachment)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>
                </div>


            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-primary"
                        *ngIf="_util.canUser(_util.Constants.Permissions.ReleasePendingPayments) && mailingInfo && mailingInfo['paymentHold']"
                        (click)="markPaymentDone()">Payment settled
                </button>

                <button type="button" class="btn btn-primary" [disabled]="reportFilesWithHeader.length"
                        (click)="sendAttachmentMail(true, false)">Send E-mail
                </button>
                <button type="button" class="btn btn-warning" *ngIf="showSmsOption"
                        [disabled]="reportFilesWithoutHeader.length" (click)="sendAttachmentMail(false, true)">Send SMS
                </button>
                <button type="button" class="btn btn-success" *ngIf="showSmsOption"
                        [disabled]="reportFilesWithHeader.length || reportFilesWithoutHeader.length"
                        (click)="sendAttachmentMail(true, true)">Send E-mail & SMS
                </button>

                <button type="button" class="btn btn-secondary"
                        data-dismiss="modal" aria-label="Close"
                        (click)="hideMailPreviewModal()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<notifier-container></notifier-container>
