import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RestService } from "../rest.service";
import { UtilityService } from "../utility.service";
import Swal from 'sweetalert2'
import * as $ from 'jquery';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.styl']
})
export class DashboardComponent implements OnInit {

    @ViewChild("carouselMain") carouselMain: ElementRef;
    @ViewChild('samplesGrid') samplesGrid: ElementRef;

    showingCarousel = false;
    carouselLabs = [];
    initialisedCarouselLabs = [];
    dataRefreshingInterval = null;
    carouselManualTimer = null;
    userName = '';
    haveAdminRights = false;
    showSmsOption = false;

    //form validation for duo and trio case variable
    patientDetailsSubmitted = true;

    //for related samples addition in altum test
    haveRelatedReport = false;
    relatedPatientFormData = {
        relatedPatientId: "",
        relatedPatientName: "",
        relatedPatientCaseType: "",
        relatedPatientRejectionReason: ""
    };

    syncStatus: string = "sync now";
    headerLineOverride: string = "";
    labLocation: string = "";
    dateRange: string = "last_30_days"; // last 30 days by default
    dateRanges = [];
    resyncingPatientInfo: number = -1;
    data = {};
    chartsReferences = {};

    testGroups = [];
    samples = [];
    reportsControl = [];
    matchedReportControl = null;
    samplesPageNo = 1;
    samplesLastPage = 1;
    showingSamplesGrid = false;
    showingSearchInput = false;
    isAccessionRecord = false;
    samplesQuery = "";
    samplesSearchString = "";
    customerPartyTypeFilter = "";
    selectedTestGroup = "";
    sortingAction = "";
    mailingInfo = null;
    unipathReportData = {};
    mailingSample = null;
    caseType: string = "";
    altumReportDetails = {
        caseType: "",
        altumCategory: "",
        altumSubCategory: "",
        gender: "",
        lschType: "",
        lohType: "",
    };
    resampleTime: string = "";
    rejectionReason: string = "";
    mailAttachmentFormData = {
        patientName2: "",
        patientId2: "",
        patientName3: "",
        patientId3: "",
        patientIsFetus: false,
    };

    financePaymentHoldNotification = {
        'pending': false,
        'notification': {},
        'pendingCount': 0,
        'pendingStatement': '',
        'barcode': '',
        'testcode': '',
        'patient': '',
    };

    //for TSV override check
    insighTSamples = {
        newInsighTSamples: [],
        existingInsighTSamples: [],
        unmatchedInsighTSamples: [],
        insighTRecordsToBeUpdated: [],
    }

    sendEmail: boolean = false;
    sendSms: boolean = false;
    reportFilesWithHeader: File[] = [];
    reportFilesWithoutHeader: File[] = [];
    supportFiles: File[] = [];

    missingIdInFiles = false;
    sample = null;

    listSortOrderColumns = ["samples.created_at", "samples.updated_at", "samples.recieved_at"];
    listSortOrderColumn = "";
    listSortOrderReverse = false;
    lightAlertString = "";

    searchDelayTimeout = null;
    allowedTestcodesForPrintingCommandCenterReports = [];
    reportPrintableOrAttachable = {};
    allowedTestcodesForEditingReports = [];
    uploadingPurpose = "";
    niptRedrawReportType = "";
    niptRedrawReportResampleReason = "";
    niptRedrawReportResampleWeeksTime = "";
    niptRedrawReportFailureTestCount = "";
    niptInconclusiveValues = [];
    niptInconclusiveValuesInput = "";
    niptRedrawPercentageLimit = "";
    allowedGeneratingTsv = false;
    generatingTsvStatus = "";
    tsvSelectedRecords = [];
    showNewNIPDLayout: false;
    NIPDTestCodes = [];

    timerSubscription: Subscription;

    constructor (
        private _rest: RestService,
        public _util: UtilityService,
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document
    ) {
    }

    ngOnInit (): void {
        this._util.redirectAccordingly();
        this.getAllData().then(() => {
        });

        this.haveAdminRights = this._util.canUser(this._util.Constants.Permissions.ManageUsers);


        if ( this._util.canUser(this._util.Constants.Permissions.ReleasePendingPayments) && !this._util.canUser(this._util.Constants.Permissions.ManageUsers) ) {
            // timer(0, 10000) call the function immediately and every 5 minutes
            this.checkFinancePaymentHoldNotification().then(() => {
            });
            this.timerSubscription = interval(300000).subscribe(( val ) => {
                this.checkFinancePaymentHoldNotification().then(() => {
                })
            });
        }

        const userInfo = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'))
        this.userName = (userInfo || {}).username;

        this.dateRanges = [
            { key: 'today', value: 'Today' },
            { key: 'yesterday', value: 'Yesterday' },
            { key: 'last_7_days', value: 'Last 7 days' },
            { key: 'last_30_days', value: 'Last 30 days' },
            { key: 'month_to_date', value: 'Month to date' },
            { key: 'year_to_date', value: 'Year to date' },
        ];
    }

    ngOnDestroy (): void {
        if ( this._util.canUser(this._util.Constants.Permissions.ReleasePendingPayments) && !this._util.canUser(this._util.Constants.Permissions.ManageUsers) ) {
            this.timerSubscription.unsubscribe();
        }
    }

    redirectToPrintReports () {
        if ( !this._util.canUser(this._util.Constants.Permissions.SeePrintDueReports) ) return;

        this._util.redirectTo('printing-pending', { location: this.labLocation || "" });
    }

    redirectToEditReport ( barcode, testcode, attachmentId = null ) {
        console.log("redirecting to edit report: ", barcode, testcode);

        if ( !this._util.canUser(this._util.Constants.Permissions.AuthenticateReports) ) return;

        //unipath cases
        if ( !!attachmentId ) {
            let queries = { 'attachement': attachmentId };
            let path = { 'barcode': this.sample.barcode, 'testcode': this.sample.test.testcode }
            const url = this._util.redirectToWithMultipleQueries('edit-report', path, queries);
            window.open(url);
        } else {
            this._util.redirectTo('edit-report', { barcode, testcode });
        }
    }

    redirectToAltumEditReport ( sample ) {

        if ( !this._util.canUser(this._util.Constants.Permissions.AuthenticateReports) ) return;

        let queryValues = {
            'caseType': this.altumReportDetails.caseType,
            'lschType': this.altumReportDetails.lschType,
            'lohType': this.altumReportDetails.lohType,
            'altumCategory': this.altumReportDetails.altumCategory,
            'altumSubCategory': this.altumReportDetails.altumSubCategory,
            'gender': this.altumReportDetails.gender
        };

        let pathValues = { 'barcode': sample.barcode, 'testscode': sample.test.testcode };

        const url = this._util.redirectToWithMultipleQueries('edit-report', pathValues, queryValues);

        window.open(url);

        this.hideAltumModal();
    }

    async resetChartAndListingFilters () {
        this.labLocation = '';
        this.dateRange = '';
        if ( !this.showingSamplesGrid ) {
            await this.getAllData();
        } else {
            let queryJson = this._util.queryToKeyValue(this.samplesQuery);
            delete queryJson['labGroup'];
            queryJson['dateRange'] = this._util.getDateOffset('last_30_days'), //30 days by default
                this.samplesQuery = this._util.keyValueToQuery(queryJson);
            await this.showGridData(this.samplesQuery);
        }
    }

    redirectToPrintConfirmingReportDashboard () {
        if ( !this._util.canUser(this._util.Constants.Permissions.SeePrintDueReports) ) return;

        this._util.redirectTo('print-confirming-pending', { location: this.labLocation || "" });
    }

    redirectToPaymentReceiptDashboard ( sample ) {
        this._util.redirectTo('payment-receipts', { barcode: sample.barcode });
    }

    redirectToAdmin () {
        if ( !this._util.canUser(this._util.Constants.Permissions.ManageUsers) ) return;

        this._util.redirectTo("manage-users");
    }

    redirectToPatchRequestsManager () {
        if ( !this._util.canUser(this._util.Constants.Permissions.AllowPatchRequest) &&
            !this._util.canUser(this._util.Constants.Permissions.ApproveStage2) ) return;

        this._util.redirectTo('patch-requests');
    }

    redirectToCustomerApprovalDashboard () {
        if ( !this._util.canUser(this._util.Constants.Permissions.ApproveNewCustomer) )
            return

        this._util.redirectTo('customer-approval-dashboard')
    }

    redirectToDownloadSystemData () {
        if ( !this._util.canUser(this._util.Constants.Permissions.UnAuthenticateReport) ) return;

        this._util.redirectTo('download-biochem')
    }

    redirectToDropbox () {
        if ( !this._util.canUser(this._util.Constants.Permissions.MailReport) ) return;

        this._util.redirectTo("report-dropbox");
    }

    redirectToTatManagement () {
        if ( !this._util.canUser(this._util.Constants.Permissions.ManageUsers) ) return;

        this._util.redirectTo("manage-tats");
    }

    hideCaseTypeModal () {
        console.log("event received hidden.bs.modal");
        this.resetMailAttachmentForm();

        if ( !this.mailingSample ) return;

        let sample = this.samples.filter(row => row.id === this.mailingSample.id);
        if ( !sample ) return;

        sample = sample[0];
        sample['customerAddress'].loadingAttachedReport = null;
        this.mailingSample.customerAddress.loadingAttachedReport = null;
    }

    async checkFinancePaymentHoldNotification () {
        console.log('checking finance notifications');
        let response = await this._rest.fetchFinancePaymentHoldNotification();

        if ( response['success'] && response['responseData']['pending'] ) {
            this.financePaymentHoldNotification.pending = response['responseData']['pending'];
            this.financePaymentHoldNotification.notification = response['responseData']['notification'];
            this.financePaymentHoldNotification.pendingCount = response['responseData']['pendingCount'];

            if ( parseInt(response['responseData']['pendingCount']) > 0 ) this.financePaymentHoldNotification.pendingStatement = '*There' + ((parseInt(response['responseData']['pendingCount']) > 1) ? ' are ' : ' is ') + response['responseData']['pendingCount'] + ' more' + ((parseInt(response['responseData']['pendingCount']) > 1) ? " cases" : " case") + ' pending.'

            this.financePaymentHoldNotification.barcode = response['responseData']['barcode'];
            this.financePaymentHoldNotification.testcode = response['responseData']['testcode'];
            this.financePaymentHoldNotification.patient = response['responseData']['patient'];

            this._util.modal('notification-modal', 'show');
        }
    }

    async dismissFinancePaymentHoldNotification () {
        let response = await this._rest.dismissFinancePaymentHoldNotification(this.financePaymentHoldNotification.notification['id']);
        this._util.modal('notification-modal', 'hide');
    }

    async goToPreview ( notification ) {
        let response = await this._rest.dismissFinancePaymentHoldNotification(this.financePaymentHoldNotification.notification['id']);
        let extraQuery = [];
        const url = window.location.origin + "/preview-report?"
            + `testcode=${this.financePaymentHoldNotification.testcode}&barcode=${this.financePaymentHoldNotification.barcode}&patient=${this.financePaymentHoldNotification.patient}`
            + `&headers=without`
            + (extraQuery ? ("&" + extraQuery) : "");

        this._util.modal('notification-modal', 'hide');

        window.open(url);
    }

    hideMailPreviewModal () {
        console.log("event received hidden.bs.modal");

        if ( !this.mailingSample ) return;

        let sample = this.samples.filter(row => row.id === this.mailingSample.id);
        if ( !sample ) return;

        sample = sample[0];
        sample['customerAddress'].loadingAttachedReport = null;
        this.mailingSample.customerAddress.loadingAttachedReport = null;
    }

    testGroupChanged () {
        console.log("testGroupChanged: ", this.selectedTestGroup, this.samplesQuery);

        if ( this.samplesQuery.indexOf('labGroup') === -1 ) {
            this.samplesQuery += '&labGroup=' + this.selectedTestGroup;
            this.showGridData(this.samplesQuery);
            return;
        }

        const queryJson = this._util.queryToKeyValue(this.samplesQuery);

        // @ts-ignore
        queryJson.labGroup = this.selectedTestGroup;
        this.samplesQuery = this._util.keyValueToQuery(queryJson);

        this.showGridData(this.samplesQuery);
    }

    searchStringChange () {
        console.log("search string changed: ", this.samplesSearchString);

        if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

        this.searchDelayTimeout = setTimeout(() => {
            this.lightAlertString = "Searching ...";

            this.samplesPageNo = 1;
            this.showGridData(this.samplesQuery);

        }, 250);
    }

    updatedPartyType () {
        console.log("customer type changed: ", this.customerPartyTypeFilter);

        if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

        this.searchDelayTimeout = setTimeout(() => {
            this.lightAlertString = "Searching ...";

            this.samplesPageNo = 1;

            const queryJson = this._util.queryToKeyValue(this.samplesQuery);
            queryJson['partyType'] = this.customerPartyTypeFilter;
            this.samplesQuery = this._util.keyValueToQuery(queryJson);

            this.showGridData(this.samplesQuery);

        }, 250);
    }

    resetSearchString () {
        this.samplesSearchString = "";

        this.samplesPageNo = 1;
        this.customerPartyTypeFilter = "";
        this.showGridData(this.samplesQuery);
    }

    endShowingCarousel = () => {
        if ( !this.showingCarousel ) return;

        this.carouselMain.nativeElement.classList.add("hidden");

        if ( this.dataRefreshingInterval !== null ) {
            clearInterval(this.dataRefreshingInterval);
            this.dataRefreshingInterval = null;
        }

        if ( this.carouselManualTimer !== null ) {
            clearInterval(this.carouselManualTimer);
            this.carouselManualTimer = null;
        }

        this.showingCarousel = false;
        this._util.closeFullscreen();

    }

    startShowingCarousel = () => {
        if ( this.showingCarousel ) return;

        this.carouselMain.nativeElement.classList.remove("hidden");

        this.carouselLabs = this.data["labs"].map(lab => {
            lab["htmlId"] = "carousel-" + this._util.toKebabCase(lab["name"]);

            setInterval(() => {
                if ( this.initialisedCarouselLabs.length === this.data["labs"].length ) return;

                const el = document.getElementById(lab["htmlId"]);
                const rect = el.getBoundingClientRect();

                if ( rect.width && (this.initialisedCarouselLabs.indexOf(lab["htmlId"]) === -1) ) {

                    setTimeout(() => {
                        this.initiateCircularCharts(lab);
                    }, 10);

                    this.initialisedCarouselLabs.push(lab["htmlId"]);
                }

            }, 500);

            return lab;
        });

        if ( this.dataRefreshingInterval !== null ) {
            clearInterval(this.dataRefreshingInterval);
            this.dataRefreshingInterval = null;
        }

        this.dataRefreshingInterval = setInterval(() => {
            this.getAllData().then(() => {
            });
        }, 20000);

        if ( this.carouselManualTimer !== null ) {
            clearInterval(this.carouselManualTimer);
            this.carouselManualTimer = null;
        }

        this.carouselManualTimer = setInterval(() => {
            eval(`$('.carousel').carousel('next')`);
        }, 5000);

        this.showingCarousel = true;
        this._util.openFullscreen(this.carouselMain.nativeElement);

        let script = this._renderer2.createElement('script');
        script.text = `$('.carousel').carousel({ interval: 5000 })`;

        setTimeout(() => {
            this._renderer2.appendChild(this._document.body, script);
        }, 200);
    }

    loadPageWithPrevQuery ( newpageno ) {
        this.samplesPageNo = newpageno;
        this.showGridData(this.samplesQuery);
    }

    sortingActionChanged () {
        this.samplesPageNo = 1;
        this.showGridData(this.samplesQuery);
    }

    shouldActionFilterBeVisible () {
        return this.samples.filter(row => row.allowPrinting).length;
    }

    changeOrderAndRefresh ( newOrder ) {

        if ( this.listSortOrderColumn.indexOf(newOrder) > -1 ) {
            this.listSortOrderReverse = !this.listSortOrderReverse;
        } else {
            this.listSortOrderColumn = newOrder;
        }

        this.samplesPageNo = 1;
        this.showGridData(this.samplesQuery);

    }

    async showGridData ( type = "all", $event = null, headerTitle = "" ) {
        if ( $event ) {
            $event.stopPropagation();
        }
        if ( headerTitle ) {
            this.headerLineOverride = headerTitle;

            // on receiving new header, reset the action sorting
            this.sortingAction = "";
        }

        if ( type !== this.samplesQuery ) {
            this.samplesPageNo = 1;
            this.selectedTestGroup = "";
        }

        this.samplesQuery = type;
        this.isAccessionRecord = false;
        this.showingSearchInput = false;
        this.allowedGeneratingTsv = false;

        const sortingActionQuery = this.sortingAction ? "&actionFilter=" + this.sortingAction : "";
        const response = await this._rest.getSamplesGridData(this.samplesQuery + sortingActionQuery, this.samplesPageNo,
            this.samplesSearchString, this.listSortOrderColumn, this.listSortOrderReverse
        );

        if ( this.samplesQuery.indexOf("labGroup") === -1 )
            this.testGroups = [];

        if ( !response['success'] ) return Swal.fire("Server error", response['message'], 'error');

        this.samplesLastPage = parseInt(response['samples']['lastPage']);
        this.samplesPageNo = parseInt(response['samples']['page']);
        this.isAccessionRecord = response["isAccessionRecord"] || false;
        this.showSmsOption = response["showSmsOption"] || false;
        this.reportsControl = response['reportsControl'] || [];
        this.reportPrintableOrAttachable = response['reportPrintableOrAttachable'] || {};
        this.lightAlertString = "";

        this.allowedTestcodesForPrintingCommandCenterReports = response["viewReportsPresentFor"] || [];
        this.allowedTestcodesForEditingReports = response["editReportsPresentFor"] || [];

        this.showingSamplesGrid = true;
        const testGroups = response["testGroups"] || [];
        if ( testGroups && testGroups.length )
            this.testGroups = testGroups;

        const nonAuthenticatedPatients = {};
        (response["notAuthenticated"] || [])
            .forEach(row => nonAuthenticatedPatients[row.patient] = row.count);
        const patCytoAuthenticated = [];
        (response["patCytoAuthenticated"] || [])
            .forEach(row => patCytoAuthenticated.push(row.patient));
        const trfDocuments = {};
        (response["trfDocuments"] || {})
            .forEach(row => trfDocuments[row.barcode] = row.path);
        const mailAttachmentApiRequests = {};
        (response['mailAttachmentApiRequests'] || [])
            .forEach(row => mailAttachmentApiRequests[row.barcode] = row);
        (response['mailAttachmentApiRequests'] || [])
            .forEach(row => mailAttachmentApiRequests[row.barcode + '-' + row.testcode] = row);
        const barcodeReportsSentViaDashboard = response["barcodeReportsSentViaDashboard"] || [];
        const accessionDataMap = response["accessionDataMap"] || {};
        const haveReceipts = response["haveReceipts"] || [];

        const cancelledPatients = [];
        (response["cancelledPatients"] || []).forEach(( element ) => {
            cancelledPatients.push(element.patientid);
        });

        const erroredSamples = [];

        this.samples = (response['samples'].data || []).map(sample => {
            sample.patient = this._util.preparePatientForView(sample.patient);
            sample.created = this._util.niceDateFormat(sample.created_at);
            sample.recieved = this._util.niceDateFormat(sample.s_recieved_at);
            sample.updated = this._util.niceDateFormat(sample.updated_at);

            if ( sample.tests && sample.tests.length && (!sample.test || !sample.test.id) ) {
                erroredSamples.push({
                    "barcode": sample.barcode,
                    "error": "unknown @test property",
                    "description": "Sample's test property not found but, tests is present.",
                });

                sample.test = sample.tests[0];
            }

            //for TAT related Data
            sample.tatInformation = this._util.calculateTATData((sample.test || {})['maximum_tat'], sample.created_at, sample.s_recieved_at);
            // if for some reason 'tests' is not present
            if ( sample.test && sample.test.id && (!sample.tests || !sample.tests.length) ) {
                // if everything is not handled, it will be now.
                // Forcing use of 'test' property, instead of 'tests'
                sample.tests = [sample.test];
            }


            sample.tests = sample.tests.map(test => {
                test.description = test.name; // + " (" + test.labType.name + ")";
                return test;
            });
            if ( sample.payment_hold ) {

                sample.status = sample.creditCustomer ? 'Credit hold' : 'Cash hold';
            }

            sample.trfDocument = trfDocuments[sample.barcode] || '';
            sample.haveReceipt = haveReceipts.indexOf(sample.barcode) > -1;

            sample.allowPrinting = sample.tests && sample.tests.length && sample.tests[0].testcode
                && (sample.tests[0].testcode.indexOf('-') === -1)
                && this.allowedTestcodesForPrintingCommandCenterReports.filter(row => sample.tests[0].testcode.indexOf(row) > -1).length;

            sample.allowGeneratingTsv = this.showNewNIPDLayout && sample.tests && sample.tests.length &&
                sample.tests[0].testcode &&
                (this.NIPDTestCodes.indexOf(sample.tests[0].testcode) > -1);
            if ( !this.allowedGeneratingTsv && sample.allowGeneratingTsv )
                this.allowedGeneratingTsv = true;

            sample.authenticated = false;
            if ( !sample.patient.limp_patient_id ) sample.authenticated = false;
            else if ( nonAuthenticatedPatients[sample.patient.limp_patient_id] ) sample.authenticated = false;
            else sample.authenticated = true;

            sample.accession = accessionDataMap[sample.barcode] || {};

            if ( sample.accession && sample.accession.meta )
                sample.accession.meta = (typeof sample.accession.meta === "string")
                    ? JSON.parse(sample.accession.meta || "{}") : sample.accession.meta;

            sample.customerAddress = sample.customerAddress || {};
            sample.customerAddress.email = sample.customerAddress.emailWithLetterhead || sample.customerAddress.emailWithoutLetterhead;
            sample.customerAddress.print = sample.customerAddress.printWithLetterhead || sample.customerAddress.printWithoutLetterhead;
            sample.patCytoAuthenticated = sample.patient && sample.patient.limp_patient_id && (patCytoAuthenticated.indexOf(sample.patient.limp_patient_id) > -1);
            sample.previousAttachedReport = barcodeReportsSentViaDashboard.indexOf(sample.barcode) > -1;

            sample.editableReportScenario = this._util.getMatchedScenarioForEditableReports(this.allowedTestcodesForEditingReports, this._util.getTestcodeFromFullSample(sample));
            sample.testcode = this._util.getTestcodeFromFullSample(sample);
            if ( this.isCytoReport(sample) ) sample.authenticated = true;

            if ( this.reportPrintableOrAttachable[sample.barcode + '-' + sample.testcode] && (this.reportPrintableOrAttachable[sample.barcode + '-' + sample.testcode] === 'printable') ) {
                sample.allowPrinting = true;
            }

            sample.isCancelled = false;
            if ( cancelledPatients.indexOf(sample.patient.limp_patient_id) > -1 ) sample.isCancelled = true;


            const barcodeTestcode = sample.barcode + '-' + sample.testcode;

            sample.previousAttachedReportRequest = mailAttachmentApiRequests[sample.barcode] && mailAttachmentApiRequests[sample.barcode]['data'] && mailAttachmentApiRequests[sample.barcode]['data']['request']
                ? mailAttachmentApiRequests[sample.barcode]['data']['request'] : {};
            sample.previousAttachedReportRequest = mailAttachmentApiRequests[barcodeTestcode] && mailAttachmentApiRequests[barcodeTestcode]['data'] && mailAttachmentApiRequests[barcodeTestcode]['data']['request']
                ? mailAttachmentApiRequests[barcodeTestcode]['data']['request'] : {};

            return sample;
        });

        if ( !this.listSortOrderColumn.length ) {
            this.samples = this.samples.sort(( a, b ) => a['tatInformation']['tatInEpoch'] - b['tatInformation']['tatInEpoch']);
        }
        this.samplesGrid.nativeElement.scrollIntoViewIfNeeded();
        erroredSamples.forEach(erroredSample => {
            this._util.showAlert(erroredSample.error + " Barcode: " + erroredSample.barcode,
                erroredSample.description);
        });
    }

    isCytoReport ( sample ) {
        if ( !sample['testcode'] ) return false;

        if ( sample['testcode'].indexOf('G04S04') > -1 || sample['testcode'].indexOf('G04S02') > -1 || sample['testcode'].indexOf('G04S01') > -1 ) return true;
        return false;
    }

    printReport ( sample ) {
        sample.customerAddress.printed = true;

        const url = window.location.origin + "/api/reports/view?"
            + `testcode=${sample.test.testcode}&barcode=${sample.barcode}&patient=${sample.patient.limp_patient_id}`
            + `&headers=without&token=${this._util.getToken()}&shouldPrint=yes`;

        window.open(url);
    }

    printStatus ( sample ) {

        let statement = "";

        let fullTime = "";

        if ( sample.printed && sample.print_confirmed ) {
            statement = `Printed By ${this._util.getUserFullname(sample.printedBy)} on ${this._util.niceDateFormat(new Date(sample.printed_at))}`;
            statement = (((sample.meta || {}).trackingId || '').length) ? statement + `\nTracking ID - ${sample.meta.trackingId}` : statement + ``;
            fullTime = `Printing Date and Time - ${this._util.niceTimeFormat(sample.printed_at)} on ${this._util.niceDateFormat(sample.printed_at)}`;
        } else if ( sample.printed && !sample.print_confirmed ) {
            statement = `Printed By ${this._util.getUserFullname(sample.printedBy)} on ${this._util.niceDateFormat(new Date(sample.printed_at))}`;
            fullTime = `Printing Date and Time - ${this._util.niceTimeFormat(sample.printed_at)} on ${this._util.niceDateFormat(sample.printed_at)}`;
        } else {
            statement = '';
            fullTime = '';
        }

        return {
            'statement': statement,
            'fullTime': fullTime
        };
    }


    previewReportWithHeader ( sample ) {
        if ( sample.isCancelled ) {
            return Swal.fire('Case is Cancelled', 'This case has been cancelled from labmate', 'error');
        }

        const url = window.location.origin + "/preview-report?"
            + `testcode=${sample.test.testcode}&barcode=${sample.barcode}&patient=${sample.patient.limp_patient_id}`
            + `&headers=with`;

        window.open(url);
    }

    async markPaymentDone ( sample = null ) {
        if ( sample !== null ) {
            this.mailingSample = sample;
            await this.showReportWizard(sample, true);
        }

        if ( !sample && this.mailingSample ) {
            sample = this.mailingSample;
            this._util.modal('mail-preview-modal', 'hide');
        }

        const barcode = this.mailingSample["barcode"];
        sample.customerAddress.loadingReleaseReport = "Releasing ...";
        const testcode = this._util.getTestcodeFromFullSample(this.sample);

        const resp = await this._rest.getMailingInfo2(this.sample.barcode, testcode, this.caseType, this.resampleTime, this.rejectionReason, this.mailAttachmentFormData);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert("Server resp", resp['message'], 'error');
        this.mailingInfo = resp["mailInfo"];
        this.mailingInfo.paymentHold = (resp["sample"] || {}).payment_hold || false;
        this.mailingInfo["supportAttachments"] = this.mailingInfo.attachments.filter(row => row.purpose === "support-file")
        this.mailingInfo["attachmentWithHeader"] = this.mailingInfo.attachments.filter(row => (row.purpose === "report") && row.header);
        this.mailingInfo["attachmentWithoutHeader"] = this.mailingInfo.attachments.filter(row => (row.purpose === "report") && !row.header);

        Swal.fire({
            title: `Releasing report ..`,
            text: 'please wait it may take a while',
            didOpen: async () => {
                Swal.showLoading();

                const resp = await this._rest.markPaymentSuccess(barcode);

                if ( resp["success"] ) await this.sendAttachmentMail(true, this.showSmsOption, true);

                sample.customerAddress.loadingReleaseReport = "Released";

                Swal.close();

                this.mailingInfo.paymentHold = false;
            }
        });
    }

    //function for checking wether the patient details were filled or not
    async checkSVAPatientDetailStatus ( sample, caseType: string ) {
        if ( ((caseType == "duo") && ((this.mailAttachmentFormData.patientName2 === "") || (this.mailAttachmentFormData.patientId2 === "")))
            || ((caseType === 'trio') && (this.mailAttachmentFormData.patientName2 === '' || this.mailAttachmentFormData.patientId2 === '' || this.mailAttachmentFormData.patientName3 === '' || this.mailAttachmentFormData.patientId3 === '')) ) {

            this.patientDetailsSubmitted = false;
            return;
        }

        await this.attachReportModal(sample);
    }

    async relatedPatientDataAltum ( patientId ) {
        var _resp = await this._rest.getTestsListForPatient(patientId);
        this.relatedPatientFormData.relatedPatientName = _resp["patientData"].patientRow.name;
        //console.log(this.relatedPatientName);
    }

    checkNextButtonDisability () {
        if ( !this.caseType && !(this.matchedReportControl || {}).isMolTestGroup ) {
            return false;
        }
        if ( this.caseType == 'resample' && !this.resampleTime ) {
            return false;
        }
        if ( this.haveRelatedReport ) {
            if ( this.relatedPatientFormData.relatedPatientCaseType == "" || this.relatedPatientFormData.relatedPatientName == "" ) {
                return false;
            }
        }
        return true;
    }

    checkAltumNextButtonDisability () {
        if ( !this.altumReportDetails.caseType ) {
            return false;
        }
        if ( this.altumReportDetails.caseType === 'lsch' && !this.altumReportDetails.lschType ) {
            return false;
        }
        if ( !this.altumReportDetails.altumCategory ) {
            return false;
        }
        if ( this.altumReportDetails.caseType !== 'loh' && !this.altumReportDetails.altumSubCategory ) {
            return false;
        }
        if ( this.altumReportDetails.caseType !== 'loh' && this.altumReportDetails.caseType !== 'common aneuploides' && this.altumReportDetails.altumSubCategory === 'postnatal' && !this.altumReportDetails.gender ) {
            return false;
        }

        return true;
    }

    async attachReportModal ( sample = null ) {
        if ( sample !== null ) this.sample = sample;

        console.log("attachReportModal: ", this.sample.barcode);


        setTimeout(() => {
            eval('$("#case-type-modal").modal("hide")');
        }, 1);

        this.mailingInfo = null;
        this.missingIdInFiles = false;
        this.mailingSample = this.sample;

        if ( this.mailingSample.testcode ) {
        } else if ( this.mailingSample.tests && this.mailingSample.tests[0] && this.mailingSample.tests[0].id )
            this.mailingSample.testcode = this.mailingSample.tests[0].testcode;
        else if ( this.mailingSample.test && this.mailingSample.test.id )
            this.mailingSample.testcode = this.mailingSample.test.testcode;


        this.sample.customerAddress.loadingAttachedReport = "Loading ...";

        const resp = await this._rest.getMailingInfo2(this.sample.barcode, this._util.getTestcodeFromFullSample(this.sample), this.caseType, this.resampleTime, this.rejectionReason, this.mailAttachmentFormData, this.relatedPatientFormData);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert("Server resp", resp['message'], 'error');
        this.mailingInfo = resp["mailInfo"];
        this.mailingInfo.paymentHold = (resp["sample"] || {}).payment_hold || false;
        this.mailingInfo["supportAttachments"] = this.mailingInfo.attachments.filter(row => row.purpose === "support-file")
        this.mailingInfo["attachmentWithHeader"] = this.mailingInfo.attachments.filter(row => (row.purpose === "report") && row.header);
        this.mailingInfo["attachmentWithoutHeader"] = this.mailingInfo.attachments.filter(row => (row.purpose === "report") && !row.header);

        setTimeout(() => {
            eval('$("#mail-preview-modal").modal("show")');
        }, 1);
    }

    matchSampleFromReportControl ( sample ) {
        if ( !sample.test || !sample.test.testcode )
            return null;

        return this.reportsControl
            .find(row => !row.isAstriaFlow && !!row.testcodes
                .filter(testcode => sample.tests
                    .filter(test => test.testcode
                        .indexOf(testcode) > -1).length).length);
    }

    async showReportWizard ( sample, ignoreReportWizard = false ) {
        this.sample = sample;

        if ( this.sample['isCancelled'] ) {
            return Swal.fire('Sample Is Cancelled', 'This sample has been cancelled in labmate', 'error');
        }

        const matchedReportControl = this.matchSampleFromReportControl(sample);

        if ( !matchedReportControl ) { // no config received from server, progressing like before.
            return this.attachReportModal(sample);
        }

        // if previously sent report is present
        if ( !sample.previousAttachedReportRequest ) return this.addReportWizard(sample);

        this.caseType = sample.previousAttachedReportRequest.caseType || '';
        this.resampleTime = sample.previousAttachedReportRequest.resampleTime || '';
        this.sendSms = sample.previousAttachedReportRequest.sms || false;
        this.sendEmail = sample.previousAttachedReportRequest.email || false;
        this.mailAttachmentFormData = sample.previousAttachedReportRequest || {};

        this.matchedReportControl = matchedReportControl;
        if ( !ignoreReportWizard )
            await this.attachReportModal(sample);
    }

    addReportWizard ( sample ) {

        this.resetMailAttachmentForm();

        this.sample = sample;

        if ( this.sample['isCancelled'] ) {
            return Swal.fire('Sample Is Cancelled', 'This sample has been cancelled in labmate', 'error');
        }

        const matchedReportControl = this.matchSampleFromReportControl(sample);
        // console.log("matchedReportControl: ", matchedReportControl);

        if ( !matchedReportControl ) { // no config received from server, progressing like before.
            return this.attachReportModal(sample);
        }

        if ( matchedReportControl.attachableReportWizardOptions && matchedReportControl.attachableReportWizardOptions.length ) {
            matchedReportControl.wizardOptions = matchedReportControl.attachableReportWizardOptions;
        }

        this.matchedReportControl = matchedReportControl;

        if ( this.matchedReportControl.showWizard )
            this.showCaseTypeModal(sample);
        else return this.attachReportModal(sample);
    }

    showCaseTypeModal ( sample ) {
        this.resetMailAttachmentForm();

        setTimeout(() => {
            eval('$("#case-type-modal").modal("show")');
        }, 1);

        this.sample = sample;
    }

    showAltumModal ( sample ) {
        this.resetMailAttachmentForm();

        this.sample = sample;
        if ( this.sample['isCancelled'] ) {
            return Swal.fire('Sample Is Cancelled', 'This sample has been cancelled in labmate', 'error');
        }

        this.matchedReportControl = this.matchSampleFromReportControl(sample);
        if ( this.matchedReportControl.showWizard ) {
            setTimeout(() => {
                eval('$("#altum-modal").modal("show")');
            })
        }
    }

    hideAltumModal () {
        this.resetAltumReportDetails();
        this._util.modal('altum-modal', 'hide');
    }

    resetAltumReportDetails () {
        this.altumReportDetails = {
            caseType: "",
            altumCategory: "",
            altumSubCategory: "",
            gender: "",
            lschType: "",
            lohType: "",
        };
    }


    async previewUnipathReport () {
        //const resp = await this._rest.extractDataFromAttachement(this.sample.barcode, this.unipathReportData['report-attachement'][0]);
        //const testcode = this._util.getTestcodeFromFullSample(this.sample)
        await this.redirectToEditReport(this.sample.barcode, this.sample.test.testcode, this.unipathReportData['report-attachement'][0]['id']);
        //console.log(resp);
    }

    async showUnipathReportModal ( sample ) {
        this.sample = sample;
        this.unipathReportData = { 'report-attachement': [] };
        const resp = await this._rest.getUnipathReportData(sample.barcode);
        if ( resp['success'] ) {
            this.unipathReportData['report-attachement'] = this.unipathReportData['report-attachement'].concat(resp['attachement']);
        }

        console.log(this.unipathReportData);
        this._util.modal('unipath-report-modal', 'show');
    }

    async selectUnipathReportAttachement ( event ) {
        const addedFiles = event.addedFiles;

        for ( let i = 0; i < addedFiles.length; i++ ) {
            const file = addedFiles[i];
            this.supportFiles.push(file);

            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('barcode', this.sample.barcode);
            formData.append('header', "0");
            formData.append('purpose', 'unipath-report');

            console.log('formData : ', formData);

            const resp = await this._rest.updateMailAttachments(formData);
            if ( !resp['success'] && resp['message'] ) {
                if ( !resp['success'] && resp['missingId'] ) {
                    this.supportFiles.pop();
                    this.missingIdInFiles = true;

                    setTimeout(() => {
                        this.missingIdInFiles = false;
                    }, 5000);
                }

                this._util.showAlert("Upload Error", resp['message']);
                continue;
            }

            this.unipathReportData['report-attachement'].push(resp['attachment']);
        }
    }

    removeUnipathReportAttachement ( $event, attachment ) {
        $event.stopPropagation();

        this.unipathReportData['report-attachement'] = this.unipathReportData['report-attachement'].filter(row => row.id !== attachment.id);
    }


    async selectedAttachmentForMail ( event, header = 1 ) {
        if ( !this.mailingSample ) return;

        const addedFiles = event.addedFiles;

        for ( let i = 0; i < addedFiles.length; i++ ) {
            const file = addedFiles[i];
            file['header'] = !!header;

            if ( header === 2 ) this.supportFiles.push(file);
            else if ( header ) this.reportFilesWithHeader.push(file);
            else this.reportFilesWithoutHeader.push(file);

            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('barcode', this.mailingSample.barcode);
            formData.append('testcode', this._util.getTestcodeFromFullSample(this.mailingSample));
            formData.append('header', header ? "1" : "0");

            if ( header === 2 ) formData.append('purpose', 'support-file')

            const resp = await this._rest.updateMailAttachments(formData);
            if ( !resp["success"] && resp["message"] ) {
                if ( !resp["success"] && resp["missingId"] ) {
                    if ( header === 2 ) this.supportFiles.pop();
                    else if ( header ) this.reportFilesWithHeader.pop();
                    else this.reportFilesWithoutHeader.pop();

                    this.missingIdInFiles = true;

                    setTimeout(() => {
                        this.missingIdInFiles = false;
                    }, 5000);
                }

                this._util.showAlert("Upload error", resp["message"]);
                continue;
            }

            if ( header === 2 ) this.supportFiles.splice(this.supportFiles.indexOf(file));
            else if ( header ) this.reportFilesWithHeader.splice(this.reportFilesWithHeader.indexOf(file));
            else this.reportFilesWithoutHeader.splice(this.reportFilesWithoutHeader.indexOf(file));

            if ( header === 2 ) this.mailingInfo.supportAttachments.push(resp['attachment']);
            else if ( header ) this.mailingInfo.attachmentWithHeader.push(resp["attachment"]);
            else this.mailingInfo.attachmentWithoutHeader.push(resp['attachment']);
        }
    }

    removeMailingInfoAttachment ( $event, attachment ) {
        $event.stopPropagation();

        this.mailingInfo.attachmentWithHeader = this.mailingInfo.attachmentWithHeader.filter(row => row.id !== attachment.id);
        this.mailingInfo.attachmentWithoutHeader = this.mailingInfo.attachmentWithoutHeader.filter(row => row.id !== attachment.id);
        this.mailingInfo.supportAttachments = this.mailingInfo.supportAttachments.filter(row => row.id !== attachment.id);
    }

    async sendAttachmentMail ( email, sms, confirmed = null ) {
        if ( this.mailingSample.status === 'reported' ) {
            if ( confirmed === null ) {

                Swal.fire({
                    title: 'Report already sent?',
                    text: "You want to resend this mail",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I am sure!'
                }).then(( result ) => {
                    this.sendAttachmentMail(email, sms, !!result.isConfirmed);
                });

                return;
            }

            if ( confirmed === false ) return;
            if ( confirmed === true ) {
                console.log("mark confirmed by user");
            }
        }

        if ( !this.mailingInfo.attachmentWithHeader.length && !this.mailingInfo.attachmentWithoutHeader.length ) {
            // show error don't let through

            Swal.fire({
                title: 'No attachments?',
                text: "Can't send mail, without any reports!",
                icon: 'error',
                showCancelButton: false,
            }).then(( result ) => {
                // this.sendAttachmentMail(email, sms, !! result.isConfirmed);
            });

            return;
        }

        this.mailingSample.customerAddress.loadingAttachedReport = "Sending ...";
        this.sample = null;

        setTimeout(() => {
            eval('$("#mail-preview-modal").modal("hide")');
        }, 1);

        // Try to authenticate parallelly as well
        this._rest.markLabmateHoldReject({
            barcode: this.mailingSample.barcode,
            testcode: this.mailingSample.testcode,
            patient: this.mailingSample.patient.limp_patient_id,
            type: 'Authenticate',
            result: "",
        }).then(( resp ) => {
            if ( !resp['success'] && resp['message'] ) {
                this._util.showAlert("Server error", resp['message']);
            }
        });

        const resp = await this._rest.mailAttachmentsTestcode(
            this.mailingSample.barcode, this.mailingSample.testcode,
            this.mailingInfo.attachmentWithHeader.concat(this.mailingInfo.attachmentWithoutHeader).concat(this.mailingInfo.supportAttachments || []).map(row => row.id),
            email, sms,
            this.caseType, this.resampleTime,
            this.rejectionReason,
            this.mailAttachmentFormData,
            this.relatedPatientFormData,
        );

        if ( !resp["success"] && resp["message"] ) {

            this.mailingSample.customerAddress.loadingAttachedReport = resp['paymentOnHold'] ? "Submitted" : "Failed";

            if ( resp['paymentOnHold'] ) return this._util.showAlert("Server resp", resp['message'], "info");
            return this._util.showAlert("Server error", resp["message"]);
        }

        this.mailingSample.status = 'reported';
        this.mailingSample.customerAddress.loadingAttachedReport = "Mailed Successfully";
        await this._util.showAlert("Success", "Sent successfully !!", "success");
    }

    previewReport ( sample, extraQuery = "" ) {
        if ( sample.isCancelled ) {
            return Swal.fire('Case is Cancelled', 'This case has been cancelled from labmate', 'error');
        }

        const url = window.location.origin + "/preview-report?"
            + `testcode=${sample.test.testcode}&barcode=${sample.barcode}&patient=${sample.patient.limp_patient_id}`
            + `&headers=without`
            + (extraQuery ? ("&" + extraQuery) : "");

        window.open(url);
    }

    previewAltumReport ( sample ) {
        if ( sample.isCancelled ) {
            return Swal.fire('Case is Cancelled', 'This case has been cancelled from labmate', 'error');
        }

        const extraQuery = `caseType=${this.altumReportDetails.caseType}&altumCategory=${this.altumReportDetails.altumCategory}&altumSubCategory=${this.altumReportDetails.altumSubCategory}&gender=${this.altumReportDetails.gender}`;

        const url = window.location.origin + "/preview-report?"
            + `testcode=${sample.test.testcode}&barcode=${sample.barcode}&patient=${sample.patient.limp_patient_id}`
            + `&headers=without` + '?' + extraQuery;

        window.open(url);
    }


    async refreshThisReport ( sample, index ) {
        console.log("refreshThisReport: ", sample);

        const testcode = (sample.test || {}).testcode || ((sample.tests || [])[0] || {}).testcode;
        const resp = await this._rest.getSampleInfo(sample.barcode, testcode);

        sample.patCytoAuthenticated = resp["patCytoAuthenticated"] || false;
    }

    async syncLabMatePatientInfo ( sample ) {
        const barcode = sample.barcode;
        const patient = (sample.patient || {}).limp_patient_id || "";
        this.resyncingPatientInfo = sample.id;

        const resp = await this._rest.syncLabMatePatientInfo(patient);
        const sampleIndex = this.samples.findIndex(sample => sample.barcode === barcode);

        //to refresh the sample grid
        await this.showGridData(this.samplesQuery);
        this.resyncingPatientInfo = -1;
    }

    mailReport ( sample ) {
        sample.customerAddress.emailed = true;

        const url = window.location.origin + "/api/reports/mail?"
            + `testcode=${sample.test.testcode}&barcode=${sample.barcode}&patient=${sample.patient.limp_patient_id}`
            + `&headers=with&token=${this._util.getToken()}`;

        window.open(url);
    }

    async requestToDownloadCsv ( query ) {
        const resp = await this._rest.getSamplesGridCSV(this.samplesQuery);
        const a = document.createElement("a");
        const object_URL = URL.createObjectURL(new Blob([resp + ""], { type: "text/plain" }));

        a.href = object_URL;
        a.download = this.samplesQuery + "_" + this._util.showDateLocally(this._util.getNow()) + ".csv";
        a.click();
        URL.revokeObjectURL(object_URL);
    }

    testDescription ( tests, test = null ) {
        if ( test && test.name ) {
            return test.name;
        }

        return tests.map(test => test.description).join(", ");
    }

    isNIPDTestcode ( sample ) {
        return !!this.NIPDTestCodes.filter(testcode => {
            return sample.tests.map(row => row.testcode)
                .indexOf(testcode) > -1;
        }).length;
    }

    async applyFilterToChartAndListing () {
        if ( !this.showingSamplesGrid ) {
            await this.getAllData();
        } else {
            let queryJson = this._util.queryToKeyValue(this.samplesQuery);
            queryJson['labGroup'] = this.labLocation;
            queryJson['dateRange'] = this._util.getDateOffset(this.dateRange);
            this.samplesQuery = this._util.keyValueToQuery(queryJson);
            await this.showGridData(this.samplesQuery);
        }
    }

    async getAllData () {
        const response = await this._rest.getDashboardData(this.labLocation, this._util.getDateOffset(this.dateRange));

        if ( !response["success"] ) return alert(response["message"]);

        this.showNewNIPDLayout = (response['constants'] || {})['showNewNIPDLayout'] || false;
        this.NIPDTestCodes = (response['constants'] || {})['NIPDTestCodes'] || [];

        this.data = response["data"];
        this.data["labs"] = this.data["labs"].filter(lab => !!lab.all).map(lab => {
            lab["htmlId"] = this._util.toKebabCase(lab["name"]);

            setTimeout(() => {
                this.initiateCircularCharts(lab);
            }, 1);

            return lab;
        });

        const rejectsArray = [];
        for ( let key in this.data["arrays"]["rejected"] ) {
            rejectsArray.push({ date: key, value: this.data["arrays"]["rejected"][key] });
        }
        this.initiateRecentRejectsChart(rejectsArray);

        const reportsArray = [];
        for ( let key in this.data["arrays"]["reported"] ) {
            reportsArray.push({ date: key, value: this.data["arrays"]["reported"][key] });
        }
        this.initiateRecentReportedChart(reportsArray);
    }

    getAccessionDangerWidth () {
        if ( !this.data['counts'] ) return 0;

        return (100 - parseInt("" + this.getAccessionGreenWidth())) + "%";
    }

    getAccessionGreenWidth () {
        if ( !this.data['counts'] ) return 0;

        return Math.ceil(((this.data['counts']['accession']['all'] - this.data['counts']['accession']['red']) / this.data['counts']['accession']['all']) * 100) + "%";
    }

    goToAccessions () {
        window.location.href = "/accession-form";
    }

    initiateCircularCharts ( lab ) {
        // Percent Chart
        var ctx = document.getElementById(lab.htmlId);
        if ( ctx ) {
            // @ts-ignore
            ctx.height = 180;
            // @ts-ignore
            var myChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [
                        {
                            label: lab.name,
                            data: [lab.green, lab.yellow, lab.red],
                            backgroundColor: ['#4caf50', '#fdd835', '#fa4251',],
                            hoverBackgroundColor: ['#4caf50', '#fdd835', '#fa4251',],
                            borderWidth: [5, 5, 5],
                            hoverBorderColor: ['transparent', 'transparent', 'transparent']
                        }
                    ],
                    labels: [
                        'within minimum TAT',
                        'within maximum TAT',
                        'outside maximum TAT',
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    rotation: Math.PI,
                    circumference: Math.PI,
                    cutoutPercentage: 76,
                    animation: {
                        animateScale: true,
                        animateRotate: true
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        display: true,
                        titleFontFamily: "Poppins",
                        xPadding: 15,
                        yPadding: 10,
                        caretPadding: 0,
                        bodyFontSize: 16,
                    }
                }
            });

            this.chartsReferences[lab.htmlId] = myChart;
        }
    }

    handleCircularGraphClickEvent = ( $event, id ) => {
        const activeElement = this.chartsReferences[id].getElementAtEvent($event);
        if ( !activeElement[0] ) return;

        const selectedValue = this.chartsReferences[id].chart.config.data.datasets[activeElement[0]._datasetIndex].data[activeElement[0]._index];

        let theLab = null;
        this.data['labs'].forEach(lab => {
            if ( lab.htmlId === id ) theLab = lab;
        });

        if ( theLab === null ) return;
        let label = "green";
        if ( theLab.red === selectedValue ) label = "red";
        if ( theLab.yellow === selectedValue ) label = "yellow";

        this.showGridData('state=processing&labType=' + theLab.labTypeId + '&label=' + label, null, `${theLab.name} (${label})`);
    }

    initiateRecentRejectsChart ( rejectsArray ) {
        // Recent Report
        const brandService = 'rgba(0,173,95,0.8)'

        const elements = 10
        const data1 = rejectsArray.map(row => row.value);
        const max = Math.ceil(Math.max(...data1) * 1.1)
        const steps = Math.ceil(max / 10);

        const elId = this.showingCarousel ? 'recent-reject-chart-carousel' : 'recent-reject-chart';
        const ctx = document.getElementById(elId);
        if ( ctx ) {
            // @ts-ignore
            ctx.height = 250;
            // @ts-ignore
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: rejectsArray.map(row => row.date),
                    datasets: [
                        {
                            label: 'Rejected samples',
                            backgroundColor: brandService,
                            borderColor: 'transparent',
                            pointHoverBackgroundColor: '#fff',
                            borderWidth: 0,
                            data: data1

                        },
                    ]
                },
                options: {
                    maintainAspectRatio: true,
                    legend: {
                        display: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                drawOnChartArea: true,
                                color: '#f2f2f2'
                            },
                            ticks: {
                                fontFamily: "Poppins",
                                fontSize: 12
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                stepSize: steps,
                                max: max,
                                fontFamily: "Poppins",
                                fontSize: 12
                            },
                            gridLines: {
                                display: true,
                                color: '#f2f2f2'

                            }
                        }]
                    },
                    elements: {
                        point: {
                            radius: 0,
                            hitRadius: 10,
                            hoverRadius: 4,
                            hoverBorderWidth: 3
                        }
                    }
                }
            });
        }
    }

    initiateRecentReportedChart ( reportsArray ) {
        // Recent Report
        const brandProduct = 'rgba(0,181,233,0.8)'

        const elements = 10
        const data2 = reportsArray.map(row => row.value);
        const max = Math.ceil(Math.max(...data2) * 1.1)
        const steps = Math.ceil(max / 10);

        const elId = this.showingCarousel ? 'recent-reported-chart-carousel' : 'recent-reported-chart';
        const ctx = document.getElementById(elId);
        if ( ctx ) {
            // @ts-ignore
            ctx.height = 250;
            // @ts-ignore
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: reportsArray.map(row => row.date),
                    datasets: [
                        {
                            label: 'Successfully reported',
                            backgroundColor: brandProduct,
                            borderColor: 'transparent',
                            pointHoverBackgroundColor: '#fff',
                            borderWidth: 0,
                            data: data2

                        }
                    ]
                },
                options: {
                    maintainAspectRatio: true,
                    legend: {
                        display: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                drawOnChartArea: true,
                                color: '#f2f2f2'
                            },
                            ticks: {
                                fontFamily: "Poppins",
                                fontSize: 12
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                stepSize: steps,
                                max: max,
                                fontFamily: "Poppins",
                                fontSize: 12
                            },
                            gridLines: {
                                display: true,
                                color: '#f2f2f2'

                            }
                        }]
                    },
                    elements: {
                        point: {
                            radius: 0,
                            hitRadius: 10,
                            hoverRadius: 4,
                            hoverBorderWidth: 3
                        }
                    }
                }
            });
        }
    }

    async syncNow () {
        this.syncStatus = "Syncing ...";

        const resp = await this._rest.syncAccessions();
        console.log("Resp: ", resp);

        this.syncStatus = "Synced !!";
        setTimeout(() => {
            this.syncStatus = "Sync now";
        }, 3000);

    }

    isTsvChecked ( patientId ) {
        patientId += "";

        return this.tsvSelectedRecords.indexOf(patientId) > -1;
    }

    toggleTSVChecked ( $event, patientId ) {
        $event.stopPropagation();

        patientId += "";

        console.log("toggleTSVChecked: ", patientId);

        if ( !this.isTsvChecked(patientId) )
            return this.tsvSelectedRecords.push(patientId);

        this.tsvSelectedRecords = this.tsvSelectedRecords
            .filter(row => row !== patientId);

        return false;
    }

    async generateBulkTsv () {
        this.generatingTsvStatus = "Generating ...";

        const resp = await this._rest.getNIPDTsv(this.tsvSelectedRecords);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        this.generatingTsvStatus = "";

        const hiddenElement = document.createElement('a');

        hiddenElement.href = 'data:attachment/text,' + encodeURI(resp['text']);
        hiddenElement.target = '_blank';
        hiddenElement.download = (new Date).getTime() + '-nipd.tsv';
        hiddenElement.click();

        this.tsvSelectedRecords = [];
    }

    async generateTsv ( sample ) {
        sample.generatingTsvStatus = "generating ...";
        const resp = await this._rest.getNIPDTsv(sample.patient.limp_patient_id, sample.test.testcode);

        if ( !resp['success'] && resp['message'] ) return this._util.showAlert("Server resp: ", resp['message']);

        sample.generatingTsvStatus = "";

        const hiddenElement = document.createElement('a');

        hiddenElement.href = 'data:attachment/text,' + encodeURI(resp['text']);
        hiddenElement.target = '_blank';
        hiddenElement.download = sample.barcode + '-nipd.tsv';
        hiddenElement.click();
    }

    async beginUploadNIPTResultTSV () {
        this.uploadingPurpose = "NIPT-TSV-Raw-2";

        setTimeout(() => {
            eval(`$("#hidden-attachment-input").trigger('click')`)
        }, 1);
    }

    async beginUploadNIPTResultXML () {
        this.uploadingPurpose = "NIPT-XML-Raw-2";

        setTimeout(() => {
            eval(`$("#hidden-xml-attachments-input").trigger('click')`)
        }, 1);
    }

    async beginUpdateNIPTResultTSV () {
        this.uploadingPurpose = "NIPT-TSV-Raw-2";
        this.updateTSVValue();
    }

    async previewNIPTReport ( sample ) {
        console.log("dummy to previewNIPTReport: ", sample.barcode);

        if ( sample.isCancelled ) {
            return Swal.fire('Case is Cancelled', 'This case has been cancelled from labmate', 'error');
        }

        this.sample = sample;
        this.niptRedrawReportType = "";
        this.niptRedrawReportResampleReason = "";
        this.niptRedrawReportResampleWeeksTime = "";
        this.niptRedrawReportFailureTestCount = "";
        this.niptInconclusiveValues = [];
        this.niptInconclusiveValuesInput = "";

        const valuesToCheck = ["T21", "T18", "T13", "XXX", "XO", "XXY", "XYY", "XXYY", "DiGeorge", "1p35 deletion", "Smith-Magenis", "Wolf-Hirshhorn"];
        if ( this.sample && this.sample.meta && this.sample.meta.Barcode && !this.sample.meta.isRedraw ) {
            valuesToCheck.forEach(row => {
                if ( this.sample.meta[row] === "not provided" )
                    this.niptInconclusiveValues.push(row);
            });
        }

        console.log("this.niptInconclusiveValues: ", this.niptInconclusiveValues, this.sample.meta);

        setTimeout(() => {
            eval('$("#nipt-reports-modal").modal("show")');
        }, 1);
    }

    async applyNIPTInconclusiveToLowRiskValues () {
        console.log("applyNIPTInconclusiveToLowRiskValues() called: ", this.niptInconclusiveValues, this.niptInconclusiveValuesInput);

        const resp = await this._rest.niptInconclusiveToLowRisk(this.sample.barcode, this.niptInconclusiveValuesInput);
        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        this.niptInconclusiveValues = this.niptInconclusiveValues.filter(row => this.niptInconclusiveValuesInput.indexOf(row) === -1);
        this.niptInconclusiveValuesInput = "";

        await this._util.showAlert('Updated successfully', '', 'success');
    }

    async previewNIPTRedrawReport () {

        this.previewReport(this.sample,
            `redraw=${this.niptRedrawReportType}&reason=${this.niptRedrawReportResampleReason}&weeks=${this.niptRedrawReportResampleWeeksTime}&testCount=${this.niptRedrawReportFailureTestCount}`);
    }

    async hideNIPTReportModal () {
        setTimeout(() => {
            eval('$("#nipt-reports-modal").modal("hide")');
        }, 1);
    }

    async hideInsighTSampleOverrideModal () {
        this._util.modal('nipt-insighT-TSV-save-modal', 'hide');
        this.resetMailAttachmentForm();
    }

    async overrideExistingInsighTSample ( item, event ) {
        //console.log(event.target.checked);
        this.insighTSamples.insighTRecordsToBeUpdated = this.insighTSamples.insighTRecordsToBeUpdated.filter(( element ) => {
            return element != item;
        });
        if ( event.target.checked ) {
            this.insighTSamples.insighTRecordsToBeUpdated.push(item);
        }
        //console.log(this.insighTSamples.insighTRecordsToBeUpdated);
    }


    async hiddenAttachmentInputChanged ( event ) {
        console.log("event: ", event, event.target.files, this.sample.barcode, this.uploadingPurpose);

        if ( !event.target.files.length ) return;

        const firstFile = event.target.files[0];
        const formData: FormData = new FormData();

        if ( event.target.files.length === 1 ) {
            formData.append('attachment', firstFile);
        } else {
            for ( let i = 0; i < event.target.files.length; i++ ) {
                formData.append('attachment', event.target.files[i]);
            }
        }
        formData.append('purpose', this.uploadingPurpose)
        formData.append('barcode', this.sample.barcode);

        const resp = await this._rest.uploadSpecial(formData);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        setTimeout(() => {
            eval('$("#hidden-attachment-input").val("");')
            eval('$("#hidden-xml-attachments-input").val("");')
        }, 1);

        switch ( this.uploadingPurpose ) {
            case 'NIPT-TSV-Raw-2':
                this.insighTSamples.newInsighTSamples = resp['new'];
                this.insighTSamples.existingInsighTSamples = resp['existing'];
                this.insighTSamples.unmatchedInsighTSamples = resp['unmatched'];

                await this.hideNIPTReportModal();
                this._util.modal('nipt-insighT-TSV-save-modal', 'show');
                break;
            case 'NIPT-XML-Raw-2':
                await this.hideNIPTReportModal();
                await this.showGridData(this.samplesQuery);
                await this._util.showAlert('Uploaded successfully', '', 'success');
                break;
            default:
                break;
        }
    }

    async updateTSVValue () {
        this.insighTSamples.insighTRecordsToBeUpdated.push(...this.insighTSamples.newInsighTSamples);
        //console.log(this.insighTSamples.insighTRecordsToBeUpdated);
        const values = { 'values': this.insighTSamples.insighTRecordsToBeUpdated, 'purpose': this.uploadingPurpose };

        const resp = await this._rest.updateInsighTTSVValues(values);

        if ( !resp['success'] && resp['message'] )
            return this._util.showAlert('Server resp', resp['message']);

        switch ( this.uploadingPurpose ) {
            case 'NIPT-TSV-Raw-2':
                await this.hideInsighTSampleOverrideModal();
                await this.showGridData(this.samplesQuery);
                await this._util.showAlert('Uploaded successfully', '', 'success');
                break;
            default:
                break;
        }
    }

    resetMailAttachmentForm () {
        this.caseType = "";
        this.resampleTime = "";
        this.sendEmail = false;
        this.sendSms = false;
        this.patientDetailsSubmitted = true;
        this.haveRelatedReport = false;

        this.insighTSamples = {
            newInsighTSamples: [],
            existingInsighTSamples: [],
            unmatchedInsighTSamples: [],
            insighTRecordsToBeUpdated: []
        }

        this.relatedPatientFormData = {
            relatedPatientId: "",
            relatedPatientCaseType: "",
            relatedPatientName: "",
            relatedPatientRejectionReason: ""
        };

        this.mailAttachmentFormData = {
            patientName2: "",
            patientId2: "",
            patientName3: "",
            patientId3: "",
            patientIsFetus: false,
        };
    }

    beginGlobalSearch () {
        this.showingSearchInput = true;
        this.samplesQuery = 'all';
    }
}
