<div class="container-fluid pt-3 fill-height">
	<div class="modal" tabindex="-1" id="rejection-reason-modal" data-keyboard="false" data-backdrop="static">
	    <div class="modal-dialog modal modal-dialog-centered">
	        <div class="modal-content">
	            <div class="modal-body">
	               <div class="vertical-flex">
	               		<h3 clas="mb-3">Reason for Rejection of Customer</h3>
	               		<input class="form-control mt-2" type="text" name="rejection-reason" [(ngModel)]="rejectionReason" placeholder="Type reason here...">
	               </div>
	            </div>
	            <div class="modal-footer">
	                <button type="button" class="btn btn-primary"
	                    [disabled]="!rejectionReason.length"
	                    (click)="rejectCustomerApplication()">
	                    Submit
	                </button>
	                <button type="button" class="btn btn-secondary"
	                    data-dismiss="modal" aria-label="Close"
	                    (click)="closeRejectionModal()">
	                    Cancel
	                </button>
	            </div>
	        </div>
	    </div>
	</div>


	<div class="row mt-3 mb-4" [hidden]="showCustomerDetail">
		<div class="col-md-12">
			<div class="col-md-12 horizontal-flex vertical-flex-on-mobile align-start-on-mobile space-between-center">

				<div class="horizontal-flex space-between-center fill-width m-1 vertical-margins-on-mobile">
				    <h3>Hi,&nbsp;{{ userName }}</h3>, &nbsp;
				</div>

				<!--<input type="search" class="form-control" (input)="customerSearchStringChange()" name="customerSearchString"
                            id="search-input"
                            [(ngModel)]="customerSearchString" placeholder="Search ..."
                            aria-label="by customer Name">-->

                        <select class="form-control mb-1 mr-1" [(ngModel)]="filterColumn" placeholder="Select Column">
                        	<option selected value="">{{"Select filter"}}</option>
                        	<option *ngFor="let key of objectKeys(filterDropdown)" [value]="key">{{ filterDropdown[key] }}</option>
                    	</select>

                    	<input [disabled] = "!filterColumn.length" type="search" class="form-control mb-1 mr-1" (input)="customerFilterStringChange()" name="filterString" id="filter-search-input" [(ngModel)]="filterString" placeholder="Search..." aria-label="enter value">	


                      	<div class="input-group-append" [hidden]="! filterString.length">
                            <button type="button" class="btn btn-danger" (click)="resetCustomerFilter()">
                                <i class="zmdi zmdi-close red"></i>
                            </button>
                        </div>

                <!--<button type="button" class="btn btn-outline-warning ml-2" (click)="beginGlobalSearch()">
		        <i class="zmdi zmdi-search"></i>
		        </button>-->
		        <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item " [ngClass]="{'disabled': customersPageNo === 1}">
                        <a class="page-link" aria-label="Previous" (click)="loadPageWithPrevQuery(customersPageNo - 1)">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>

                    <li class="page-item" [ngClass]="{'active': customersPageNo === i}" *ngFor="let i of _util.getPagesRange(customersPageNo, customersLastPage)">
                        <a class="page-link" (click)="loadPageWithPrevQuery(i)">{{ i }}</a>
                    </li>

                    <li class="page-item" [ngClass]="{'disabled': customersPageNo === customersLastPage}">
                        <a class="page-link" aria-label="Next" (click)="loadPageWithPrevQuery(customersPageNo + 1)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
		        <button type="button" class="btn btn-outline-primary ml-2 vertical-margins-on-mobile" (click)="returnToDashboard()" >
		        Back to Dashboard
		        </button>
			</div>
		</div>
	</div>

	<div class="row mb-1" *ngIf="isMasterRole">
		<div class="col-md-12 horizontal-flex justify-content-end">
			<button class="btn btn-primary" (click)="downloadCustomerData()">
				<i class="zmdi zmdi-download"></i> &nbsp;
				Download Customer Data
			</button>
		</div>
	</div>

	<div class="row" [hidden]="showCustomerDetail">
		<div class="sufee-alert alert with-close alert-warning alert-dismissible fade show" role="alert"
            [hidden]="newCustomerList.length" style="width: 100%;">
            No records found !!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col-md-12 table-responsive hide-on-mobile" [hidden]="!newCustomerList.length">
        	<table class="table table-sm">
        		<thead>
        			<tr>
        				<th scope="col">
        					Customer Name
        				</th>
        				<th scope="col">
        					BDM executive
        				</th>
        				<th scope="col">
        					Stage
        				</th>
        				<th scope="col">
        					HQ
        				</th>
        				<th>
        					RP Approved At
        				</th>
        				<th>
        					Master Approved At
        				</th>
        				<th scope="col">
        					Pending Duration
        				</th>
        				<th scope="col">
        					Actions
        				</th>
        			</tr>
        		</thead>
        		<tbody>
        			<tr *ngFor="let customer of filteredCustomerList" [ngClass]="{'red-text': (customer.stage === 'Rejected By Master') || (customer.stage === 'Rejected By RP'), 'green-text': (customer.stage === 'Approved By RP') || (customer.stage === 'Finalized Signed Mou') || (customer.stage === 'Approved By Master'), 'blue-text': customer.stage === 'Pending Approval'}">
        				<td>
        					{{customer.customer_name}}
        				</td>
        				<td>
        					{{customer.firstname + ' ' + customer.lastname}}
        				</td>
        				<td>
        					{{customer.stage}}
        				</td>
        				<td>
        					{{customer['customer_data']['marketing-location']}}
        				</td>
        				<td>{{getApprovalDate(customer['meta'], 'rp')}}</td>
    					<td>{{getApprovalDate(customer['meta'], 'master')}}</td>
        				<td>
        					{{getPendingDurationForCustomer(customer)}}
        				</td>
        				<td>
        					<button type="button" class="btn btn-primary btm-xs mr-1" (click)="showCustomerDetails(customer)">
        						View Details
        					</button>
        					<button type="button" class="btn btn-outline-primary mr-1" (click)="downloadCustomerDetails(customer.id)" *ngIf="isMasterRole">
        						<i class="zmdi zmdi-download"></i> &nbsp; Customer Data
        					</button>	
        				</td>
        			</tr>
        		</tbody>
        	</table>
        </div>
        <ol class="show-on-mobile ml-4" style="none">
        		<li *ngFor="let customer of filteredCustomerList">
        				<div class="card">
				            <div class="card-body" [ngClass]="{'red-text': (customer.stage === 'Rejected By Master') || (customer.stage === 'Rejected By RP'), 'green-text': (customer.stage === 'Approved By RP') || (customer.stage === 'Finalized Signed Mou') || (customer.stage === 'Approved By Master'), 'blue-text': customer.stage === 'Pending Approval'}">
				                    <div class="card-title">
				                        <div class="d-flex flex-row" (click)="selectTest(selectedTest)">
				                            <input type="checkbox" name="{{ selectedTest['Gcode'] + selectedTest['Scode'] + selectedTest['TestCode'] }}" [checked]="tableType === 'selected'" *ngIf="showCheckInput">
				                            &nbsp;&nbsp;
				                            <h4 class="overflow-wrap">{{customer['customer_name'] || ''}}</h4>
				                        </div>
				                    </div>
				                    <div class="card-text">
				                        Rp Approval At - {{getApprovalDate(customer['meta'], 'rp')}}<br>
				                        <div class="overflow-wrap">Master Approval At - {{getApprovalDate(customer['meta'], 'master')}}</div>
				            			<div class="overflow-wrap">Stage - {{customer['stage'] || ''}}</div>
				            			Pending Duration - {{getPendingDurationForCustomer(customer)}}  
				            			<div class="overflow-wrap" *ngIf="isRpRole">BDM Executive - {{(customer['firstname'] || '') + ' ' + (customer['lastname']||'')}}</div>        
				                    </div>
				                </div>
				                <div class="list-group list-group-flush">
				                    <li class="list-group-item">
				                       <button type="button" class="btn btn-primary btm-xs mr-1" (click)="showCustomerDetails(customer)">
			        						View Details
			        					</button> 
						        		<button type="button" class="btn btn-outline-primary mr-1" (click)="downloadCustomerDetails(customer.id)" *ngIf="isMasterRole">
			        						<i class="zmdi zmdi-download"></i> &nbsp; Customer Data
			        					</button>
				                    </li>      
				                </div>
				            </div>
  
        		</li>
        	</ol>
	</div>

	<div class="row align-center justify-content-center" [hidden]="!showCustomerDetail">
		<div class="col-md-10 horizontal-flex space-between-center">

				<div class="horizontal-flex space-between-center m-1">
				    <button type="button" class="btn btn-primary" *ngIf="(isRpRole || isMasterRole) && !customerIsApprovedOrRejected" (click)="approveCustomerApplication()">
				    	Approve
				    </button>
				    &nbsp;&nbsp;
				    <button type="button" class="btn btn-danger" *ngIf="(isRpRole || isMasterRole) && !customerIsApprovedOrRejected" (click) ="showRejectionModal()">
				    	Reject
				    </button>
				    &nbsp;&nbsp;
				    <button type="button" class="btn btn-primary" *ngIf="(!isRpRole && !isMasterRole && ['Approved By RP', 'Approved By Master', 'Finalized Signed Mou'].indexOf(currentCustomer['stage']) == -1) || byPassMasterRole" (click)="editCustomer()">Edit application</button>
				</div>

				<button type="button" class="btn btn-danger" (click)="closeCustomerDetail()">
                <i class="zmdi zmdi-close red"></i>
                </button>
			</div>
		<div class="col-md-10">
			<div class="vertical-flex align-center">
				<h3 class="mt-4 mb-2">
					Tests Data
				</h3>
				<app-test-detail-table class="col-md-12 p-0 mb-3" [displayList]="customerTestData" [editable]="false" [tableType]="'selected'" [showHeader]="true" [showCheckInput]="false"></app-test-detail-table>

		        <div id="accordion" class="fill-width">
		          <div class="card">
		            <div class="card-header" id="headingOne">
		              <h5 class="mb-0">
		                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
		                  View Remaining Tests
		                </button>
		              </h5>
		            </div>

		            <div id="collapseOne" class="collapse collapse" aria-labelledby="headingOne" data-parent="#accordion">
		              <div class="card-body small-scrollable-container" >
		              	<ol class="flex-fill vertical-flex start-center">
		                    <app-test-search-filter [testsSearchString]='testsSearchString' [testList]="testList" [testDropdownLabels]="testDropdownLabels" (testsSearchStringChanged)='searchStringChange($event)' (filterStringChanged)='filterStringChange($event)' (testsSearchStringReset)='resetSearchAndFilterString()' (filterStringReset)='resetSearchAndFilterString()'></app-test-search-filter>
		                    <app-test-detail-table *ngIf="remainingTestList.length" [displayList]="remainingTestList" [editable]="false" [tableType]="'remaining'" [showHeader]="false" [showCheckInput]="false"></app-test-detail-table>
		                    
		                </ol>
		              </div>
		            </div>
		          </div>
		        </div>
				<h3 class="mt-3 mb-2">Customer Details</h3>
				<div class="table-responsive">
					<table class="table table-striped">
						<tbody>
							<tr *ngFor="let customerDetail of customerDataLabels" class="hover-table-rows">
								<td class="col-4">{{formatDetailLabel(customerDetail)}}</td>
								<td class="overflow-wrap col-8"><strong>{{formatCustomerDetailValue(currentCustomer['customer_data'][customerDetail], customerDetail)}}</strong></td>
							</tr>
							<tr *ngIf="(currentCustomer['meta'] || {})['rejectionReasonByRP']">
								<td class="col-4 bold">Rejection Reason By RP</td>
								<td class="col-8 overflow-wrap bold">
									{{currentCustomer['meta']['rejectionReasonByRP']}}
								</td>
							</tr>
							<tr *ngIf="currentCustomer['pcpndt-license']">
								<td class="col-4 bold">PCPNDT License</td>
								<td class="col-8 overflow-wrap bold"><a href="{{currentCustomer['pcpndt-license']['last_url']}}"><button class="btn btn-primary">Open File</button></a></td>
							</tr>
							<tr *ngIf="(currentCustomer['meta'] || {})['rejectionReasonByMasterDataTeam']">
								<td class="col-4 bold">Rejection Reason By RP</td>
								<td class="col-8 overflow-wrap bold">
									{{currentCustomer['meta']['rejectionReasonByMasterDataTeam']}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col-md-10 horizontal-flex mt-4 mb-5">
			<div class="horizontal-flex space-between-center m-1" *ngIf="(isRpRole || isMasterRole) && !customerIsApprovedOrRejected">
				    <button type="button" class="btn btn-primary" (click)="approveCustomerApplication()">
				    	Approve
				    </button>
				    &nbsp;&nbsp;
				    <button type="button" class="btn btn-danger" (click)="showRejectionModal()">
				    	Reject
				    </button>
		</div>
	</div>
	
</div>