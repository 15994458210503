<div class="modal" tabindex="-1" id="session-termination-warning" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h3>Session will expire soon</h3>
                <p>Your session if going to expire in next 10 seconds. If you want to extend the session please proceed
                    by clicking 'Extend Session' else ignore by pressing 'Cancel'</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="extendSession()">Extend Session</button>
                <button type="button" class="btn btn-secondary"
                        (click)="_util.modal('session-termination-warning', 'hide')">Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="session-occupancy-warning" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h2>Sample {{previewParams['barcode']}}, is currently being worked upon
                    by {{(currentSessionDetails || {})['session_owner'] || ''}}.</h2>
                <p>The sample will be available again by
                    - {{(currentSessionDetails || {})['session_valid_till'] || ''}} ({{(currentSessionDetails || {})['sealedTimeDiffernece'] + ' remaining'}})</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="takeOverSession()" *ngIf="haveAdminRights">Take
                    Over
                </button>
                <button type="button" class="btn btn-secondary" (click)="redirectBack()">Return</button>
            </div>
        </div>
    </div>
</div>
