import { Component, OnInit } from '@angular/core';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";

@Component({
  selector: 'app-accession-form',
  templateUrl: './accession-form.component.html',
  styleUrls: ['./accession-form.component.styl']
})
export class AccessionFormComponent implements OnInit {

  labLocations: [];
  form = {
    location: "",
    barcode: "",
    loading: false,
  }
  formMessage = {
    text: "",
    type: "alert-primary"
  }

  constructor( private _rest: RestService, private _util: UtilityService ) { }

  ngOnInit(): void {
    if ( ! this._util.canUser(this._util.Constants.Permissions.PreviewReport))
      this._util.redirectTo();

    this.form.location = this._util.getLocalData(this._util.Constants.Keys.lastUsedLabLocation, "");

    this._rest.getAllLabs().then( (response) => {
      if ( ! response["success"] ) return;

      this.labLocations = response["locations"];
    });
  }

  public async submitForm() {
    this.form.loading = true;
    this._util.setLocalData(this._util.Constants.Keys.lastUsedLabLocation, this.form.location);

    const response = await this._rest.createNewAccession(this.form.barcode, this.form.location);

    this.formMessage.type = response['success'] ? 'alert-success' : 'alert-danger';
    this.formMessage.text = response['message'];

    setTimeout(() => {
      this.formMessage = {text: "", type: ""};
    }, 3000);

    this.form.barcode = '';
    this.form.loading = false;
  }

  redirectToHome() {
    window.location.href = "/";
  }
}
