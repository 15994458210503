<div class="container-fluid pt-3 fill-height">

    <div class="row">
        <div class="col-md-2 title-2">
            Hi {{ userName }}
        </div>

        <div class="col-md-4 horizontal-flex space-between-center gap-10">
            <input type="date" name="filter-date-start" id="filter-date-start" data-toggle="tooltip"
                   title="Start date for filter" [(ngModel)]="form.filterDateStart">
            <input type="date" name="filter-date-end" id="filter-date-end" data-toggle="tooltip"
                   title="End date for filter" [(ngModel)]="form.filterDateEnd">
            <button class="btn btn-primary" (click)="refreshListing()">Filter</button>
            <button *ngIf="form.filterDateStart || form.filterDateEnd" class="btn btn-secondary"
                    (click)="form.filterDateStart = ''; form.filterDateEnd = ''">Clear
            </button>

        </div>

        <div class="col-md-6 horizontal-flex end-center">
            <select name="select-filter-type" id="select-filter-type" class="form-control mr-2" [(ngModel)]="form['filterType']" (change)="setFilterType()" data-toggle="tooltip" title="Filter based on listing columns">
                <option value="">Select Filter Type</option>

                <option value="custname">Client Name</option>
                <option value="logistic_person">Collected By</option>
                <option value="deposite_id">Deposite Id</option>
                <option value="hq">HQ</option>
                <option value="transaction_status">Transaction Status</option>
            </select>


            <input  [(ngModel)]="form['selectedFilterOption']" data-toggle="tooltip" title="Filter option based on filter type" *ngIf='form["filterType"].length && form["filterType"] !== "transaction_status"' list="selectedFilterOptions" class="filter-search-input" (change)="refreshListing()">
            <datalist id="selectedFilterOptions" *ngIf='form["filterType"].length && form["filterType"] !== "transaction_status"' list="selectedFilterOptions">
                <option value="">Select Filter Option</option>

                <option *ngFor="let option of filterData['selectedFilterOptions']" value={{option}}>{{option}}</option>
            </datalist>

            <select name="select-popular-filters" id="select-popular-filters" class="form-control mr-2" *ngIf='form["filterType"].length && form["filterType"] === "transaction_status"'
                    [(ngModel)]="form.filter" (change)="refreshListing()"
                    data-toggle="tooltip" title="Filter based on most common options">
                <option value="">Common Filters</option>

                <option value="unverified-cash">Un-verified cash</option>
                <option value="verified-cash">Verified cash</option>
                <option value="verified-self">Verified by you</option>
                <option value="pending-receipts">Pending receipts</option>
                <option value="failed-jobs">Failed jobs</option>
                <option value="deposited">Deposited in Bank</option>
                <option value="partial-reciept">Partial Payment</option>
            </select>

            <button *ngIf="form.selectedFilterOption.length || form.filterType.length" class="btn btn-secondary mr-2"
                    (click)="resetColumnFilters()">Clear
            </button>

            <button class="btn btn-danger" (click)="_util.redirectTo()">
                Back to dashboard
            </button>
        </div>
    </div>

    <div class="row mt-2">

        <div class="col-md-4 end-center">

            <div class="vertical flex">
                <div class="btn-group btn-group-toggle w-100 mb-1" data-toggle="buttons">
                  <label class="btn btn-primary btn-xs w-50 text-center" [class]="{'active': form['paymentLabmateStatusFilter'] === 'archived'}" (click)="toggleFilter('paymentLabmateStatusFilter', 'archived')">
                    <input type="radio" name="options" id="option1" autocomplete="off"> Archived
                  </label>
                  <label class="btn btn-primary btn-xs w-50 text-center" [class]="{'active': form['paymentLabmateStatusFilter'] === 'pending'}" (click)="toggleFilter('paymentLabmateStatusFilter', 'pending')">
                    <input type="radio" name="options" id="option2" autocomplete="off"> Pending
                  </label>
                  <label class="btn btn-primary btn-xs w-50 text-center" [class]="{'active': form['paymentLabmateStatusFilter'] === 'exception'}" (click)="toggleFilter('paymentLabmateStatusFilter', 'exception')">
                    <input type="radio" name="options" id="option2" autocomplete="off"> Exception
                  </label>
                </div>
                <div class="btn-group btn-group-toggle w-100 mt-1" data-toggle="buttons">
                  <label class="btn btn-info btn-xs w-25 text-center" [class]="{'active': form['paymentMethodFilter'] === 'cash'}" (click)="toggleFilter('paymentMethodFilter', 'cash')">
                    <input type="radio" name="options" id="option1" autocomplete="off"> Cash
                  </label>
                  <label class="btn btn-info btn-xs w-25 text-center" [class]="{'active': form['paymentMethodFilter'] === 'upi'}" (click)="toggleFilter('paymentMethodFilter', 'upi')">
                    <input type="radio" name="options" id="option2" autocomplete="off"> UPI
                  </label>
                  <label class="btn btn-info btn-xs w-25 text-center" [class]="{'active': form['paymentMethodFilter'] === 'online'}" (click)="toggleFilter('paymentMethodFilter', 'online')">
                    <input type="radio" name="options" id="option3" autocomplete="off"> Online
                  </label>
                  <label class="btn btn-info btn-xs w-25 text-center" [class]="{'active': form['paymentMethodFilter'] === 'credit'}" (click)="toggleFilter('paymentMethodFilter', 'credit')">
                    <input type="radio" name="options" id="option4" autocomplete="off"> Credit
                  </label>
                </div>


            </div>

            <div class="horizontal-flex ml-2 justify-content-around mt-2 mb-2">
                <button class="btn btn-secondary" *ngIf="form.paymentMethodFilter.length > 1 || form.paymentLabmateStatusFilter.length > 1" (click)="resetFilter()">Reset</button>
                <button class="btn btn-primary" *ngIf="filterApplied()" (click)="downloadCollectionAppData()">
                <i class="zmdi zmdi-download"></i> &nbsp;
                Download Collection Data
                </button>
            </div>
        </div>

        <div class="col-md-8 horizontal-flex end-center vertical-flex-on-mobile">
            <div class="input-group mr-3">
                <input type="text" class="form-control" placeholder="Press enter to search" [(ngModel)]="form.search"
                       (change)="refreshListing()"
                       (keyup.enter)="refreshListing()">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="refreshListing()">
                        <i class="zmdi zmdi-search"></i>
                    </button>
                    <button class="btn btn-outline-danger" type="button" (click)="form.search = ''; refreshListing()"
                            *ngIf="form.search.length">
                        <i class="zmdi zmdi-close"></i>
                    </button>
                </div>
            </div>

            <div class="input-group mr-3">
                <input type="text" class="form-control" placeholder="Enter Barcode Here" [(ngModel)]="form.barcode">
                <div class="input-group-append">
                    <button class="btn btn-outline-primary" type="button" (click)="syncBrcodeWiseTransaction()" data-toggle="tooltip" title="Sync/Create all transactions related to the barcode enterred">
                        <i class="zmdi zmdi-refresh-sync"></i>
                    </button>
                    <button class="btn btn-outline-danger" type="button" (click)="form.barcode = ''; refreshListing()"
                            *ngIf="form.search.length">
                        <i class="zmdi zmdi-close"></i>
                    </button>
                </div>
            </div>

            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item " [ngClass]="{'disabled': form.page === 1}">
                        <a class="page-link" aria-label="Previous" (click)="refreshListingWthPage(form.page - 1)">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>

                    <li class="page-item" [ngClass]="{'active': form.page === i}"
                        *ngFor="let i of _util.getPagesRange(form.page, form.lastPage)">
                        <a class="page-link" (click)="refreshListingWthPage(i)">{{ i }}</a>
                    </li>

                    <li class="page-item" [ngClass]="{'disabled': form.page === form.lastPage}">
                        <a class="page-link" aria-label="Next" (click)="refreshListingWthPage(form.page + 1)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-md-12" *ngIf="! records.length">
            <div class="alert alert-primary fill-width" role="alert">
                No records found
            </div>
        </div>

        <div class="col-md-12" style="overflow-y: scroll;" *ngIf="records.length">

            <div class="tableFixHead">
                <table class="table table-striped" style="min-width: 2000px">
                <thead>
                <tr>
                    <th scope="col" class="min-width-100">#</th>
                    <th scope="col" class="min-width-120">Barcode</th>
                    <th scope="col" class="min-width-200">Patient name</th>
                    <th scope="col" class="min-width-250">Client name</th>
                    <th scope="col" class="min-width-150">Collected By</th>
                    <th scope="col" class="min-width-120">Amount Paid</th>
                    <th scope="col" class="min-width-120">Billed Amount</th>
                    <th scope="col" class="min-width-250">Actions</th>
                    <th scope="col" class="min-width-150">Bank Deposit</th>
                    <th scope="col" class="min-width-150">Client Type</th>
                    <th scope="col" class="min-width-150">HQ</th>
                    <th scope="col" class="min-width-120">Method</th>
                    <th scope="col" class="min-width-160">Collector Mobile</th>
                    <th scope="col" class="min-width-160">Collection Date</th>
                    <th scope="col" class="min-width-150">Deposited On</th>
                    <th scope="col" class="min-width-200">Deposit Id</th>
                    <th scope="col" class="min-width-200">Transaction No.</th>
                    <th scope="col" class="min-width-150">Labmate <i class="zmdi zmdi-info-outline zmdi-hc-sm text-info" data-toggle="tooltip" title="Labmate DB status"></i></th>
                    <th scope="col" class="min-width-150" >Payment <i class="zmdi zmdi-info-outline zmdi-hc-sm text-info" data-toggle="tooltip" title="Labmate Receipt Job status"></i></th>
                    <th scope="col" class="min-width-150">Db sync <i class="zmdi zmdi-info-outline zmdi-hc-sm text-info" data-toggle="tooltip" title="Remote DB Sync Status"></i></th>
                    <th scope="col" class="min-width-150">Patient Id</th>
                    <th scope="col" class="min-width-200">Labmate Bill Amount</th>
                    <th scope="col" class="min-width-200">Tranasaction Status</th>
                    <th scope="col" class="min-width-200">Remarks</th>
                    <th scopr="col" class="min-width-200">Receipt No.</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let record of records">
                    <th scope="row" class="min-width-100">{{ record.id }}</th>
                    <td scope="col" class="min-width-120">{{ record.barcode }}</td>
                    <td scope="col" class="min-width-200">{{ record.meta.paymentDBRecord.fullname }}</td>
                    <td scope="col" class="min-width-250">{{ record.meta.paymentDBRecord.custname }}</td>
                    <td scope="col" class="min-width-150">{{ record.logistics_person }}</td>
                    <td scope="col" class="min-width-120">{{ record.meta.paymentDBRecord.amount }}</td>
                    <td scope="col" class="min-width-120">{{ record.to_pay }}</td>
                    <td scope="col" class="min-width-250">
                        <button class="btn btn-xs btn-primary mr-2" data-toggle="tooltip"
                                title="Re-sync the details by barcode"
                                (click)="reSyncRow(record)">
                            <span *ngIf="form.reSyncing === record.id">...</span>
                            <i *ngIf="form.reSyncing !== record.id" class="zmdi zmdi-refresh"></i>
                        </button>

                        <button class="btn btn-xs btn-secondary mr-2" data-toggle="tooltip"
                                title="Update barcode and resync"
                                (click)="updateBarcode(record)">
                            <span *ngIf="form.changingBarcode === record.id">...</span>
                            <i *ngIf="form.changingBarcode !== record.id" class="zmdi zmdi-code"></i>
                        </button>

                        <button class="btn btn-xs btn-success mr-2" data-toggle="tooltip"
                                title="verify that this payment has been deposited"
                                *ngIf="record.payment_method === 'cash' && record.db_sync_status === 'success' && record.labmate_status === 'success'"
                                (click)="openRecieptRemarkModal(record)">
                            {{ form.verifying === record.id ? '...' : 'Verify' }}
                        </button>

                        <button class="btn btn-xs btn-warning mr-2" data-toggle="tooltip"
                                title="Mark this record as paid in labmate"
                                *ngIf="record.payment_method === 'cash' && record.db_sync_status === 'success' && record.job_status !== 'failed' && record.bdm_status === 'success' && record.labmate_status === 'success'"
                                (click)="markReceipt(record)">
                            {{ form.markingReceipt === record.id ? '...' : 'Receipt' }}
                        </button>

                        <button class="btn btn-xs btn-danger mr-2" data-toggle="tooltip"
                                title="Mark this record as paid in labmate"
                                *ngIf="record.db_sync_status === 'success' && record.job_status === 'failed' && record.labmate_status === 'pending'"
                                (click)="markReceiptConfirmation(record)">
                            {{ form.markingReceipt === record.id ? '...' : 'Receipt' }}
                        </button>


                    </td>
                    <td scope="col" class="min-width-150">{{ record.meta.paymentDBRecord.bank_deopsit }}</td>
                    <td scope="col" class="min-width-150">{{ record.meta.paymentDBRecord.clientType}}</td>
                    <td scope="col" class="min-width-150">{{ record.meta.paymentDBRecord.hq}}</td>
                    <td scope="col" class="min-width-120">{{ record.payment_method }}</td>
                    <td scope="col" class="min-width-160">{{ record.meta.paymentDBRecord.collector_mobile}}</td>
                    <td scope="col" class="min-width-160">{{ record.meta.paymentDBRecord.collection_date}}</td>
                    <td scope="col" class="min-width-150">{{ record.meta.paymentDBRecord.deposited_on}}</td>
                    <td scope="col" class="min-width-150">{{ record.meta.paymentDBRecord.deposite_id}}</td>
                    <td scope="col" class="min-width-200">{{ record.meta.paymentDBRecord.transaction_no}}</td>
                    <td scope="col" class="min-width-150">
                        <app-status-icon [status]="record.labmate_status"></app-status-icon>
                    </td>
                    <td scope="col" class="min-width-150">
                        <app-status-icon [status]="record.job_status"></app-status-icon>
                    </td>
                    <td scope="col" class="min-width-150">
                        <app-status-icon [status]="record.db_sync_status"></app-status-icon>
                    </td>
                    <td scope="col" class="min-width-150">{{ record.patient_id || '-' }}</td>
                    <td scope="col" class="min-width-200">{{ record.toPay || '-'}}</td>
                    <td scope="col" class="min-width-200">{{ record.transaction_detail_status || '-'}}</td>
                    <td scope="col" class="min-width-200">{{ record.system_remarks }}</td>
                    <td scope="col" class="min-width-200">{{ record['meta']['paymentDBRecord']['labmateReceiptNo'] || 'Not passed yet'}}</td>
                </tr>
                <tr *ngIf="showTotal">
                    <td scope="col" colspan="2" class="bold">Total Amount : </td>
                    <td scope="col" colspan="21" class="bold">{{ totalRecordsAmount }}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</div>
<div class="modal fill-width" id="receipt-remark-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content" >
            <div class="modal-header">
                <h5 class="modal-title fill-width">
                    Please fill in the remarks to be used in generating the labmate reciept/</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeReceiptRemarkModal()">
                </button>
            </div>

            <div class="modal-body">
                <div class="vertical flex">
                    <div><label for="receipt-marker">Marker (max. 35 characters)</label></div>
                    <div><textarea name="receipt-marker" id="receipt-marker" cols="30" rows="3" maxlength="50" [(ngModel)]='receiptRemarks' placeholder="Type here (max.35 characters)" class="border border-secondary" maxlength="35"></textarea></div>
                    <div class="horizontal-flex">
                        <button class="btn btn-primary" (click)="verifyPaymentDeposited()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
