<div class="horizontal-flex space-around-center" >
    <input type="number" class="form-control smaller auto-size form-control-sm" [(ngModel)]="tatNumber"
        min="1"
        max="999"
        step="1"
        [name]="'tat-value-' + index + '-' + tatType"
        (change)="valueChanged()"
        onclick="this.select()"
        placeholder="Value">
    <select class="custom-select form-control-sm"
        (change)="valueChanged()"
        [name]="'tat-unit-' + index + '-' + tatType"
        [(ngModel)]="tatUnit">
        <option [value]="'hours'">Hours</option>
        <option [value]="'days'">Days</option>
    </select>
</div>