import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RestService} from '../rest.service';
import {UtilityService} from '../utility.service';
import * as $ from 'jquery';
import {ActivatedRoute} from "@angular/router";
import Swal from "sweetalert2";
import * as es6printJS from "print-js"

@Component({
  selector: 'app-print-dashboard',
  templateUrl: './print-dashboard.component.html',
  styleUrls: ['./print-dashboard.component.styl']
})
export class PrintDashboardComponent implements OnInit {

    labs = [];
    labTypes = [];
    labLocation = "";
    labType = "";
    refreshingListing = false;
  customers = [];

  showingPatients = false;
  customer = {};
  sample = {};
  showingSample = false;
  isReportLoaded = true;
  printingConfirmedSuccessfully = false;
  confirmingSampleId = "";
  sampleTrackingId = "";
    bulkReportPrintLimit = 0;

  isReportLoadedInterval = null;
  shouldPrintImmediately = false;

  printedReports: string[] = [];
    allowedTestcodesForPrintingCommandCenterReports: string[] = [];

    isPrintPendingView = false;

    @ViewChild('iFrame', {static: true}) iFrame: ElementRef;
    downloading: boolean = false;

  constructor(public _util: UtilityService, public _rest: RestService, private _ActivatedRoute: ActivatedRoute) { }

  ngOnInit() {

    if ( ! this._util.canUser(this._util.Constants.Permissions.SeePrintDueReports))
      return this._util.redirectAccordingly();

    this.isPrintPendingView = window.location.href.indexOf('printing-pending') > -1;
    // this.labLocation = this._ActivatedRoute.snapshot.paramMap.get('location') || "";

    this.refreshListing();
    this.getLabs();
  }

  async showPatients(customer) {
      if ( this.showingPatients) {
          this.showingPatients = false;
          return;
      }

      this.customer = customer;
      this.showingPatients = true;
  }

  async getLabs() {
      const resp = await this._rest.getAllLabs();

      if ( ! resp['success'] && resp['message'])
          return this._util.showAlert("Server resp", resp['message']);

      this.labs = resp['locations'];
      this.labTypes = resp['types'];
  }

  async previewReport(sample, shouldPrintImmediately = false) {
      this.sample = sample;


      if ( this.iFrame.nativeElement.contentWindow.unload )
          this.iFrame.nativeElement.contentWindow.unload();

      this.iFrame.nativeElement.src = this.getPreviewUrl(this.sample);
      this.isReportLoaded = false;
      this.shouldPrintImmediately = shouldPrintImmediately;

      if ( this.isReportLoadedInterval !== null ) {
          clearInterval(this.isReportLoadedInterval);
          this.isReportLoadedInterval = null;
      }

      this.isReportLoadedInterval = setInterval(() => {
          const isLoaded = this.iFrame.nativeElement.contentWindow.isLoaded
              && this.iFrame.nativeElement.contentWindow.isLoaded();

          if ( ! isLoaded) return false;

          clearInterval(this.isReportLoadedInterval);
          this.isReportLoadedInterval = null;
          this.isReportLoaded = true;

          if ( ! this.shouldPrintImmediately)
              return false;    

          this.hideModal();
          if(this.isSamplePreviewAble(this.sample)) this.printNow();
      }, 500);

      this.showModal();
      this.isSamplePreviewAble(this.sample);
  }

  getPreviewUrl(sample) {
      return window.location.origin + "/api/reports/view?"
          + `testcode=${sample.test.testcode}&barcode=${sample.barcode}&patient=${sample.patient.limp_patient_id}`
          + `&headers=without&token=${this._util.getToken()}`;
  }

    printNow() {
        this.iFrame.nativeElement.contentWindow.print();

        this.logPrintReport();
    }

    isSamplePreviewAble(sample) {
      if ( ! sample || ! sample.test || ! sample.test.testcode) return false;

      if ( sample && sample.isConvertedToAstria) return false;

      return !! this.allowedTestcodesForPrintingCommandCenterReports
          .filter(testcode => sample.test.testcode.indexOf(testcode) > -1 )
          .length;
    }

    isSamplePrinted(sampleBarcode) {
      return this.printedReports.indexOf(sampleBarcode) > -1;
    }

    openAttachment($event, attachment) {
      this.logPrintReport();
      this._util.openAttachmentInNewTab($event, attachment);
    }

    logPrintReport() {
        this.printedReports.push(this.sample['barcode']);

      this._rest.logPrintReport(this.sample["barcode"], this._util.getTestcodeFromFullSample(this.sample))
          .then();
    }

    async confirmPrint() {
        if ( ! this.confirmingSampleId.length) return;

        const printStatusInfo = {
            'print_confirmed':true,
        };

        const testcodes = [];
        this.customers.forEach(customer => {
            (customer.samples || []).forEach(sample => {
                if ( this.confirmingSampleId === sample.barcode){
                    testcodes.push(this._util.getTestcodeFromFullSample(sample));
                }
            });
        });


        const resp = await this._rest.confirmReportPrinted(this.confirmingSampleId, testcodes, this.sampleTrackingId);
        if ( ! resp['success'] && resp['message']) return this._util.showAlert('Server resp: ', resp['message']);

        this.printingConfirmedSuccessfully = true;

        this.confirmingSampleId = "";
        setTimeout(() => {
            eval(`$("#confirm-print-input").focus()`)
        }, 1);

        setTimeout(() => {
            this.printingConfirmedSuccessfully = false;
            this.sampleTrackingId = "";
        }, 3000);
    }

  showModal() {
      this.showingSample = true;

      setTimeout(() => {
          eval('$("#report-preview-modal").modal("show")');
      }, 1);
  }

  hideModal() {
      this.showingSample = false;

      setTimeout(() => {
          eval('$("#report-preview-modal").modal("hide")');
      }, 100);
  }

  async refreshListing() {

      if ( this.refreshingListing) return false;

    console.log('refreshing listing ...');
    this.refreshingListing = true;
    this.showingPatients = false;

    const response = await this._rest.getPrintDueSamples(this.labLocation, this.labType);

    this.refreshingListing = false;
    if ( ! response['success'] && response['message'])
      return this._util.showAlert('Server resp', response['message']);

    if ( response['bulkReportPrintLimit'] && (response['bulkReportPrintLimit'] > 0))
        this.bulkReportPrintLimit = response['bulkReportPrintLimit'];

    this.customers = response['rows'];
      this.allowedTestcodesForPrintingCommandCenterReports = response["viewReportsPresentFor"] || [];
      const reportsPrintedByDb = response['reportsPrinted'] || [];
      const reportsPrintedByDbIdentifiers = reportsPrintedByDb.map(row => row.identifier);

      this.customers = this.customers.map(customer => {
          customer['samples'] = (customer.samples || []).filter(sample => {
              const sampleIdentifier = sample.barcode + '-' + this._util.getTestcodeFromFullSample(sample);

              if (this.isPrintPendingView) return reportsPrintedByDbIdentifiers.indexOf(sampleIdentifier) === -1;
              else return reportsPrintedByDbIdentifiers.indexOf(sampleIdentifier) > -1;
          });

          // attachmentsCount, missingAttachment
          customer["samplesMissingAttachment"] = customer['samples'].filter(sample => {
              return !(sample['attachments'] && sample['attachments'].length);
          }).map(row => row.barcode);

          customer["attachmentsCount"] = customer['samples'].map(sample => {
              return (sample.attachments || []).length
          }).reduce((previousValue, currentValue) => previousValue + currentValue, 0);

          return customer;
      }).filter(customer => customer.samples.length)
          .sort((a, b) => {
              a = a.samples.length;
              b = b.samples.length;

              if ( a === b) return 0;
              return a < b ? 1 : -1;
          });
  }

    async downloadTodayPrintedRecords() {
        if ( this.downloading) return;

        if ( ! this._util.canUser(this._util.Constants.Permissions.DownloadPrintedReportsLog))
            return;

        this.downloading = true;
        const resp = await this._rest.getTodaysPrintConfirmedRecords();

        this.downloading = false;
        if ( ! resp['success'] && resp['message'])
            return this._util.showAlert('Server resp', resp['message']);

        const samples = resp['samples'] || [];
        const date = (new Date);
        const filename = "todays-confirmed-print-reports-" + this._util.niceDateFormat(date) + ".csv";

        this.exportToCSV(samples, filename);
    }

    exportToCSV(rows, filename) {
        let processRow = function (row) {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile = '';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    async jointPrintAll() {
        console.log("jointPrintAll clicked: ");

        let attachmentIds = [];
        this.customers.forEach(customer => {
            customer.samples.forEach(sample => {
                if ( this.printedReports.indexOf(sample.barcode) > -1)
                    return;

                if(sample.payment_hold) //if payment hold is true
                    return;

                sample.attachments.forEach(attachment => {

                    if ( attachmentIds.length === this.bulkReportPrintLimit)
                        return;

                    if ( attachmentIds.indexOf(attachment.id) === -1)
                        attachmentIds.push(attachment.id);
                });
            });
        });

        if ( ! attachmentIds.length) return this._util.showAlert('No attachments to download.');

        const confirmResult = await Swal.fire({
            title: 'Joint print ' + attachmentIds.length +  ' files?',
            text: "You are about to initiate bulk print command for " + attachmentIds.length + " files, this might take some time on server.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, confirm!'
        });

        if ( ! confirmResult.isConfirmed) return;

        Swal.fire({
            title: 'Loading ...',
            showCancelButton: false,
            didOpen(popup: HTMLElement) {
                Swal.showLoading();
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(r => {}) ;

        const resp = await this._rest.bulkPrintAttachment(attachmentIds);

        if ( resp['erroredAttachments'] && resp['erroredAttachments'].length) {
            attachmentIds = attachmentIds.filter(id => resp['erroredAttachments'].indexOf(id) === -1);

            if ( ! attachmentIds.length) {
                Swal.close();
                return this._util.showAlert('Reports cannot be bulk printed, please do 1 by 1.');
            }
        }

        Swal.close();

        if ( ! resp['success']) return this._util.showAlert("Server resp", resp['message']);

        es6printJS({
            printable: resp['combined'],
            type: 'pdf', base64: true,
            onPrintDialogClose: () => {
                this.logPrintedAttachments(attachmentIds);
            }
        });
    }

    async logPrintedAttachments(attachmentIds = []) {
      const resp = await this._rest.logPrintedAttachments(attachmentIds);

      if ( ! resp['success']) return this._util.showAlert('Server resp', resp['message']);

      if ( resp['barcodes'] && resp['barcodes'].length)
          for ( let i = 0; i < resp['barcodes'].length; i ++ ) {

              this.printedReports.push(resp['barcodes'][i]);
          }

      if ( resp['barcodeTestcodeArray'] && resp['barcodeTestcodeArray'].length)
          for ( let i = 0; i < resp['barcodeTestcodeArray'].length; i ++ ) {

              this.printedReports.push(resp['barcodeTestcodeArray'][i].barcode);
          }
    }
}
