<div class="container-fluid max-height vertical-scroll">
    <div class="row p-2">
        <div class="col-md-8 horizontal-flex space-between-center">
            <h2>BDM Raised patch requests
                <span *ngIf="editing && currentRequest.id">#{{ currentRequest.id }}</span>
            </h2>

            <button class="btn btn-basic" *ngIf="loading">Loading ...</button>

            <nav aria-label="Page navigation example" [hidden]="loading || editing" *ngIf="patchRequests.length">
                <ul class="pagination">
                    <li class="page-item " [ngClass]="{'disabled': pagination.page === 1}">
                        <a class="page-link" aria-label="Previous" (click)="loadListing(pagination.page - 1)">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>

                    <li class="page-item" [ngClass]="{'active': pagination.page === i}" *ngFor="let i of _util.getPagesRange(pagination.page, pagination.lastPage)">
                        <a class="page-link" (click)="loadListing(i)">{{ i }}</a>
                    </li>

                    <li class="page-item" [ngClass]="{'disabled': pagination.page === pagination.lastPage}">
                        <a class="page-link" aria-label="Next" (click)="loadListing(pagination.page + 1)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="col-md-4 horizontal-flex end-center">
            <button class="btn btn-secondary mr-1" *ngIf="isOperator" [hidden]="loading || editing || showingDownloadOperatorReport" (click)="showingDownloadOperatorReport = true">
                Download report
            </button>

            <button class="btn btn-secondary mr-1" [hidden]="loading || editing" (click)="_util.redirectTo('/dashboard')">Return to dashboard</button>
            <button class="btn btn-primary mr-1" [hidden]="loading || editing" (click)="refreshListing()">Refresh</button>
        </div>

        <div class="col-md-12 horizontal-flex start-center mt-2" [hidden]="loading || editing">
            Pending requests: &nbsp;&nbsp;
            <span class="bold red">Customers {{ stats.pending.customers }}</span>
            &nbsp;|&nbsp;
            <span class="bold yellow">Doctors {{ stats.pending.doctors }}</span>
            &nbsp;|&nbsp;
            <span class="bold green">Unlink-doctor {{ stats.pending.unlinkDoctor }}</span>
        </div>

        <div class="col-md-12 horizontal-flex start-center mt-2" [hidden]="loading || editing">
            Filter by: &nbsp;&nbsp;&nbsp;

            <select class="custom-select form-control max-width-196 mr-2" [(ngModel)]="filter.hoName"
                    name="filter-ho-name">
                <option value="">HO Name</option>

                <option [value]="location.id" *ngFor="let location of uniqueHOLocations">
                    {{ location.name }}
                </option>
            </select>

            <select class="custom-select form-control max-width-196 mr-2" [(ngModel)]="filter.requestType"
                    name="filter-request-type">
                <option value="">Request type</option>

                <option value="customer">Customer</option>
                <option value="doctor">Doctor</option>
                <option value="un-link">Unlink</option>
            </select>

            <select class="custom-select form-control max-width-196 mr-2" [(ngModel)]="filter.requestedBy"
                    name="filter-requested-by">
                <option value="">Requested by</option>

                <option *ngFor="let user of uniqueUsers" [value]="user['user_id']">
                    {{ user.name }}
                </option>
            </select>

            <div class="form-group mb-0 mr-2">
                <input type="text" class="form-control max-width-196"
                       [(ngModel)]="filter.customerDoctorName"
                       id="filter-entity-name" name="filter-entity-name"
                       placeholder="Customer / doctor name">
            </div>


            <button class="btn btn-primary" (click)="applyFilters()">Filter</button>
        </div>

        <div class="col-md-12 horizontal-flex end-center" *ngIf="isOperator && showingDownloadOperatorReport">
            <div class="horizontal-flex start-center" >
                <form>
                    <div class="form-row">
                        <div class="col">
                            <input type="date" class="form-control form-control-sm" name="start-date"
                                   [(ngModel)]="operatorRangedSheetFrom"
                                   placeholder="Start date*">
                        </div>
                        <div class="col">
                            <input type="date" class="form-control form-control-sm" name="end-date"
                                   [(ngModel)]="operatorRangedSheetTo"
                                   placeholder="End date (optional)">
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-primary btn-sm"
                                    (click)="downloadOperatorRangedSheet()"
                            >Download sheet</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="flex-filler"></div>

            <p>Logged in as: <strong>{{ _util.loggedInUserName() }}</strong></p>
        </div>
    </div>

    <div class="row" *ngIf="editing">
        <div class="col-md-12">

            <div class="alert alert-warning" role="alert" *ngIf="currentRequest && (currentRequest.status === 'stage-3') && ShouldShowNonLabmateFieldsCheckbox">
                <div class="form-group form-check mt-2 mb-0 mr-2"
                     (click)="toggleNonLabmateFieldCheckbox($event)">
                    <input type="checkbox" class="form-check-input" id="non-labmate-field-checkbox" [checked]="NonLabmateFieldsCheckboxChecked" >
                    <label class="form-check-label" for="non-labmate-field-checkbox"
                           (click)="toggleNonLabmateFieldCheckbox($event)"><strong>I have copied all Non labmate field values.</strong></label>
                </div>
            </div>

            <div class="horizontal-flex start-center mb-2">
                <button type="button" class="btn mr-2 btn-primary" (click)="acceptSelected()" *ngIf="acceptedColumns.length">Accept selected</button>
                <button type="button" class="btn mr-2 btn-success" (click)="acceptRequest()" [hidden]="rejectedColumns.length">Accept full patch request</button>
                <button type="button" class="btn mr-2 btn-warning" (click)="acceptRemaining()" *ngIf="rejectedColumns.length">Accept remaining</button>
                <button type="button" class="btn mr-2 btn-danger" (click)="rejectAll()" [hidden]="acceptedColumns.length">Delete patch request</button>
                <button type="button" class="btn mr-2 btn-secondary" (click)="goBackToListing()">Go back</button>
            </div>

            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Labels</th>
                    <th scope="col">LIMS copy</th>
                    <th scope="col">{{ _util.getUserFullname(currentRequest.user) }}'s copy</th>
                    <th scope="col" *ngIf="! isSalesExecutive">Actions</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngIf="updatedLabmateColumns.length">
                    <th scope="row" colspan="5">
                        <strong class="ml-4 pl-4">Labmate columns</strong>
                    </th>
                </tr>
                <tr *ngFor="let field of updatedLabmateColumns; index as i"
                    [ngClass]="{'table-success': acceptedColumns.indexOf(field) > -1, 'table-danger': rejectedColumns.indexOf(field) > -1}">
                    <th scope="row">{{ i + 1 }}.</th>
                    <td>{{ FieldLabels[field] }}</td>
                    <td>{{ getValueForField(field, false) }} </td>
                    <td class="pre-wrapped-white-space">{{ getValueForField(field, true) }}</td>
                    <td *ngIf="! isSalesExecutive">
                        <button type="button" class="btn btn-xs btn-success mr-2" (click)="acceptField(field)"
                                [hidden]="acceptedColumns.indexOf(field) > -1">
                            Accept</button>
                        <button type="button" class="btn btn-xs btn-danger" (click)="rejectField(field)"
                                [hidden]="rejectedColumns.indexOf(field) > -1">
                            Reject</button>
                    </td>
                </tr>

                <tr *ngIf="updatedNonLabmateColumns.length">
                    <th scope="row" colspan="5">
                        <strong class="ml-4 pl-4">Non-Labmate columns</strong>
                    </th>
                </tr>
                <tr *ngFor="let field of updatedNonLabmateColumns; index as i"
                    [ngClass]="{'table-success': acceptedColumns.indexOf(field) > -1, 'table-danger': rejectedColumns.indexOf(field) > -1}">
                    <th scope="row">{{ i + 1 }}.</th>
                    <td>{{ FieldLabels[field] }}</td>
                    <td>{{ getValueForField(field, false) }} </td>
                    <td>{{ getValueForField(field, true) }}</td>
                    <td *ngIf="! isSalesExecutive">
                        <button type="button" class="btn btn-xs btn-success mr-2" (click)="acceptField(field)" [hidden]="acceptedColumns.indexOf(field) > -1">Accept</button>
                        <button type="button" class="btn btn-xs btn-danger" (click)="rejectField(field)" [hidden]="rejectedColumns.indexOf(field) > -1">Reject</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row p-2" *ngIf="! patchRequests.length && ! loading">
        <div class="col-md-12">
            <div class="alert alert-primary fill-width" role="alert">
                All patch requests are completed. Hurray !!!
            </div>
        </div>
    </div>

    <div class="row p-2" *ngIf="loading">
        <div class="col-md-12 mt-4" >
            <div class="loader"></div>
        </div>
    </div>

    <div class="row" [hidden]="editing" *ngIf="patchRequests && patchRequests.length">
        <div class="col-md-12">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" (click)="changeSortedColumn('id')">
                        Req. no.

                        <i class="zmdi zmdi-caret-down" *ngIf="sortedColumn === 'id' && sortedColumnReverse"></i>
                        <i class="zmdi zmdi-caret-up" *ngIf="(sortedColumn === 'id') && ! sortedColumnReverse"></i>
                    </th>
                    <th scope="col" (click)="changeSortedColumn('type')">
                        Type

                        <i class="zmdi zmdi-caret-down" *ngIf="sortedColumn === 'type' && sortedColumnReverse"></i>
                        <i class="zmdi zmdi-caret-up" *ngIf="(sortedColumn === 'type') && ! sortedColumnReverse"></i>
                    </th>
                    <th scope="col" (click)="changeSortedColumn('identifier')">
                        Customer name

                        <i class="zmdi zmdi-caret-down" *ngIf="sortedColumn === 'identifier' && sortedColumnReverse"></i>
                        <i class="zmdi zmdi-caret-up" *ngIf="(sortedColumn === 'identifier') && ! sortedColumnReverse"></i>
                    </th>
                    <th scope="col" (click)="changeSortedColumn('updated_at')">
                        Status

                        <i class="zmdi zmdi-caret-down" *ngIf="sortedColumn === 'updated_at' && sortedColumnReverse"></i>
                        <i class="zmdi zmdi-caret-up" *ngIf="(sortedColumn === 'updated_at') && ! sortedColumnReverse"></i>
                    </th>
                    <th scope="col" (click)="changeSortedColumn('created_at')">
                        Created at

                        <i class="zmdi zmdi-caret-down" *ngIf="sortedColumn === 'created_at' && sortedColumnReverse"></i>
                        <i class="zmdi zmdi-caret-up" *ngIf="(sortedColumn === 'created_at') && ! sortedColumnReverse"></i>
                    </th>
                    <th scope="col">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let request of patchRequests">
                    <th scope="row">{{ request.id }}</th>
                    <td>{{ request.type + ' (' + request.identifier + ')' }}</td>
                    <td>{{ request.orig.pname }}</td>
                    <td>
                        {{ request.status }} <br>
                        {{ request.status === "stage-1" ? "Raised by: " : "Approved by: " }}
                        {{ _util.getUserFullname(request.user) }}
                    </td>
                    <td>{{ _util.showDateLocally(request.updated_at) }} <br>
                        {{ request.creator ? "By " + _util.getUserFullname(request.creator) : "" }}
                    </td>
                    <td>
                        <button type="button" [hidden]="! request.orig" class="btn btn-primary btn-xs" (click)="viewFullRequest(request)">View full</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
