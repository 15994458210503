<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <img src="/assets/images/lilac-logo.png" class="big-logo">
    </h5>
    <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
    <button type="button" class="btn btn-outline-danger"
        (click)="_util.logout()">{{ _util.logoutButtonText ||  "Logout" }}</button>
</div>


<div class="container pb-3">
    <div class="row">
        <div class="col-md-12 horizontal-flex start-center">
            <div class="input-group mb-1 mr-2 max-width-328">
                <select class="custom-select" (change)="resetPaginationAndSearch()" [(ngModel)]="filter.labType">
                    <option selected value="">Filter by department type</option>
                    <option *ngFor="let labType of labTypes" [value]="labType.id">{{ labType.name }}</option>
                </select>
            </div>

            <div class="input-group mb-1 max-width-328">
                <input type="search" class="form-control" placeholder="Search by testname / testcode"
                       aria-label="Search by testname / testcode" aria-describedby="button-search-button"
                       [(ngModel)]="filter.query"
                       (keyup)="resetPaginationAndSearch()"
                >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" id="button-search-button"
                            *ngIf="filter.query.length"
                            (click)="clearSearch()"
                    >&times;</button>
                </div>
            </div>

            <div class="flex-filler"></div>

            <button type="button" class="btn mr-2" [class.btn-outline-secondary]="! filter.comboOnly" [class.btn-secondary]="filter.comboOnly"
                    [hidden]="showingChildrenLayout || showingCreateTestLayout || filter.query.length"
                    (click)="showComboOnly()">
                Combo only
            </button>
            <button type="button" class="btn btn-secondary" (click)="backToListing()" *ngIf="showingChildrenLayout || showingCreateTestLayout">Back to listing</button>

            <nav aria-label="Page navigation example" *ngIf="pagination.max > 1" [hidden]="showingChildrenLayout || showingCreateTestLayout">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" aria-label="Previous" (change)="loadTestPage(pagination.current - 1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    <li class="page-item" [ngClass]="{'active': pagination.current === i}" *ngFor="let i of _util.getPagesRange(pagination.current, pagination.max)">
                        <a class="page-link" (click)="loadTestPage(i)">{{ i }}</a>
                    </li>

                    <li class="page-item">
                        <a class="page-link" aria-label="Next" (change)="loadTestPage(pagination.current + 1)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>

        </div>

        <div class="col-md-12">
            <div class="table-responsive table--no-card m-b-30">
                <table class="table table-borderless table-striped table-earning">
                    <thead>
                        <tr>
                            <th>Department</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Min Tat</th>
                            <th>Max Tat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let test of tests; let i = index" [hidden]="showingChildrenLayout && openTest.id !== test.id">
                            <td class="p-2 pl-3">{{ test.type }}</td>
                            <td class="p-2">
                                {{ test.code }}
                                <i class="zmdi zmdi-info ml-1 red" *ngIf="isChildType(test.code)"
                                   data-toggle="tooltip" data-placement="top"
                                   title="Test is a Temp Testcode and doesn't exists on Labmate DB"></i>
                            </td>
                            <td class="p-2">{{ test.name }}</td>

                            <td class="p-2"><tat-input [value]="test.minimum_tat" [index]="i" [tatType]="'min'" (tatChanged)="tatChanged($event, i, 'min')"></tat-input></td>
                            <td class="p-2"><tat-input [value]="test.maximum_tat" [index]="i" [tatType]="'max'" (tatChanged)="tatChanged($event, i, 'max')"></tat-input></td>

                            <td class="p-2">
                                <button type="button" class="btn btn-primary btn-sm mr-2" *ngIf="test.haveChanges"
                                    (click)="saveChanges(i)">{{ test.saveButtonText || "save" }}</button>

                                <button type="button" class="btn btn-sm" (click)="showChildren(test)"
                                    [class.btn-primary]="test['is_combo']" [class.btn-light]="! test['is_combo']"
                                >
                                    <i class="zmdi zmdi-device-hub"></i>
                                </button>


                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-md-12 " *ngIf="showingChildrenLayout || showingCreateTestLayout">
            <div class="horizontal-flex">
                <div class="vertical-flex flex-fill mr-3">
                    <h3 class="mb-2">Manage {{ openTest.name }}'s children</h3>

                    <div class="input-group mb-3">
                        <input type="text" class="form-control"
                            [(ngModel)]="testNameFilterQuery"
                            name="test-name-filter-query"
                            (keyup)="searchChildren()"
                            placeholder="Search by testname / testcode"
                            aria-label="Search by testname / testcode" aria-describedby="button-addon-search-button">
                        <div class="input-group-append">
                        <button class="btn btn-outline-primary"
                            (click)="searchChildren()"
                            type="button" id="button-addon-search-button">Search</button>
                        </div>
                    </div>

                    <ul class="list-group" *ngIf="searchedTests.length">
                        <li class="list-group-item horizontal-flex space-between-center" *ngFor="let test of searchedTests">
                            <strong>({{ test.testcode }})</strong>&nbsp;{{ test.name }}

                            <div class="flex-fill"></div>

                            <div class="button btn-sm btn-success clickable"
                                (click)="addChildren(test)"
                            >Add</div>
                        </li>
                    </ul>

                    <div class="alert alert-primary" role="alert" *ngIf="testNameFilterQuery && ! searchedTests.length">
                        No search results found for '{{ testNameFilterQuery }}' in LocalDB.
                    </div>

                    <div class="alert alert-success mt-2" role="alert" *ngIf="testNameFilterQuery">
                        Not matching? You can create new Test <div class="btn btn-primary btn-sm" (click)="showCreateTestLayout()">here</div>.
                    </div>
                </div>


                <div class="vertical-flex flex-fill ml-3" *ngIf="showingCreateTestLayout">
                    <h3 class="mb-2">Create new test for {{ openTest.name }}</h3>

                    <form (ngSubmit)="submitCreateTestForm()">
                        <div class="form-group">
                            <label class="mb-0" for="create-test--test-group">Test group</label>
                            <select class="form-control"
                                name="create-test--test-group"
                                id="create-test--test-group" required
                                aria-describedby="create-test--test-group--help"
                                [(ngModel)]="createTestForm.lab_type_id">
                                <option *ngFor="let labType of labTypes"
                                    [value]="labType.id"
                                    [selected]="(labType.id + '') === (createTestForm.lab_type_id + '')">
                                        {{ labType.name }}</option>
                            </select>
                            <small id="create-test--test-group--help" class="form-text text-muted">This can be different than combo</small>
                        </div>

                        <div class="form-group">
                            <label class="mb-0" for="create-test--test-code">Test code</label>
                            <input type="text" required class="form-control" id="create-test--test-code"
                                [(ngModel)]="createTestForm.testcode"
                                name="create-test--test-code" aria-describedby="create-test--test-code--help">
                            <small id="create-test--test-code--help" class="form-text text-muted">This can be same as combo, no problem.</small>
                        </div>

                        <div class="form-group">
                            <label class="mb-0" for="create-test--test-name">Test name</label>
                            <input type="text" required class="form-control" id="create-test--test-name"
                                [(ngModel)]="createTestForm.name"
                                name="create-test--test-name" aria-describedby="create-test--test-name--help">
                            <small id="create-test--test-name--help" class="form-text text-muted">Please make sure, it doesn't exist in system before.</small>
                        </div>

                        <div class="form-group">
                            <label class="mb-0" for="create-test--minimum-tat">Minimum TAT</label>

                            <tat-input [value]="createTestForm.minimum_tat" id="create-test--minimum-tat" [index]="create-1" [tatType]="'min'" (tatChanged)="tatChanged($event, i, 'min')"></tat-input>
                        </div>

                        <div class="form-group">
                            <label class="mb-0" for="create-test--maximum-tat">Maximum TAT</label>
                            <tat-input [value]="createTestForm.maximum_tat" id="create-test--maximum-tat" [index]="create-1" [tatType]="'max'" (tatChanged)="tatChanged($event, i, 'max')"></tat-input>
                        </div>

                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>

            <table class="table table-borderless table-striped table-earning" [hidden]="showingCreateTestLayout">
                <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Department</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Min Tat</th>
                    <th>Max Tat</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let child of openTest['children']; let j = index" [hidden]="! showingChildrenLayout || testNameFilterQuery.length">
                    <td class="p-2 pl-5">{{ j + 1 }}</td>
                    <td class="p-2">{{ getLabTypeLabel(child.lab_type_id) }}</td>
                    <td class="p-2">{{ child['testcode'] }}</td>
                    <td class="p-2">{{ child.name }}</td>

                    <td class="p-2"><tat-input [value]="child.minimum_tat" [index]="j + openTest['id']" [tatType]="'min'" (tatChanged)="tatChanged($event, openTest['id'] + '-child-' + j, 'min')"></tat-input></td>
                    <td class="p-2"><tat-input [value]="child.maximum_tat" [index]="j + openTest['id']" [tatType]="'max'" (tatChanged)="tatChanged($event, openTest['id'] + '-child-' + j, 'max')"></tat-input></td>

                    <td class="p-2">

                        <button type="button" class="btn btn-primary btn-sm mr-2" *ngIf="child['haveChanges']"
                                (click)="saveChildChanges(child)">{{ child['saveButtonText'] || "save" }}</button>

                        <button type="button" class="btn btn-secondary btn-sm" (click)="detachChild(child.id)">Remove</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
