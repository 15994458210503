import { Component, OnInit, NgZone } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import {TestDetailRowComponent} from "../test-detail-row/test-detail-row.component";
import {TestDetailTableComponent} from "../test-detail-table/test-detail-table.component";
import {TestSearchFilterComponent} from "../test-search-filter/test-search-filter.component";
import Swal from 'sweetalert2';
import {EventService} from '../event.service';


@Component({
  selector: 'app-customer-approval-dashboard',
  templateUrl: './customer-approval-dashboard.component.html',
  styleUrls: ['./customer-approval-dashboard.component.styl']
})
export class CustomerApprovalDashboardComponent implements OnInit {
  objectKeys = Object.keys;
  showingSearchInput = false;
  userName = '';
  newCustomerList = [];
  currentCustomer = {};
  showCustomerDetail = false;
  isRpRole = false;
  isMasterRole = false;
  byPassMasterRole = false;
  customerDataLabels = [];
  testDataLabels = [];
  bdmDataLabels = [];
  currentCustomerTestList = [];
  filteredCustomerList = [];
  bDMCustomerFormFields = [];
  testList = [];
  testsSearchString = "";
  searchDelayTimeout = 0;
  remainingTestList = [];
  testsFilterString = "";
  customerTestData = [];
  testDropdownLabels = {};
  rejectionReason = '';
  customerSearchString = '';
  customerIsApprovedOrRejected = false;
  customersPageNo = 1;
  customersLastPage = 1;
  customersQuery = "";
  filterColumn = "";
  filterString = "";
  filterDropdown = {
    "customer_name":"Customer Name",
    "contact_name":"Contact Name",
    "contact_email":"Contact Email",
    "stage":"Stage",
    "bdm_executive":"BDM Name",
    "hq":"HQ", 
  }


  constructor(
    private _rest: RestService,
    public _util: UtilityService,
    public _event: EventService,
    public _zone: NgZone) { }

  ngOnInit(): void {
    //this._util.redirectAccordingly();
    this.getNewCustomerDataForApproval().then(() => { });

    this.getBDMCustomerFormFields();

    this.getTestList();

    this._event.tableSearch_searchStringEmitter.subscribe((event) => {
            this.searchStringChange(event);
            this._zone.run(() => {});
        });
    this._event.tableSearch_filterStringEmitter.subscribe((event) => {
            this.filterStringChange(event);
            this._zone.run(() => {});
        });

    const userInfo = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));

    if(userInfo['permissions'].indexOf(this._util.Constants.Permissions.ApproveNewCustomer) > -1) this.isRpRole = true;
    if(userInfo['permissions'].indexOf(this._util.Constants.Permissions.VerifyNewCustomer) > -1) this.isMasterRole = true;
    this.userName  = (userInfo || {}).username
  }

  async downloadCustomerDetails(customerId){
    const response = await this._rest.downloadCustomerDetails(customerId);
    const a = document.createElement("a");
        let bin = atob(decodeURIComponent(response['data']));
        let ab = this._util.s2ab(bin);
        let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        let object_URL = window.URL.createObjectURL(blob);
        a.href = object_URL;
        a.download = 'customer-data' + "_" + this._util.showDateLocally(this._util.getNow()) + ".xlsx";
        a.click();
  }

  async downloadCustomerData(){
    const response = await this._rest.downloadCustomerData();
    if(response['success']){
        const a = document.createElement("a");
        let bin = atob(decodeURIComponent(response['data']));
        let ab = this._util.s2ab(bin);
        let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        let object_URL = window.URL.createObjectURL(blob);
        a.href = object_URL;
        a.download = 'customer-data' + "_" + this._util.showDateLocally(this._util.getNow()) + ".xlsx";
        a.click();
      }
    else if(!response['success']){
      Swal.fire('Error in download', response['message'], 'error');
    }

      
    /*if(response && response.success){
      Swal.fire('Customer Data downloaded Successfully', 'The requested data has been downloaded successfully', 'success');
    }*/
  }

  customerFilterStringChange(){
    if(! this.filterString.length) this.resetCustomerFilter();
    switch(this.filterColumn){
      case 'customer_name':
        this.filteredCustomerList = this.newCustomerList.filter((element) => {
          return element['customer_name'].toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        });
        break;

      case 'contact_email':
        this.filteredCustomerList = this.newCustomerList.filter((element) => {
          return element['contact_email'].toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        });
        break;

      case 'contact_name':
        this.filteredCustomerList = this.newCustomerList.filter((element) => {
          return element['contact_name'].toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        });
        break;

      case 'stage':
        this.filteredCustomerList = this.newCustomerList.filter((element) => {
          return element['stage'].toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        });
        break;

      case 'bdm_executive':
        this.filteredCustomerList = this.newCustomerList.filter((element) => {
          return (element['firstname'] + ' ' + element['lastname']).toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        });
        break;

      case 'hq':
        this.filteredCustomerList = this.newCustomerList.filter((element) => {
          return (element['customer_data']['marketing-location']).toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        });
        break;

      default:
        break;
    }
  }

  loadPageWithPrevQuery(newpageno) {
    this.customersPageNo = newpageno;
    this.getNewCustomerDataForApproval();
  }

  getApprovalDate(meta , type){
    if(typeof meta !== 'object' || meta === null)
      return '';

    if(type === 'rp'){
      if(meta['rpApprovalTimeStamp']){
        return this._util.dateObjIndianToHumanReadable(meta['rpApprovalTimeStamp'], 'date');
      }
    }
    if(type === 'master'){
      if(meta['masterApprovalTimeStamp']){
        return this._util.dateObjIndianToHumanReadable(meta['masterApprovalTimeStamp'], 'date');
      }
    }

    return '';
  }

  resetCustomerFilter(){
    this.filterString = '';
    this.filterColumn = '';
    this.filteredCustomerList = this.newCustomerList.sort((n1, n2) => this.getExactPendingDuration(n2) - this.getExactPendingDuration(n1));
  }

  async getNewCustomerDataForApproval(){
    let newBDMCustomerResponse = await this._rest.getNewBDMCustomersData();

    this.newCustomerList = newBDMCustomerResponse['newCustomersListForApproval']['data'] || [];

    if(newBDMCustomerResponse['byPassMasterRole']) this.byPassMasterRole = true;

    this.filteredCustomerList = this.newCustomerList;

    if(this.isRpRole){
      this.filteredCustomerList = this.newCustomerList.filter(function(element){
        return ['Approved By Master', 'Rejected By Master'].indexOf(element['stage']) === -1;
      })
    }

    if(this.isMasterRole){
      this.filteredCustomerList = this.newCustomerList.filter(function(element){
        return ['Rejected By RP'].indexOf(element['stage']) === -1;   
      })
    }

    this.filteredCustomerList = this.filteredCustomerList.sort((n1, n2) => this.getExactPendingDuration(n2) - this.getExactPendingDuration(n1));

  }

  
  async getTestList(){

    const testResponse = await this._rest.getProfileTestCodes({'query':'select * from test where Profile = 1'});

    this.testList = testResponse['tests'];
    this.testDropdownLabels = testResponse['departmentTestLabels'];
  }

  async getBDMCustomerFormFields(){
    let formFieldResponse = await this._rest.getBDMMOUAppFormFields();

    this.bDMCustomerFormFields = formFieldResponse['fields'] || [];

    this.bDMCustomerFormFields.filter(row => row.presentIn.indexOf('BDMApp') !== -1);

  }

  async showCustomerDetails(customer){
    this.currentCustomer = customer;
    const approvalRejectionStates = []; 
    if(this.isMasterRole){
      approvalRejectionStates.push('Rejected By Master', 'Approved By Master');
    }
    if(this.isRpRole){
      approvalRejectionStates.push('Rejected By RP', 'Approved By RP');
    }

    if(approvalRejectionStates.indexOf(this.currentCustomer['stage']) > -1){
      this.customerIsApprovedOrRejected = true;
    }

    this.filterTests();
    if(this.currentCustomer['tests_data'])
      this.currentCustomerTestList = this.currentCustomer['tests_data']['tests'];
    this.customerDataLabels = Object.keys(this.currentCustomer['customer_data']);
    this.showCustomerDetail = true;
  }


  filterTests(){
    let selectedTests = [];


    if(this.currentCustomer['tests_data']){
      selectedTests = (this.currentCustomer['tests_data']['tests']).map(element => element.Gcode + element.Scode + element.TestCode);
      this.customerTestData = this.currentCustomer['tests_data']['tests'];
    }

    this.remainingTestList = this.testList.filter(function(element){
      return selectedTests.indexOf(element.Gcode + element.Scode + element.TestCode) === -1;
    });
  }

  discountPercentage(type, test){
        switch(type){
            case 'standard':
                return (((test.MRP - test.StdPrice)/test.MRP) * 100).toFixed(2);

            case 'final':
                return (((test.MRP - test.FinalPrice)/test.MRP) * 100).toFixed(2); 
        }
    }

  formatDetailLabel(dataLabel){
    let unformattedLabelArray = dataLabel.split('-');
    let labelArray = [];

    unformattedLabelArray.forEach(function(element){
      element = element.charAt(0).toUpperCase() + element.slice(1);
      if(element === 'State Of Institute') element = 'Status Of Institute';
      labelArray.push(element);
    });

    let formattedLabel = (labelArray.length > 1) ? labelArray.join(" ") : labelArray[0];

    return formattedLabel;
  }

  formatCustomerDetailValue(value, label){
    let selectedField = this.bDMCustomerFormFields.find(function(element){return element.identifier === label});

    if(selectedField){
      if(selectedField.suffix){
        value = value + " " + selectedField.suffix; 
      }
      else if(selectedField.prefix){
        value = selectedField.prefix + " " + value;
      }
    }

    if(label === 'clinic-timings'){
      let formattedValue = [];
      if(typeof value === 'object'){
        value.forEach(function(element){
          formattedValue.push(element.from + ' - ' + element.to); 
        })  
        value = formattedValue.join(', ');
      }
    }

    return value;
  }

  closeCustomerDetail(){
    this.showCustomerDetail = false;
    this.customerIsApprovedOrRejected = false;
    this.currentCustomerTestList.length = 0;
  }

  getPendingDurationForCustomer(customer){
    let currentDate = new Date().getTime();
    let customerCreationDate = new Date(customer['created_at']).getTime();
    if(customer['stage'].indexOf('Approved') > -1 || customer['stage'].indexOf('Rejected') > -1) return "";
    return this._util.calcDateDiff(currentDate, customerCreationDate);
  }

  getExactPendingDuration(customer){
    let currentDate = new Date().getTime();
    let customerCreationDate = new Date(customer['created_at']).getTime();
    if(customer['stage'].indexOf('Approved') > -1 || customer['stage'].indexOf('Rejected') > -1) return 0;
    return currentDate - customerCreationDate;
  }


  searchStringChange(event) {
        this.testsSearchString = event;
        console.log("search string changed: ", this.testsSearchString);

        if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

        this.searchDelayTimeout = setTimeout(() => {

            if(this.testsSearchString.charAt(0) === 'G'){
                this.filterByTestCode('search');
            }
            else{
                this.filterByTestName();
            }

        }, 250);
    }

    filterStringChange(event){
        this.testsFilterString = event;
        console.log("filter string changed: ", this.testsFilterString);
        if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

        this.searchDelayTimeout = setTimeout(() => {

            this.filterByTestCode('filter');

        }, 250);
    }

    filterByTestCode(action = 'search'){
        let selectedTests = [];
        let filter = (action === 'search') ? this.testsSearchString : this.testsFilterString;
        selectedTests = this.currentCustomer['tests_data']['tests'].map(element => element.Gcode + element.Scode + element.TestCode);

        this.remainingTestList = this.testList.filter(function(element){
          if(selectedTests.indexOf(element.Gcode + element.Scode + element.TestCode) === -1 && (element.Gcode + element.Scode + element.TestCode).indexOf(filter) > -1) return element;
        })
    }

    filterByTestName(){
        let selectedTests = [];
        let filter = this.testsSearchString;
        selectedTests = this.currentCustomer['tests_data']['tests'].map(element => element.Gcode + element.Scode + element.TestCode);

        this.remainingTestList = this.testList.filter(function(element){
          if(selectedTests.indexOf(element.Gcode + element.Scode + element.TestCode) === -1 && element.Description.toUpperCase().indexOf(filter.toUpperCase()) > -1) return element;
        });
        
    }


    resetSearchAndFilterString() {
        this.testsSearchString = '';
        this.testsFilterString = '';
        let selectedTests = [];
        selectedTests = this.currentCustomer['tests_data']['tests'].map(element => element.Gcode + element.Scode + element.TestCode);
        this.remainingTestList = this.testList.filter(function(element){
            return selectedTests.indexOf(element) === -1;
        });
    }

    returnToDashboard() {
      this._util.redirectTo('jarvis-home');
    }

    showRejectionModal(){
      this._util.modal('rejection-reason-modal', 'show');
    }

    closeRejectionModal(){
      this.rejectionReason = "";
      this._util.modal('rejection-reason-modal', 'hide');
    }

    async rejectCustomerApplication() {
      if(!this.rejectionReason.length){
        Swal.fire('Rejection Reason not mentioned', 'Please fill in the Rejection Reason before proceeding', 'error');
      }
      console.log('rejecting');
      const rejectionResponse = await this._rest.rejectBDMCustomer(this.rejectionReason, this.currentCustomer);
        console.log('rejectionResponse:', rejectionResponse);
        this.customerIsApprovedOrRejected = true;
        this._util.modal('rejection-reason-modal', 'hide');
        this.showCustomerDetail = false;
    }

    async approveCustomerApplication() {
      let mouLink = window.location.origin + '/preview-mou';
      if(this.isMasterRole && this.currentCustomer['stage'] !== 'Approved By RP'){
        return Swal.fire('Not Allowed', 'This application is yet to be approved by RP', 'error');
      }

      await Swal.fire({
            title: `Approving Customer ..`,
            text: 'should not take much time',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,

            didOpen: async () => {
                Swal.showLoading();
                
                await this._rest.approveCustomerAppilcation(this.currentCustomer, mouLink).then((response) => {
                  Swal.close();
                  this.showCustomerDetail = false;
                  this.customerIsApprovedOrRejected = true;
                })
                
            }
        });
    }

    editCustomer(){
      if(['Approved', 'Finalized Signed Mou'].indexOf(this.currentCustomer['stage']) == -1){
        window.location.href = this._util.redirectToWithQuery('customer-creation','customerId', this.currentCustomer['id']);
      }
    }

}
