<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
      File name format: patientid_sampleid_testcode_%.(ext)

      <div class="horizontal-flex">
        <div class="form-check mr-4">
          <input class="form-check-input" [checked]="shouldSendEmail" 
            type="checkbox" [(ngModel)]="shouldSendEmail" id="defaultCheck1" name="shouldSendEmail">
          <label class="form-check-label mt-1" for="defaultCheck1">
            Send E-mail
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" [checked]="shouldSendSms" 
            type="checkbox" [(ngModel)]="shouldSendSms" id="defaultCheck2" name="shouldSendSms">
          <label class="form-check-label mt-1" for="defaultCheck2">
            Send SMS
          </label>
        </div>
      </div>
    </h5>
    <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
    <button type="button" class="btn btn-outline-danger"
      (click)="_util.logout()"
    >{{ _util.logoutButtonText ||  "Logout" }}</button>  
</div>

<div class="container p-4">
  <div class="horizontal-flex">
    <ngx-dropzone (change)="selectedAttachmentForMail($event, true)" class="attachments flex-fill mr-2 " >

      <ngx-dropzone-label>Drop <strong>With</strong> header reports</ngx-dropzone-label>

        <ngx-dropzone-preview *ngFor="let f of reportFilesWithHeader" [removable]="false">
            <ngx-dropzone-label>{{ f.overrideFilename || f.name }}<br /> (uploading ...)</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>

    <ngx-dropzone (change)="selectedAttachmentForMail($event, false)" class="attachments flex-fill ml-2">

      <ngx-dropzone-label>Drop <strong>With-out</strong> header reports</ngx-dropzone-label>

        <ngx-dropzone-preview *ngFor="let f of reportFilesWithoutHeader" [removable]="false">
            <ngx-dropzone-label>{{ f.overrideFilename || f.name }}<br /> (uploading ...)</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>
</div>

<div class="container p-4">
    <div class="alert alert-info fill-width" role="alert" *ngIf="! mailingQueue.length">
      Nothing in queue
    </div>

    <div class="table-responsive" *ngIf="mailingQueue.length">
      <table class="table">
        <thead>
          <tr>
            <th>Patient</th>
            <th>Test</th>
            <th>Status</th>
            <th>Attachments</th>
            <th style="max-width: 72px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-shadow" *ngFor="let row of mailingQueue.slice().reverse()">
            <td>{{ row.info ? row.info.patientName : "" }} <br> ( {{ row.patient }} ) </td>
            <td>{{ row.info ? row.info.testName : "" }} <br> ( {{ row.testcode }} )</td>
            <td>{{ row.status || "" }} 
              <br *ngIf="row.status === 'Failed'">
              {{ row.reason || "" }}
            </td>
            <td class="horizontal-flex start center">
              <div class="attachment" *ngFor="let attachment of row.attachments"
                  (click)="openAttachmentInNewTab($event, attachment)"
              >
                  <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                  <div class="remove"
                      *ngIf="! row.deleted"
                      (click)="removeMailingInfoAttachment($event, attachment)"
                  >
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </div>
              </div>
            </td>

            <td>
              <button type="button" class="btn btn-outline-primary ml-2"
                  *ngIf="! row.deleted"
                  (click)="row.deleted = true">
                  <i class="zmdi zmdi-delete"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>