<div class="container pb-3">
    <div class="row">

        <div class="col-md-12">
            <div class="alert alert-danger m-2" *ngIf="fullWidthError && fullWidthError.length" role="alert">
                fullWidthError
            </div>
        </div>

        <div class="col-md-3" *ngIf="(matchedReportTypesRow && matchedReportTypesRow['readOnly']) || ! sample['id']">

            <a class="btn btn-outline-secondary mb-2 mt-2 fill-width" href="/print-report">Go back</a>

            <div class="alert alert-warning" role="alert"
                 *ngIf="matchedReportTypesRow && matchedReportTypesRow['readOnly']">
                No actions allowed on this report yet.
            </div>

            <div class="alert alert-danger" role="alert"
                 *ngIf="! sample['id']"
            >
                <strong>Incorrect Barcode or Testcode</strong>, <br>
                either confirm the barcode & testcode or contact IT Team.
            </div>

            <button class="btn btn-secondary mb-2 mt-2 fill-width" (click)="showPCPNDTForm()"
                    *ngIf="matchedReportTypesRow && matchedReportTypesRow['havePCPNDTForm']"
            >Show PC-PNDT Form
            </button>
        </div>

        <div class="col-md-3"
             [class.hidden]="(matchedReportTypesRow && matchedReportTypesRow['readOnly']) || ! sample['id']">
            <a class="btn btn-outline-secondary mb-2 mt-2 fill-width" href="/print-report">Go back</a>

            <div class="btn btn-block mt-1 mb-1 btn-outline-danger" (click)="backToEditing()"
                 *ngIf="matchedReportTemplate"
            >Back to editing
            </div>

            <div class="alert alert-warning" role="alert" *ngIf="sample && sample['payment_hold']">
                Report on hold due to pending payment
            </div>

            <div class="alert alert-warning" role="alert"
                 *ngIf="patResultVerified === false && (!matchedReportTypesRow || ! matchedReportTypesRow['ignoreResultNotVerified'])">
                Not all Test results are marked verified.
            </div>

            <div class="alert alert-primary" role="alert" *ngIf="infoMessage && infoMessage.length">
                {{ infoMessage }}
            </div>

            <input type="file" hidden (change)="fileSelectedToUpload($event)" id="biochemistry-email-attachment"
                   accept="application/pdf">

            <div class="alert mb-1" *ngIf="patCytoStatus && patCytoStatus.length && ! infoMessage.length"
                 [ngClass]="{'alert-success': patCytoStatus === 'authenticate', 'alert-danger': patCytoStatus === 'reject', 'alert-warning': patCytoStatus === 'hold'}">
                <p><span
                    class="bold">{{ _util.ucFirst(patCytoStatus) }}</span>{{ patCytoStatusLocal ? '(locally)' : ' ' }}
                    by <span
                        class="bold">{{ patCytoStatusBy }}</span> on <span
                        class="bold">{{ _util.showDateLocally(patCytoStatusDateTime, true) }}</span></p>
                <hr>
                <p> {{ patCytoStatusReason }} </p>
            </div>

            <div class="fill-width mb-2 btn btn-danger" (click)="unAuthenticateReport()"
                 *ngIf="patCytoStatus && patCytoStatus.length && _util.canUser(_util.Constants.Permissions.UnAuthenticateReport)">
                Un-Authenticate Report
            </div>

            <div class="form-group" *ngIf="patResultVerified"
                 [hidden]="matchedReportTypesRow && matchedReportTypesRow['hideSuggestionsAndNotes']">
                <label for="pat-cyto-suggestions" class="fill-width">Suggestions:
                    <small [class]="'float-right mt-1'">{{ patCytoSuggestion.length }} / 500</small>
                </label>
                <textarea class="form-control" maxlength="500"
                          [readOnly]="(patCytoStatus === 'authenticate') && ! canAuthenticateReports"
                          [(ngModel)]="patCytoSuggestion" (keyup)="checkIfNotesOrSuggestionsChanged()"
                          id="pat-cyto-suggestions" rows="3"></textarea>
            </div>

            <div class="form-group" *ngIf="patResultVerified"
                 [hidden]="matchedReportTypesRow && matchedReportTypesRow['hideSuggestionsAndNotes']">
                <label for="pat-cyto-notes" class="fill-width">Notes:
                    <small [class]="'float-right mt-1'">{{ patCytoNotes.length }} / 500</small>
                </label>
                <textarea class="form-control"
                          [readOnly]="(patCytoStatus === 'authenticate') && ! canAuthenticateReports" maxlength="500"
                          [(ngModel)]="patCytoNotes" (keyup)="checkIfNotesOrSuggestionsChanged()" id="pat-cyto-notes"
                          rows="3"></textarea>
            </div>

            <div class="fill-width mb-2 btn btn-success"
                 *ngIf="patCytoNotesChanged && patResultVerified && canAuthenticateReports"
                 (click)="saveNotesSuggestions()">
                Save
            </div>

            <div class="fill-width mb-2 btn btn-success" *ngIf="patResultVerified && canAuthenticateReports"
                 [hidden]="(patCytoStatus === 'authenticate') || patCytoNotesChanged" (click)="askForResultAndSubmit()">
                Authenticate
            </div>
            <div class="fill-width mb-2 btn btn-warning"
                 *ngIf="!patCytoAuthenticated && patResultVerified && canAuthenticateReports"
                 [hidden]="(patCytoStatus === 'hold') || patCytoNotesChanged" (click)="askForReasonAndSubmit('hold')">
                Hold
            </div>
            <div class="fill-width mb-2 btn btn-danger"
                 *ngIf="!patCytoAuthenticated && patResultVerified && canAuthenticateReports"
                 [hidden]="(patCytoStatus === 'reject') || patCytoNotesChanged"
                 (click)="askForReasonAndSubmit('reject')">Reject
            </div>

            <a class="fill-width mb-2 btn btn-secondary" *ngIf="trfDocument" target="_blank" href={{trfDocument}}>View
                TRF</a>

            <button class="fill-width mb-2 btn btn-info" *ngIf="showSendToGCExpert" (click)="beginSendingToGCExpert()">
                Send To GC Expert
            </button>

            <button class="fill-width mb-2 btn btn-primary" (click)="convertToAstria()"
                    *ngIf="isAstriaTestcode() && ! this.isConvertedToAstria && ! patCytoAuthenticated"
            >
                Convert to Astraia
            </button>

            <button class="fill-width mb-2 btn btn-warning" (click)="convertBackToNonAstria()"
                    *ngIf="isAstriaTestcode() && this.isConvertedToAstria && ! patCytoAuthenticated"
            >
                Revert back to non-Astraia
            </button>

            <button class="fill-width mb-2 btn btn-warning" (click)="putAmmendmentStamp()"
                    [disabled]="sample['amended_at']"
                    *ngIf="(patCytoStatus === 'authenticate') && _util.canUser(_util.Constants.Permissions.AuthenticateReports) && haveSignature">
                Put Ammended Stamp
            </button>

            <div class="fill-width mb-2 btn btn-outline-primary"
                 (click)="beginUploadFileWithPurpose('biochem-attachment')">
                {{ uploadButtonText || (uploadedFileUrl && uploadedFileUrl.length ? 'Change attached file' : 'Attach file') }}</div>

            <div class="fill-width mb-2 btn btn-outline-primary"
                 (click)="beginUploadFileWithPurpose('thal-report-embed')"
                 *ngIf="isThalTestcode()">
                {{ uploadedThalReportButtonText || (uploadedThalReportFileUrl && uploadedThalReportFileUrl.length ? 'Change THAL graph' : 'Import THAL graph') }}
            </div>

            <div class="fill-width mb-2 btn btn-outline-primary"
                 (click)="openModal('thal-report-values')"
                 *ngIf="isThalTestcode()">
                Update THAL report values
            </div>

            <!--<div class="fill-width mb-2 btn btn-outline-primary"
                 (click)="openModal('reticulocyte-report-values')"
                 *ngIf="isFlowCytometryTestcode() && flowCytometryTestType === 'Reticulocyte Count'">
                Update Interpretation
            </div>-->

            <div class="fill-width mb-2 btn btn-outline-primary"
                 (click)="openModal('cbc-report-values')"
                 *ngIf="isFlowCytometryTestcode() && flowCytometryTestType === 'CBC'">
                Update CBC report values
            </div>

            <button class="btn btn-secondary mb-2 mt-2 fill-width" (click)="showPCPNDTForm()"
                    *ngIf="matchedReportTypesRow && matchedReportTypesRow['havePCPNDTForm']"
            >Show PC-PNDT Form
            </button>

            <a class="fill-width mb-2 btn btn-link" target="_blank"
               [href]="uploadedFileUrl" *ngIf="uploadedFileUrl && uploadedFileUrl.length">
                View attached file</a>

            <div class="fill-width mb-2 btn btn-outline-secondary" (click)="refreshPage()"
                 *ngIf="patCytoAuthenticated === false">
                Refresh
            </div>


            <div class="fill-width mb-2 btn btn-primary" (click)="printReport()"
                 *ngIf="patCytoAuthenticated === true"
                 [disabled]="printing">Print
            </div>
            <div class="fill-width mb-2 btn btn-primary" (click)="openModal('gestational-age-modal')"
                 *ngIf="isSfltPlgfTestcode()">
                Change Gestational Age
            </div>
            <div class="fill-width mb-2 btn btn-secondary" (click)="mailReport()"
                 *ngIf="sample && !sample['payment_hold'] && patCytoAuthenticated === true && (customerAddress['emailWithLetterhead'] || customerAddress['emailWithoutLetterhead'])"
                 [disabled]="mailing">Email
            </div>
            <div class="fill-width mb-2 btn btn-secondary" (click)="printAndEmailReport()"
                 *ngIf="sample && !sample['payment_hold'] && patCytoAuthenticated === true && ((customerAddress['emailWithLetterhead'] || customerAddress['emailWithoutLetterhead']))"
                 [disabled]="mailing || printing">Print & Email
            </div>

            <div class="fill-width mb-2 btn btn-warning" (click)="sendPreviouslySharedReports()"
                 *ngIf="withoutHeaderReportAttachmentId && headerReportAttachmentId && ! this.isConvertedToAstria"
            >
                Send previous reports again
            </div>

            <div class="fill-width mb-2 btn btn-info" (click)="markReportReleased()"
                 *ngIf="sample['authenticated_at'] && !sample['released_at'] && !sample['payment_hold'] && _util.canUser(_util.Constants.Permissions.UnAuthenticateReport)"
            >
                Mark report released
            </div>

            <div class="fill-width mb-2 btn btn-info hidden" (click)="resyncCytoImages()"
                 *ngIf="isCytoTestcode()"
            >
                Resync Cyto Images
            </div>

            <div class="fill-width mb-2 btn btn-secondary" (click)="markPaymentDone()"
                 *ngIf="sample && sample['payment_hold'] && _util.canUser(_util.Constants.Permissions.ReleasePendingPayments) && patCytoAuthenticated === true && (customerAddress['emailWithLetterhead'] || customerAddress['emailWithoutLetterhead'])"
                 [disabled]="mailing">Release report
            </div>
        </div>
        <div class="col-md-9">
            <div class="for-reports-attachments mb-2" *ngIf="isConvertedToAstria">

                <h2 class="center-aligned">Attach non-portal reports here ..</h2>

                <div class="alert alert-danger" role="alert" *ngIf="missingIdInFiles">
                    Attachment is missing Sample / Patient ID
                </div>

                <div class="horizontal-flex mt-2">
                    <p class="flex-fill center-aligned">Header reports here ..</p>
                    <p class="flex-fill center-aligned">Non Header reports here ..</p>
                    <p class="flex-fill center-aligned"
                       *ngIf="matchedReportTypesRow && matchedReportTypesRow['haveSupportAttachments']">Support
                        attachments here ..</p>
                </div>
                <div class="horizontal-flex">
                    <ngx-dropzone (change)="selectedAttachmentForMail($event, 1)" class="attachments flex-fill mr-2 ">
                        <ngx-dropzone-preview *ngFor="let f of reportFilesWithHeader" [removable]="false">
                            <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                        <div class="attachment" *ngFor="let attachment of mailingInfo.attachmentWithHeader"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                            <div class="remove"
                                 (click)="removeMailingInfoAttachment($event, attachment)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>

                    <ngx-dropzone (change)="selectedAttachmentForMail($event, 0)" class="attachments flex-fill ml-2">
                        <ngx-dropzone-preview *ngFor="let f of reportFilesWithoutHeader" [removable]="false">
                            <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                        <div class="attachment" *ngFor="let attachment of mailingInfo.attachmentWithoutHeader"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                            <div class="remove"
                                 (click)="removeMailingInfoAttachment($event, attachment)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>

                    <ngx-dropzone *ngIf="matchedReportTypesRow && matchedReportTypesRow['haveSupportAttachments']"
                                  (change)="selectedAttachmentForMail($event, 2)" class="attachments flex-fill ml-2">
                        <ngx-dropzone-preview *ngFor="let f of supportFiles" [removable]="false">
                            <ngx-dropzone-label>{{ f.name }}<br/> (uploading ...)</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                        <div class="attachment" *ngFor="let attachment of mailingInfo.supportAttachments"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment.last_url))" alt="Icon">
                            <div class="remove"
                                 (click)="removeMailingInfoAttachment($event, attachment)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>
                </div>
            </div>

            <div [class.disabled-astria]="isConvertedToAstria" [class.disabled-cyto-process]="isCytoDisabledReport()"
                 class="flex-filler" style="position: relative">
                <iframe title="Report preview" class="fill-width min-height-100 " #iFrame></iframe>
            </div>
        </div>

    </div>

    <div class="modal" tabindex="-1" id="pdf-preview-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="horizontal-flex space-between-center mb-2">
                        <h3>{{ pdfPreviewTitle }}</h3>

                        <div class="btn-group" role="group" aria-label="Actions">
                            <button type="button" class="btn btn-primary" (click)="downloadPCPNDTForm()">
                                {{ this.showDownloadingStatus ? "Downloading ..." : "Download" }}</button>
                            <button type="button" class="btn btn-secondary" (click)="printWebViewer()">Print</button>
                            <button type="button" class="btn btn-outline-warning"
                                    (click)="_util.modal('pdf-preview-modal', 'hide')">Close
                            </button>
                        </div>
                    </div>

                    <div #pdfViewer class="pdf-viewer" [hidden]="!showPdfViewer"></div>

                    <iframe title="Web preview" class="fill-width min-height-100 " #webViewer
                            [hidden]="!showWebViewer"></iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="gestational-age-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="vertical-flex mb-2" *ngIf="!sfltPlgfTestValues.showConfirmationMessage">
                        <h2>Change Gestational Age</h2>
                        <p class="red"
                           *ngIf="sfltPlgfTestValues.updatedGestationalAgeWeeks < 20 || sfltPlgfTestValues.updatedGestationalAgeWeeks > 42">
                            Min. gestational age week count is - 20 and Maximum gestational age week count is - 42</p>
                        <p class="red"
                           *ngIf="sfltPlgfTestValues.updatedGestationalAgeDays < 0 || sfltPlgfTestValues.updatedGestationalAgeDays > 6">
                            Min. gestational age day count is - 0 and Maximum gestational age day count is - 6</p>
                        <div class="form-inline mt-2 mb-2">
                            <label for="gestational-age-modal--weeks">Gestational Age in Weeks :</label>
                            <input type="number" class="form-control" id="gestational-age-modal--weeks"
                                   [(ngModel)]="sfltPlgfTestValues.updatedGestationalAgeWeeks"
                                   placeholder="Enter gestational age week count" max="42" min="20">
                        </div>
                        <div class="form-inline mt-2">
                            <label for="gestational-age-modal--days">Gestational Age in Days :</label>
                            <input type="number" class="form-control" id="gestational-age-modal--days"
                                   [(ngModel)]="sfltPlgfTestValues.updatedGestationalAgeDays"
                                   placeholder="Enter gestational age day count" max="6" min="0">
                        </div>
                    </div>
                    <div class="vertical-flex mb-2" *ngIf="sfltPlgfTestValues.showConfirmationMessage">
                        <h2>Confirm Gestational Age</h2>
                        <p class="mt-1 bold">Please verify and confirm the following Gestational age</p>
                        <p class="mt-2 bold">Gestational Age in weeks
                            - {{sfltPlgfTestValues.updatedGestationalAgeWeeks}}</p>
                        <p class="mb-2 bold">Gestational Age in days
                            - {{sfltPlgfTestValues.updatedGestationalAgeDays}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmationPrompt()"
                            *ngIf="!sfltPlgfTestValues.showConfirmationMessage">Proceed
                    </button>
                    <button type="button" class="btn btn-primary" (click)="updateGestationalAge()"
                            *ngIf="sfltPlgfTestValues.showConfirmationMessage">Confirm
                    </button>
                    <button class="btn btn-secondary" (click)="closeModal('gestational-age-modal')">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="gc-expert-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="vertical-flex mb-2">
                        <h2>Remarks for GC Expert &nbsp;&nbsp;<small *ngIf="gcExpertState.loading"><i>(loading... )</i></small>
                        </h2>

                        <div class="form-group">
                            <label class=" mt-3 mb-0" for="gc-expert-to">E-mail TO</label>
                            <input type="text" class="form-control" placeholder="Email TO"
                                   [(ngModel)]="gcExpertState.mailVars['to']"
                                   name="gc-expert-to" id="gc-expert-to" aria-describedby="E-mail TO" readonly
                            >

                            <label class="mt-3 mb-0" for="gc-expert-cc">E-mail CC</label>
                            <textarea type="text" class="form-control " placeholder="E-mail CC"
                                      [(ngModel)]="gcExpertForm.ccEmail"
                                      (focus)="gcExpertState.activeAutocomplete = 'cc'"
                                      name="gc-expert-cc" id="gc-expert-cc" aria-describedby="E-mail CC">
                            </textarea>
                            <div class="mail-ids-container" *ngIf="gcExpertState.activeAutocomplete === 'cc'">
                                <span class="email-id"
                                      (click)="addThisEmail('ccEmail', email)"
                                      *ngFor="let email of this.getAutoCompleteEmails(gcExpertForm.ccEmail)">
                                    {{ email }}
                                </span>
                            </div>

                            <label class="mt-3 mb-0" for="gc-expert-bcc">E-mail BCC</label>
                            <textarea type="text" class="form-control " placeholder="E-mail BCC"
                                      [(ngModel)]="gcExpertForm.bccEmail"
                                      (focus)="gcExpertState.activeAutocomplete = 'bcc'"
                                      name="gc-expert-bcc" id="gc-expert-bcc" aria-describedby="E-mail BCC">
                            </textarea>
                            <div class="mail-ids-container" *ngIf="gcExpertState.activeAutocomplete === 'bcc'">
                                <span class="email-id"
                                      (click)="addThisEmail('bccEmail', email)"
                                      *ngFor="let email of this.getAutoCompleteEmails(gcExpertForm.bccEmail)">
                                    {{ email }}
                                </span>
                            </div>

                            <label class="mt-3 mb-0" for="gc-expert-remarks">Added remarks</label>
                            <textarea class="form-control " placeholder="Remarks" [(ngModel)]="gcExpertForm.remarks"
                                      name="gc-expert-remarks" id="gc-expert-remarks"
                                      aria-describedby="Remarks"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            (click)="sendGCEmail()">{{ gcExpertState.mailing ? 'Sending ...' : 'Send E-mail' }}</button>
                    <button class="btn btn-secondary" (click)="closeModal('gc-expert-modal')">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="thal-report-values" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">THAL Values</h2>

                    <form>
                        <div class="form-group horizontal-flex end-center">
                            <label for="hb-a0-value" class="mr-2 bold mt-1">HbA0</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.A0"
                                   name="hb-a0-value" id="hb-a0-value" aria-describedby="Hb A0 value">
                        </div>

                        <div class="form-group horizontal-flex end-center">
                            <label class="mr-2 bold mt-1">P2/HbA1c</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.P2"
                                   name="p2-value" id="p2-value" aria-describedby="Hb P2 value"
                                   *ngIf="(sample['meta'] || {})['thalGraphVariant'] === 'variant-2'">
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.A1c"
                                   name="p2-value" id="a1c-value" aria-describedby="Hb A1c value"
                                   *ngIf="(sample['meta'] || {})['thalGraphVariant'] === 'variant-1'">
                        </div>

                        <div class="form-group horizontal-flex end-center">
                            <label for="p3-value" class="mr-2 bold mt-1">P3</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.P3"
                                   name="p3-value" id="p3-value" aria-describedby="Hb P3 value">
                        </div>

                        <div class="form-group horizontal-flex end-center">
                            <label for="hb-a2-value" class="mr-2 bold mt-1">HbA2</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.A2"
                                   name="hb-a2-value" id="hb-a2-value" aria-describedby="Hb A2 value">
                        </div>

                        <div class="form-group horizontal-flex end-center">
                            <label for="hb-f-value" class="mr-2 bold mt-1">HbF</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.F"
                                   name="hb-f-value" id="hb-f-value" aria-describedby="Hb F value">
                        </div>

                        <div class="form-group horizontal-flex end-center">
                            <label for="hb-d-value" class="mr-2 bold mt-1">HbD</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.D"
                                   name="hb-d-value" id="hb-d-value" aria-describedby="Hb D value">
                        </div>

                        <div class="form-group horizontal-flex end-center">
                            <label for="hb-s-value" class="mr-2 bold mt-1">HbS</label>
                            <input type="text" class="form-control width-90" placeholder="Default: absent"
                                   [(ngModel)]="thalReportValue.S"
                                   name="hb-s-value" id="hb-s-value" aria-describedby="Hb S value">
                        </div>

                        <br>

                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" id="hb-fraction-present"
                                   name="hb-fraction-present"
                                   [(ngModel)]="thalReportValue.FractionPresent">
                            <label class="form-check-label" for="hb-fraction-present">HB Fraction present</label>
                        </div>

                        <div class="horizontal-flex start-center" *ngIf="thalReportValue.FractionPresent">
                            <input type="text" class="form-control mr-1" placeholder="Hb Fraction label"
                                   [(ngModel)]="thalReportValue.FractionLabel"
                                   name="hb-fraction-label" id="hb-fraction-label" aria-describedby="hb Fraction help">

                            <input type="text" class="form-control mr-1" placeholder="Observed value"
                                   [(ngModel)]="thalReportValue.FractionObserved"
                                   name="hb-fraction-observed" id="hb-fraction-observed"
                                   aria-describedby="hb Fraction observed value help">

                            <input type="text" class="form-control mr-1" placeholder="Expected value"
                                   [(ngModel)]="thalReportValue.FractionExpected"
                                   name="hb-fraction-expected" id="hb-fraction-expected"
                                   aria-describedby="hb Fraction expected value help">

                            <div class="form-group min-width-104 mb-0">
                                <select class="form-control" name="fraction-abnormal" id="fraction-abnormal"
                                        [(ngModel)]="thalReportValue.FractionAbnormal">
                                    <option value="0">Normal</option>
                                    <option value="1">Abnormal</option>
                                </select>
                            </div>
                        </div>

                        <br>
                        <div class="form-group horizontal-flex end-center">
                            <label for="blood-transfusion-value" class="mr-2 bold mt-1 min-width-300">Blood Transfusion
                                present</label>

                            <select class="custom-select" id="blood-transfusion-value" name="blood-transfusion-value"
                                    [(ngModel)]="thalReportValue.BloodTransfusion"
                                    aria-describedby="Blood transfusion value"
                            >
                                <option selected>Default: empty</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                        <br>

                        <div class="form-group">
                            <label for="hb-fraction-interpretation">Interpretation for this test</label>
                            <select class="form-control" name="hb-fraction-interpretation"
                                    id="hb-fraction-interpretation" [(ngModel)]="thalReportValue.InterpretationName"
                                    (change)="thalInterpretationChanged()"
                            >
                                <option value="">Select interpretation</option>
                                <option *ngFor="let option of thalInterpretations" [value]="option.name">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="thal-interpretation">Interpretations: </label>
                            <textarea class="form-control" id="thal-interpretation" name="thal-interpretation"
                                      [(ngModel)]="thalReportValue.InterpretationText" rows="3"></textarea>
                        </div>

                        <div class="form-group">
                            <label for="thal-suggestion">Suggestions: </label>
                            <textarea class="form-control" name="thal-suggestion" id="thal-suggestion"
                                      [(ngModel)]="thalReportValue.SuggestionText" rows="3"></textarea>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" area-label="submit"
                            (click)="fillThalReportValues()"
                    >
                        Save Values
                    </button>
                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="reticulocyte-report-values" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">Interpretation</h2>

                    <form>
                        <textarea name="reticulocyte-count-interpretation" id="reticulocyte-count-interpretation"
                                  rows="13" [(ngModel)]="reticulocyteCountTestReportValues['InterpretationText']"
                                  class="black-thin-border fill-width" maxlength=1000></textarea>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" area-label="submit"
                            (click)="fillReticulocyteCountReportValues()"
                    >
                        Save Values
                    </button>
                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="cbc-report-values" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">CBC report values</h2>

                    <form class="vertical flex">
                        <div class="vertical-flex fill-width">
                            <label for="rbc-text-value" class="bold">RBC</label>
                            <textarea rows="3" maxlength="250" [(ngModel)]="cbcTestReportValues['rbcText']"
                                      name="rbc-text-value" id="rbc-text-value" class="black-thin-border"></textarea>

                        </div>

                        <div class="vertical-flex fill-width">
                            <label class="bold" for="wbc-text-value">WBC</label>
                            <textarea class="black-thin-border" rows="3" maxlength="250"
                                      [(ngModel)]="cbcTestReportValues['wbcText']" name="wbc-text-value"
                                      id="wbc-text-value"></textarea>

                        </div>

                        <div class="vertical-flex fill-width">
                            <label class="bold" for="platelet-text-value">Platelets</label>
                            <textarea class="black-thin-border" rows="2" maxlength="180"
                                      [(ngModel)]="cbcTestReportValues['plateletsText']" name="platelet-text-value"
                                      id="platelet-text-value"></textarea>
                        </div>

                        <div class="vertical-flex fill-width">
                            <label class="bold" for="parasite-text-value">Parasites</label>
                            <textarea class="black-thin-border" rows="2" maxlength="180"
                                      [(ngModel)]="cbcTestReportValues['parasitesText']" name="parasite-text-value"
                                      id="parasite-text-value"></textarea>
                        </div>

                        <div class="vertical-flex fill-width">
                            <label class="bold" for="impression-text-value">Impression</label>
                            <textarea class="black-thin-border" rows="3" maxlength="250"
                                      [(ngModel)]="cbcTestReportValues['impressionText']" name="impression-text-value"
                                      id="impression-text-value"></textarea>
                        </div>

                        <div class="vertical-flex fill-width">
                            <label class="bold" for="advised-text-value">Advised</label>
                            <textarea class="black-thin-border" rows="3" maxlength="250"
                                      [(ngModel)]="cbcTestReportValues['advisedText']" name="advised-text-value"
                                      id="advised-text-value"></textarea>
                        </div>

                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" area-label="submit"
                            (click)="fillCBCReportValues()"
                    >
                        Save Values
                    </button>
                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <app-reports-session-manager [previewParams]="this.previewParams"></app-reports-session-manager>

</div>
