import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {TestDetailRowComponent} from "../test-detail-row/test-detail-row.component";
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import { SortPipe } from "../shared/pipes/sort.pipe";
import {EventService} from '../event.service';

@Component({
  selector: 'app-test-detail-table',
  templateUrl: './test-detail-table.component.html',
  styleUrls: ['./test-detail-table.component.styl']
})
export class TestDetailTableComponent implements OnInit {
  
  @Input('displayList') displayList: any[];
  @Input('editable') editable: boolean;
  @Input('showHeader') showHeader: boolean;
  @Input('showCheckInput') showCheckInput: boolean;
  @Input('tableType') tableType: string;
  
  constructor(private _rest: RestService, public _util: UtilityService, public _sortPipe: SortPipe, public _event: EventService) { }

  ngOnInit(): void {

    this._event.tableRow_selectedTestEmitter.subscribe((obj) => {

      if ( (obj.event === 'testDeselected' && (this.tableType === 'selected')) 
        || (obj.event === 'testSelected' && (this.tableType === 'remaining'))) 
        {console.log('recieved event:',obj); this.onSelectedTestListChange(obj.test);}
    });

    this._event.tableRow_valueChangeEmitter.subscribe((event) => {
      this.onValueChange(event)
    })

  }

  onSelectedTestListChange(event) {
    console.log('event',event);
    this._event.table_selectedTestEmitter.emit(event);
    //this.sortBy();
  }

  onValueChange(event){
    this._event.table_valueChangeEmitter.emit(event);
  }

  uniqueOutput() {
    console.log("onUniqueOutput: received;");
  }
}
