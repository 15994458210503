import { Component, OnInit } from '@angular/core';
import {UtilityService} from "../utility.service";
import {RestService} from "../rest.service";

@Component({
  selector: 'app-administration-controller',
  templateUrl: './administration-controller.component.html',
  styleUrls: ['./administration-controller.component.styl']
})
export class AdministrationControllerComponent implements OnInit {

    markingUnverifiedIdentifier = "";
    otpResetIdentifier = "";

  constructor( public _util: UtilityService, public _rest: RestService) { }

  ngOnInit(): void {

      if ( ! this._util.canUser(this._util.Constants.Permissions.ManageUsers))
          this._util.redirectAccordingly().then();
  }

    async markUnVerify() {
      if ( ! this.markingUnverifiedIdentifier.length) return;

      const resp = this._rest.markIdentifierUnverified(this.markingUnverifiedIdentifier);
      if ( ! resp['success'] && resp['message']) return this._util.showAlert('Server resp', resp['message']);

      this.markingUnverifiedIdentifier = "";
      return this._util.showAlert('Marked Un-verified successfully', '', 'success');
    }

    async resetOTPTimeout() {
      if  ( ! this.otpResetIdentifier.length) return;

      const resp = await this._rest.resetSalesOTP(this.otpResetIdentifier);
      if ( ! resp['success'] && resp['message']) return this._util.showAlert('Server resp', resp['message']);

      this.otpResetIdentifier = "";
        return this._util.showAlert("OTP reset successfull", '', 'success');

    }
}
