<div class="container-fluid pt-3 fill-height">
	<div class="row mt-3 mb-4">
		<div class="col-md-12">
			<div class="col-md-12 horizontal-flex vertical-flex-on-mobile align-start-on-mobile space-between-center font-size-12-on-mobile">

				<div class="horizontal-flex space-between-center fill-width m-1 vertical-margins-on-mobile">
				    <h3>Hi,&nbsp;{{ userName }}</h3>, &nbsp;
				</div>

				<select class="form-control mb-1 mr-1 font-size-12-on-mobile" [(ngModel)]="selectedBDMId" placeholder="Select BDM" (change)="bdmFilterChange()" *ngIf = "isRPRole">
							<option value="" selected>Select BDM</option>
                        	<option *ngFor="let bdm of bdmList" [value]="bdm['ABARID']">
                        		{{bdm['pname']}}
                        	</option>>
                    	</select>

                <select class="form-control mb-1 mr-1 font-size-12-on-mobile" [(ngModel)]="selectedCustomerId" placeholder="Select Customer" (change)="customerFilterChange()" [disabled]="!customerList.length">
                        	<option value="" selected>Select Customer</option>
                        	<option *ngFor="let customer of customerList" [value]="customer['pname']">
                        		{{customer['pname']}}
                        	</option>>
                    	</select>

                    	
                      	<div class="input-group-append" [hidden]="! selectedCustomerId.length && !selectedBDMId.length">
                            <button type="button" class="btn btn-danger" (click)="resetAllFilters()">
                                <i class="zmdi zmdi-close red"></i>
                            </button>
                        </div>

                <button type="button" class="btn btn-outline-primary ml-2 vertical-margins-on-mobile font-size-12-on-mobile unset-margin-left-on-mobile" (click)="returnToDashboard()" >
		        Back to Dashboard
		        </button>
			</div>
		</div>
		
		<div class="row fill-width mt-2">
			<div class="sufee-alert alert with-close alert-warning alert-dismissible fade show" role="alert"
            [hidden]="filteredOutstandingBalanceRecords.length" style="width: 100%;">
            	No records found !!
	            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
	                <span aria-hidden="true">&times;</span>
	            </button>
	        </div>
	        <div class="col-md-12 table-responsive" [hidden]="!filteredOutstandingBalanceRecords.length">
	        	<table class="table table-sm font-size-12-on-mobile">
	        		<thead>
	        			<tr>
	        				<th scope="col">
	        					Patient ID
	        				</th>
	        				<th scope="col">
	        					Patient Name
	        				</th>
	        				<th scope="col">
	        					Amount Due
	        				</th>
	        			</tr>
	        		</thead>
	        		<tbody>
	        			<tr *ngFor="let record of filteredOutstandingBalanceRecords">
	        				<td>{{record.limp_patient_id}}</td>
	        				<td>{{record.name}}</td>
	        				<td>₹ {{record.active_amount_due}}</td>
	        			</tr>
	        		</tbody>
	        	</table>
	        </div>
		</div>   
</div>