<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <img src="/assets/images/lilac-logo.png" class="big-logo">
    </h5>
    <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
    <button type="button" class="btn btn-outline-danger"
        (click)="_util.logout()">{{ _util.logoutButtonText ||  "Logout" }}</button>
</div>

<input type="file" id="signature-upload-container" accept="image/*"  hidden (change)="onSignatureUploaded($event)">


<div class="container pb-3">
    <div class="row">

        <div class="col-md-12 horizontal-flex end-center mb-2">

            <div class="flex-fill" *ngIf="message.text && message.text.length">
                <div class="alert mb-0 mr-2" [ngClass]="{'alert-success': message.type === 'success'}" role="alert">{{ message.text }}</div>
            </div>

            <select class="custom-select" name="filter-by-access"
                [(ngModel)]="filterByRole" style="width: 218px;">
                <option selected value="">Filter by role</option>+
                <option *ngFor="let role of roles" [value]="role.id" >
                    {{ role.label }}
                </option>
              </select>

            <button class="ml-2 btn btn-info" *ngIf="!managingTokens" (click)="managingTokens = true">Manage tokens</button>
            <button class="ml-2 btn btn-primary" *ngIf="! creatingUser" (click)="creatingUser = true">Create user</button>


            <button class="ml-2 btn btn-outline-primary" *ngIf="managingTokens" (click)="createToken()">Create Token</button>
            <button class="ml-2 btn btn-outline-warning" *ngIf="creatingUser || managingTokens" (click)="cancelCreatingUser()">Cancel</button>
        </div>

        <div class="col-md-12">

            <div class="card" *ngIf="creatingUser">
                <div class="card-header">
                    <strong>Create</strong> User
                </div>
                <div class="card-body card-block">
                    <form (submit)="createUser()">
                        <div class="form-group">
                            <input type="email" id="nf-email" required name="nf-email" placeholder="Enter Email.." [(ngModel)]="form.email"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" id="nf-name" required name="nf-name" placeholder="Enter Name.."
                                [(ngModel)]="form.name"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" id="nf-username" required name="nf-username" placeholder="Enter Username.."
                                [(ngModel)]="form.username"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <select class="custom-select" id="nf-role" [(ngModel)]="form.role" required name="nf-role">
                                <option [value]="'0'">Select role</option>
                                <option *ngFor="let role of roles" [value]="role.id">
                                    {{ role.label }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <input type="password" id="nf-password" required name="nf-password" placeholder="Enter Password.."
                                [(ngModel)]="form.password"
                                class="form-control">
                            <small class="help-block form-text">More than 8 characters alpha numeric with Uppercase, lowercase and Special characters</small>
                        </div>
                    </form>
                </div>

                <div class="card-footer">
                    <button type="submit" class="btn btn-primary btn-sm mr-2" (click)="createUser()">
                        <i class="fa fa-dot-circle-o"></i> {{ createButtonText || "Create" }}
                    </button>

                    <button type="reset" class="btn btn-danger btn-sm" (click)="cancelCreatingUser()">
                        <i class="fa fa-ban"></i> Cancel
                    </button>
                </div>
            </div>


            <div class="table-responsive table--no-card m-b-30" *ngIf="managingTokens">
                <table class="table table-borderless table-striped table-earning">
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Token</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let token of tokens; let i = index">
                            <td class="p-1">
                                {{ token.label }}
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="token.token" readonly
                                    placeholder="you can copy by ctrl + c" title="you can copy by ctrl + c">
                            </td>

                            <td class="p-1">
                                <button type="button" class="mr-1 btn btn-sm btn-success" *ngIf="! token.active"
                                    (click)="enableToken(i, true)">Enable token</button>
                                <button type="button" class="mr-1 btn btn-sm btn-warning" *ngIf="token.active"
                                    (click)="enableToken(i, false)">Disable token</button>

                                <button type="button" class="mr-1 btn btn-sm btn-danger"
                                    (click)="deleteToken(i)">Delete token</button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="table-responsive table--no-card m-b-30" *ngIf="!creatingUser && !managingTokens">
                <table class="table table-borderless table-striped table-earning">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Signature</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of filteredUsers(); let i = index">
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="user.name"
                                    (change)="user.haveChanges = true" placeholder="Name">
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="user.username"
                                    (change)="user.haveChanges = true" placeholder="username">
                            </td>
                            <td>
                                <input type="email" class="form-control" [(ngModel)]="user.email"
                                    (change)="user.haveChanges = true" placeholder="email">
                            </td>
                            <td>
                                <a [href]="user.signature" target="_blank" type="button" class="btn btn-light" *ngIf="user.signature">
                                    <i class="zmdi zmdi-link"></i>
                                </a>

                                <button *ngIf="isSuperAdmin(user) || isOperationsHOD(user) || isLabHOD(user)"  type="button" class="btn btn-light" (click)="uploadSignature(user)">
                                    Upload sign.
                                </button>

                            </td>
                            <td [ngClass]="{'bg-success': !user.disabled, 'bg-warning': user.disabled}">
                                <select class="custom-select" (change)="user.haveChanges = true"
                                    [disabled]="user.disabled" [(ngModel)]="user.role_id">
                                    <option *ngFor="let role of roles" [value]="role.id">
                                        {{ role.label }}
                                    </option>
                                </select>
                            </td>

                            <td>
                                <button type="button" class="btn btn-secondary" *ngIf="user.isSalesExecutive"
                                        (click)="askForceReSync(user)"
                                >
                                    {{ "Force Resync" }}
                                </button>

                                <button type="button" class="btn btn-primary" *ngIf="user.haveChanges"
                                    (click)="saveUser(user)">{{ user.saveButtonText || "save" }}</button>

                                <button type="button" class="btn btn-success" *ngIf="user.disabled"
                                    (click)="enableLogin(user)">Enable Login</button>
                                <button type="button" class="btn btn-warning" *ngIf="! user.disabled"
                                    (click)="disableLogin(user)">Disable Login</button>

                                <button type="button" class="btn btn-secondary" *ngIf="!user.haveChanges"
                                    (click)="updatePassword(user)">Change password</button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
