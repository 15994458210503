import { Component, Input, ViewChild, OnInit, HostListener } from '@angular/core';
import {EventService} from '../event.service';

@Component({
  selector: 'signature-pad',
  template: `
  <br/>
  <canvas #sigPad width="250" height="150" (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"></canvas>
  <br/>
  <button class="btn btn-primary mr-1" (click)="save()">save</button>
  <button class="btn btn-secondary ml-1" (click)="clear()">clear</button>
  <br/>
  <img [src]="img">
  <br/>
  <span>{{img}}</span>`,
  styles: [`
  canvas {
    border: 1px solid #000;
  }
  span {
    width: 300px;
    height: 200px;
  }
  `]
})
export class SignaturePadComponent implements OnInit {
  @Input() name: string;
  @ViewChild('sigPad') sigPad;
  sigPadElement;
  context;
  isDrawing = false;
  img;

  constructor(public _event: EventService){ }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#0c0c0d'; 
  }


  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  save() {
    this.img = this.sigPadElement.toDataURL("image/png");
    this._event.signaturePad_signEmitter.emit(this.img);
  }

}

