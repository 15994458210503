<div class="horizontal-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <img src="/assets/images/lilac-logo.png" class="big-logo">
    </h5>

    <div class="vertical-flex">
        <div class="horizontal-flex end-center">
            <a class="btn btn-outline-secondary mr-2" href="/dashboard">Return to Dashboard</a>
            <button type="button" class="btn btn-outline-danger"
                    (click)="_util.logout()">{{ _util.logoutButtonText ||  "Logout" }}</button>
        </div>

        <div class="horizontal-flex mt-2 end-center">
            <button type="button" class="btn btn-secondary mr-2"
                    *ngIf="_util.canUser(_util.Constants.Permissions.DownloadPrintedReportsLog)"
                    [disabled]="downloading"
                    (click)="downloadTodayPrintedRecords()"
            >
                {{ downloading ? "Querying ...": "Download today's records" }}
            </button>

            <select name="select" id="select-lab-location" class="form-control mr-2"
                    *ngIf="false" [(ngModel)]="labLocation" (change)="refreshListing()"
                    title="filter following based on lab locations">
                <option value="">Lab</option>
                <option *ngFor="let labLocation of this.labs" [value]="labLocation['id']">
                    {{ labLocation["name"]}}</option>
            </select>

            <select name="select" id="select-address-lab-location" class="form-control mr-2"
                    *ngIf="true" [(ngModel)]="labLocation" (change)="refreshListing()"
                    title="filter following based on address lab locations">
                <option value="">Address lab</option>
                <option value="100">Mumbai</option>
                <option value="101">Pune</option>
                <option value="120">Chandigarh</option>
                <option value="110">Bangalore</option>
                <option value="190">Kolkata</option>
            </select>

            <select name="select" id="select-lab-type" class="form-control mr-2"
                    *ngIf="this.labTypes" [(ngModel)]="labType" (change)="refreshListing()"
                    title="filter following based on lab Type">
                <option value="">Department</option>
                <option *ngFor="let labType of this.labTypes" [value]="labType['id']">
                    {{ labType["name"]}}</option>
            </select>

            <button type="button" class="btn btn-outline-primary"
                    [disabled]="refreshingListing"
                    (click)="refreshListing()">{{ refreshingListing ? "Loading ..." : "Refresh listing" }}</button>

        </div>

        <div class="horizontal-flex mt-2 end-center" style="min-width: 379px" [hidden]="isPrintPendingView">
            <div class="input-group">
                <input type="text" class="form-control mb-0" placeholder="Sample id, to confirm print"
                       [(ngModel)]="confirmingSampleId"
                       id="confirm-print-input" (keydown.enter)="confirmPrint()"
                       aria-label="Sample id, to confirm print" aria-describedby="confirmPrint">
                <input type="text" class="form-control mb-0" placeholder="Tracking Id (optional)"
                       [(ngModel)]="sampleTrackingId"
                       id="confirm-print-input-tracking-id" (keydown.enter)="confirmPrint()"
                       aria-label="Tracking Id(optional)" aria-describedby="confirmPrint">
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button" (click)="confirmPrint()" id="confirmPrint">Confirm</button>
                </div>
            </div>
        </div>

        <div class="horizontal-flex mt-2 end-center" style="min-height: 36px">
            <div class="alert alert-success pt-1 pb-1 mb-0" role="alert" [hidden]="! printingConfirmedSuccessfully">
                <i class="zmdi zmdi-check"></i> &nbsp;&nbsp;Marked 'reported' successfully !!
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">

    <div class="row">
        <div class="col-md-8 mb-4">
            <h1 *ngIf="isPrintPendingView">Samples waiting to be printed</h1>
            <h1 [hidden]="isPrintPendingView">Printed samples waiting for confirmation</h1>
        </div>

        <div class="col-md-4 mb-4 horizontal-flex end-center" *ngIf="isPrintPendingView">
            <button type="button" class="btn btn-primary" (click)="jointPrintAll()">Print first {{ bulkReportPrintLimit }} reports</button>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-info" role="alert" *ngIf="! customers.length">
                No pending samples found !!!
            </div>

            <table class="table table-sm" *ngIf="customers.length">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Customer (identifier)</th>
                    <th scope="col">Reports</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let row of customers; let i = index"
                      [hidden]="showingPatients && (customer['identifier'] !== row['identifier'])"
                  >
                    <th scope="row">{{ i + 1 }}</th>
                    <td width="90%">
                        {{ row.name }} ({{ row.identifier }})

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{ row.attachmentsCount }} <i class="zmdi zmdi-attachment"></i>

                        <span *ngIf="row.samplesMissingAttachment && row.samplesMissingAttachment.length">
                            &nbsp;&nbsp;
                            {{ row.samplesMissingAttachment && row.samplesMissingAttachment.length }}
                            <i class="zmdi zmdi-alert-triangle red"
                               title="Samples missing preconfigured report."></i>
                        </span>

                    </td>
                    <td width="100px" class="p-0"><button type="button" class="btn btn-link" (click)="showPatients(row)">
                        {{ row.samples && row.samples.length ? row.samples.length : 1 }}
                        <i class="zmdi zmdi-chevron-down" [hidden]="showingPatients"></i>
                        <i class="zmdi zmdi-chevron-up" [hidden]="! showingPatients"></i>
                    </button></td>
                  </tr>

                    <tr [hidden]="! showingPatients">
                        <td></td>
                        <td colspan="3">
                            <table class="table table-sm" style="background-color: transparent;">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Patient name (details)</th>
                                    <th scope="col">Patient ID</th>
                                    <th scope="col">Test</th>
                                    <th scope="col">Released at</th>
                                    <th scope="col">Preview</th>
                                    <th scope="col">Print</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr *ngFor="let sample of customer['samples']; let j = index"
                                    [ngClass]="this.printedReports.indexOf(sample.barcode) > -1 ? 'table-active' : ''">
                                    <td>{{ j + 1 }}</td>
                                    <td width="20%">{{ sample.patient.name }}</td>
                                    <td width="15%">{{ sample.patient.limp_patient_id }}</td>
                                    <td width="35%">{{ sample.test.name }}</td>
                                    <td width="20%">{{ _util.showDateLocally(sample.released_at) }}</td>
                                    <td class="p-0 pt-1" colspan="2" *ngIf="sample.payment_hold"><button type="button" class="btn btn-danger disabled btn-sm mr-1">Payment pending</button></td>

                                    <td class="p-0 pt-1" *ngIf="!sample.payment_hold"><button type="button" (click)="previewReport(sample)" class="btn btn-primary btn-sm mr-1">Preview</button></td>
                                    <td class="p-0 pt-1" *ngIf="!sample.payment_hold"><button type="button" (click)="previewReport(sample, true)" class="btn btn-secondary btn-sm">
                                        Print
                                    </button></td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>

<div class="modal fill-width" id="report-preview-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content" >
            <div class="modal-header">
                <h5 class="modal-title fill-width" *ngIf="sample && sample['patient']">
                    Previewing report: {{ sample["patient"].name}} ({{ sample["patient"]["limp_patient_id"] }})</h5>

                <button type="button" class="btn btn-light btn-sm p-0" [disabled]="! isReportLoaded" *ngIf="isSamplePreviewAble(this.sample)" [hidden]="isReportLoaded">
                    Loading ...</button>
                <button type="button" aria-label="Close" class="close" (click)="printNow()" [hidden]="! isReportLoaded">
                    <i class="zmdi zmdi-print"></i>
                </button>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="fill-width min-height-100 report-iframe" [hidden]="!! (isSamplePreviewAble(this.sample) && isReportLoaded)"></div>

                <iframe title="Report preview" class="fill-width min-height-100 report-iframe" #iFrame
                        [hidden]="! ( isSamplePreviewAble(this.sample) && isReportLoaded)"></iframe>

                <div class="attachments-container attachments horizontal-flex"
                     *ngIf="this.sample && this.sample['attachments'] && this.sample['attachments'].length && !this.isSamplePreviewAble(this.sample)">
                    <div class="attachment" *ngFor="let attachment of this.sample['attachments']; let i = index"
                         (click)="openAttachment($event, attachment)"
                    >
                        <img [src]="_util.imagePathForIcon(_util.matchIcon(attachment['last_url']))" alt="Icon">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
