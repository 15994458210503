import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import Swal from 'sweetalert2'
import * as $ from 'jquery';
import {JamsifyStatisticsChartsComponent} from '../jamsify-statistics-charts/jamsify-statistics-charts.component';
import {JamsifyRecordsStatisticsComponent} from '../jamsify-records-statistics/jamsify-records-statistics.component';


@Component({
  selector: 'app-jamsify-dashboard',
  templateUrl: './jamsify-dashboard.component.html',
  styleUrls: ['./jamsify-dashboard.component.styl']
})
export class JamsifyDashboardComponent implements OnInit {

  whiteListCustomers = [];
  blackListCustomers = [];
  blackListCustomerRecords = [];
  whiteListCustomerRecords = [];
  customersToBeBlacklist = [];
  whatsappSentCount = 0;
  whatsappOptInCount = 0;
  whatsappAttemptCount = 0;
  whatsappRecords = [];
  filteredWhatsappRecords = [];
  userName = "";
  recordsSearchString = "";
  searchDelayTimeout = null;
  recordsPageNo = 1;
  recordsLastPage = 1;
  lightAlertString = "";
  customerPartyTypeFilter = "";
  chartXAxisLabels = [];
  chartYAxisData = [];
  customersToBeBlacklisted = "";
  status = {
      fetchingLogs: false,
      fetchingCounts: false,
  }

  statisticsRange='daily';


  constructor(
    private _rest: RestService,
    public _util: UtilityService,
  ) { }

  ngOnInit(): void {
    this.fetchJamsifyCustomers().then(() => {});

    const userInfo = JSON.parse(this._util.getLocalData(this._util.Constants.Keys.user, '{}'));
    this.userName  = (userInfo || {}).username;
    console.log('username : ', this.userName);
  }

  async blacklistCustomer(){
    let customerList = this.customersToBeBlacklisted.split(/\r?\n/).join(',').split(',');

    customerList = customerList.filter((element, index, arr) => {
      return (arr.indexOf(element) === index) && (element.length > 4) && (element.indexOf('C') === 0);
    });

    const conversionResponse = await this._rest.convertJamsifyCustomer(customerList, 'blacklist', 'bulk');
    this.closeBlacklistModal();
    console.log('conversionResponse : ', conversionResponse);
  }

  async fetchJamsifyCustomers (){
     const response = await this._rest.fetchJamsifyCustomers();

     if ( ! response['success'] && response['message']) {
         return await this._util.showAlert("Server resp", response['message'], "error");
     }

     this.customersToBeBlacklisted = (response['jamsifyBlacklistCustomer']['value'] || '').split(',').join('\n');

     await this.fetchJamsifyWhatsappRecords();
     await this.fetchJamsfyStatistics();
  }

  loadPageWithPrevQuery(pageNo) {
    this.recordsPageNo = pageNo;
    this.fetchJamsifyWhatsappRecords().then(() => {});
  }

  openBlacklistModal(){
    this._util.modal('blacklist-customer-modal', 'show');
  }

  closeBlacklistModal(){
    this._util.modal('blacklist-customer-modal', 'hide');
  }

  async fetchJamsifyWhatsappRecords(){
      this.status.fetchingLogs = true;
    const response = await this._rest.fetchJamsifyWhatsappRecords(this.recordsPageNo);
    this.status.fetchingLogs = false;

    this.whatsappRecords = response['whatsappLogs']['data'];
    this.filteredWhatsappRecords = this.whatsappRecords;

    this.recordsPageNo = parseInt(response['whatsappLogs']['page']);
    this.recordsLastPage = parseInt(response['whatsappLogs']['lastPage']);
  }

  async fetchJamsfyStatistics() {


      this.status.fetchingCounts = true;
      const statisticsResponse = await this._rest.fetchJamsifyWhatsappLogStatistics(this.statisticsRange);
      this.status.fetchingCounts = false;

      this.whatsappAttemptCount = statisticsResponse['whatsappStatistics']['whatsappAttempts'];
      this.whatsappOptInCount = statisticsResponse['whatsappStatistics']['whatsappOptIns'];
      this.whatsappSentCount = statisticsResponse['whatsappStatistics']['whatsappSents'];
      this.chartXAxisLabels = statisticsResponse['whatsappStatistics']['xAxisLabels'];
      this.chartYAxisData = statisticsResponse['whatsappStatistics']['yAxisData'];
  }

  resetSearchString() {
    this.recordsSearchString = "";
    this.filteredWhatsappRecords = this.whatsappRecords;

    this.recordsPageNo = 1;
    this.customerPartyTypeFilter = "";
  }

  async convertJamsifyCustomer(customerId, action){
    const conversionResponse = await this._rest.convertJamsifyCustomer(customerId, action);
    console.log('conversionResponse : ', conversionResponse);
  }

  searchStringChange() {
    console.log("search string changed: ", this.recordsSearchString);

    this.filteredWhatsappRecords = this.whatsappRecords.filter((element) => {
      return element['barcode'].indexOf(this.recordsSearchString) > -1 || element['data']['patientId'].indexOf(this.recordsSearchString) > -1 || element['data']['customerId'].indexOf(this.recordsSearchString) > -1 || element['data']['patientMobile'].indexOf(this.recordsSearchString) > -1;
    })

    if ( this.searchDelayTimeout ) clearTimeout(this.searchDelayTimeout);

    this.searchDelayTimeout = setTimeout(() => {
      this.lightAlertString = "Searching ...";
    }, 250);
  }

    statusFromResp(data) {
      const resp = data['resp'] || data['optInResp'] || '{"response": {"status": ""}}';
      const json = JSON.parse(resp);

      return json.response.status;
    }

}
