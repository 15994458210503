<div class="page-wrapper">
        <div class="page-content--bge5">
            <div class="container">
                <div class="login-wrap">
                    <div class="login-content">
                        <div class="login-logo">
                            <a href="/">
                                <img src="/assets/images/lilac-logo.png" alt="Lilac insights logo">
                            </a>
                        </div>

                        <div class="mt-2" *ngIf="errorMessage && errorMessage.length">
                        	<div class="alert alert-danger" role="alert">
								{{ errorMessage }}
							</div>
                        </div>

                        <div class="login-form">
                            <form (submit)="smartSubmit()">
                                <div class="form-group">
                                    <label>Username / e-mail</label>
                                    <input class="au-input au-input--full" type="text" name="username" placeholder="Username"
                                    	[(ngModel)]="username" (change)="checkForValidEmail()" (keyup)="ifEmailThenProceed()"
                                    >
                                </div>

                                <div class="form-group horizontal-flex start-center" [hidden]="! askForSalesData">
                                    <input type="text" class="form-control centered mr-2" readonly value="E-" name="sales-atype">
                                    <input type="tel" class="form-control centered ml-2 mr-2" [(ngModel)]="salesCenterId" name="sales-center-id" placeholder="Center ID">

                                    <input type="tel" class="form-control centered ml-2" [(ngModel)]="salesCode" name="sales-code" placeholder="Code">
                                </div>

                                <div class="form-group" [hidden]="! requestedSalesOtp">
                                    <label>OTP (check your e-mail)</label>
                                    <input class="au-input au-input--full" type="tel" name="otp" placeholder="OTP"
                                           [(ngModel)]="otp"
                                    >
                                </div>

                                <div class="form-group" [hidden]="! askForPassword">
                                    <label>Password</label>
                                    <input class="au-input au-input--full" type="password" name="password" placeholder="Password"
                                        (keyup)="passwordOnKeyUp()"
                                    	[(ngModel)]="password"
                                    >

                                    <div class="alert alert-danger mt-1" role="alert" *ngIf="password.length && ! passwordFullValid">
                                        <h5 class="text-center mb-1">Password must contain the following: </h5>
                                        <ul>
                                            <li *ngIf="passwordValidation.lowercaseCharacter">A lowercase character</li>
                                            <li *ngIf="passwordValidation.uppercaseCharacter">A uppercase character</li>
                                            <li *ngIf="passwordValidation.numericCharacter">A numeric character</li>
                                            <li *ngIf="passwordValidation.specialCharacter">A special character</li>
                                            <li *ngIf="passwordValidation.moreThan8Characters">More than 8 characters</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="login-checkbox" [hidden]="!password.length || !askForPassword">
                                    <label>
                                        <input type="checkbox" name="remember"
                                        	[(ngModel)]="rememberMe"
                                        >Remember Me
                                    </label>

                                    <label [hidden]="true">
                                        <a href="#">Forgotten Password?</a>
                                    </label>
                                </div>

                                <button class="au-btn au-btn--block btn-primary m-b-20" type="button" *ngIf="requestedSalesOtp"
                                        (click)="verifySalesOtp()"
                                >
                                    {{ buttonText || "Verify Sales OTP" }}
                                </button>

                                <button class="au-btn au-btn--block btn-secondary m-b-20" type="button" *ngIf="askForSalesOtp && !(requestedSalesOtp && this.buttonText)"
                                        (click)="requestSalesOtp()"
                                >{{ buttonText || "Request OTP" }}</button>

                                <button class="au-btn au-btn--block btn-secondary m-b-20" type="button" *ngIf="askForSalesData"
                                        (click)="createSalesLogin()"
                                >{{ buttonText || "Create Sales login" }}</button>

                                <button class="au-btn au-btn--block btn-secondary m-b-20" type="button" [hidden]="askForSalesOtp || askForSalesData"
                                        (click)="signIn()"
                                >{{ buttonText || "Sign in" }}</button>


                            </form>
                            <div class="register-link" [hidden]="true">
                                <p>
                                    Haven't created your password?
                                    <a href="#">Sign Up Here</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
