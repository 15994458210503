import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'tat-input',
  templateUrl: './tat-input.component.html',
  styleUrls: ['./tat-input.component.styl']
})
export class TatInputComponent implements OnInit {

  @Input() value: number;
  @Input() index: number;
  @Input() tatType: string;
  @Output() tatChanged = new EventEmitter();

  tatNumber: number = 0;
  tatUnit: string = "hours";

  constructor() { }

  ngOnInit(): void {
    this.convertTo();
  }

  convertTo = () => {
    this.tatUnit = this.value % 1440 ? "hours" : "days";
    this.tatNumber = (this.tatUnit === "hours") ? Math.ceil(this.value / 60) : Math.ceil(this.value / (60 * 24))
  }

  convertFor = () => {
    return (this.tatUnit === "hours") 
      ? (this.tatNumber * 60)
      : (this.tatNumber * 60 * 24);
  }

  valueChanged = () => {
    return this.tatChanged.emit(this.convertFor() + "");
  }
}
