<div class="horizontal-flex end-center fill-width m-1">
    Logged in as: &nbsp;<strong>{{ userName }}</strong>, &nbsp;
    <button type="button" class="ml-3 mr-2 btn btn-danger btn-xs" (click)="_util.logout()"> {{ _util.logoutButtonText || "Logout" }}</button>
</div>

<div class="container-fluid max-height-width vertical-flex center-center">
    <div class="dialog-container vertical-flex start-center">
       <img src="/assets/images/lilac-logo.png" class="big-logo mb-5">
        <h4 class="dialog flex-filler">
            Hello, I am Jarvis, your Digital Assistant. <br><br>
            I have been created to simplify your manual workflows and increase your productivity. <br><br>
            Tasks like daily sales data, customer business continuity, report portal and few others have been 
completed by me till date. <br><br>
            I am also working on some more exciting stuff which will be revealed soon. <br><br>
            This portal currently will help you to create, modify customer details. Kindly help increase my usage by 
creating more new customers and keep me busy. <br><br>
        </h4>
        <!--<h4 class="dialog flex-filler">
            Hello, I am Jarvis your Digital Transformation Partner. <br><br>
            I have digitalized your Daily sales data, inactive customers and working on many more things which you will see in future over here. <br><br>
            As of now, you can help me by validating your and your customers data in LIMS.
        </h4>-->

        <div class="horizontal-flex vertical-flex-on-mobile center-center mt-4">
            <!--<button type="button" class="btn btn-primary btn-fluid mobile-margins mr-2" (click)="viewCustomerDetails()"
                    *ngIf="_util.canUser(_util.Constants.Permissions.SalesDataView) && !hideCustomerDetails" [hidden]="true">
                View customer details &gt;&gt;</button>-->

            <button type="button" class="btn btn-primary btn-fluid mobile-margins mr-2" (click)="viewBDMCustomerDetails()"
                    *ngIf="_util.canUser(_util.Constants.Permissions.SalesDataView && _util.Constants.Permissions.CreateNewCustomers)">
                View customer details &gt;&gt;</button>    

            <button type="button" class="btn btn-secondary mobile-margins btn-fluid mr-2"
                    (click)="_util.redirectTo('customer-creation')"
                    *ngIf="_util.canUser(_util.Constants.Permissions.SalesDataView && _util.Constants.Permissions.CreateNewCustomers)"
            >
                Create new customer +
            </button>

            <button type="button" class="btn btn-secondary mobile-margins btn-fluid mr-2"
                    (click)="_util.redirectTo('customer-approval-dashboard')"
                    *ngIf="_util.canUser(_util.Constants.Permissions.ApproveNewCustomer) || _util.canUser(_util.Constants.Permissions.VerifyNewCustomer)"
            >
                View New Customers
            </button>

            <button type="button" class="btn btn-secondary mobile-margins btn-fluid mr-2"
                    (click)="_util.redirectTo('outstanding-balance-dashboard')"
                    *ngIf="_util.canUser(_util.Constants.Permissions.CreateNewCustomers) || _util.canUser(_util.Constants.Permissions.ApproveNewCustomer)"
            >
                View Outstanding Balance
            </button>

            <button type="button" class="btn btn-secondary mobile-margins btn-fluid mr-2" (click)="redirectToApprovingChildrenAddressManagerRequests()"
                    *ngIf="childrenCount"
            >
                Approve Address manager requests &gt;&gt;
            </button>
        </div>


    </div>

</div>
