<div class="overview-item mb-2 overview-item--c1">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix horizontal-flex">
                        <div class="text flex-fill">
                            <h2>{{whatsappAttemptCount}}</h2>
                            <span>No. Of Attempts</span>
                        </div>
                    </div>

                    <p class="text-white"></p>
                </div>
            </div>
            <div class="overview-item mb-2 overview-item--c4">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="text">
                            <h2>{{whatsappOptInCount}}</h2>
                            <span>Customers Opted in</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c2">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="text">
                            <h2>{{whatsappSentCount}}</h2>
                            <span>No. of sent messages</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-item mb-2 overview-item--c2">
                <div class="overview__inner pb-4">
                    <div class="overview-box clearfix">
                        <div class="text">
                            <h2>{{whatsappAttemptCount - whatsappSentCount}}</h2>
                            <span>No. of failed messages</span>
                        </div>
                    </div>
                </div>
            </div>
