import { Component, OnInit } from '@angular/core';

import {RestService} from "../rest.service";
import {UtilityService} from "../utility.service";
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {

	username = "";
	password = "";
	otp: "";
	salesCode = "";
	salesCenterId = "";
	rememberMe = false;
	errorMessage = "";
	buttonText = "";

	dashboardLoginUsernames = [];

	passwordFullValid = true;
	passwordValidation = {
		lowercaseCharacter: false,
		uppercaseCharacter: false,
		specialCharacter: false,
		moreThan8Characters: false,
		numericCharacter: false,
	}

    askForPassword = false;
    askForSalesData = false;
    askForSalesOtp = false;

    checkingLogin = false;
    haveOverwrittenCheckingLogin = false;
    requestedSalesOtp = false;

    constructor(private _util: UtilityService, private _rest: RestService) { }

    ngOnInit(): void {
    	this._util.redirectAccordingly();
    	this.fillLoginUsernames();

	}

	resetPasswordValidationVars() {
		this.passwordFullValid = true;
		this.passwordValidation = {
			lowercaseCharacter: false,
			uppercaseCharacter: false,
			specialCharacter: false,
			moreThan8Characters: false,
			numericCharacter: false,
		};
	}

	async fillLoginUsernames() {
        this.dashboardLoginUsernames = [];

        const resp = await this._rest.getLoginUsernames();
        if ( resp["usernames"] && resp["usernames"].length )
            this.dashboardLoginUsernames = resp["usernames"];
    }

	async requestSalesOtp() {
        console.log(" requesting otp");
        this.buttonText = "Requesting otp ...";

        const resp = await this._rest.requestSalesOtp(this.username);
        if ( ! resp['success'] && resp['message'])
            return this._util.showAlert('Server says', resp['message']);

        this.buttonText = "";
        this.requestedSalesOtp = true;
        await this._util.showAlert(
            'OTP sent',
            "a one-time 6 digit code is shared on your e-mail. Please enter to continue. ",
            "success"
        );
    }

    async verifySalesOtp() {
        console.log("verifying sales otp");
        this.buttonText = "Verifying sales otp ...";

        const resp = await this._rest.verifySalesOtp(this.username, this.otp);
        this.buttonText = "";

        if (!resp['success'] && resp['message'])
            return this._util.showAlert('Server says', resp['message']);

        this.saveLoginInfo(resp);
    }

    async createSalesLogin() {
        console.log(" create sales login");
        this.buttonText = "Creating ...";

        if ( ! this.salesCode || ! this.salesCenterId)
            return this._util.showAlert('Missing sales code / center id');

        const resp = await this._rest.createSalesLogin(this.username, this.salesCenterId, this.salesCode, 'E');
        this.buttonText = "";

        if ( !resp['success'] && resp['message'])
            return this._util.showAlert('Server error', resp['message']);

        this.askForSalesData = false;
        await this.requestSalesOtp();
    }

    async ifEmailThenProceed() {
        // user is already in login DB, so we should use should use it as a password login
        console.log("ifEmailThenProceed: ", this.username, this.dashboardLoginUsernames.indexOf(this.username));
        if ( this.dashboardLoginUsernames.indexOf(this.username) > -1) {
            return this.checkForValidEmail();
        }

        // will only allow domains that have three letter on last end.

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
        const isValid = re.test(String(this.username).toLowerCase());

        if ( isValid) return this.checkForValidEmail();
        else return;
    }

    async checkForValidEmail() {
        if ( this.checkingLogin) {
            this.haveOverwrittenCheckingLogin = true;
            return;
        }

        this.buttonText = "Checking email ..."

        this.askForPassword = false;
        this.askForSalesOtp = false;
        this.askForSalesData = false;

        this.checkingLogin = true;
        const resp = await this._rest.checkLogin(this.username);

        this.buttonText = "";

        this.checkingLogin = false;
        if ( this.haveOverwrittenCheckingLogin) {
            this.haveOverwrittenCheckingLogin = false;
            return this.checkForValidEmail();
        }

        if ( ! resp['success']) { return; }

        if ( resp['askForPassword']) this.askForPassword = true;
        if ( resp['askForSalesData']) this.askForSalesData = true;
        if ( resp['askForSalesOtp']) this.askForSalesOtp = true;
    }

	passwordOnKeyUp() {
		this.resetPasswordValidationVars();
		let anyValidationFailed = false;

		if ( ! this.password.match(/[a-z]/)) {
			this.passwordValidation.lowercaseCharacter = true;
			anyValidationFailed = true;
		}

		if ( ! this.password.match(/[A-Z]/)) {
			this.passwordValidation.uppercaseCharacter = true;
			anyValidationFailed = true;
		}

		if ( ! this.password.match(/[0-9]/)) {
			this.passwordValidation.numericCharacter = true;
			anyValidationFailed = true;
		}

		if ( ! this.password.match(/[^0-9A-Za-z]/)) {
			this.passwordValidation.specialCharacter = true;
			anyValidationFailed = true;
		}

		if ( this.password.length < 9) {
			this.passwordValidation.moreThan8Characters = true;
			anyValidationFailed = true;
		}

		this.passwordFullValid = ! anyValidationFailed;
	}

    smartSubmit() {
        if ( this.requestedSalesOtp)
            return this.verifySalesOtp();

        if ( this.askForSalesOtp)
            return this.requestSalesOtp();

        if ( this.askForSalesData)
            return this.createSalesLogin();

        if ( ! this.askForSalesData && ! this.askForSalesOtp)
            return this.signIn();
    }

    async signIn() {
    	this.buttonText = "";
    	console.log("signIn() called: ", this.username, this.password, this.rememberMe);

    	if ( ! this.username && ! this.password)
    		return Swal.fire("Missing fields", "Please fill all fields", "error");

    	const resp = await this._rest.tryLogginIn(this.username, this.password);

    	if ( ! resp["success"] && resp["message"])
    		return Swal.fire("Server resp: ", resp["message"], "error");

        this.saveLoginInfo(resp);
    }

    saveLoginInfo(resp) {
        this.buttonText = "Redirecting ...";
        this._util.setLocalData(this._util.Constants.Keys.token, resp["accessToken"]);
        this._util.setLocalData(this._util.Constants.Keys.user, JSON.stringify(resp["user"]));
        
        if ( resp["permissions"] ) {
            this._util.setLocalData(this._util.Constants.Keys.permissions, JSON.stringify(resp["permissions"]));
            this._util.userPermissions = resp["permissions"];
        }

        this._util.redirectAccordingly();
    }

}
