<div class="container max-height">
    <div class="row fill-height">
        <div class="col-md-3 p-1 pt-3">

            <button type="button" class="btn btn-outline-info btn-block"
                    [class.btn-info]="isSureTExcelResultLoaded()"
                    [class.btn-outline-info]="! isSureTExcelResultLoaded()"
                    *ngIf="matchedEditableReportsMaster === 'SureT'"
                    (click)="beginUploadFileWithPurpose('sure-t-excel-result')">
                {{ uploadButtonText || (isSureTExcelResultLoaded() ? 'Update SureT report Sheet' : 'Add result sheet') }}
            </button>

            <button type="button" class="btn btn-outline-primary btn-block"
                    [class.btn-primary]="filteredAttachmentsForTube().length > 0"
                    *ngIf="matchedEditableReportsMaster === 'SureT'"
                    (click)="openModal('suret-embroyo-pictures')"
            >
                {{ uploadButtonText || "Upload Embryo Pictures" }}
            </button>

            <button type="button" class="btn btn-outline-primary btn-block"
                    *ngIf="matchedEditableReportsMaster === 'SureT'"
                    (click)="openModal('suret-cnv-modal')"
            >
                Hide CNV
            </button>
            <button type="button" class="btn btn-outline-primary btn-block"
                    *ngIf="matchedEditableReportsMaster === 'SureT' && arrowsUpdated"
                    (click)="saveArrowPositions()"
            >
                Save CNV markers
            </button>

            <button type="button" class="btn btn-outline-danger btn-block"
                    *ngIf="matchedEditableReportsMaster === 'SureT' && arrowsPresent"
                    (click)="openModal('suret-cnv-marker-position-modal')"
            >
                Delete CNV marker
            </button>

            <button type="button" class="btn btn-outline-danger btn-block"
                    *ngIf="matchedEditableReportsMaster === 'SureT' && arrowsPresent"
                    (click)="deleteArrows()"
            >
                Delete All CNV markers
            </button>

            <button type='button' class="btn btn-outline-info btn-block"
                    *ngIf="matchedEditableReportsMaster === 'Altum' && altumParams['caseType'] === 'commonaneuploides' && altumAbnormalCasesWithoutResultTable.indexOf(altumParams['altumSubCategory']) == -1"
                    (click)="openAltumModal('result')">
                Update Result
            </button>

            <button type='button' class="btn btn-outline-info btn-block"
                    *ngIf="matchedEditableReportsMaster === 'Altum' && altumParams['caseType'] === 'lsch'"
                    (click)="openAltumModal('annexure')">
                Update Annexure Table
            </button>

            <button type='button' class="btn btn-outline-info btn-block"
                    *ngIf="matchedEditableReportsMaster === 'pCREditable'"
                    (click)="showHideRemarks()">
                {{remarkButtonText}}
            </button>

            <button type='button' class="btn btn-info btn-block"
                    *ngIf="matchedEditableReportsMaster === 'pCREditable' && baseParams['testcode'] === 'G06S04T16' && hasHiddenFields"
                    (click)="showHiddenFields()">
                Show Hidden Fields
            </button>

            <button type="button" class="btn btn-primary btn-block"
                    *ngIf="haveUpdatedFields()"
                    (click)="saveUpdatedValues()"
            >
                {{ saveButtonText || 'Save fields' }}
            </button>

            <button type="button" class="btn btn-primary btn-block" (click)="saveReviewedUpdates()"
                    *ngIf="_util.canUser(_util.Constants.Permissions.ReviewReportUpdates) && haveReviewableFields"
            >
                Save Reviewed Updates
            </button>

            <button type="button" class="btn btn-outline-primary btn-block" (click)="refreshPage()">
                Refresh
            </button>

            <input type="file" hidden (change)="fileSelectedToUpload($event)"
                   id="edit-report-attachment"
                   accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">

            <button type="button" class="btn btn-warning btn-block mt-2" (click)="goToPreview()">
                To Preview
            </button>

        </div>


        <div class="col-md-9">
            <div class="flex-filler fill-height" style="position: relative">
                <iframe title="Report preview" class="fill-width fill-height min-height-100" #iFrame></iframe>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="suret-embroyo-pictures" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">SureT Embroyo Pictures</h2>

                    <ngx-dropzone (change)="fileSelectedToUpload($event, embroyo.TubeName)"
                                  class="attachments flex-fill mr-2 mb-1"
                                  *ngFor="let embroyo of sureTEmbroyos">
                        <ngx-dropzone-label><strong>{{ embroyo.TubeName }}</strong></ngx-dropzone-label>

                        <div class="attachment" *ngFor="let attachment of filteredAttachmentsForTube(embroyo.TubeName)"
                             (click)="_util.openAttachmentInNewTab($event, attachment)"
                        >
                            <img [src]="attachment.last_url" alt="Icon" style="max-height: 90px">
                            <div class="remove"
                                 (click)="removeSureTEmbroyoAttachment($event, attachment, embroyo.TubeName)"
                            >
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ngx-dropzone>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            data-dismiss="modal" aria-label="Refresh report"
                            (click)="refreshPage()"
                    >
                        Refresh report
                    </button>

                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="suret-cnv-marker-position-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">Delete CNV Marker</h2>
                    <h4>Enter the tubename where marker is to be removed</h4>
                    <input type="text" name="cnv-marker-tubename" [(ngModel)]="cnvMarkerTubeName"
                           class="border border-dark">
                    <h4>Enter the position of the marker from left</h4>
                    <input type="number" name="cnv-marker-position" [(ngModel)]="cnvMarkerPosition"
                           class="border border-dark" min="1">
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            data-dismiss="modal" aria-label="Refresh report"
                            (click)="deleteArrow()"
                    >
                        Submit
                    </button>

                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal" tabindex="-1" id="altum-result-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">Update Result</h2>

                    <table rules="all" class="text-center" *ngIf="altumModalType === 'result'">
                        <thead>
                        <th>Parameters</th>
                        <th *ngFor="let resultRow of altumResultRows">
                            Row {{altumResultRows.indexOf(resultRow) + 1}}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Type</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['type']">
                            </td>
                        </tr>
                        <tr>
                            <th>Chr. No.</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['chrNo']">
                            </td>
                        </tr>
                        <tr>
                            <th>CytoBand</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['cytoBand']">
                            </td>
                        </tr>
                        <tr>
                            <th>Size (kbp)</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['size']">
                            </td>
                        </tr>
                        <tr>
                            <th>CN State</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['cnState']">
                            </td>
                        </tr>
                        <tr>
                            <th>Gene Count</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['geneCount']">
                            </td>
                        </tr>
                        <tr>
                            <th>Genomic Coordinates(ISCN 2016)</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['genomicCoordinates']">
                            </td>
                        </tr>
                        <tr>
                            <th>Interpretation</th>
                            <td *ngFor="let resultRow of altumResultRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="resultRow['interpretation']">
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td *ngFor="let resultRow of altumResultRows" class="text-center">
                                <button type='button' class='btn text-red'
                                        (click)='deleteTableRow(resultRow)'>
                                    Delete Row
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <table rules="all" class="text-center" *ngIf="altumModalType === 'annexure'">
                        <thead>
                        <th>Parameters</th>
                        <th *ngFor="let annexureRow of altumAnnexureRows">
                            Row {{altumResultRows.indexOf(resultRow) + 1}}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Range</th>
                            <td *ngFor="let annexureRow of altumAnnexureRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="annexureRow['range']">
                            </td>
                        </tr>
                        <tr>
                            <th>Contigious Stretch</th>
                            <td *ngFor="let annexureRow of altumAnnexureRows">
                                <input type="text" class="bg-light min-width text-center"
                                       [(ngModel)]="annexureRow['contigiousStretch']">
                            </td>
                        </tr>
                        <tr>
                            <th>CytoBand</th>
                            <td *ngFor="let annexureRow of altumAnnexureRows">
                                <input type="text" class="bg-light min-width text-center height-line-wise"
                                       [(ngModel)]="annexureRow['description']">
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td *ngFor="let annexureRow of altumAnnexureRows" class="text-center">
                                <button type='button' class='btn text-red'
                                        (click)='deleteTableRow(annexureRow)'>
                                    Delete Row
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            data-dismiss="modal" aria-label="Refresh report"
                            (click)="saveAltumResult()"
                    >
                        Save Details
                    </button>

                    <button type='button' class='btn btn-outline-primary'
                            (click)='addTableRow()'
                            *ngIf='altumResultRows.length <= 4 || altumAnnexureRows.length <= 10'>
                        Add Row
                    </button>

                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close"
                            (click)="_util.modal('altum-result0modal', 'hide')">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="suret-cnv-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-lg modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="bd-title mb-2">Update CNV table Visibility Status</h2>
                    <p>Please select the embryos where the cnv table is to be hidden</p>
                    <table class="text-center fill-width">
                        <thead>
                        <th>Embryo</th>
                        <th>CNV Table Hidden</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let embryo of sureTEmbroyos">
                            <td>{{embryo['SampleID']}} ({{embryo.TubeName}})</td>
                            <td>
                                <div class="ui checkbox"><input type="checkbox" [(ngModel)]="embryo.HideCNV"
                                                                (change)="changeCNVVisibilityStatus(embryo, $event)"
                                                                [checked]="embryo.HideCNV"/></div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            data-dismiss="modal" aria-label="Refresh report"
                            (click)="updateSureTCNVStatus()"
                    >
                        Save Details
                    </button>

                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close"
                            (click)="_util.modal('suret-cnv-modal', 'hide')">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" id="suret-TSV-save-modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal modal-dialog-centered" style="max-width: 650px;">>
            <div class="modal-content">
                <div class="modal-body" style="height: 50vh; overflow-y: auto; max-width: 650px;">
                    <div class="alert alert-light" role="alert"
                         *ngIf="sureTSamples.newSureTSampleTSVValues.length !== 0 || sureTSamples.existingSureTSampleTSVValues.length !== 0 || sureTSamples.unmatchedSureTSampleTSVValues.length !== 0 || sureTSamples.currentSureTSample.length !== 0">
                        <h4 class="alert-heading">Following SureT Records will be updated</h4>
                        <hr>
                        <div *ngIf="sureTSamples.currentSureTSample.length !== 0"
                             style="max-width: 600px; margin-bottom: 30px;"><span
                            style="font-weight: bold; color: green;">Current SureT Sample (will be added/overriden):</span>
                            <table>
                                <thead>
                                <tr>
                                    <th scope="col" style="min-width: 50px;">S.No.</th>
                                    <th scope="col" style="min-width: 120px;">Barcode</th>
                                    <th scope="col" style="min-width: 150px;">Tube Name</th>
                                    <th scope="col" style="min-width: 150px;">Checked<span class="ui checkbox"
                                                                                           style="margin-left:10px; margin-right: 10px;"><input
                                        type="checkbox" [(ngModel)]="sureTSamples.currentSampleCheckedAll"
                                        (change)="currentSureTSampleTSV($event)"/></span>(all)
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of sureTSamples.currentSureTSample">
                                    <td>{{sureTSamples.currentSureTSample.indexOf(item) + 1}}</td>
                                    <td>{{item['SampleID']}}</td>
                                    <td>{{item['TubeName']}}</td>
                                    <td>
                                        <div class="ui checkbox"><input type="checkbox"
                                                                        [checked]="(sureTSamples.currentSampleCheckedAll)"
                                                                        (change)="currentSureTSampleTSV($event, item)"/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="sureTSamples.newSureTSampleTSVValues.length !== 0"
                             style="max-width: 600px; margin-bottom: 30px;"><span
                            style="font-weight: bold; color: green;">New SureT Samples (will be added):</span>
                            <table>
                                <thead>
                                <tr>
                                    <th scope="col" style="min-width: 50px;">S.No.</th>
                                    <th scope="col" style="min-width: 120px;">Barcode</th>
                                    <th scope="col" style="min-width: 150px;">Tube Name</th>
                                    <th scope="col" style="min-width: 150px;">Embryo Count</th>
                                    <th scope="col" style="min-width: 50px;">Checked</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of sureTSamples.newSureTSampleTSVValues">
                                    <td>{{sureTSamples.newSureTSampleTSVValues.indexOf(item) + 1}}</td>
                                    <td>{{item[0]['SampleID']}}</td>
                                    <td>{{item[0]['TubeName']}}</td>
                                    <td>{{item.length}}</td>
                                    <td>
                                        <div class="ui checkbox"><input type="checkbox" disabled="disabled"
                                                                        checked="checked"/></div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="sureTSamples.existingSureTSampleTSVValues.length !== 0"
                             style="max-width: 600px; margin-bottom: 30px;"><span
                            style="font-weight: bold; color: #8B8000;">Existing SureT Samples (will be overriden):</span>
                            <table>
                                <thead>
                                <tr>
                                    <th scope="col" style="min-width: 50px;">S.No.</th>
                                    <th scope="col" style="min-width: 120px;">Barcode</th>
                                    <th scope="col" style="min-width: 150px;">Tube Name</th>
                                    <th scope="col" style="min-width: 150px;">Embryo Count</th>
                                    <th scope="col" style="min-width: 50px;">Checked</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of sureTSamples.existingSureTSampleTSVValues">
                                    <td>{{sureTSamples.existingSureTSampleTSVValues.indexOf(item) + 1}}</td>
                                    <td>{{item[0]['SampleID']}}</td>
                                    <td>{{item[0]['TubeName']}}</td>
                                    <td>{{item.length}}</td>
                                    <td>
                                        <div class="ui checkbox"><input type="checkbox"
                                                                        (change)="overrideExistingSureTSampleTSV(item, $event)"/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="sureTSamples.unmatchedSureTSampleTSVValues.length !== 0"
                             style="max-width: 600px; margin-bottom: 30px;"><span
                            style="font-weight: bold; color: red;">Unmatched SureT Samples (not found):</span>
                            <table>
                                <thead>
                                <tr>
                                    <th scope="col" style="min-width: 50px;">S.No.</th>
                                    <th scope="col" style="min-width: 120px;">Barcode</th>
                                    <th scope="col" style="min-width: 150px;">Tube Name</th>
                                    <th scope="col" style="min-width: 150px;">Embryo Count</th>
                                    <th scope="col" style="min-width: 50px;">Checked</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of sureTSamples.unmatchedSureTSampleTSVValues">
                                    <td>{{sureTSamples.unmatchedSureTSampleTSVValues.indexOf(item) + 1}}</td>
                                    <td>{{item[0]['SampleID']}}</td>
                                    <td>{{item[0]['TubeName']}}</td>
                                    <td>{{item.length}}</td>
                                    <td>
                                        <div class="ui checkbox"><input type="checkbox" disabled="disabled"/></div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            (click)="updateSureTTSVValues()"
                    >
                        Proceed to update
                    </button>
                    <button type="button" class="btn btn-secondary"
                            data-dismiss="modal" aria-label="Close"
                            (click)="hideSureTTSVSaveModal()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <app-reports-session-manager [previewParams]="this.baseParams"></app-reports-session-manager>
</div>
