import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";

// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class RestService {
    private header: { headers: HttpHeaders; };

    constructor ( public _http: HttpClient ) {
    }

    getAccessToken () {
        return window.localStorage.getItem('access_token') || 'foo';
    }

    setRequestHeader () {
        this.header = {
            headers: new HttpHeaders({
                'token': this.getAccessToken(),
            }),
        };

        return this.header;
    }

    async createSalesLogin ( email, center, code, atype = 'E' ) {
        return this._http.post('/api/auth/sales-login', { email, center, code, atype })
            .toPromise();
    }

    async requestSalesOtp ( email ) {
        return this._http.post('/api/auth/request-sales-otp', { email })
            .toPromise();
    }

    async verifySalesOtp ( email, otp ) {
        return this._http.post('/api/auth/verify-sales-otp', { email, otp })
            .toPromise();
    }

    async checkLogin ( identifier: string ) {
        return this._http.post('/api/auth/check-login', { identifier })
            .toPromise();
    }

    async getSalesConstants () {
        return this._http.get('/api/sales/constants', this.setRequestHeader())
            .toPromise();
    }

    async getSalesCustomersListing ( page = 1, searchQuery = "", hoLocation = "", verificationFilter = "" ) {
        return this._http.get('/api/my/customers/' + page + "?search=" + searchQuery + "&location=" + hoLocation + "&verification=" + verificationFilter, this.setRequestHeader())
            .toPromise();
    }

    async getSalesCustomerDoctorsListing ( customer, page = 1 ) {
        return this._http.get('/api/my/customers/' + customer + "/doctors/" + page,
            this.setRequestHeader())
            .toPromise();
    }

    async getAllLabs () {
        return this._http.get('/api/terms/labs', this.setRequestHeader())
            .toPromise();
    }

    async createTestChildren ( body ) {
        return this._http.post('/api/tests/children/add', body, this.setRequestHeader())
            .toPromise();
    }

    async createNewAccession ( barcode, labLocationId ) {
        return this._http.post('/api/accessions/create', { barcode, labLocationId }, this.setRequestHeader())
            .toPromise();
    }

    async syncAccessions () {
        return this._http.post('/api/accessions/sync', {}, this.setRequestHeader())
            .toPromise();
    }

    async getDashboardData ( labId = "", dateRange = "" ) {
        return this._http.post('/api/accessions/dashboard', { location: labId, dateRange }, this.setRequestHeader())
            .toPromise();
    }

    async getSampleInfo ( barcode, testcode = null ) {
        if ( testcode && testcode.length )
            return this._http.get('/api/samples/barcode/' + barcode + '/' + testcode, this.setRequestHeader())
                .toPromise();

        return this._http.get('/api/samples/barcode/' + barcode, this.setRequestHeader())
            .toPromise();
    }

    async getSamplesGridData ( query, page = 1, search = "", sortColumn = "updated_at", sortOrderReverse = false ) {
        return this._http.post('/api/samples/list', {
            page, query, search, sortColumn, sortOrderReverse
        }, this.setRequestHeader())
            .toPromise();
    }

    async mailReport ( testcode, barcode, patient ) {
        const query = `testcode=${testcode}&patient=${patient}&barcode=${barcode}&headers=without&token=${this.getAccessToken()}&onlyJson=1`;

        return this._http.get('/api/reports/mail?' + query, this.setRequestHeader())
            .toPromise();
    }

    async mailReportAllParams ( params ) {
        const queryParams = [];
        for ( let key in params ) queryParams.push(`${key}=${params[key]}`);

        const query = `headers=without&token=${this.getAccessToken()}&onlyJson=1&` + queryParams.join('&');

        return this._http.get('/api/reports/mail?' + query, this.setRequestHeader())
            .toPromise();
    }

    async syncSample ( barcode, labId = "" ) {
        return this._http.get(`/api/samples/sync/${barcode}/${labId}`, this.setRequestHeader())
            .toPromise();
    }

    async getPrintDueSamples ( labLocation = "", labType = "" ) {
        return this._http.get('/api/samples/get-print-due?location=' + labLocation + '&type=' + labType, this.setRequestHeader())
            .toPromise();
    }

    async updateUserForceResyncStatus ( userid ) {
        return this._http.post('/api/users/force-resync/' + userid, {}, this.setRequestHeader())
            .toPromise();
    }

    async getTodaysPrintConfirmedRecords () {
        return this._http.get('/api/samples/get-todays-print-confirmed-records', this.setRequestHeader())
            .toPromise();
    }

    async confirmReportPrinted ( barcode, testcodes, sampleTrackingId ) {
        return this._http.post('/api/samples/confirm-report-printed', {
            barcode,
            testcodes,
            sampleTrackingId
        }, this.setRequestHeader())
            .toPromise();
    }

    async logPrintReport ( barcode, testcode ) {
        return this._http.post('/api/samples/log-printed-report', { barcode, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async logPrintedAttachments ( attachments ) {
        return this._http.post('/api/samples/log-printed-attachments', { attachments }, this.setRequestHeader())
            .toPromise();
    }

    async getSamplesGridCSV ( query ) {
        const headers = this.setRequestHeader();
        headers["responseType"] = 'text';

        return this._http.post('/api/samples/download', { query }, headers)
            .toPromise();
    }

    async getMatchingPatientSampleId ( patient = "", barcode = "" ) {
        return this._http.post('/api/reports/search-patient-sample', { patient, barcode }, this.setRequestHeader())
            .toPromise();
    }

    async getTestsListForPatient ( patient = "", barcode = "" ) {
        return this._http.post('/api/reports/get-patient-tests', { patient, barcode }, this.setRequestHeader())
            .toPromise();
    }

    async tryLogginIn ( username, password ) {
        return this._http.post('/api/auth/user-login', { username, password }, this.setRequestHeader())
            .toPromise();
    }

    async invalidateLogin () {
        const headers = this.setRequestHeader();

        return this._http.post('/api/auth/user-logout', { token: headers.headers["token"] }, this.setRequestHeader())
            .toPromise();
    }

    async getAllUsers () {
        return this._http.get('/api/users/all', this.setRequestHeader())
            .toPromise();
    }

    async markPaymentSuccess ( barcode ) {
        return this._http.post('/api/samples/mark-payment-success', { barcode }, this.setRequestHeader())
            .toPromise();
    }

    async markReportReleased ( barcode, testcode ) {
        return this._http.get('/api/sample/mark-report-released/' + barcode + '/' + testcode, this.setRequestHeader())
            .toPromise();
    }

    async markPaymentSuccessAndReleaseReport ( barcode ) {
        return this._http.post('/api/samples/mark-payment-success-release-report', { barcode }, this.setRequestHeader())
            .toPromise();
    }

    async convertToNonAstria ( barcode, testcode ) {

        return this._http.post('/api/sample/convert-to-astria', {
            barcode,
            testcode,
            revert: 1
        }, this.setRequestHeader())
            .toPromise();
    }

    async convertToAstria ( barcode, testcode ) {
        return this._http.post('/api/sample/convert-to-astria', { barcode, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async createBioChemReportJob ( typeString, startDate, endDate ) {
        return this._http.post('/api/samples/create-biochem-data-job', {
            typeString, startDate: startDate.getTime(), endDate: endDate.getTime(),
        }, this.setRequestHeader())
            .toPromise();
    }

    async createTestSpecificJob ( typeString, startDate, endDate ) {
        return this._http.post('/api/samples/create-test-specific-data-job', {
            typeString, startDate: startDate.getTime(), endDate: endDate.getTime(),
        }, this.setRequestHeader())
            .toPromise();
    }

    async createQueuedDataJob ( typeString, extraData ) {
        return this._http.post('/api/samples/create-queued-job', {
            type: typeString,
            ...extraData,
        }, this.setRequestHeader())
            .toPromise();
    }

    async putAmendedStamp ( barcode, testcode ) {
        return this._http.post('/api/samples/put-amended-stamp', { barcode, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async getQueuedJobsCount () {
        return this._http.get('/api/samples/get-queued-jobs', this.setRequestHeader())
            .toPromise();
    }

    async disableUser ( id ) {
        return this._http.post('/api/users/disable', { user: id }, this.setRequestHeader())
            .toPromise();
    }

    async updateUserPassword ( id, password ) {
        return this._http.post('/api/users/update-password', { user: id, password }, this.setRequestHeader())
            .toPromise();
    }

    async createUser ( name, username, email, password, role ) {
        const nameParts = this.namePartsFromName(name);

        return this._http.post('/api/users/create', {
            username, email, role, password,
            firstname: nameParts.firstname,
            lastname: nameParts.lastname || "",
        }, this.setRequestHeader()).toPromise();
    }

    async updateUser ( id, username, name, email, disabled, role ) {
        const nameParts = this.namePartsFromName(name);

        //@ts-ignore
        return this._http.post('/api/users/update', {
            user: id, username,
            firstname: nameParts.firstname,
            lastname: nameParts.lastname,
            email, disabled, role
        }, this.setRequestHeader()).toPromise();
    }

    async refreshAppKeys () {
        return this._http.get('/api/users/tokens/all', this.setRequestHeader())
            .toPromise();
    }

    async updateToken ( tokenid, enabled = false ) {
        return this._http.post('/api/users/tokens/update', {
            token: tokenid,
            active: enabled,
        }, this.setRequestHeader()).toPromise();
    }

    async createToken ( label ) {
        return this._http.post('/api/users/tokens/create', {
            label
        }, this.setRequestHeader()).toPromise();
    }

    async deleteToken ( token ) {
        return this._http.delete("/api/users/tokens/" + token, this.setRequestHeader())
            .toPromise();
    }

    async getTests ( params = {} ) {
        return this._http.post('/api/tests/get', params, this.setRequestHeader())
            .toPromise();
    }

    async getProfileTestCodes ( params = {} ) {
        console.log('requesting testcodes');
        return this._http.post('/api/tests/get-profile-test-codes', params, this.setRequestHeader())
            .toPromise();
    }

    async getTestcodeCount ( testcode ) {
        return this._http.get('/api/tests/get-testcode-suffix/' + testcode, this.setRequestHeader())
            .toPromise();
    }

    async getTestChildren ( testid ) {
        return this._http.get('/api/tests/children/' + testid, this.setRequestHeader())
            .toPromise();
    }

    async detachTestChild ( parent, child ) {
        return this._http.post('/api/tests/children/detach', {
            parent_id: parent, child_id: child
        }, this.setRequestHeader())
            .toPromise();
    }

    async attachTestChild ( parent, child ) {
        return this._http.post('/api/tests/children/attach', {
            parent_id: parent, child_id: child
        }, this.setRequestHeader())
            .toPromise();
    }

    async searchTestChildren ( query ) {
        return this._http.get('/api/tests/children/search/' + query, this.setRequestHeader())
            .toPromise();
    }

    async updateTest ( testId, params = {} ) {
        return this._http.patch('/api/tests/' + testId, params, this.setRequestHeader())
            .toPromise();
    }

    // Deprecated, doesn't get used, anymore
    async getMailingInfo ( barcode, caseType = '', resampleTime = '' ) {
        return this._http.get('/api/reports/mail/info/'
            + barcode
            + (caseType ? ('/' + caseType) : '')
            + (resampleTime ? ('/' + resampleTime) : ''),
            this.setRequestHeader())
            .toPromise();
    }

    async getMailingInfo2 ( barcode, testcode = '', caseType = '', resampleTime = '', rejectionReason = '', otherData = {}, relatedPatientData = {} ) {
        return this._http.post('/api/reports/mail/info/' + barcode, {
            caseType, resampleTime, testcode, rejectionReason,
            ...otherData, ...relatedPatientData
        }, this.setRequestHeader())
            .toPromise();
    }

    async saveNotesAndSuggestions ( data ) {
        return this._http.post('/api/reports/labmate/save-suggestions', data, this.setRequestHeader())
            .toPromise();
    }

    async markLabmateHoldReject ( data ) {
        return this._http.post('/api/reports/labmate/mark-hold-reject', data, this.setRequestHeader())
            .toPromise();
    }

    async markLabmateUnAuthenticated ( barcode, testcode ) {
        return this._http.post('/api/reports/labmate/un-authenticate', { barcode, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async updateLabmateLinkedPatient ( barcode, patient, linkedPatient = "0" ) {
        return this._http.post('/api/reports/labmate/update-linked-patient',
            { barcode, patient, linkedPatient },
            this.setRequestHeader())
            .toPromise();
    }

    async updateTHALReportValues ( barcode, postData ) {
        return this._http.post('/api/samples/thal/update-report-values', { barcode, ...postData }, this.setRequestHeader())
            .toPromise();
    }

    async updateReticulocyteCountReportValues ( barcode, postData ) {
        return this._http.post('/api/samples/flow-cytometry/update-report-values', { barcode, ...postData }, this.setRequestHeader())
            .toPromise();
    }

    async updateCBCReportValues ( barcode, postData ) {
        return this._http.post('/api/samples/flow-cytometry/update-report-values', { barcode, ...postData }, this.setRequestHeader())
            .toPromise();
    }

    async updateSfltPlgfReportValues ( barcode, postData ) {
        return this._http.post('/api/samples/sflt-plgf/update-report-values', { barcode, ...postData }, this.setRequestHeader())
            .toPromise();
    }

    async updateMailAttachments ( formData ) {
        return this._http.post('/api/samples/attachment/upload', formData, this.setRequestHeader())
            .toPromise();
    }

    async showPCPNDTForm ( barcode, testcode, download = false ) {
        return this._http.post('/api/sample/show-pc-pndt-form', {
            barcode,
            testcode,
            download
        }, this.setRequestHeader())
            .toPromise();
    }

    async mailAttachmentsTestcode ( barcode, testcode, attachments = [], email = false, sms = false, caseType = '', resampleTime = '', rejectionReason = '', otherData = {}, relatedPatientData = {} ) {
        return this._http.post('/api/reports/mail/attachments', {
            barcode,
            testcode,
            attachments,
            email,
            sms,
            caseType,
            resampleTime,
            rejectionReason, ...otherData, ...relatedPatientData
        }, this.setRequestHeader())
            .toPromise();
    }

    async mailAttachments ( barcode, attachments = [], email = false, sms = false, caseType = '', resampleTime = '', rejectionReason = '', otherData = {}, relatedPatientData = {} ) {
        return this._http.post('/api/reports/mail/attachments', {
            barcode,
            attachments,
            email,
            sms,
            caseType,
            resampleTime,
            rejectionReason, ...otherData, ...relatedPatientData
        }, this.setRequestHeader())
            .toPromise();
    }

    async invalidateCytoImages ( barcode ) {
        return this._http.post('/api/sample/invalidate-cyto-images', { barcode }, this.setRequestHeader())
            .toPromise();
    }

    async reviewReportUpdates ( barcode, testcode ) {
        return this._http.post('/api/sample/review-report-updates', { barcode, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async uploadSpecial ( formData ) {
        return this._http.post('/api/samples/specials/upload', formData, this.setRequestHeader())
            .toPromise();
    }

    async updateInsighTTSVValues ( values ) {
        return this._http.post('/api/samples/insighT/update', values, this.setRequestHeader())
            .toPromise();
    }

    async updateSureTTSVValues ( values ) {
        return this._http.post('/api/samples/sureT/update', values, this.setRequestHeader())
            .toPromise();
    }

    async updateSureTCNVStatus ( barcode, testcode, content ) {
        return this._http.post('/api/samples/sureT/update-cnv-status', {barcode, testcode, content}, this.setRequestHeader())
            .toPromise();
    }

    async updateAltumReportValues ( rows, barcode, testcode, altumValueType ) {
        return this._http.post('/api/sample/altum/update-result', {
            rows,
            barcode,
            testcode,
            altumValueType
        }, this.setRequestHeader()).toPromise();
    }

    async unlinkSureTEmbryoImage ( barcode, attachment ) {
        return this._http.post('/api/sample/sureT/attachments/unlink', { barcode, attachment }, this.setRequestHeader())
            .toPromise();
    }

    async fetchAttachments ( barcode, testcode ) {
        return this._http.post('/api/sample/attachments/fetch', { barcode, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async saveEditedFields ( scenario, barcode, testcode, content ) {
        return this._http.post("/api/samples/reports/edit", {
            scenario,
            barcode,
            testcode,
            content
        }, this.setRequestHeader())
            .toPromise();
    }

    async deleteCnvMarker(barcode, testcode, cnvMarker, cnvMarkerTubeName = ""){
        return this._http.post("/api/samples/reports/edit/delete-cnv-marker", {barcode, testcode, cnvMarker, cnvMarkerTubeName}, this.setRequestHeader()).toPromise();
    }

    async niptInconclusiveToLowRisk ( barcode, keys ) {
        return this._http.post('/api/samples/nipt/inconclusive-to-low-risk', { barcode, keys }, this.setRequestHeader())
            .toPromise();
    }

    async uploadSignature ( formData ) {
        const request = new HttpRequest('POST',
            `/api/users/upload-signature`,
            formData, {
                ...{
                    reportProgress: false,
                    responseType: 'json'
                },
                ...this.setRequestHeader(),
            });

        return this._http.request(request).toPromise();
    }

    async raisePatchRequest ( identifier, type, body ) {
        return this._http.post('/api/patch-requests/new', { identifier, type, body }, this.setRequestHeader())
            .toPromise();
    }

    async listPatchRequest2 ( page, queryParams = {} ) {
        let paramQuery = [];

        for ( let key in queryParams )
            paramQuery.push(`${key}=${queryParams[key]}`);

        return this._http.get('/api/patch-requests/' + page + (paramQuery.length ? '?' : '') + paramQuery.join('&'),
            this.setRequestHeader())
            .toPromise();
    }

    async listPatchRequests ( page, sortColumn = "id", sortOrder = "desc" ) {
        return this._http.get('/api/patch-requests/' + page + "?sort=" + sortColumn + "&order=" + sortOrder,
            this.setRequestHeader())
            .toPromise();
    }

    async acceptPatchRequest ( id ) {
        return this._http.get('/api/patch-requests/accept/' + id, this.setRequestHeader())
            .toPromise();
    }

    async markPatchRequestVerified ( id ) {
        return this._http.get('/api/patch-requests/mark-verified/' + id, this.setRequestHeader())
            .toPromise();
    }

    async markIdentifierUnverified ( identifier ) {
        return this._http.get('/api/patch-requests/mark-unverified/' + identifier, this.setRequestHeader())
            .toPromise();
    }

    async downloadOperatorLogSheet ( from, to = "" ) {
        return this._http.get('/api/patch-requests/download-operator-sheet/' + from + '/' + to, this.setRequestHeader())
            .toPromise();
    }

    async updatePatchRequestColumns ( id, columns, values ) {
        return this._http.patch('/api/patch-requests/' + id, { columns, values }, this.setRequestHeader())
            .toPromise();
    }

    async rejectPatchRequest ( id ) {
        return this._http.get('/api/patch-requests/reject/' + id, this.setRequestHeader())
            .toPromise();
    }

    async getLoginUsernames () {
        return this._http.get('/api/auth/login-usernames', this.setRequestHeader())
            .toPromise();
    }

    async bulkPrintAttachment ( attachments ) {
        return this._http.post('/api/samples/bulk-print-attachments', { attachments }, this.setRequestHeader())
            .toPromise();
    }

    async getLabmatePatientInfo ( patientId ) {
        return this._http.get('/api/samples/labmate/patient-info/' + patientId, this.setRequestHeader())
            .toPromise();
    }

    async syncLabMatePatientInfo ( patientId ) {
        return this._http.get('/api/samples/labmate/sync-patient-info/' + patientId, this.setRequestHeader())
            .toPromise();
    }

    async getNIPDTsv ( patient, testcode = null ) {
        return this._http.post('/api/samples/generate-nipd-tsv', { patient, testcode }, this.setRequestHeader())
            .toPromise();
    }

    async resetSalesOTP ( identifier ) {
        return this._http.post('/api/auth/reset-otp', { identifier }, this.setRequestHeader())
            .toPromise();
    }

    async unlinkAttachment ( barcode, attachment ) {
        return this._http.post('/api/samples/attachment/unlink', { barcode, attachment }, this.setRequestHeader())
            .toPromise();
    }

    async getBDMMOUAppFormFields () {
        return this._http.get('/api/customer-creations/get-form-fields', this.setRequestHeader())
            .toPromise();
    }

    async getBDMMOUAppDefaultValues () {
        return this._http.get('/api/customer-creations/get-default-data', this.setRequestHeader())
            .toPromise();
    }

    async createBDMCustomer ( customerObject ) {
        return this._http.post('/api/customer-creations/create-customer', { customerObject }, this.setRequestHeader())
            .toPromise();
    }

    async uploadPCPNDTLicense ( formData ) {
        return this._http.post('/api/customer-creations/upload-pcpndt-license', formData, this.setRequestHeader()).toPromise();
    }

    async updateBDMCustomer ( customerId, customerUpdateData ) {
        return this._http.post('/api/customer-creations/update-customer', {
            customerId,
            customerUpdateData
        }, this.setRequestHeader())
            .toPromise();
    }

    async uploadCustomerSignatures ( formData ) {
        return this._http.post('/api/customer-creations/upload-customer-signature', formData, this.setRequestHeader()).toPromise();
    }

    async getNewBDMCustomersData () {
        return this._http.get('/api/customer-creations/get-new-customers-data', this.setRequestHeader())
            .toPromise();
    }

    async fetchBDMCustomerById ( customerId ) {
        return this._http.post('/api/customer-creations/fetch-customer-by-id', { customerId }, this.setRequestHeader()).toPromise();
    }

    async approveCustomerAppilcation ( customerObject, mouLink ) {
        return this._http.post('/api/customer-creations/approve-customer', {
            customerObject,
            mouLink
        }, this.setRequestHeader()).toPromise();
    }

    async rejectBDMCustomer ( rejectionReason, customer ) {
        return this._http.post('/api/customer-creations/mark-rejected', {
            rejectionReason,
            customer
        }, this.setRequestHeader())
            .toPromise();
    }

    async downloadCustomerData(){
        return this._http.get('/api/customer-creations/download-customer-data', this.setRequestHeader())
            .toPromise();
    }

    async downloadCustomerDetails( customerId ){
        return this._http.post('/api/customer-creations/download-customer-details', {customerId}, this.setRequestHeader())
            .toPromise();
    }

    
    async fetchFinancePaymentHoldNotification () {
        return this._http.get('/api/notification/fetch-finance-payment-hold-notification', this.setRequestHeader()).toPromise();
    }

    async dismissFinancePaymentHoldNotification ( notificationId ) {
        return this._http.post('/api/notification/dismiss-finance-payment-hold-notification', { notificationId }, this.setRequestHeader()).toPromise();
    }

    async sendMailToGCExpert ( gcExpertForm, previewOnly = false ) {
        return this._http.post('/api/reports/mail-to-gc-expert', { gcExpertForm, previewOnly }, this.setRequestHeader())
            .toPromise();
    }

    async fetchJamsifyCustomers () {
        return this._http.get('/api/jamsify-dashboard/fetch-customers', this.setRequestHeader())
            .toPromise();
    }

    async fetchJamsifyWhatsappRecords ( pageno ) {
        console.log(pageno);
        return this._http.post('/api/jamsify-dashboard/fetch-whatsapp-logs', { pageno }, this.setRequestHeader())
            .toPromise();
    }

    async fetchJamsifyWhatsappLogStatistics ( statisticsRange ) {
        return this._http.post('/api/jamsify-dashboard/fetch-whatsapp-logs-statistics', { statisticsRange }, this.setRequestHeader())
            .toPromise();
    }

    async getPaymentReceipts ( form ) {
        return this._http.post('/api/payments/listing', form, this.setRequestHeader())
            .toPromise();
    }

    async reSyncPaymentReceipts ( rowId ) {
        return this._http.post('/api/payments/reSync/' + rowId, {}, this.setRequestHeader())
            .toPromise()
    }

    async reSyncPaymentReceiptBarcodeWise ( barcode ) {
        return this._http.post('/api/payments/reSync/barcode-wise', {'barcode':barcode, 'transaction':'multiple',}, this.setRequestHeader())
            .toPromise()
    }

    async markPaymentReceipt ( rowId ) {
        return this._http.post('/api/payments/receipt/' + rowId, {}, this.setRequestHeader())
            .toPromise()
    }

    async updatePaymentBarcode ( rowId, barcode ) {
        return this._http.post('/api/payments/updateBarcode/' + rowId, { barcode }, this.setRequestHeader())
            .toPromise()
    }

    async verifyPaymentReceipts ( rowId, remarks ) {
        return this._http.post('/api/payments/verify/' + rowId, {remarks}, this.setRequestHeader())
            .toPromise()
    }

    async downloadCollectionAppData(form){
        return this._http.post('/api/payments/download-collection-data', form, this.setRequestHeader())
            .toPromise();
    }

    async convertJamsifyCustomer ( customerId, action, dataType = null ) {
        return this._http.post('/api/jamsify-dashboard/convert-jamsify-customer', {
            customerId,
            action,
            dataType
        }, this.setRequestHeader())
            .toPromise();
    }

    async getOutstandingBalanceData ( pageno ) {
        return this._http.post('/api/outstanding-balance-query/fetch-patient-wise', { pageno }, this.setRequestHeader())
            .toPromise();
    }

    async filterByCustomer ( customer ) {
        return this._http.post('/api/outstanding-balance-query/fetch-customer-wise', { customer }, this.setRequestHeader())
            .toPromise();
    }

    async filterByBDM ( bdm ) {
        return this._http.post('/api/outstanding-balance-query/fetch-bdm-wise', { bdm }, this.setRequestHeader())
            .toPromise();
    }

    async getUnipathReportData ( barcode ) {
        return this._http.post('/api/sample/unipath-report-data', { barcode }, this.setRequestHeader()).toPromise();
    }

    async extractDataFromAttachement ( barcode, attachment ) {
        return this._http.post('/api/reports/extract-data-from-pdf', {
            barcode,
            attachment
        }, this.setRequestHeader()).toPromise();
    }

    async checkForExistingActiveSession(previewParams){
        return this._http.post('/api/reportSessions/checkForExistingActiveSessions', {previewParams}, this.setRequestHeader()).toPromise();
    }

    async createSession(previewParams){
        return this._http.post('/api/reportSessions/createSession', {previewParams}, this.setRequestHeader()).toPromise();
    }

    async extendReportSession(previewParams, currentSessionDetails){
        return this._http.post('/api/reportSessions/extendSession', {previewParams, currentSessionDetails}, this.setRequestHeader()).toPromise();
    }

    async terminateSession(previewParams, currentSessionDetails){
        return this._http.post('/api/reportSessions/terminateSession', {previewParams, currentSessionDetails}, this.setRequestHeader()).toPromise();
    }

    async takeOverSession(previewParams, currentSessionDetails){
        return this._http.post('/api/reportSessions/takeOverSession', {previewParams, currentSessionDetails}, this.setRequestHeader()).toPromise();
    }

    namePartsFromName ( fullname ) {
        const nameParts = fullname.split(" ");
        let firstname = "";
        let lastname = "";

        if ( nameParts.length === 1 ) {
            firstname = nameParts.join(" ");
        } else if ( nameParts.length > 1 ) {
            lastname = nameParts.slice(-1).join(" ");
            firstname = nameParts.slice(0, nameParts.length - 1).join(" ");
        }

        return { firstname, lastname };
    }
}
