<div class="container-fluid">
    <div class="row p-2">
        <div class="col-md-12 horizontal-flex space-between-center">
            <h2 class="min-width-300">{{ this.editingType ? ('Editing ' + this.editingType) : 'Customers listing' }}</h2>

            <div class="flex-filler"></div>

            <button class="btn btn-basic" *ngIf="loading">Loading ...</button>

            <nav class="mr-4" aria-label="Page navigation example" [hidden]="loading || editing || reviewing">
                <ul class="pagination">
                    <li class="page-item " [ngClass]="{'disabled': customerPagination.current === 1}">
                        <a class="page-link" aria-label="Previous" (click)="loadCustomerListingWithPage(customerPagination.current - 1)">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>

                    <li class="page-item" [ngClass]="{'active': customerPagination.current === i}" *ngFor="let i of _util.getPagesRange(customerPagination.current, customerPagination.max)">
                        <a class="page-link" (click)="loadCustomerListingWithPage(i)">{{ i }}</a>
                    </li>

                    <li class="page-item" [ngClass]="{'disabled': customerPagination.current === customerPagination.max}">
                        <a class="page-link" aria-label="Next" (click)="loadCustomerListingWithPage(customerPagination.current + 1)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>

            <button class="btn btn-primary mr-1" (click)="beginReviewing()" *ngIf="editing && updatedFields.length">
                Review changes
            </button>

            <button class="btn btn-danger mr-1" (click)="discardAllAndGoBack()" *ngIf="editing">
                {{ updatedFields.length ? 'Discard all' : 'Close' }}
            </button>

            <button class="btn btn-secondary mr-1" [hidden]="editing || reviewing" (click)="_util.redirectTo('/dashboard')">Return to dashboard</button>
            <button class="btn btn-primary mr-1" [hidden]="editing || reviewing" (click)="refreshCustomerListing()">Refresh</button>
            <button class="btn btn-info mr-1" [hidden]="editing || reviewing" (click)="_util.logout()">{{ _util.logoutButtonText || "Logout" }}</button>
        </div>

        <div class="col-md-12 horizontal-flex space-between-center mt-2" style="min-height: 40px;">
            <button type="button" class="btn btn-secondary btn-sm" *ngIf="reviewing" (click)="reviewing = false; editing = true;">
                &lt; go back</button>

            <button type="button" class="btn btn-secondary btn-sm" *ngIf="showingDoctor"
                    [hidden]="this.editing || this.reviewing || this.loading"
                    (click)="toggleViewingDoctor(null)">
                &lt; Go back / View all customers
            </button>

            <select class="custom-select max-width-328 mr-2"
                    name="HOName-filter"
                    [hidden]="this.editing || this.reviewing || this.loading || this.showingDoctor"
                    [(ngModel)]="chosenHOLocation"
                    (change)="refreshCustomerListing()">
                <option selected value="">Filter by HO location</option>
                <option *ngFor="let option of uniqueHOLocations" [value]="option">{{ option }}</option>
            </select>

            <div class="input-group mr-4 max-width-500 mr-2" [hidden]="this.editing || this.reviewing || this.loading || this.showingDoctor">
                <input type="search" class="form-control" placeholder="Search customers " [(ngModel)]="searchQuery"
                       aria-label="Search in your customers by name" aria-describedby="Customer search">
                <div class="input-group-append" id="button-addon4">
                    <button class="btn btn-secondary" type="button" (click)="refreshCustomerListing()">
                        <i class="zmdi zmdi-search"></i>
                    </button>
                    <button class="btn btn-outline-secondary" type="button" (click)="resetSearch()">
                        <i class="zmdi zmdi-close"></i>
                    </button>
                </div>
            </div>


            <div class="horizontal-flex center-center ml-1 mr-1 min-width-500"
                 [hidden]="this.editing || this.reviewing || this.loading || this.showingDoctor">
                <div class="vertical-flex center-end mr-1">
                    <span class="bold">Customers: </span>
                    <span class="bold">Doctors: </span>
                </div>

                <div class="vertical-flex">
                    <div class="horizontal-flex start-center">
                        <span class="green"><i class="zmdi zmdi-shield-check green ml-1"></i> {{ stats.customer.verified }} Verified &nbsp;&nbsp;</span>
                        <span class="yellow"><i class="zmdi zmdi-hourglass yellow ml-1"></i> {{ stats.customer.all - stats.customer.touched }} Pending &nbsp;&nbsp;</span>
                        <span class="gray hidden"><i class="zmdi zmdi-hourglass gray ml-1"></i> {{ stats.customer.underReview }} Reviewing &nbsp;&nbsp;</span>
                    </div>

                    <div class="horizontal-flex start-center">
                        <span class="green"><i class="zmdi zmdi-shield-check green ml-1"></i> {{ stats.doctor.verified }} Verified &nbsp;&nbsp;</span>
                        <span class="yellow"><i class="zmdi zmdi-hourglass yellow ml-1"></i> {{ stats.doctor.all - stats.doctor.touched }} Pending &nbsp;&nbsp;</span>
                        <span class="gray hidden"><i class="zmdi zmdi-hourglass gray ml-1"></i> {{ stats.doctor.underReview }} Reviewing &nbsp;&nbsp;</span>
                    </div>
                </div>
            </div>

            <div class="flex-filler"></div>

            <p class="mb-2 min-width-300 right-align">Logged in as: <strong>{{ _util.loggedInUserName() }}</strong></p>
        </div>

        <div class="col-md-12 vertical-flex start-center" *ngIf="editing">
            <h4 class="thin fill-width">{{ this.editingLabel }}</h4>

            <div class="alert alert-success fill-width p-1 mt-1 pr-2 horizontal-flex end-center" role="alert" *ngIf="workingObject.verified">
                {{ getTitleForVerificationEntity(workingObject) }}
            </div>

            <div class="alert alert-light fill-width p-1 mt-1 pr-2 horizontal-flex end-center" role="alert" [hidden]="workingObject.verified">
                All details for this {{editingType}} seems okay?
                <button class="btn btn-xs btn-primary ml-2" (click)="markVerified()">Mark verified</button>
            </div>

            <form autocomplete="off">
                <div class="vertical-flex end-center mt-4">
                    <div class="form-group width-90 horizontal-flex space-between-center mb-0">
                        <label class="width-30">&nbsp;</label>
                        <p class="centered width-30"><strong>LIMS copy</strong></p>
                        <p class="centered width-30"><strong>Your copy</strong></p>
                    </div>

                    <div class="form-group width-90 horizontal-flex space-between-start" *ngFor="let field of EditableFields">
                        <label class="mr-2 mb-0 width-40 right-align" [for]="field"
                               [hidden]="this.HiddenFields.indexOf(field) > -1"
                        >{{ FieldLabels[field] + ((OptionalFields.indexOf(field) > -1) ? ' (Optional)' : '') }}&nbsp;:</label>
                        <input class="form-control max-width-500 width-25"
                               placeholder="" type="text" tabindex="-1"
                               [title]="'LIMPs copy of ' + field"
                               [value]="getValueForField(field)"
                               [hidden]="this.HiddenFields.indexOf(field) > -1"
                               [id]="'backup.' + field" [readOnly]="true"
                        >

                        <select class="form-control width-25 max-width-500" aria-label="Value filled with label"
                                *ngIf="SelectionBoxLabels.indexOf(field) > -1"
                                [(ngModel)]="workingObject[field]"
                                [title]="'Your copy of ' + field"
                                [ngClass]="{'updated': updatedFields.indexOf(field) > -1}"
                                (change)="addUpdatedField(field, $event)"
                                [name]="field"
                                [value]="workingObject[field]"
                                [id]="field"
                        >
                            <option value="" [selected]="[false, 0, '', null, undefined].indexOf(workingObject[field]) > -1">Select option</option>
                            <option *ngFor="let row of SelectionBoxOptions[field] | keyvalue" [value]="row.key">{{ row.value }}</option>
                        </select>

                        <select class="form-control width-25 max-width-500" aria-label="Value filled with label"
                                *ngIf="BooleanFields.indexOf(field) > -1"
                                [title]="'Your copy of ' + field"
                                [ngClass]="{'updated': updatedFields.indexOf(field) > -1}"
                                (change)="addUpdatedField(field, $event)"
                                [id]="field"
                        >
                            <option value="0" [selected]="[false, 0, '0', null, undefined].indexOf(workingObject[field]) > -1">No</option>
                            <option value="1" [selected]="[true, 1, '1'].indexOf(workingObject[field]) > -1">Yes</option>
                        </select>

                        <div class="vertical-flex max-width-500 width-25" *ngIf="SemicolonSeperated.indexOf(field) > -1">
                            <input type="text" class="form-control"
                                   *ngFor="let value of SemicolonSeperatedValues[field]; let i = index"
                                   [ngClass]="{'updated': updatedFields.indexOf(field) > -1}"
                                   (change)="updateSemicolonSeperatedValue(field, $event, i)"
                                   (blur)="updateSemicolonSeperatedValue(field, $event, i)"
                                   [value]="SemicolonSeperatedValues[field][i]"
                                   [name]="field + '-' + i"
                                   autocomplete="off" list="autocompleteOff"
                            >

                            <button type="button" class="btn btn-light" (click)="SemicolonSeperatedValues[field].push('')">+ more</button>
                        </div>

                        <input class="form-control max-width-500 width-25"
                               *ngIf="MobileNumberFields.indexOf(field) > -1"
                               placeholder="Mobile no." type="number" min="1111111111" max="9999999999"
                               onwheel="this.blur()"
                               [title]="'Your copy of ' + field" [id]="field"
                               [ngClass]="{'updated': updatedFields.indexOf(field) > -1}"
                               [hidden]="this.HiddenFields.indexOf(field) > -1"
                               [value]="undefinedOrEmpty(workingObject[field])"
                               (keyup)="addUpdatedField(field, $event)"
                               (keydown)="onlyAllowNumericValues($event, field)"
                               (change)="addUpdatedField(field, $event)"
                               [disabled]="DisabledFields.indexOf(field) > -1"
                        >

                        <input class="form-control max-width-500 width-25"
                               *ngIf="(BooleanFields.indexOf(field) === -1) && (SelectionBoxLabels.indexOf(field) === -1) && (SemicolonSeperated.indexOf(field) === -1) && (MobileNumberFields.indexOf(field) === -1)"
                               placeholder="" [type]="DatetimeFields.indexOf(field) > -1 ? 'datetime-local' : ( DateFields.indexOf(field) > -1 ? 'date' : 'text')"
                               [title]="'Your copy of ' + field" [id]="field"
                               [ngClass]="{'updated': updatedFields.indexOf(field) > -1}"
                               [hidden]="this.HiddenFields.indexOf(field) > -1"
                               [value]="undefinedOrEmpty(workingObject[field])"
                               (keyup)="addUpdatedField(field, $event)"
                               (change)="addUpdatedField(field, $event)"
                               [disabled]="DisabledFields.indexOf(field) > -1"
                        >
                    </div>
                </div>
            </form>
        </div>

        <div class="col-md-12 vertical-flex start-center" *ngIf="reviewing">
            <p class="fill-width">
                Review your changes
            </p>

            <table class="table table-sm comparison-table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Field</th>
                    <th scope="col">Old</th>
                    <th scope="col">New</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let field of updatedFields; index as i"
                    [ngClass]="{'table-success': acceptedFields.indexOf(field) > -1, 'table-danger': rejectedFields.indexOf(field) > -1}">
                    <th scope="row">{{ i + 1}}</th>
                    <td>{{ FieldLabels[field] }}&nbsp;</td>
                    <td>{{ this.getValueForField(field) }}</td>
                    <td>{{ this.getValueForField(field, true) }}</td>
                    <td>
                        <div class="btn btn-success btn-sm mr-1" (click)="acceptField(field)"><i class="zmdi zmdi-check"></i> Accept</div>
                        <div class="btn btn-danger btn-sm" (click)="rejectField(field)"><i class="zmdi zmdi-close"></i> Reject</div>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="horizontal-flex start-center mt-4 fill-width">
                <button class="mr-2 btn btn-primary" *ngIf="(rejectedFields.length + acceptedFields.length) === updatedFields.length && acceptedFields.length" (click)="raisePatchRequest()">Raise patch request</button>
                <button class="mr-2 btn btn-primary" *ngIf="rejectedFields.length && ! acceptedFields.length" (click)="acceptRemainingFields()">Accept remaining</button>
                <button class="mr-2 btn btn-success" *ngIf="! rejectedFields.length && (acceptedFields.length !== updatedFields.length)" (click)="acceptAll()">Accept all</button>
                <button class="mr-2 btn btn-danger" *ngIf="! acceptedFields.length && (updatedFields.length !== rejectedFields.length)" (click)="discardAll()">Discard all</button>
                <button class="mr-2 btn btn-danger" *ngIf="rejectedFields.length === updatedFields.length" (click)="discardAllAndGoBack()">Close</button>
            </div>

        </div>

        <div class="col-md-12 mt-4" *ngIf="loading">
            <div class="loader"></div>
        </div>

        <div class="col-md-12 mt-2" [hidden]="editing || reviewing">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col" class="horizontal-flex space-between-center">
                        <span>Customer name</span>

                        <select class="custom-select custom-select-sm" name="verification-filter-value"
                                style="max-width: 192px;"
                                (change)="refreshCustomerListing()"
                                [(ngModel)]="verificationFilterValue">
                            <option selected value="">Filter by verifying status</option>
                            <option value="verified">Fully verified customers & doctors</option>
                            <option value="half-verified">Verified customers</option>
                            <option value="under-review" hidden>Under review only</option>
                            <option value="un-verified">Un-verified any customer or doctor</option>
                            <option value="un-verified-customers">Un-verified customers</option>
                            <option value="un-verified-doctors">Un-verified doctors</option>
                            <option value="pending">Pending customers or doctors</option>
                            <option value="pending-customers">Pending customers</option>
                            <option value="pending-doctors">Pending doctors</option>
                        </select>
                    </th>
                    <th scope="col">Doctors</th>
                    <th scope="col">Last sample on</th>
                    <th scope="col">Executive name</th>
                    <th scope="col">Location</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let customer of customers; index as i" [hidden]="showingDoctor && (doctorCustomer['identifier'] !== customer.identifier)">
                        <th class="min-width-104" scope="row">{{ customer.identifier }}</th>
                        <td class="link" [class.disabled]="disabledIdentifiers.indexOf(customer.identifier) > -1"
                            (click)="beginEditing(customer, 'customer')">{{ customer.name }}

                            <i class="zmdi zmdi-shield-check green ml-1" *ngIf="customer.selfVerified"
                               data-toggle="tooltip" data-placement="top" [title]="getTitleForVerificationEntity(customer)"
                            ></i>
                            <i class="zmdi zmdi-hourglass yellow ml-1" *ngIf="isEntityUnderReview(customer)"
                               data-toggle="tooltip" data-placement="top" title="Entity Under review, in JARVIS portal"
                            ></i>
                        </td>
                        <td class="link" (click)="toggleViewingDoctor(customer)">
                            {{ customer.doctorCount }}
                            <i class="zmdi zmdi-caret-down" *ngIf="! showingDoctor"></i>
                            <i class="zmdi zmdi-caret-up" *ngIf="showingDoctor"></i>

                            <i class="zmdi zmdi-shield-check green ml-1" *ngIf="customer.doctorsVerified"
                               data-toggle="tooltip" data-placement="top" title="All doctors for customer verified, see doctor listing for more."
                            ></i>
                            <i class="zmdi zmdi-hourglass yellow ml-1" *ngIf="customer.doctorsUnderReview"
                               data-toggle="tooltip" data-placement="top" title="All doctors for customer are either verified or under-review."
                            ></i>
                        </td>
                        <td class="min-width-178">{{ _util.niceDateFormat(customer.lastPatientDate) }}</td>
                        <td>{{ customer.executive.name }}</td>
                        <td>{{ customer.executive.locationName }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table table-sm ml-4" *ngIf="showingDoctor && doctors.length">
                <thead>
                <tr>
                    <td>ID</td>
                    <td>Doctor name</td>
                    <td>Designation</td>
                    <td>Location</td>
                    <td>e-mail</td>
                    <td>Actions</td>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let doctor of doctors; index as i">
                    <td class="min-width-104">{{ doctor.Atype + '-' + doctor.CenterID + '-' + doctor.code }}</td>
                    <td class="link"
                        [class.disabled]="disabledIdentifiers.indexOf(doctor.Atype + '-' + doctor.CenterID + '-' + doctor.code) > -1"
                        (click)="beginEditing(doctor, 'doctor')">
                        {{ doctor.pname }}
                        <i class="ml-1 zmdi zmdi-shield-check green" *ngIf="doctor.verified"
                           data-toggle="tooltip" data-placement="top" [title]="getTitleForVerificationEntity(doctor)"
                        ></i>
                        <i class="ml-1 zmdi zmdi-hourglass yellow" *ngIf="isEntityUnderReview(doctor)"
                           data-toggle="tooltip" data-placement="top" title="Entity Under review, in JARVIS portal"
                        ></i>
                        <i class="ml-1 zmdi zmdi-minus-circle red" *ngIf="isUnlinkingIdentifier(doctor)"
                           data-toggle="tooltip" data-placement="top" title="Entity's unlink request is under review, in JARVIS portal"
                        ></i>
                    </td>
                    <td>{{ doctor.desi }}</td>
                    <td>{{ doctor.city }}</td>
                    <td>{{ doctor.email }}</td>
                    <td>
                        <button type="button"
                                [class.disabled]="disabledIdentifiers.indexOf(doctor.Atype + '-' + doctor.CenterID + '-' + doctor.code) > -1"
                                class="btn btn-sm" [ngClass]="isDoctorUnlinked(doctor) ? 'btn-secondary' : 'btn-danger'" (click)="unlinkThisDoctor(doctor)">Unlink</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
