import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessionFormComponent } from "./accession-form/accession-form.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageTatComponent } from './manage-tat/manage-tat.component';
import { ReportPrintComponent } from './report-print/report-print.component';
import { PreviewReportComponent } from './preview-report/preview-report.component';
import { ReportDropboxComponent } from './report-dropbox/report-dropbox.component';
import { DownloadBiochemComponent } from "./download-biochem/download-biochem.component";
import { JarvisHomeComponent } from "./jarvis-home/jarvis-home.component";
import { SalesCustomerListingComponent } from "./sales-customer-listing/sales-customer-listing.component";
import { PatchRequestsManagementComponent } from "./patch-requests-management/patch-requests-management.component";
import { PrintDashboardComponent } from './print-dashboard/print-dashboard.component';
import { AdministrationControllerComponent } from "./administration-controller/administration-controller.component";
import { EditReportComponent } from "./edit-report/edit-report.component";
import { CustomerCreationComponent } from "./customer-creation/customer-creation.component";
import {
    CustomerApprovalDashboardComponent
} from "./customer-approval-dashboard/customer-approval-dashboard.component";
import { PreviewMouComponent } from "./preview-mou/preview-mou.component";
import { JamsifyDashboardComponent } from "./jamsify-dashboard/jamsify-dashboard.component";
import {
    OutstandingBalanceDashboardComponent
} from "./outstanding-balance-dashboard/outstanding-balance-dashboard.component";
import { PaymentReceiptDashboardComponent } from "./payment-receipt-dashboard/payment-receipt-dashboard.component";

const routes: Routes = [
    { path: 'accession-form', component: AccessionFormComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'login', component: LoginComponent },
    { path: 'print-report', component: ReportPrintComponent },
    { path: 'printing-pending/:location', component: PrintDashboardComponent },
    { path: 'printing-pending', component: PrintDashboardComponent },
    { path: 'print-confirming-pending/:location', component: PrintDashboardComponent },
    { path: 'print-confirming-pending', component: PrintDashboardComponent },
    { path: 'preview-report', component: PreviewReportComponent },
    { path: 'manage-users', component: ManageUsersComponent },
    { path: 'manage-tats', component: ManageTatComponent },
    { path: 'report-dropbox', component: ReportDropboxComponent },
    { path: 'download-biochem', component: DownloadBiochemComponent },
    { path: 'administration', component: AdministrationControllerComponent },
    { path: 'jarvis-home', component: JarvisHomeComponent },
    { path: 'customer-details', component: SalesCustomerListingComponent },
    { path: 'patch-requests', component: PatchRequestsManagementComponent },
    { path: 'customer-creation', component: CustomerCreationComponent },
    { path: 'customer-approval-dashboard', component: CustomerApprovalDashboardComponent },
    { path: 'preview-mou', component: PreviewMouComponent },
    { path: 'edit-report/:barcode/:testcode', component: EditReportComponent },
    { path: 'jamsify-dashboard', component: JamsifyDashboardComponent },
    { path: 'outstanding-balance-dashboard', component: OutstandingBalanceDashboardComponent },
    { path: 'payment-receipts', component: PaymentReceiptDashboardComponent },
    { path: 'payment-receipts/:barcode', component: PaymentReceiptDashboardComponent },
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
